import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import moment from 'moment';

const ShowEmployeeTasks = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const email = sessionStorage.getItem('email'); // Get email from session storage
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);
  let isMGEmployee=null;
  if(email){
     isMGEmployee =
    employeeData &&
    employeeData.empid &&
    typeof employeeData.empid === 'string' &&
    employeeData.empid.startsWith('MG');}
    else{
      isMGEmployee=null;
    }

  useEffect(() => {
    const fetchemployeetasks = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showemployeetasks');
        setUsers(groupTasks(response.data));
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching users!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchemployeetasks();
  }, []);

  const groupTasks = (users) => {
    
    const groupedData = users.reduce((acc, user) => {
      const key = `${user.empid}-${user.clientid}-${user.mainTask}-${user.remarks || '-'}`;
      if (!acc[key]) {
        acc[key] = {
          ID: user.ID[1],
          empid: user.empid[0],
          FirstName: user.FirstName,
          LastName: user.LastName,
          clientid: user.clientid,
          TradeName: user.TradeName,
          mainTask: user.mainTask ? user.mainTask : '-',
          taskname: [user.taskname],
          AssignedBy: user.AssignedBy,
          deadline: user.deadline ? moment(user.deadline).format('DD-MM-YYYY') : '-',
          remarks: user.remarks || '-',
        };
      } else {
        acc[key].taskname.push(user.taskname);
      }

      return acc;
    }, {});

    return Object.values(groupedData);
  };

  const handleDelete = (row) => {
    // console.log(row.ID);
    
    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete this task for employee ${row.empid}. This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // console.log(row.ID);
          
          // Make a POST request to delete the task from the backend
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteAssignTask', {
            taskId: row.ID // Sending taskId in the request body
          }, {
            headers: {
              'Content-Type': 'application/json' // Ensure JSON content type
            }
          });

          Swal.fire('Deleted!', 'The task has been deleted.', 'success').then(() => {
            // Reload the page after deletion
            window.location.reload();
          });
        } catch (error) {
          Swal.fire('Error', 'Failed to delete the task!', 'error');
        }
      }
    });
};

  

const columns = useMemo(
  () => [
    { Header: 'TM ID', accessor: 'empid' },
    { Header: 'TM Name', accessor: (row) => `${row.FirstName} ${row.LastName}` },
    { Header: 'CC', accessor: 'clientid' },
    { Header: 'Trade Name', accessor: 'TradeName' },
    { Header: 'Main Task', accessor: 'mainTask' },
    { Header: 'Sub Task', accessor: (row) => row.taskname.join(', ') },
    { Header: 'Remarks', accessor: (row) => row.remarks || '-' },
    { Header: 'Deadline', accessor: 'deadline' },
    { Header: 'Assigned By', accessor: 'AssignedBy' },
    ...(isMGEmployee
      ? [
          {
            Header: 'Action',
            Cell: ({ row }) => (
              <button className="btn btn-danger" onClick={() => handleDelete(row.original)}>
                Delete
              </button>
            ),
          },
        ]
      : []),
  ],
  [isMGEmployee] // Add isMGEmployee to dependencies
);


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0, pageSize: 5 }, // Default page size
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <div className='container'>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <p className='para-heading mt-5'>Assignments</p>
          <input
            type="text"
            placeholder="Search..."
            className="form-control mb-3"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div className="table-responsive">
            <table {...getTableProps()} className='table table-striped'>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Pagination controls */}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </button>
              <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                className="form-select"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 15, 20].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShowEmployeeTasks;
