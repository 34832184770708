// TransporterDetailsModal.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const TransporterDetailsModal = ({ CC }) => {
  const [transporters, setTransporters] = useState([]);

  useEffect(() => {
    const fetchTransporters = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchTransporters', { CC });
        setTransporters(response.data);
        console.log(transporters,'transportes');
        
      } catch (error) {
        console.error('Error fetching transporters:', error);
      }
    };

    fetchTransporters();
  }, [CC]);

  const renderTransportersTable = () => {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>CC</th>
            <th>Transporter Name</th>
            <th>Trade Name</th>
            <th>gstin</th>
            <th>address</th>
          </tr>
        </thead>
        <tbody>
          {transporters.length > 0 ? (
            transporters.map((transporter, index) => (
              <tr key={index}>
                <td>{transporter.CC}</td>
                <td>{transporter.transporterName}</td>
                <td>{transporter.TradeName}</td>
                <td>{transporter.gstin}</td>
                <td>{transporter.address}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">No transporters found</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {renderTransportersTable()}
    </div>
  );
};

export default TransporterDetailsModal;
