import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import AdminDashboard from './components/adminDashboard'; 
import ClientDashboard from './components/clientDashboard';
import UserDashboard from './components/userDashboard';
import ForgotPassword from './components/forgotPassword';
import SessionWrapper from './components/SessionWrapper';
import TimesheetPreviewFixed from './components/timesheetPreviewFixed';
import TimesheetPreviewFloating from './components/timesheetPreviewFloating';

const App = () => {
  
  return (
    <Router>
      <SessionWrapper/>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/adminDashboard/*" element={<AdminDashboard />} />
        <Route path="/userDashboard/*" element={<UserDashboard />} />
        <Route path="/clientDashboard/*" element={<ClientDashboard />} /> 
        <Route path='/employeetimesheetfixed/:id' element={<TimesheetPreviewFixed/>} />
        <Route path='/employeetimesheetfloating/:id' element={<TimesheetPreviewFloating/>} />
      </Routes>
    </Router>
  );
};

export default App;