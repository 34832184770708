import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash, faSync } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
const Swal = require('sweetalert2');

library.add(faFilter, faEyeSlash, faSync);

const UserMyClients = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState({
    empid:true,
    CC: true,
    ClientName: true,
    TradeName: true,
    PAN: true,
    GSTID: true,
    GSTPass: true,
    EwayID: true,
    EwayPass: false,
    IRNID: false,
    IRNPass: false,
    ITID: false,
    ITPass: false,
    TAN: false,
    TracesID: false,
    TracesPass: false,
  });
  const [refresh, setRefresh] = useState(false); // State to trigger re-fetch

  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchUserClientRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/usermyclientdetails', { email });
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching client details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserClientRequests();
  }, [email, refresh]); // Add refresh as a dependency

  useEffect(() => {
    if (requests.some(request => request.access === 1)) {
      const timers = requests
        .filter(request => request.access === 1)
        .map(request =>
          setTimeout(async () => {
            // Update access status to 0 after 300 seconds
            try {
              await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateaccess', { email, clientId: request.CC,access:null });
              setRefresh(prevRefresh => !prevRefresh); // Trigger data re-fetch
            } catch (error) {
              console.error('Error updating access status:', error);
            }
          }, 300000) // 300 seconds - 5 mins
        );

      return () => timers.forEach(timer => clearTimeout(timer));
    }
  }, [requests, email]);

  const handleColumnChange = (e) => {
    setSelectedColumns({
      ...selectedColumns,
      [e.target.name]: e.target.checked
    });
  };

  const handleViewAccess = (request) => {
    axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateaccess', { email, clientId: request.CC , access:0})
      .then(response => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Request Sent ',
          showConfirmButton: false,
          timer: 3000
        });
        setRefresh(prevRefresh => !prevRefresh); // Toggle refresh to trigger data re-fetch
      })
      .catch(error => {
        console.error('Error sending request to admin:', error);
      });
  };

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh); // Toggle refresh to re-fetch data
  };

  return (
    <div className="container mt-5" style={{ marginLeft: '1px', maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <p className='para-heading'>My Clients</p>
        <div className="d-flex align-items-center">
          <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button>
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faFilter} /> Filter Columns
            </button>
            <ul className="dropdown-menu" aria-labelledby="filterDropdown">
              {Object.keys(selectedColumns).map((column) => (
                <li key={column} className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={column}
                      checked={selectedColumns[column]}
                      onChange={handleColumnChange}
                      id={`checkbox-${column}`}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                      {column}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        requests.length === 0 ? (
          <p>No Clients found.</p>
        ) : (
          <table className="table table-striped">
            <thead style={{fontSize:'15px',verticalAlign:'baseline'}}>
              <tr>
              {selectedColumns.empid && <th>Team Member ID</th>}
                {selectedColumns.CC && <th>Client Code</th>}
                {selectedColumns.ClientName && <th>Client Name</th>}
                {selectedColumns.TradeName && <th>Trade Name</th>}
                {selectedColumns.PAN && <th>PAN</th>}
                {selectedColumns.GSTID && <th>Gst ID</th>}
                {selectedColumns.GSTPass && <th>GST Pass</th>}
                {selectedColumns.EwayID && <th>EWAY ID</th>}
                {selectedColumns.EwayPass && <th>EWAY Pass</th>}
                {selectedColumns.IRNID && <th>IRN ID</th>}
                {selectedColumns.IRNPass && <th>IRN Pass</th>}
                {selectedColumns.ITID && <th>IT ID</th>}
                {selectedColumns.ITPass && <th>IT Pass</th>}
                {selectedColumns.TAN && <th>TAN</th>}
                {selectedColumns.TracesID && <th>Traces ID</th>}
                {selectedColumns.TracesPass && <th>Traces Pass</th>}
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{fontSize:'14px',verticalAlign:'baseline'}}>
              {requests.map((request, index) => (
                <tr key={index}>
                    {selectedColumns.empid && <td>{request.empid[0]}</td>}
                  {selectedColumns.CC && (
                    <td>
                      {request.CC}
                    </td>
                  )}
                  {selectedColumns.ClientName && <td>{request.ClientName}</td>}
                  {selectedColumns.TradeName && <td>{request.TradeName}</td>}
                  {selectedColumns.PAN && <td>{request.PAN}</td>}
                  {selectedColumns.GSTID && (
                    <td>
                      {request.access === 1 ? request.GSTID : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.GSTPass && (
                    <td>
                      {request.access === 1 ? request.GSTPass : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.EwayID && (
                    <td>
                      {request.access === 1 ? request.EwayID : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.EwayPass && (
                    <td>
                      {request.access === 1 ? request.EwayPass : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.IRNID && (
                    <td>
                      {request.access === 1 ? request.IRNID : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.IRNPass && (
                    <td>
                      {request.access === 1 ? request.IRNPass : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.ITID && (
                    <td>
                      {request.access === 1 ? request.ITID : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.ITPass && (
                    <td>
                      {request.access === 1 ? request.ITPass : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.TAN && <td>{request.TAN}</td>}
                  {selectedColumns.TracesID && (
                    <td>
                      {request.access === 1 ? request.TracesID : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  {selectedColumns.TracesPass && (
                    <td>
                      {request.access === 1 ? request.TracesPass : <FontAwesomeIcon icon={faEyeSlash} />}
                    </td>
                  )}
                  <td>
                    <button className="btn btn-success" onClick={() => handleViewAccess(request)}>
                      Get View Access
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default UserMyClients;
