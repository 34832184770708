import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip'; // Correct import
import { faEdit, faTrash,faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import DateRangePickerComponent from './CustomDateRangePicker'
const Swal = require('sweetalert2');

const CompletedTaskForAdmin = () => {
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [clientNames, setClientNames] = useState({});
  const navigate = useNavigate();
  // Fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const TimeDisplay = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const getClient = async (client) => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getClientByCode', {
        params: {
          client: client,
        },
      });

      if (response.data.length > 0) {
        return response.data[0].TradeName;
      } else {
        return 'Client not found';
      }
    } catch (error) {
      console.error('Error fetching client:', error);
      return 'Error fetching client';
    }
  };

  const fetchTasks = async () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                console.error("Please select a valid date range");
                return;
            }
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/completedDayTasksByAdmin', {
        empid: selectedUser,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
      const tasks = response.data;
      setTasks(tasks);

      const clientNamesTemp = {};
      for (const task of tasks) {
        const tradeName = await getClient(task.CC);
        clientNamesTemp[task.CC] = tradeName;
      }
      setClientNames(clientNamesTemp);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const groupTasksByMid = (tasks) => {
    return tasks.reduce((acc, task) => {
      const { mid } = task;
      if (!acc[mid]) {
        acc[mid] = [];
      }
      acc[mid].push(task);
      return acc;
    }, {});
  };

  // const handleStartDateChange = (event) => {
  //   setStartDate(event.target.value);
  // };

  // const handleEndDateChange = (event) => {
  //   setEndDate(event.target.value);
  // };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchTasks();
  };

  const exportToExcel = () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
    const groupedTasks = groupTasksByMid(tasks);
    const exportData = [];

    Object.entries(groupedTasks).forEach(([mid, tasksInGroup]) => {
      exportData.push({
        "Reporting Date": moment(tasksInGroup[0].reportingDate[0]).format('DD-MMM-YYYY'),
        "Client": clientNames[tasksInGroup[0].CC] || 'Loading...',
        "Main Task Name": tasksInGroup[0].mainTask[0],
        "Sub Tasks": tasksInGroup.map((task) => task.subTask).join(', '),
        "Task Periods": tasksInGroup
          .map((task) => (task.taskto && moment(task.taskto).year() > 2000 ? moment(task.taskto).format('DD-MMM-YYYY') : 'No Period'))
          .join(', '),
        "Task Time": TimeDisplay(tasksInGroup[0].taskTimeInMinutes), // Combine task times
                "Travel Time": tasksInGroup[0].travelTime, // Combine travel times
                "Worked At": tasksInGroup[0].location, // Combine locations
        "Remarks": tasksInGroup.map((task) => task.remarks).join(', '),
      });
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Member Name:", selectedUser || 'N/A'],
      ["Date Range:", `${moment(startDate).format('DD-MMM-YYYY')} - ${moment(endDate).format('DD-MMM-YYYY')}`],
      ["", ""],
      ["Reporting Date", "Client", "Main Task Name", "Sub Tasks", "Task Periods", "Task Time", "Travel Time", "Worked At","Remarks"]
    ]);

    XLSX.utils.sheet_add_json(worksheet, exportData, { skipHeader: true, origin: -1 });
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tasks");
    XLSX.writeFile(workbook, `MemberTasks_${selectedUser}.xlsx`);
  };
  const handleEdit = (taskid) => {
    navigate('../editDailyWorking', { state: { taskid } });
};


const handleDelete = async (taskId) => {
  try {
      // Show a confirmation dialog
      Swal.fire({
          title: 'Are you sure?',
          text: 'Do you want to delete this task? This action cannot be undone!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, cancel',
      }).then(async (result) => {
          // If the user confirmed, proceed with the deletion
          if (result.isConfirmed) {
              const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deletedwrtask', { id: taskId });

              if (response.status === 200) {
                  Swal.fire({
                      toast: true,
                      position: 'top-end',
                      icon: 'success',
                      text: response.data.message,
                      showConfirmButton: false,
                      timer: 1000,
                  }).then(() => {
                      fetchTasks()
                  });
              } else {
                  Swal.fire({
                      toast: true,
                      position: 'top-end',
                      icon: 'info',
                      text: response.data,
                      showConfirmButton: false,
                      timer: 3000,
                  });
              }
          }
      });
  } catch (error) {
      Swal.error('Error deleting task:', error);
  }
};

  const groupedTasks = groupTasksByMid(tasks);
  const truncateRemarks = (remarks, maxLength = 10) => {
    // Join remarks into a single string
    const fullRemarks = remarks.join(' '); 
    // Check if the length exceeds the maxLength
    return fullRemarks.length > maxLength 
        ? `${fullRemarks.substring(0, maxLength)}...` // Truncate and add ellipsis
        : fullRemarks; // Return full remarks if within limit
};

  return (
    <div className="container">
      <h2 className="my-4 mt-5">TM - DTR</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
            <div className="row">
            <div className="col-md-6">
          <label htmlFor="user">Select User:</label>
          <select id="user" className="form-control" value={selectedUser} onChange={handleUserChange} required>
            <option value="">Select User</option>
            {users.map((user) => (
              <option key={user.id} value={user.empid}>
                {user.empid} - {user.FirstName}
              </option>
            ))}
          </select>
          </div>
        <div className="col-md-6">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
        </div>
        </div>
        <button type="submit" className="btn btn-success m-2">
          Get Tasks
        </button>
        <button type="button" className="btn btn-secondary m-2 " onClick={exportToExcel}>
          Export to Excel
        </button>
      </form>

      <div className="mt-4">
        {Object.keys(groupedTasks).length > 0 ? (
          <table className="table table-striped mt-4">
            <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
              <tr>
                <th>Date</th>
                <th>Client</th>
                <th>Main Task Name</th>
                <th>Sub Tasks</th>
                <th>Task Periods</th>
                <th>Enteries</th>
                <th>Task Time</th>
                <th>Travel Time</th>
                <th>Location</th>
                <th>Remarks</th>
                <th>Actions</th> {/* New actions column */}
              </tr>
            </thead>
            <tbody style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
              {Object.entries(groupedTasks).map(([mid, groupedTasks], index) => (
                <tr key={index}>
                  <td>{moment(groupedTasks[0].reportingDate[0]).format('DD-MMM-YY')}</td>
                  <td>{clientNames[groupedTasks[0].CC] || 'Loading...'}</td>
                  <td>{groupedTasks[0].mainTask[0]}</td>
                  <td>
                    {groupedTasks.map((task) => (
                      <div key={task.subTask}>{task.subTask}</div>
                    ))}
                  </td>
                  <td>
                      {groupedTasks.map((task) => {
                        const taskToDate = moment(task.taskto);
                        const taskFromDate = moment(task.taskfrom);

                        if (task.taskto && taskToDate.year() > 2000 && task.taskfrom && taskFromDate.year() > 2000) {
                          const formattedTaskTo = taskToDate.format('DD-MMM-YY');
                          const formattedTaskFrom = taskFromDate.format('DD-MMM-YY');
                          return (
                            <div key={task.taskto}>
                              {formattedTaskFrom} - {formattedTaskTo}
                            </div>
                          );
                        }

                        return (
                          <div key={task.taskto}>
                            No Period
                          </div>
                        );
                      })}
                    </td>
                    <td>
                    {groupedTasks.map((task) => (
                      <div key={task.NumberOfEntries}>{task.NumberOfEntries}</div>
                    ))}
                  </td>
                  <td>{TimeDisplay(groupedTasks[0].taskTimeInMinutes)}</td>
                  <td>{groupedTasks[0].travelTime}</td>
                  <td>{groupedTasks[0].location}</td>
                  <td>
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            data-tooltip-id="remarks-tooltip"
                                            data-tooltip-content={groupedTasks.map((task) => task.remarks).join(', ')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Tooltip id="remarks-tooltip" />
                    </td>

                  <td>
                    <button className="btn btn-success btn-sm" onClick={() => handleEdit(groupedTasks[0].mid)}>
                    <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(groupedTasks[0].mid)}>
                    <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No tasks found for the selected user and date range.</p>
        )}
      </div>
    </div>
  );
};

export default CompletedTaskForAdmin;
