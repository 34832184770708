import React, { useState } from 'react';
import axios from 'axios';
const AddLedger = () => {
  const [formData, setFormData] = useState({
    ContactName: '',
    ContactMobile: '',
    address: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Sending a POST request with the formData to your Node.js API
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addledger', formData);
      
      // Handle the response as needed
      console.log('Data sent successfully:', response.data);

      // You can also reset the form after successful submission
      setFormData({
        ContactName: '',
        ContactMobile: '',
        address: ''
      });
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="col-md-4 p-3 border rounded shadow-sm" style={{ backgroundColor: '#f8f9fa' }}>
        <h4 className="text-center mb-4">Add Ledger</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-2">
            <label htmlFor="name" className="form-label">Contact Name</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="name"
              name="ContactName"
              value={formData.ContactName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mb-2">
            <label htmlFor="mobile" className="form-label">Contact Mobile</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="mobile"
              name="ContactMobile"
              value={formData.ContactMobile}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group mb-2">
            <label htmlFor="address" className="form-label">Address</label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className="btn btn-success btn-sm w-100 mt-2">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddLedger;
