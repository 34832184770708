import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      toast.success("Please Wait....");  // Success toast
      const response = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/login`, { email, password });
      toast.success(response.data.message);  // Success toast

      // Store email and role in session storage
      const role = response.data.role;
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('role', role);

      // Delay navigation to ensure toast is visible
      await delay(750);

      // Navigate based on role
      switch(role) {
        case 'user':
          navigate('/userdashboard/employeeHomePage');
          break;
        case 'client':
          navigate('/clientdashboard');
          break;
        case 'lead':
        case 'superadmin':
          navigate('/admindashboard');
          break;
        default:
          navigate('/'); // Default route if role is unknown
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.message);  // Error toast
      } else {
        toast.error('An unexpected error occurred');  // Error toast
      }
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
      <div className="card shadow p-4" style={{ width: '400px', height: 'auto' }}>
        <h2 className="text-center mb-4">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3 position-relative">
            <label htmlFor="password" className="form-label">Password</label>
            
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span className="position-absolute" style={{ right: '10px', top: '73%', transform: 'translateY(-50%)', cursor: 'pointer' }} onClick={toggleShowPassword}>
                <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
              </span>
            
          </div>
          <div className="d-grid mb-3">
            <button type="submit" className="btn btn-success">Login</button>
          </div>
        </form>
        <div className="text-center">
        <Link to="/forgot-password" style={{ color: '#198754' }}>Forgot Password?</Link>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={700}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          closeButton={false}  // Remove close button
        />
      </div>
    </div>
  );
};

export default Login;
