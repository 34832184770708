import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const DayInOutReport = () => {
  const [employees, setEmployees] = useState([]);
  const [inOutTimes, setInOutTimes] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  // Fetch employees when component mounts
  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then(response => {
        setEmployees(response.data);
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
      });
  }, []);

  // Fetch in/out times when the selected date changes
  useEffect(() => {
    if (selectedDate) {
      axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getEmployeeInOutTimes', {
        params: { date: selectedDate }
      }).then(response => {
        setInOutTimes(response.data);
      })
      .catch(error => {
        console.error('Error fetching in/out times:', error);
      });
    }
  }, [selectedDate]);

  // Function to format time in HH:MM AM/PM format
  const formatTimeInAmPm = (timeString) => {
    if (!timeString) return 'Not done yet';

    const [datePart, timePart] = timeString.split('T'); // Split date and time
    let [hours, minutes] = timePart.split(':'); // Extract hours and minutes

    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

    return `${hours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
  };

  // Function to format date and time in DD-MMM HH:MM AM/PM format
const formatDateTimeInAmPm = (dateTimeString) => {
  if (!dateTimeString) return 'Not done yet';

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [datePart, timePart] = dateTimeString.split('T'); // Split date and time

  // Extract date
  const [year, month, day] = datePart.split('-');
  const formattedDate = `${day}-${months[parseInt(month) - 1]}`; // Format DD-MMM

  // Extract time
  let [hours, minutes] = timePart.split(':'); // Extract hours and minutes
  const isPM = hours >= 12;
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

  return `${formattedDate} ${hours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
};


const getInOutTimesForEmployee = (empid) => {
  const times = inOutTimes.find(time => time.empid === empid);
  if (times) {
    const intime = times.intime ? formatTimeInAmPm(times.intime) : ' - ';
    const subIntime = times.submissionInTime ? formatDateTimeInAmPm(times.submissionInTime) : ' Not Yet ';
    const outtime = times.outtime ? formatTimeInAmPm(times.outtime) : ' - ';
    const subOuttime = times.submissionOutTime ? formatDateTimeInAmPm(times.submissionOutTime) : ' Not Yet ';
    const inplace = times.inplace ? times.inplace : ' - ';
    const outplace = times.outplace ? times.outplace : ' - ';
    return { intime, inplace, outtime, outplace,subIntime, subOuttime };
  }
  return { intime: ' - ', outtime: ' - ', inplace:' - ', outplace:' - ', subIntime:' Not Yet ', subOuttime:' Not Yet ' };
};

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-body">
          <h4 className="card-title mb-4">Day In / Out Report</h4>

          {/* Date Picker */}
          <div className="mb-4">
            <label htmlFor="datePicker" className="form-label">Select Date:</label>
            <input
              type="date"
              id="datePicker"
              className="form-control"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>

          {/* Employee Attendance Table */}
          <table className="table table-striped table-hover align-middle">
            <thead className="" style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
              <tr>
              <th>TM Code</th>
                  <th>Name</th>
                  <th>In Time</th>
                  <th>Reported</th>
                  <th>In Location</th>
                  <th>Out Time</th>
                  <th>Reported</th>
                  <th>Out Location</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
              {employees.map((employee, index) => {
                const { intime, inplace, outtime, outplace, subIntime, subOuttime} = getInOutTimesForEmployee(employee.empid);
                return (
                  <tr key={index}>
                      <td>{employee.empid}</td>
                      <td>{employee.FirstName} {employee.LastName}</td>
                      <td>{intime}</td>
                      <td>{subIntime}</td>
                      <td>{inplace}</td>
                      <td>{outtime}</td>
                      <td>{subOuttime}</td>
                      <td>{outplace}</td>
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DayInOutReport;
