import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';

// Global filter component for searching
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <input
    value={globalFilter || ''}
    onChange={e => setGlobalFilter(e.target.value)}
    placeholder="Search..."
    className="form-control mb-3"
  />
);

const AssignClient = () => {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [assignedClientdata, setAssignedClientdata] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [responsibleMembers, setResponsibleMembers] = useState({
    first: null,
    second: null,
    third: null,
    fourth: null,
    fifth: null,
    sixth: null,
    seventh: null,
    eighth: null,
    ninth: null,
  });

  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectemployee')
      .then(response => setEmployees(response.data))
      .catch(error => Swal.fire('Error', 'Failed to fetch employees', 'error'));

    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients')
      .then(response => setClients(response.data))
      .catch(error => Swal.fire('Error', 'Failed to fetch clients', 'error'));

      axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/allassignedclients')
      .then(response => setAssignedClientdata(response.data))
      .catch(error => Swal.fire('Error', 'Failed to fetch Data', 'error'));


  }, []);

  const handleClientChange = (client) => {
    setSelectedClient(client);
    if (client) {
      axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getResponsibleMembers/${client.value}`)
        .then(response => {
          const members = response.data;
          const newResponsibleMembers = {
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null,
            sixth: null,
            seventh: null,
            eighth: null,
            ninth: null,
          };

          members.forEach(member => {
            if (member.responsibility === 1) newResponsibleMembers.first = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 2) newResponsibleMembers.second = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 3) newResponsibleMembers.third = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 4) newResponsibleMembers.fourth = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 5) newResponsibleMembers.fifth = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 6) newResponsibleMembers.sixth = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 7) newResponsibleMembers.seventh = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 8) newResponsibleMembers.eighth = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
            if (member.responsibility === 9) newResponsibleMembers.ninth = { value: member.empid, label: `${member.empid} - ${member.FirstName} ${member.LastName}` };
          });

          setResponsibleMembers(newResponsibleMembers);
        })
        .catch(() => {
          setResponsibleMembers({
            first: null,
            second: null,
            third: null,
            fourth: null,
            fifth: null,
            sixth: null,
            seventh: null,
            eighth: null,
            ninth: null,
          });
        });
    } else {
      setResponsibleMembers({
        first: null,
        second: null,
        third: null,
        fourth: null,
        fifth: null,
        sixth: null,
        seventh: null,
        eighth: null,
        ninth: null,
      });
    }
  };

  const handleSubmit = () => {
    const { first, second, third, fourth, fifth, sixth, seventh, eighth, ninth } = responsibleMembers;
    if (!selectedClient) {
      Swal.fire('Error', 'Please select a client before submitting.', 'error');
      return;
    }
    if (!first) {
      Swal.fire('Error', 'Please select at least one responsible member.', 'error');
      return;
    }

    Swal.fire({
      title: 'Are you sure?',
      text: `You are about to assign members to client ${selectedClient.label}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, submit it!',
    }).then((result) => {
      if (result.isConfirmed) {
        const clientData = {
          cc: selectedClient?.value,
          tradeName: selectedClient?.label.split(' - ')[1],
        };

        axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteAssignments/${clientData.cc}`)
          .then(() => {
            const membersData = [
              { member: first, responsibility: 1 },
              { member: second, responsibility: 2 },
              { member: third, responsibility: 3 },
              { member: fourth, responsibility: 4 },
              { member: fifth, responsibility: 5 },
              { member: sixth, responsibility: 6 },
              { member: seventh, responsibility: 7 },
              { member: eighth, responsibility: 8 },
              { member: ninth, responsibility: 9 },
            ];

            const validMembers = membersData.filter(item => item.member !== null);

            const assignPromises = validMembers.map(({ member, responsibility }) => {
              const data = {
                empid: member.value,
                firstName: member.label.split(' - ')[1].split(' ')[0],
                lastName: member.label.split(' - ')[1].split(' ')[1],
                ...clientData,
                responsibility,
              };

              return axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assignclient', data)
                .then(() => Swal.fire('Success', `Members Assigned Successfully`, 'success'))
                .catch(error => {
                  Swal.fire('Error', 'Failed to assign client.', 'error');
                  throw error;
                });
            });

            return Promise.all(assignPromises);
          })
          .then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch(error => {
            console.error("Error assigning clients: ", error);
          });
      }
    });
  };

  const handleMemberChange = (selectedOption, position) => {
    setResponsibleMembers(prevState => ({
      ...prevState,
      [position]: selectedOption,
    }));
  };

  const filterTeamMembers = useMemo(() => {
    const selectedEmpIds = Object.values(responsibleMembers)
      .filter(member => member !== null)
      .map(member => member.value);

    return employees.filter(emp => !selectedEmpIds.includes(emp.empid));
  }, [employees, responsibleMembers]);

  const teamMemberOptions = useMemo(() => filterTeamMembers.map(emp => ({
    value: emp.empid,
    label: `${emp.empid} - ${emp.FirstName} ${emp.LastName}`,
  })), [filterTeamMembers]);

  const reviewerOptions = employees.map(emp => ({
    value: emp.empid,
    label: `${emp.empid} - ${emp.FirstName} ${emp.LastName}`,
  }));

  const data = useMemo(() => {
        return clients.map(client => {
          const assignedMembers = assignedClientdata.filter(member => member.cc === client.CC);
          const responsibilities = Array(9).fill('-');
          assignedMembers.forEach(member => {
            if (member.responsibility >= 1 && member.responsibility <= 9) {
              responsibilities[member.responsibility - 1] = `${member.empid} - ${member.FirstName}`;
            }
          });
          return {
            client: `${client.CC} - ${client.TradeName}`,
            one: responsibilities[0],
            two: responsibilities[1],
            three: responsibilities[2],
            four: responsibilities[3],
            five: responsibilities[4],
            six: responsibilities[5],
            seven: responsibilities[6],
            eight: responsibilities[7],
            nine: responsibilities[8],
          };
        });
      }, [clients, assignedClientdata]);

  const columns = useMemo(() => [
    {
      Header: 'Client',
      accessor: 'client',
    },
    {
      Header: '1',
      accessor: 'one',
    },
    {
      Header: '2',
      accessor: 'two',
    },
    {
      Header: '3',
      accessor: 'three',
    },
    {
      Header: '4',
      accessor: 'four',
    },
    {
      Header: '5',
      accessor: 'five',
    },
    {
      Header: '6',
      accessor: 'six',
    },
    {
      Header: '7',
      accessor: 'seven',
    },
    {
      Header: '8',
      accessor: 'eight',
    },
    {
      Header: '9',
      accessor: 'nine',
    },
  ], []);

  // Setup table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter, pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    pageOptions,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Start at page 0
    },
    useGlobalFilter,
    usePagination
  );
  return (
    <div className="container mt-5">
      <h2>Assign Team</h2>

      <div className="form-group">
        <label>Select Client</label>
        <Select
          options={clients.map(client => ({
            value: client.CC,
            label: `${client.CC} - ${client.TradeName}`,
          }))}
          onChange={handleClientChange}
          placeholder="Select a client..."
        />
      </div>

      <div className="table mt-4">
        <table className="table table-bordered">
          <tbody>
            <tr className="thead-dark">
              <th>Team Member 1</th>
              <th>Team Member 2</th>
              <th>Team Member 3</th>
            </tr>
            <tr>
              <td>
                <Select
                  options={teamMemberOptions}
                  value={responsibleMembers.first}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'first')}
                  placeholder="Select Team Member 1..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={teamMemberOptions}
                  value={responsibleMembers.second}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'second')}
                  placeholder="Select Team Member 2..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={teamMemberOptions}
                  value={responsibleMembers.third}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'third')}
                  placeholder="Select Team Member 3..."
                  isClearable
                />
              </td>
            </tr>
            <tr>
              <th>Reviewer 1</th>
              <th>Reviewer 2</th>
              <th>Reviewer 3</th>
            </tr>
            <tr>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.fourth}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'fourth')}
                  placeholder="Select Reviewer 1..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.fifth}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'fifth')}
                  placeholder="Select Reviewer 2..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.sixth}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'sixth')}
                  placeholder="Select Reviewer 3..."
                  isClearable
                />
              </td>
            </tr>
            <tr>
              <th>Reviewer 4</th>
              <th>Reviewer 5</th>
              <th>Reviewer 6</th>
            </tr>
            <tr>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.seventh}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'seventh')}
                  placeholder="Select Reviewer 4..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.eighth}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'eighth')}
                  placeholder="Select Reviewer 5..."
                  isClearable
                />
              </td>
              <td>
                <Select
                  options={reviewerOptions}
                  value={responsibleMembers.ninth}
                  onChange={(selectedOption) => handleMemberChange(selectedOption, 'ninth')}
                  placeholder="Select Reviewer 6..."
                  isClearable
                />
              </td>
            </tr>
          </tbody>
        </table>

        <button className="btn btn-success" onClick={handleSubmit}>
          Assign
        </button>
      </div>

      <div className="mt-5">
        <h3>Assigned Clients</h3>

        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />

        <table {...getTableProps()} className="table table-striped table-bordered">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
            </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-between align-items-center">
        <div>
        <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
        <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
        </div>
        <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        </div>
        <div>
        <select
        className="form-select"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 15, 20].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AssignClient;