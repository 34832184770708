import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'; // Import for alerts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

const EmployeeHomePage = () => {
  const [username, setUsername] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [lateEarlyRequest, setLateEarlyRequests] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [inOutTimes, setInOutTimes] = useState([]);
  const [notices, setNotices] = useState([]); // New state for notices
  const [isMGEmployee, setIsMGEmployee] = useState(false); // State for MG Employee check
  const [userType, setUserType] = useState(''); // User type to check for notice visibility
  const [newNotice, setNewNotice] = useState(''); // State to handle new notice input
  const [editingNoticeId, setEditingNoticeId] = useState(null);
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    // Fetch username
    axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata?email=${email}`)
      .then(response => {
        const userData = response.data[0];
        setEmployeeData(response.data[0]);
        setUsername(userData.FirstName);
        setUserType(userData.type); // Assuming 'type' field contains the user's type

        // Check if the employee is an MG Employee
        if (userData.empid.includes('MG')) {
          setIsMGEmployee(true);
        }
      })
      .catch(error => {
        console.error('Error fetching username:', error);
      });

    // Fetch leave requests
    axios
      .get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLeaveRequests')
      .then(response => {
        const acceptedLeaves = response.data
          .filter(leave => {
            const leaveStartDate = new Date(leave.fromdate);
            const leaveEndDate = new Date(leave.todate);
            const today = new Date();
            today.setHours(0, 0, 0, 0); // Reset to midnight for date comparison
            return leave.status !== 0 && (leaveStartDate >= today || leaveEndDate >= today);
          })
          .sort((a, b) => new Date(a.fromdate) - new Date(b.fromdate)); // Sorting by fromdate
        setLeaveRequests(acceptedLeaves);
      })
      .catch(error => {
        console.error('Error fetching leave requests:', error);
      });

    // Fetch late/early requests
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLateEarlyRequests')
      .then(response => {
        const acceptedRequest = response.data.filter(request => {
          const requestDate = new Date(request.date);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset to midnight for date comparison
          return request.status !== 0 && (requestDate >= today);
        });
        setLateEarlyRequests(acceptedRequest);
      })
      .catch(error => {
        console.error('Error fetching leave requests:', error);
      });

    // Fetch employee data
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then(response => {
        setEmployees(response.data);
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
      });

    // Fetch in/out times
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getEmployeeInOutTimes', {
      params: { date: currentDate }
    }).then(response => {
        setInOutTimes(response.data);
      })
      .catch(error => {
        console.error('Error fetching in/out times:', error);
      });

    // Update current time every second
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Fetch the notices from the backend
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getNotices')
      .then(response => {
        if (Array.isArray(response.data)) {
          // If response is directly an array
          setNotices(response.data);
        } else if (response.data.data) {
          // If response contains a 'data' field
          setNotices(response.data.data);
        } else {
          // Handle any other unexpected format
          setNotices([]);
        }
      })
      .catch(error => {
        console.error('Error fetching notices:', error);
      });
  }, []);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-IN', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    }).format(date).replace(/ /g, '-');
  };
  
  const getFirstName = (fullname) => {
    return fullname.split(' ')[0];
  };
  
  const formatTimeInAmPm = (timeString) => {
    if (!timeString) return 'Not done yet';
    const [datePart, timePart] = timeString.split('T'); // Split date and time
    let [hours, minutes] = timePart.split(':'); // Extract hours and minutes
    const isPM = hours >= 12;
    hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
    return `${hours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
  };

  const getInOutTimesForEmployee = (empid) => {
    const times = inOutTimes.find(time => time.empid === empid);
    if (times) {
      const intime = times.intime ? formatTimeInAmPm(times.intime) : ' - ';
      const subIntime = times.submissionInTime ? formatTimeInAmPm(times.submissionInTime) : ' - ';
      const outtime = times.outtime ? formatTimeInAmPm(times.outtime) : ' - ';
      const subOuttime = times.submissionOutTime ? formatTimeInAmPm(times.submissionOutTime) : ' - ';
      const inplace = times.inplace ? times.inplace : ' - ';
      const outplace = times.outplace ? times.outplace : ' - ';
      return { intime, inplace, outtime, outplace,subIntime, subOuttime };
    }
    return { intime: ' - ', outtime: ' - ', inplace:' - ', outplace:' - ', subIntime:' - ', subOuttime:' - ' };
  };

  // Handle notice delete
  const handleNoticeDelete = (noticeId) => {
    axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteNotice/${noticeId}`)
      .then(response => {
        Swal.fire('Notice deleted successfully');
        // Remove the deleted notice from the state
        setNotices(notices.filter(notice => notice.id !== noticeId));
      })
      .catch(error => {
        console.error('Error deleting notice:', error);
        Swal.fire('Error deleting notice');
      });
  };

  // Handle notice submit (create or edit)
  const handleNoticeSubmit = () => {
    if (editingNoticeId) {
      // If editing, update the notice
      axios.put(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateNotice/${editingNoticeId}`, { content: newNotice })
        .then(response => {
          Swal.fire('Notice updated successfully');
          // Update the notice list
          const updatedNotices = notices.map(notice =>
            notice.id === editingNoticeId ? { ...notice, content: newNotice } : notice
          );
          setNotices(updatedNotices);
          setNewNotice(''); // Clear the input field
          setEditingNoticeId(null); // Reset editing state
        })
        .catch(error => {
          console.error('Error updating notice:', error);
          Swal.fire('Error updating notice');
        });
    } else {
      // If not editing, create a new notice
      axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/postNotice', { content: newNotice, empid:employeeData.empid })
        .then(response => {
          Swal.fire('Notice created successfully');
          // Add the new notice to the list
          setNotices([...notices, response.data]);
          setNewNotice(''); // Clear the input field
        })
        .catch(error => {
          console.error('Error creating notice:', error);
          Swal.fire('Error creating notice');
        });
    }
  };

  // Handle edit button click
  const handleNoticeEdit = (notice) => {
    setNewNotice(notice.content); // Set the current notice content in the input field
    setEditingNoticeId(notice.id); // Store the id of the notice being edited
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });
  };


  return (
   <div className="container mt-5">
    {/* Flex container for greeting and notices */}
    <div className="d-flex justify-content-between align-items-start mb-3">
      <div>
        <h2>Hello, {username}!</h2>
        <h4>{formatDate(currentDate)}, {formatTime(currentDate)}</h4>
      </div>

      {/* Notice Section on the right */}
      {/* Submit Notice Section */}
    
      <div>
      <div className="notice-section" style={{ maxHeight: '80px', overflowY: 'auto', fontSize: 'small', width: '900px' }}>
        <ul className="list-group">
          {notices.length > 0 ? (
            notices.map((notice, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                <span>{notice.content}</span>

                {/* Edit and Delete buttons for MG Users */}
                {isMGEmployee && (
                  <div>
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="blue"
                      className="me-2"
                      onClick={() => handleNoticeEdit(notice)}
                      style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="red"
                      onClick={() => handleNoticeDelete(notice.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                )}
              </li>
            ))
          ) : (
            <li className="list-group-item">No notices found</li>
          )}
        </ul>
      </div>

      {/* Notice Input Field for MG Users */}
      {isMGEmployee && (
        <div className="mt-2 d-flex align-items-center">
          <input
            className="form-control mb-0 me-2"
            value={newNotice}
            onChange={(e) => setNewNotice(e.target.value)}
            placeholder="Enter your notice here"
            style={{ height: '38px' }}
          />
          <button className="btn btn-success btn-sm" onClick={handleNoticeSubmit}>
            <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      )}
    </div>
    </div>

        {/* Team Member Attendance Table */}
        <div className="container mt-2">
          <div className="card shadow">
            <div className="card-body">
              <h4 className="card-title mb-2">Team Member Attendance</h4>
              <div className="table-responsive">
                <table className="table table-striped table-hover align-middle">
                  <thead className="" style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
                    <tr>
                      <th>TM Code</th>
                      <th>Name</th>
                      <th>In Time</th>
                      <th>Reported</th>
                      <th>In Location</th>
                      <th>Out Time</th>
                      <th>Reported</th>
                      <th>Out Location</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
                    {employees.map((employee, index) => {
                      const { intime, inplace, outtime, outplace, subIntime, subOuttime } = getInOutTimesForEmployee(employee.empid);
                      return (
                        <tr key={index}>
                          <td>{employee.empid}</td>
                          <td>{employee.FirstName} {employee.LastName}</td>
                          <td>{intime}</td>
                          <td>{subIntime}</td>
                          <td>{inplace}</td>
                          <td>{outtime}</td>
                          <td>{subOuttime}</td>
                          <td>{outplace}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      {/* Other components (Leave Requests, Late/Early Requests) */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h5>On Leaves</h5>
              <ul className="list-group">
                {leaveRequests.map((request, index) => (
                  <li key={index} className="list-group-item">
                    {getFirstName(request.fullname)} - {request.fromdate === request.todate 
                      ? formatDate(new Date(request.fromdate)) 
                      : `${formatDate(new Date(request.fromdate))} to ${formatDate(new Date(request.todate))}`} 
                      - <span style={{ color: request.status === 1 ? 'green' : 'red', fontWeight:'bold' }}>
                        {request.status === 1 ? 'Accepted' : 'Pending'}
                      </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h5>Late Entry or Early Exit</h5>
              <ul className="list-group">
                {lateEarlyRequest.map((request, index) => (
                  <li key={index} className="list-group-item">
                    {getFirstName(request.fullname)} on {formatDate(new Date(request.date))} - {request.type} by {request.timeDuration} Hours 
                    - <span style={{ color: request.status === 1 ? 'green' : 'red', fontWeight:'bold' }}>
                      {request.status === 1 ? 'Accepted' : 'Pending'}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeHomePage;
