import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserSessionTimeout = (timeout = 30 * 60 * 1000) => { // Default timeout is 5 minutes
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        sessionStorage.removeItem('email');
        navigate('/');
      }, timeout);
    };

    const handleActivity = () => {
      resetTimeout();
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    resetTimeout(); // Initialize the timeout

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [navigate, timeout]);
};

export default UserSessionTimeout;
