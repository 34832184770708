import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ClientList = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const email = sessionStorage.getItem('email'); // Get email from session storage
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients');
        setClients(response.data);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching clients!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'P', accessor: 'Priority' },
      { Header: 'CC', accessor: 'CC' },
      { Header: 'Client Name', accessor: 'ClientName' },
      { Header: 'Trade Name', accessor: 'TradeName' },
      { Header: 'GSTIN', accessor: 'GSTIN' },
      { Header: 'GST', accessor: 'gstApplicable' },
      { Header: 'PAN', accessor: 'PAN' },
      { Header: 'TAN', accessor: 'TAN' },
      { Header: 'TDS', accessor: 'tdsApplicability' },
      { Header: 'TCS', accessor: 'tcsApplicability' },
      { Header: 'Visits', accessor: d => `${d.NumberOfVisits} Visits/${d.InWeeks} Weeks` },
      { Header: 'Active', accessor: d => (d.isActive === 1 ? 'Active' : 'Inactive') },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <>
            <button
              className={`btn ${row.original.isActive ? 'btn-danger' : 'btn-success'} me-1`}
              style={{fontSize:'13px'}}
              onClick={() => handleToggleStatus(row.original.id, row.original.CC, row.original.isActive)}
            >
              {row.original.isActive ? "D'ACT" : 'ACT'}
            </button>
            <button
              className='btn btn-success'
              style={{fontSize:'13px'}}
              onClick={() => handleEditUser(row.original)}
            >
              Edit
            </button>
          </>
        )
      }
    ],
    []
  );

  const handleToggleStatus = async (clientId, CC, isActive) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${isActive === 1 ? 'deactivate' : 'activate'} this client?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    });

    if (result.isConfirmed) {
      try {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deactivateClient', { CC: CC });

        // Update the client list to reflect the change
        setClients(prevClients => 
          prevClients.map(client =>
            client.id === clientId ? { ...client, isActive: isActive === 1 ? 0 : 1 } : client
          )
        );

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: isActive === 1 ? 'Client deactivated successfully.' : 'Client activated successfully.',
          showConfirmButton: false,
          timer: 3000
        });
      } catch (error) {
        console.error('Error toggling client status:', error);
        Swal.fire('Error', 'There was an error toggling the client status!', 'error');
      }
    }
  };

  const handleEditUser = (client) => {
    if(employeeData){
      navigate(`/userDashboard/edit-client/${client.CC}`, { state: client });
    }
    else{
      navigate(`/adminDashboard/edit-client/${client.CC}`, { state: client });
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: clients,
      initialState: { pageIndex: 0, pageSize: 5 }, // Default page size
    },
    useGlobalFilter,
    usePagination
  );
  const activeClientsCount = clients.filter(client => client.isActive === 1).length;
  const deactiveClientsCount = clients.filter(client => client.isActive === 0).length;

  return (
    <div className='container'>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h4 className='text-center mt-5'>Clients List</h4>
          <div className="d-flex justify-content-end">
  <ul className="list-unstyled mb-0 d-flex gap-4">
    <li className="text-danger fw-bold">• Deactive Client - {deactiveClientsCount}</li>
    <li className="text-success fw-bold">• Active Clients - {activeClientsCount}</li>
    <li className="text-secondary fw-bold">• Total Clients - {deactiveClientsCount + activeClientsCount}</li>
  </ul>
</div>

          <input
            type="text"
            placeholder="Search..."
            className="form-control mb-3"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div className="table-responsive">
            <table {...getTableProps()} className='table table-striped '>
              <thead className='text-center'>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className='text-center'
              style={{fontSize:'14px',verticalAlign:'baseline'}}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Pagination controls */}
          <div className="d-flex justify-content-between align-items-center">
            <div >
              <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </button>
              <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                className="form-select"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 15,20,50].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientList;
