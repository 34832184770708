import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import { Form, Button, InputGroup, Table, Pagination } from 'react-bootstrap';

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <InputGroup className="mb-3">
    <InputGroup.Text>Search</InputGroup.Text>
    <Form.Control
      value={globalFilter || ''}
      onChange={e => setGlobalFilter(e.target.value || undefined)}
      placeholder="Type to search..."
    />
  </InputGroup>
);

const LateEntryEarlyExitForm = () => {
  const sessionEmail = sessionStorage.getItem('email');
  const [formdata, setFormData] = useState({});
  const [formState, setFormState] = useState({
    name: '',
    type: '',
    date: '',
    timeDuration: '',
    reason: '',
    otherReason: '',
    sessionEmail: sessionEmail,
    difftime: ''  // Add difftime field
  });
  const [requests, setRequests] = useState([]);

  const reasons = [
    "Medical Issues - Self",
    "Medical Issues - Family Members",
    "Casualty of close relatives",
    "Traffic Congestion",
    "Delay due to public transport",
    "Household tasks",
    "Visit to Govt Institutions for Self",
    "Visit to Govt Institutions for Family Members",
    "Any Other"
  ];

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email: sessionEmail }
        });
        const employeeData = response.data;
        setFormData(employeeData[0]);
        setFormState({
          ...formState,
          name: `${employeeData[0]?.FirstName + ' ' + employeeData[0]?.LastName}`
        });
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchEmployeeData(); // Fetch employee data on component mount
  }, [sessionEmail]);

  const fetchRequests = async () => {
    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getUserPendingLateEarlyRequests`, {
        params: { email: sessionEmail }
      });
      setRequests(response.data);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  useEffect(() => {
    if (sessionEmail) {
      fetchRequests(); // Fetch requests on component mount
    }
  }, [sessionEmail]);

  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => {
      const newState = { ...prevState, [name]: value };

      // Calculate difftime if fromTime and toTime are available
      if (name === 'timeDuration') {
        const { timeDuration } = newState;
        if (timeDuration) {
          newState.difftime = convertToMinutes(timeDuration);
        } else {
          newState.difftime = ''; // Clear difftime if times are not available
        }
      }

      return newState;
    });
  };

  const fullname = formdata.FirstName + ' ' + formdata.LastName;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitLateEntryEarlyExitForm', formState);
      Swal.fire({
        icon: 'success',
        title: 'Form submitted successfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      }).then(() => {
        setFormState({
          ...formState,
          type: '',
          date: '',
          timeDuration: '',
          reason: '',
          otherReason: '',
          sessionEmail: sessionEmail,
          difftime: '' // Reset difftime after submission
        });
        fetchRequests(); // Fetch updated requests after submission
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error submitting form',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleDelete = async (requestId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteLateEarlyRequest/${requestId}`);
          Swal.fire({
            icon: 'success',
            title: 'Request deleted successfully',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
          fetchRequests(); // Refresh the list after deletion
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error deleting request',
            text: error.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
          console.error('Error deleting request:', error);
        }
      }
    });
  };

  const getStatus = (status) => {
    if (status === null) return 'Pending';
    if (status === 0) return 'Denied';
    if (status === 1) return 'Approved';
    return '';
  };

  const formatDate = (dateString) => {
    return moment(dateString).tz('Asia/Kolkata').format('DD-MMMM-YYYY');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => formatDate(value),
      },
      {
        Header: 'Time Duration',
        accessor: 'timeDuration',
      },
      {
        Header: 'Reason',
        accessor: 'reason',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => getStatus(value),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => (
          <Button variant="danger" onClick={() => handleDelete(row.original.id)}>
            Delete
          </Button>
        ),
      },
    ],
    []
  );

  const data = useMemo(() => requests, [requests]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    preGlobalFilteredRows,
    setPageSize,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="container">
      <h2 className="mt-5">Late Entry / Early Exit Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col">
            <label htmlFor="name">Name*</label>
            <input type="text" className="form-control" id="name" name="name" value={fullname} disabled/>
          </div>
          <div className="col">
            <label htmlFor="date">Date*</label>
            <input type="date" className="form-control" id="date" name="date" value={formState.date} onChange={handleChange} required/>
          </div>
          <div className="col">
            <label htmlFor="type">Type*</label>
            <select className="form-control" id="type" name="type" value={formState.type} onChange={handleChange} required>
              <option value="">Select Type</option>
              <option value="Late Entry">Late Entry</option>
              <option value="Early Exit">Early Exit</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="timeDuration">Time Duration*</label>
            <input type="text" className="form-control" id="timeDuration" name="timeDuration" value={formState.timeDuration} onChange={handleChange} required placeholder='HH:MM' pattern="^(?:[01]\d|2[0-3]):[0-5]\d$"/>
          </div>
          <div className="col">
            <label htmlFor="reason">Reason*</label>
            <select className="form-control" id="reason" name="reason" value={formState.reason} onChange={handleChange} required>
              <option value="">Select Reason</option>
              {reasons.map((reason, index) => (
                <option key={index} value={reason}>{reason}</option>
              ))}
            </select>
          </div>
          {formState.reason === 'Any Other' && (
            <div className="col">
              <label htmlFor="otherReason">Any Other Reason</label>
              <input type="text" className="form-control" id="otherReason" name="otherReason" value={formState.otherReason} onChange={handleChange} />
            </div>
          )}
        </div>

        <Button type="submit" className="btn btn-success">Send for approval</Button>
      </form>

      <h3 className="mt-5">My Requests</h3>
      
      <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />

      <Table striped bordered hover {...getTableProps()}>
        <thead style={{fontSize:'15px',verticalAlign:'baseline'}}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody style={{fontSize:'14px',verticalAlign:'baseline'}} {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Pagination className="justify-content-center">
        <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
        <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
        <Pagination.Item>
          Page{' '}
          <strong>
            {state.pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Pagination.Item>
        <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
        <Pagination.Last onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} />
      </Pagination>

      <Form.Select
        className="mt-3"
        value={state.pageSize}
        onChange={e => {
          setPageSize(Number(e.target.value));
        }}
        style={{ width: 'auto', margin: '0 auto' }}
      >
        {[5, 10, 15, 20, 25, 50].map(pageSize => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default LateEntryEarlyExitForm;
