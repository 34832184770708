import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/teamMember.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const AddTeamMember = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    contactNo: '',
    email: '',
    password: '',
    monthlyRemuneration: '',
    position: '',
    monthlyLeaves: '',
    // signImage: null,
    EmployeeID: '',
    hoursType: '',
    floatingHours: '',
    scheduleIn: '',
    scheduleOut: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
      Swal.fire({
        title: 'Invalid File Type',
        text: 'Please upload an image file (PNG, JPEG, or JPG).',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      e.target.value = ''; // Clear the file input
      return;
    }
    setFormData({ ...formData, signImage: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate mobile number
    if (formData.contactNo.length > 10) {
      Swal.fire({
        title: 'Invalid Mobile Number',
        text: 'Mobile number cannot exceed 10 digits.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });

    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',
      text: 'Please Wait!',
      showConfirmButton: false,
      timer: 3000
    });

    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addUser', formDataToSend, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          navigate('../assignclient');
        });

        setFormData({
          firstName: '',
          lastName: '',
          address: '',
          contactNo: '',
          email: '',
          password: '',
          monthlyRemuneration: '',
          position: '',
          monthlyLeaves: '',
          signImage: null,
          EmployeeID: '',
          hoursType: '',
          floatingHours: '',
          scheduleIn: '',
          scheduleOut: ''
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: response.data,
          showConfirmButton: false,
          timer: 3000
        });
      }
    } catch (error) {
      console.error('There was an error!', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  return (
    <div className='mainscreenadmin mt-5'>
      <div className="container formcontainer">
        <form onSubmit={handleSubmit}>
          <h4 className='text-center'>Add Team Member</h4>
          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>First Name</label>
                <input type="text" className="form-control" placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Last Name</label>
                <input type="text" className="form-control" placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Address</label>
                <input type="text" className="form-control" placeholder="Enter Address" name="address" value={formData.address} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Contact No</label>
                <input type="text" className="form-control" placeholder="Contact Number" name="contactNo" value={formData.contactNo} onChange={handleChange} required />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Email Address</label>
                <input type="email" className="form-control" placeholder="Email Address" name="email" value={formData.email} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Password" name="password" value={formData.password} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Monthly Remuneration</label>
                <input type="number" className="form-control" placeholder="Monthly Remuneration" name="monthlyRemuneration" value={formData.monthlyRemuneration} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Position</label>
                <input type="text" className="form-control" placeholder="Position" name="position" value={formData.position} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Monthly Leaves Allowed</label>
                <input type="number" className="form-control" placeholder="Monthly Leaves Allowed" name="monthlyLeaves" value={formData.monthlyLeaves} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Team Member ID</label>
                <input type="text" className="form-control" placeholder="Team Member ID" name="EmployeeID" value={formData.EmployeeID} onChange={handleChange} required/>
              </div>
            </div>

          </div>

          <div className="form-group">
            <label>Working Hours Type</label>
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>
                  <input type="radio" name="hoursType" value="fixed" checked={formData.hoursType === 'fixed'} onChange={handleChange} /> Fixed Hours
                </label>
              </div>
              <div className="col-md-6 inputdiv">
                <label>
                  <input type="radio" name="hoursType" value="floating" checked={formData.hoursType === 'floating'} onChange={handleChange} /> Floating Hours
                </label>
              </div>
            </div>
          </div>

          {formData.hoursType === 'fixed' && (
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 inputdiv">
                  <label>Schedule In</label>
                  <input type="time" className="form-control" placeholder="Schedule In" name="scheduleIn" value={formData.scheduleIn} onChange={handleChange} required/>
                </div>
                <div className="col-md-6 inputdiv">
                  <label>Schedule Out</label>
                  <input type="time" className="form-control" placeholder="Schedule Out" name="scheduleOut" value={formData.scheduleOut} onChange={handleChange} required/>
                </div>
              </div>
            </div>
          )}

          {formData.hoursType === 'floating' && (
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 inputdiv">
                  <label>Number of Hours</label>
                  <input type="number" className="form-control" placeholder="Number of Hours" name="floatingHours" value={formData.floatingHours} onChange={handleChange} required/>
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Team Member ID</label>
                <input type="text" className="form-control" placeholder="Team Member ID" name="EmployeeID" value={formData.EmployeeID} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-success">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeamMember;
