import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash, faEye, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faFilter, faEyeSlash, faEye, faSortUp, faSortDown);

const ECOMCredentials = () => {
  const [ecomDetails, setEcomDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedColumns, setSelectedColumns] = useState({
    TradeName: true,
    CC: true,
    EcomPortal: true,
    ecomId: true,
    ecomPass: true,
  });
  const [visibleRequests, setVisibleRequests] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchUserClientRequestsAndEcomDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchecomdetails', { email });
        console.log("API Response:", response.data);

        if (response.data.EcomDetails && Array.isArray(response.data.EcomDetails)) {
          setEcomDetails(response.data.EcomDetails);
        } else {
          console.error('Expected EcomDetails to be an array but received:', response.data);
          setEcomDetails([]);
        }
      } catch (error) {
        console.error('Error fetching client details or ecom details:', error);
        setEcomDetails([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUserClientRequestsAndEcomDetails();
  }, [email]);

  const toggleVisibility = (cc) => {
    setVisibleRequests((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [cc]: !prevVisibleRequests[cc],
    }));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const requestMatchesSearchQuery = (request) => {
    return (
      searchQuery === '' ||
      request.CC.join(' ').toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const sortedRequests = (requests) => {
    if (sortConfig.key) {
      return [...requests].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return requests;
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredAndSortedRequests = sortedRequests(ecomDetails.filter(requestMatchesSearchQuery));

  return (
    <div className="container mt-5" style={{ marginLeft: '1px', maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>E-Commerce List</h2>
        <div className="dropdown">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="filterDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faFilter} /> Filter Columns
          </button>
          <ul className="dropdown-menu" aria-labelledby="filterDropdown">
            {Object.keys(selectedColumns).map((column) => (
              <li key={column} className="dropdown-item">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={column}
                    checked={selectedColumns[column]}
                    onChange={handleColumnChange}
                  />
                  <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                    {column}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredAndSortedRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
              {selectedColumns.CC && (
                <th onClick={() => handleSort('CC')} style={{ cursor: 'pointer' }}>
                  CC {sortConfig.key === 'CC' && (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                </th>
              )}
              {selectedColumns.TradeName && (
                <th onClick={() => handleSort('TradeName')} style={{ cursor: 'pointer' }}>
                  TradeName {sortConfig.key === 'TradeName' && (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                </th>
              )}
              {selectedColumns.EcomPortal && (
                <th onClick={() => handleSort('ecomPortal')} style={{ cursor: 'pointer' }}>
                  EcomPortal {sortConfig.key === 'ecomPortal' && (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                </th>
              )}
              {selectedColumns.ecomId && (
                <th onClick={() => handleSort('ecomId')} style={{ cursor: 'pointer' }}>
                  ecomId {sortConfig.key === 'ecomId' && (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                </th>
              )}
              {selectedColumns.ecomPass && (
                <th onClick={() => handleSort('ecomPass')} style={{ cursor: 'pointer' }}>
                  ecomPass {sortConfig.key === 'ecomPass' && (sortConfig.direction === 'ascending' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />)}
                </th>
              )}
            </tr>
          </thead>
          <tbody style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            {filteredAndSortedRequests.map((request, index) => {
              const { CC, TradeName, ecomPortal, ecomId, ecomPass } = request;
              return (
                <tr key={`${CC}-${index}`}>
                  {selectedColumns.CC && <td>{CC[0]}</td>}
                  {selectedColumns.TradeName && <td>{TradeName[0]}</td>}
                  {selectedColumns.EcomPortal && <td>{ecomPortal}</td>}
                  {selectedColumns.ecomId && (
                    <td>
                      {visibleRequests[CC] ? (
                        ecomId
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(CC)} />
                      )}
                    </td>
                  )}
                  {selectedColumns.ecomPass && (
                    <td>
                      {visibleRequests[CC] ? (
                        ecomPass
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(CC)} />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ECOMCredentials;
