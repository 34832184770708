import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

const VDateMatrix = () => {
  const [mainTask, setMainTask] = useState('Books of Accounts');
  const [subTasks, setSubTasks] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [employeeData, setEmployeeData] = useState({});
  const [taskToData, setTaskToData] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});
  const [formData, setFormData] = useState({}); // State for client details
  const [responsibleMember, setResponsibleMember] = useState({});
  const email = sessionStorage.getItem('email');

  // Fetch subtasks based on the selected main task
  useEffect(() => {
    const fetchSubTasks = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', {
          maintasks: mainTask,
        });
        const fetchedSubTasks = response.data;
        setSubTasks(fetchedSubTasks);
        
        // Initialize selectedColumns state
        const initialSelection = {};
        fetchedSubTasks.forEach(subTask => {
          initialSelection[subTask.SubTasks] = true;
        });
        setSelectedColumns(initialSelection);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching subtasks!', 'error');
      }
    };

    if (mainTask) {
      fetchSubTasks();
    }
  }, [mainTask]);

  // Fetch employee data and assigned clients
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: email },
        });
        const employeeData = response.data[0];
        if (employeeData) {
          setEmployeeData(employeeData);
          fetchAssignedClients(employeeData.empid);
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
    }
  }, [email]);

  const fetchAssignedClients = (empid) => {
    axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${empid}`)
      .then((response) => {
        const clientOptions = response.data.map(client => ({
          value: client.CC,
          label: `${client.CC} - ${client.TradeName}`,
        }));
        setClients(clientOptions);
      })
      .catch(error => console.error('Error fetching clients:', error));
  };

  // Fetch the latest task-to values
  useEffect(() => {
    const fetchTaskToValues = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/latest-taskto');
        setTaskToData(response.data);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching taskto values!', 'error');
      }
    };

    fetchTaskToValues();
  }, []);

  // Fetch client details when a client is selected
  const fetchClientData = async (clientId) => {
    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/client/${clientId}`);
      if (response.status === 200) {
        setFormData(response.data);
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: 'Failed to load client data.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error('There was an error fetching the client data!', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  // Fetch responsible member data when a client is selected
  const fetchResponsibleMemberData = async (clientId) => {
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', {
        CC: clientId,
      });
      if (response.data.responsibleMember) {
        setResponsibleMember(response.data.responsibleMember);
      } else {
        setResponsibleMember({}); // Reset if no member found
      }
    } catch (error) {
      console.error('Error fetching responsible member data:', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred while fetching responsible member data.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };


  const handleClientChange = (selected) => {
    setSelectedClient(selected);
    if (selected) {
      fetchClientData(selected.value); // Fetch client data when selected
      fetchResponsibleMemberData(selected.value); // Fetch responsible member data
    } else {
      setFormData({}); // Clear data if no client is selected
      setResponsibleMember({}); // Reset responsible member data
    }
  };

  // Handle subtask checkbox change
  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Select all subtasks
  const handleSelectAll = () => {
    const newSelection = {};
    subTasks.forEach(subTask => {
      newSelection[subTask.SubTasks] = true;
    });
    setSelectedColumns(newSelection);
  };

  // Unselect all subtasks
  const handleSelectNone = () => {
    const newSelection = {};
    subTasks.forEach(subTask => {
      newSelection[subTask.SubTasks] = false;
    });
    setSelectedColumns(newSelection);
  };

  // Filtered subtasks based on selected columns
  const filteredSubTasks = useMemo(() => {
    return subTasks.filter(subTask => selectedColumns[subTask.SubTasks]);
  }, [subTasks, selectedColumns]);

  // Data transformation to associate taskTo data with selected client
  const dataWithTaskTo = useMemo(() => {
    return filteredSubTasks.map(subTask => {
      const rowData = { subTask: subTask.SubTasks };

      if (selectedClient) {
        const task = taskToData.find(task => task.CC === selectedClient.value && task.subTask === subTask.SubTasks);
        rowData[selectedClient.value] = task ? task.taskto : 'N/A';
      }

      return rowData;
    });
  }, [selectedClient, taskToData, filteredSubTasks]);

  return (
    <div className="container mt-5">
      <h3>Client Overview</h3>

      <div className="d-flex mb-3">
        <div className="me-2 flex-grow-1">
          <label className="form-label">Select Client</label>
          <Select 
            options={clients} 
            className="mb-3"
            onChange={handleClientChange} // Update client change handler
            isClearable 
          />
        </div>
        <div className=" flex-grow-1">
          <label htmlFor="mainTaskSelect" className="form-label">Select Main Task</label>
          <select 
            id="mainTaskSelect" 
            className="form-select" 
            value={mainTask} 
            onChange={(e) => setMainTask(e.target.value)}
          >
            <option value="Books of Accounts">Books of Accounts</option>
            <option value="GST Compliances">GST Compliances</option>
            <option value="TAX Compliances">TAX Compliances</option>
          </select>
        </div>

      </div>

            

      {/* Dropdown Filter for Subtasks */}
      <div className="dropdown mb-3 d-flex justify-content-between align-item-end">
        {/* Responsible Member Information
        {selectedClient && responsibleMember.firstname && ()} */}

        <div className="d-flex align-items-end">
        <strong>Member : </strong> {responsibleMember.empid} - {responsibleMember.firstname} 
        </div>
        <button
  className="btn btn-secondary dropdown-toggle"
  type="button"
  id="filterDropdown"
  data-bs-toggle="dropdown"
  aria-expanded="false"
>
  <FontAwesomeIcon icon={faFilter} /> Filter Tasks
</button>
<ul className="dropdown-menu" aria-labelledby="filterDropdown">
  <li className="dropdown-item">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="selectAll"
        checked={Object.values(selectedColumns).every(Boolean)}
        onChange={handleSelectAll}
      />
      <label
        className="form-check-label"
        htmlFor="selectAll"
        onClick={(e) => e.stopPropagation()} // Stop event propagation
      >
        Select All
      </label>
    </div>
  </li>
  <li className="dropdown-item">
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        id="selectNone"
        checked={Object.values(selectedColumns).every(value => !value)}
        onChange={handleSelectNone}
      />
      <label
        className="form-check-label"
        htmlFor="selectNone"
        onClick={(e) => e.stopPropagation()} // Stop event propagation
      >
        Select None
      </label>
    </div>
  </li>
  {Object.keys(selectedColumns).map((column) => (
    <li key={column} className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          name={column}
          checked={selectedColumns[column]}
          onChange={handleColumnChange}
          id={`checkbox-${column}`}
        />
        <label
          className="form-check-label"
          htmlFor={`checkbox-${column}`}
          onClick={(e) => e.stopPropagation()} // Stop event propagation
        >
          {column}
        </label>
      </div>
    </li>
  ))}
</ul>

      </div>

      {/* Layout for table and client details */}
      <div className="row">
        <div className="col-md-4">
          <div className="card shadow">
            <div className="card-header">Client Profile</div>
            <div className="card-body">
              {selectedClient ? (
                <>
                  <p><strong>Priority: </strong> 
                  {formData?.Priority === 'F' ? 'First' : 
                  formData?.Priority === 'S' ? 'Second' : 
                  formData?.Priority === 'T' ? 'Third' : 'N/A'}
                  </p>
                  <p><strong>Visits/Weeks: </strong> {`${formData?.NumberOfVisits }Visits/${formData?.InWeeks} Weeks` || 'N/A'}</p>
                  <p><strong>Standard Hours/Weeks: </strong> {`${formData?.stdTime} Hours/${formData?.stdTimeInWeeks} Weeks` || 'N/A'}</p>
                  <p><strong>Level of BOA: </strong> 
                  {formData?.levelOfBOA === 'A' ? 'Audit' : 
                  formData?.levelOfBOA === 'B' ? 'Balance Sheet' : 
                  formData?.levelOfBOA === 'G' ? 'GST Return' : 
                  formData?.levelOfBOA === 'I' ? 'ITR' : 'N/A'}
                  </p>
                  <p><strong>Reporting Frequency: </strong> 
                  {formData?.reportingFrequency === 'D' ? 'Daily' : 
                  formData?.reportingFrequency === 'M' ? 'Monthly' : 
                  formData?.reportingFrequency === 'F' ? 'Fortnightly' : 
                  formData?.reportingFrequency === 'Y' ? 'Yearly' : 
                  formData?.reportingFrequency === 'H' ? 'Half Yearly' : 
                  formData?.reportingFrequency === 'Q' ? 'Quarterly' : 'N/A'}
                  </p>
                  <p><strong>Time Lag: </strong> {`${formData?.timeLag} Days` || 'N/A'}</p>
                  <p><strong>GST Return Frequency: </strong> 
                  {formData?.gstrf === 'M' ? 'Monthly' : 
                  formData?.gstrf === 'Y' ? 'Yearly' : 
                  formData?.gstrf === 'H' ? 'Half Yearly' : 
                  formData?.gstrf === 'Q' ? 'Quarterly' : 'N/A'}
                  </p>
                  <p><strong>TDS Applicability: </strong> {formData?.tdsApplicability || 'N/A'}</p>
                  <p><strong>TCS Applicability: </strong> {formData?.tcsApplicability || 'N/A'}</p>
                  <p><strong>E-Com Applicability: </strong> {formData?.ecomApplicabilty || 'N/A'}</p>
                  
                  {/* Add more fields as necessary */}
                </>
              ) : (
                <p>Please select a client to see details.</p>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-8">
        <table className="table table-striped table-bordered" style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
          <thead>
            <tr>
              <th>Task</th>
              {selectedClient && (
                <th>{selectedClient.label}</th>
              )}
              <th>Task</th>
              {selectedClient && (
                <th>{selectedClient.label}</th>
              )}
            </tr>
          </thead>
          <tbody>
            {dataWithTaskTo.map((row, rowIndex) => {
              // Calculate the index for splitting the tasks
              const isEvenIndex = rowIndex % 2 === 0;

              return (
                <tr key={rowIndex}>
                  {isEvenIndex ? (
                    <>
                      <td>{row.subTask}</td>
                      {selectedClient && (
                        <td>
                          {row[selectedClient.value] === 'N/A' ? (
                            'N/A'
                          ) : (
                            new Date(row[selectedClient.value]).toLocaleDateString('en-GB', {
                              day: '2-digit',
                              month: 'short',
                              year: '2-digit',
                            })
                          )}
                        </td>
                      )}
                      {/* Second task cell */}
                      {rowIndex + 1 < dataWithTaskTo.length ? (
                        <>
                          <td>{dataWithTaskTo[rowIndex + 1].subTask}</td>
                          {selectedClient && (
                            <td>
                              {dataWithTaskTo[rowIndex + 1][selectedClient.value] === 'N/A' ? (
                                'N/A'
                              ) : (
                                new Date(dataWithTaskTo[rowIndex + 1][selectedClient.value]).toLocaleDateString('en-GB', {
                                  day: '2-digit',
                                  month: 'short',
                                  year: '2-digit',
                                })
                              )}
                            </td>
                          )}
                        </>
                      ) : (
                        // If there's no next task, leave the cells empty
                        <>
                          <td></td>
                          {selectedClient && <td></td>}
                        </>
                      )}
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
      </div>
    </div>
  );
};

export default VDateMatrix;
