import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEye, faSync, faEdit } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import EditStatusModal from './TdsEditModal';

import ReactDOM from 'react-dom/client';
const Swal = require('sweetalert2');

library.add(faFilter, faEye, faSync, faEdit);

const TdsTcsCredentials = () => {
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedColumns, setSelectedColumns] = useState({
    CC: true,
    TradeName: true,
    PAN: true,
    TAN: true,
    TracesID: true,
    TracesPass: true,
    Priority:true,
    tdsApplicableReturn:true,
    TracesLastEdited:false,
    tdsReturnFreq:true,
    tdsReturnFreqStatus:true,
    tdsChallanFreq:true,
    tdsChallanFreqStatus:true,
    ResponsibleMember:true,
    levelOfBOA:true,
    ITD:true
  });
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleFields, setVisibleFields] = useState({});
  const [visibleRequests, setVisibleRequests] = useState({});
  const [editingStatus, setEditingStatus] = useState({}); // Track which status is being edited

  const email = sessionStorage.getItem('email');
  const FullName = requests[0]?.FirstName + ' ' + requests[0]?.LastName;

  useEffect(() => {
    const fetchUserClientRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/tdstcsclientdetails', { email });
        const requestsData = response.data;
        setRequests(requestsData);
        setFilteredRequests(requestsData);
        
        // Fetch responsible members for each request's CC
        const memberRequests = requestsData.map(request => {
          return axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC: request.CC });
        });
  
        const members = await Promise.all(memberRequests);
        const responsibleMembersData = members.reduce((acc, memberResponse, index) => {
          acc[requestsData[index].CC] = memberResponse.data.responsibleMember;
          return acc;
        }, {});
  
        setResponsibleMembers(responsibleMembersData);
      } catch (error) {
        console.error('Error fetching client details or responsible members:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserClientRequests();
  }, [email, refresh]);

  useEffect(() => {
    const filtered = requests.filter((request) =>
      Object.values(request).some(
        (value) =>
          value !== null &&
          value !== undefined &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredRequests(filtered);
  }, [searchTerm, requests]);

  const handleColumnChange = (e) => {
    setSelectedColumns({
      ...selectedColumns,
      [e.target.name]: e.target.checked,
    });
  };

  const handleRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };

  // const taxLogin = () => {
  //   window.open('https://eportal.incometax.gov.in/iec/foservices/#/login', '_blank', 'noopener,noreferrer,width=1800,height=1800');
  // };
  
  const tracesLogin = () => {
    window.open('https://www.tdscpc.gov.in/app/login.xhtml?usr=Ded', '_blank', 'noopener,noreferrer,width=1800,height=1800');
  };

  // const toggleVisibility = (index, field) => {
  //   setVisibleFields((prev) => ({
  //     ...prev,
  //     [field]: !prev[field],
  //   }));
  // };

  const toggleVisibility = (requestCC) => {
    setVisibleRequests((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [requestCC]: !prevVisibleRequests[requestCC],
    }));
  };

  const handleSelectAll = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true; // Set all columns to true
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleSelectNone = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false; // Set all columns to false
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };


  const handleStatusEdit = (request, statusType, dateType, editedKey, amountKey,editedDate) => {
    Swal.fire({
      title: 'Edit Status',
      html: '<div id="edit-status-modal-root"></div>',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      width: '80%',
      didOpen: () => {
        const modalRoot = document.getElementById('edit-status-modal-root');
        const root = ReactDOM.createRoot(modalRoot);
        root.render(
          <EditStatusModal
            request={request}
            statusType={statusType}
            dateType={dateType}
            editedKey={editedKey}
            amountKey={amountKey}
            editedDate={editedDate}
            handleRefresh={handleRefresh}
            onClose={() => Swal.close()}
          />
        );
      },
      showCloseButton: true,
    });
  };
  


const handleEdit = (request, fieldType) => {
  const fieldPass = `${fieldType}Pass`; // ITDPass, TracesPass, etc.
  const fieldID = `${fieldType}ID`; // ITDID, TracesID, etc.
  
  // Create modal content with sequence preserved
  const modalContent = `
    <div class="mb-3">
      <label class="form-label  float-start">Team Member</label>
      <input type="email" class="form-control" value="${request.empid[0]}" disabled>
    </div>
    ${fieldType !== 'ITD' ? `
      <div class="mb-3">
        <label class="form-label float-start">${fieldType} ID</label>
        <input type="text" class="form-control" id="edit-id" value="${request[fieldID]}" disabled>
      </div>
    ` : ''}
    <div class="mb-3">
      <label class="form-label float-start">Current ${fieldType} Password</label>
      <input type="text" class="form-control" id="old-password" value="${request[fieldPass]}" disabled>
    </div>
    <div class="mb-3">
      <label class="form-label float-start">Set New ${fieldType} Password</label>
      <input type="text" id="new-password" class="form-control" placeholder="Enter new password">
    </div>
  `;

  Swal.fire({
    title: `Edit ${fieldType} Credentials`,
    html: modalContent,
    showCancelButton: true,
    confirmButtonText: 'Update',
    confirmButtonColor: '#198754',
    preConfirm: () => {
      const id = fieldType !== 'ITD' ? document.getElementById('edit-id').value : null; // Only get ID if not ITD
      const newPassword = document.getElementById('new-password').value;
      const oldPassword = document.getElementById('old-password').value;

      if (!newPassword) {
        Swal.showValidationMessage('New password is required');
      }

      return { newPassword, oldPassword, id };
    },
  }).then((result) => {
    if (result.isConfirmed) {
      const { id, newPassword, oldPassword } = result.value;

      axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updatetaxcredentials', {
        email,
        empid: requests[0].empid[0],
        FullName,
        CC: request.CC,
        newPassword,
        oldPassword,
        type: fieldType, // ITD
        credentialID: id, // `id` will be `null` for ITD
        TradeName: request.TradeName,
      })
        .then(() => {
          Swal.fire({
            toast: true,
            text: `${fieldType} credentials have been updated.`,
            icon: 'success',
            position: 'top-end',
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
            willClose: () => {
              handleRefresh();
            },
          });
        })
        .catch((error) => {
          Swal.fire('Error!', 'Failed to update the credentials.', 'error');
          console.error('Error updating credentials:', error);
        });
    }
  });
};



  return (
    <div className="container mt-5" style={{ maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center">
        <h2>TDS Compliances<p className='text align-items mt-2' style={{fontSize:'15px',fontWeight:'400'}}> Number of Clients - {requests.length}</p></h2>
        <div className="d-flex align-items-center">
          <button className="btn btn-secondary me-2" onClick={tracesLogin}>
             Traces Login
          </button>
          {/* <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button> */}
          <div className="dropdown me-2">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
              <FontAwesomeIcon icon={faFilter} /> Filter Columns
            </button>
            <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          checked={Object.values(selectedColumns).every(Boolean)}
          onChange={handleSelectAll}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Select All
        </label>
      </div>
    </li>
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectNone"
          checked={Object.values(selectedColumns).every(value => !value)}
          onChange={handleSelectNone}
        />
        <label className="form-check-label" htmlFor="selectNone">
          Select None
        </label>
      </div>
    </li>
              {Object.keys(selectedColumns).map((column) => (
                <li key={column} className="dropdown-item">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={column}
                      checked={selectedColumns[column]}
                      onChange={handleColumnChange}
                      id={`checkbox-${column}`}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                      {column}
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
            {selectedColumns.ResponsibleMember && <th>Member</th>}
              {selectedColumns.TradeName && <th>Trade Name</th>}
              {selectedColumns.ITD && <th>ITD Pass</th>}
              {selectedColumns.ITD && <th>Edit</th>}
              {selectedColumns.TracesID && <th>Traces ID</th>}
              {selectedColumns.TracesPass && <th>Traces Pass</th>}
              {selectedColumns.TracesPass && <th>Edit</th>}
              {selectedColumns.tdsChallanFreq && <th>TDS CF</th>}
              {selectedColumns.tdsChallanFreqStatus && <th>Status</th>}
              {selectedColumns.tdsChallanFreqStatus && <th>Edit</th>}
              {selectedColumns.tdsApplicableReturn && <th>TDS AR</th>}
              {selectedColumns.tdsReturnFreq && <th>TDS RF</th>}
              {selectedColumns.tdsReturnFreqStatus && <th>Status</th>}
              {selectedColumns.tdsReturnFreqStatus && <th>Edit</th>}
              {selectedColumns.TracesLastEdited && <th>Traces Last Edited</th>}
            </tr>
          </thead>
          <tbody>
          {filteredRequests.map((request, index) => {
const responsibleMember = responsibleMembers[request.CC];
return (
<tr key={index}>
{selectedColumns.ResponsibleMember && (
  <td>
    {responsibleMember ? (
      <>
        {responsibleMember.firstname}
        <br />
        {request.Priority} {request.levelOfBOA} {request.CC}
      </>
    ) : (
      'N/A'
    )}
  </td>
)}


{selectedColumns.TradeName && <td>{request.TradeName} <br/>{request.TAN}</td>}        

{selectedColumns.ITD && (
  <td>
    {visibleRequests[request.CC] ? (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {request.ITDPass}
        
      </span>
    ) : (
      <span>
        <FontAwesomeIcon
          icon={faEye}
          onClick={() => toggleVisibility(request.CC)} // Handle ITD visibility toggle
          style={{ cursor: 'pointer' }}
        />
      </span>
    )}
  </td>
)}
<td> {visibleRequests[request.CC] ? (<FontAwesomeIcon
          icon={faEdit}
          className="ms-2 text-success cursor-pointer"
          onClick={() => handleEdit(request, 'ITD')} // Handle Edit ITD
          style={{ cursor: 'pointer' }}
        />): <span>
        <FontAwesomeIcon
          icon={faEye}
          onClick={() => toggleVisibility(request.CC)} // Handle ITD visibility toggle
          style={{ cursor: 'pointer' }}
        />
      </span>}</td>

                {selectedColumns.TracesID && (
                  <td>
                    {visibleRequests[request.CC] ? (
                      <span>{request.TracesID}</span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>
                )}

                {selectedColumns.TracesPass && (
                  <td>
                    {visibleRequests[request.CC] ? (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {request.TracesPass}
                       
                        
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>
                    )}
                  </td>

                )}
                <td> {visibleRequests[request.CC] ? (<FontAwesomeIcon icon={faEdit} className="ms-2 text-success cursor-pointer"  onClick={() => handleEdit(request, 'Traces')} style={{ cursor: 'pointer' }}/>):<span>
                        <FontAwesomeIcon
                          icon={faEye}
                          onClick={() => toggleVisibility(request.CC)}
                          style={{ cursor: 'pointer' }}
                        />
                      </span>}</td>

{selectedColumns.tdsChallanFreq && <td>{request.tdsChallanFreq}</td>}
                {selectedColumns.tdsChallanFreqStatus && (
                  <td style={{
                    fontWeight:'600',
                    color:
                      request.tdsChallanFreqStatus === 'Paid'  || request.tdsChallanFreqStatus === 'Paid by CA/TP' || request.tdsChallanFreqStatus === 'Not Required'? moment(request.tdsChallanFreqMonth).isBefore(moment().subtract(1, 'month'), 'month')
                      ? 'blue'  // Change color to blue if gstar1Date is older than 1 month
                      : '#006400'  // Green if gstar1Status is Filled or To be Filed, but within one month
                        : 'red', // Default color if none of the statuses match
                  }}>
                    {request.tdsChallanFreqStatus ? `${moment(request.tdsChallanFreqMonth).format('MM/YY')} ${request.tdsChallanFreqStatus}` : ''}
                   <br />
    {request.tdsChallanFreqEditedBy && request.tdsChallanFreqEditedByDate
      ? `${request.tdsChallanFreqEditedBy}
      `
      : '-'}
                  </td>
                )}
                <td> {request.tdsChallanFreq  && (  <FontAwesomeIcon
                      icon={faEdit}
                      className="ms-2 text-success cursor-pointer"
                      onClick={() => handleStatusEdit(request, 'tdsChallanFreqStatus','tdsChallanFreqMonth','tdsChallanFreqEditedBy','tdsChallanFreqAmount',
                        'tdsChallanFreqEditedByDate'
                      )}
                    />
                    )}</td>

                {selectedColumns.tdsApplicableReturn && <td>{request.tdsApplicableReturn}</td>}

                {selectedColumns.tdsReturnFreq && <td>{request.tdsReturnFreq}</td>}

{selectedColumns.tdsReturnFreqStatus && (
   <td
   style={{
     fontWeight: '600',
     color:
       request.tdsReturnFreqStatus === 'To be Filed'
         ? 'red' // Set color to red if status is "To be Filed"
         : request.tdsReturnFreqStatus === 'Filled' || request.tdsReturnFreqStatus === 'Filed - WRT'
         ? moment(request.tdsReturnFreqMonth).isBefore(moment().subtract(1, 'month'), 'month')
           ? 'blue' // Set color to blue if gstar2Date is older than 1 month
           : '#006400' // Set color to green if within one month
         : '#F9A602', // Default color
   }}
 >
    {request.tdsReturnFreqStatus? `${moment(request.tdsReturnFreqMonth).format('MM/YY')} ${request.tdsReturnFreqStatus}` : ''}
    <br />
    {request.tdsReturnFreqEditedBy && request.tdsReturnFreqEditedByDate
      ? `${request.tdsReturnFreqEditedBy}
      `
      : '-'}
  </td>
)}
         <td>{request.tdsReturnFreq  && (<FontAwesomeIcon
      icon={faEdit}
      className="ms-2 text-success cursor-pointer"
      onClick={() => handleStatusEdit(request, 'tdsReturnFreqStatus','tdsReturnFreqMonth','tdsReturnFreqEditedBy','tdsChallanFreqAmount','tdsReturnFreqEditedByDate')}
    />
    )}</td>     
               
                             
   {selectedColumns.TracesLastEdited && (
  <td>
    {request.lastTracesEditedBy && request.lastTracesDate
      ? `By ${request.lastTracesEditedBy} on ${moment(request.lastTracesDate).format('DD-MMM-YY')}`
      : 'Not Edited'}
  </td>
    )}
              </tr>
               );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TdsTcsCredentials;
