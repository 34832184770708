import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import moment from 'moment';

const EditChallanStatus = ({ request, handleRefresh, statusKey }) => {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(request.gstChallanMonth);
  const [amounts, setAmounts] = useState({
    Tax: { IGST: '', CGST: '', SGST: '', CESS: '' },
    Interest: { IGST: '', CGST: '', SGST: '', CESS: '' },
    Penalty: { IGST: '', CGST: '', SGST: '', CESS: '' },
    Fees: { IGST: '', CGST: '', SGST: '', CESS: '' },
    Other: { IGST: '', CGST: '', SGST: '', CESS: '' },
  });
  
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    setSelectedStatus('');
    setSelectedMonth(request.gstChallanMonth);
    setAmounts({
      Tax: { IGST: '', CGST: '', SGST: '', CESS: '' },
      Interest: { IGST: '', CGST: '', SGST: '', CESS: '' },
      Penalty: { IGST: '', CGST: '', SGST: '', CESS: '' },
      Fees: { IGST: '', CGST: '', SGST: '', CESS: '' },
      Other: { IGST: '', CGST: '', SGST: '', CESS: '' },
    });
    setShowTable(false);
  }, [request]);

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    setShowTable(status === 'Paid' || status === 'Paid by CA/TP' || status === 'Save as Draft');
  };

  const handleInputChange = (category, field, value) => {
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [category]: { ...prevAmounts[category], [field]: value },
    }));
  };

  const calculateRowTotal = (row) => {
    return Object.keys(amounts[row]).reduce(
      (sum, category) => sum + (parseFloat(amounts[row][category]) || 0),
      0
    );
  };

  const calculateGrandTotal = () => {
    return ['Tax', 'Interest', 'Penalty', 'Fees', 'Other'].reduce((grandTotal, row) => {
      return grandTotal + calculateRowTotal(row);
    }, 0);
  };

  const handleSaveChanges = async () => {
    const lastPaidMonth = moment(request.gstChallanMonth);
    const selectedMonthMoment = moment(selectedMonth);
    const currentMonth = moment();
    const dbstatusKey = request[statusKey] || '';
    
    if (
      (dbstatusKey === 'Paid' || dbstatusKey === 'Not Required' || dbstatusKey === 'Paid by CA/TP') &&
      selectedMonthMoment.isBefore(lastPaidMonth)
    ) {
      Swal.showValidationMessage('Cannot select an earlier month than the last paid or completed month.');
      return;
    }
    
    if (selectedMonthMoment.isSame(currentMonth, 'month') || selectedMonthMoment.isAfter(currentMonth)) {
      Swal.showValidationMessage('Cannot file a challan for the current or future months.');
      return;
    }
    
    Swal.resetValidationMessage();
    
    const amountsForDB = [];
    ['Tax', 'Interest', 'Penalty', 'Fees', 'Other'].forEach((category) => {
      const data = {
        category,
        IGST: parseFloat(amounts[category].IGST) || 0,
        CGST: parseFloat(amounts[category].CGST) || 0,
        SGST: parseFloat(amounts[category].SGST) || 0,
        CESS: parseFloat(amounts[category].CESS) || 0,
      };
      amountsForDB.push(data);
    });
    
    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateGstChallanStatus', {
        email: request.email,
        empid: request.empid[0],
        FullName: request.FullName,
        CC: request.CC,
        gstChallanStatus: selectedStatus,
        gstChallanMonth: selectedMonth,
        amounts: selectedStatus === 'Paid' || selectedStatus === 'Paid by CA/TP' ? amountsForDB : [],
        grandTotal: calculateGrandTotal(),
      });
    
      Swal.fire({
        text: 'The status has been updated.',
        icon: 'success',
        toast: true,
        showConfirmButton: false,
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        willClose: handleRefresh,
      });
    } catch (error) {
      console.error('Error updating GST Challan status:', error);
      Swal.fire({
        text: 'There was a problem saving your changes.',
        icon: 'error',
        toast: true,
        showConfirmButton: false,
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        willClose: handleRefresh,
      });
    }
  };

  return (
    <div>
      <div className="row mb-3">
        <div className="col-md-6">
          <label className="form-label float-start">Team Member</label>
          <input type="email" className="form-control" value={request.empid[0]} disabled />
        </div>
        <div className="col-md-6">
          <label className="form-label float-start">Month</label>
          <input
            type="month"
            className="form-control"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          />
        </div>
      </div>
      <div className="row mb-3">
        
        <div className="col-md-6">
          <label className="form-label float-start">Select Status</label>
          <select className="form-select" value={selectedStatus} onChange={handleStatusChange}>
            <option value="">Select Option</option>
            <option value="Amt Pending">Amt Pending</option>
            <option value="Paid">Paid</option>
            <option value="Paid by CA/TP">Paid by CA/TP</option>
            <option value="To be paid">To be paid</option>
            <option value="Not Required">Not Required</option>
            <option value="Save as Draft">Save as Draft</option>
          </select>
        </div>

        <div className="col-md-6">
          <label className="form-label float-start">Last Edited</label>
          <input
            type="text"
            className="form-control"
            value={`${request.gstChallanEditedBy} / ${moment(request.gstChallanEditedByDate).format('DD-MMM-YY')}`}
            disabled
          />
        </div>

      </div>
      {showTable && (
        <div className="table-responsive mb-3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Cat</th>
                <th>CGST (₹)</th>
                <th>IGST (₹)</th>
                <th>SGST (₹)</th>
                <th>CESS (₹)</th>
                <th>Total (₹)</th>
              </tr>
            </thead>
            <tbody>
              {['Tax', 'Interest', 'Penalty', 'Fees', 'Other'].map((row) => (
                <tr key={row}>
                  <td >{row.charAt(0).toUpperCase() + row.slice(1)}</td>
                  {['CGST', 'IGST', 'SGST', 'CESS'].map((category) => (
                    <td key={category}>
                      <input
                        type="text"
                        className="form-control"
                        value={amounts[row][category]}
                        onChange={(e) => handleInputChange(row, category, e.target.value)}
                      />
                    </td>
                  ))}
                  <td style={{width:'15%'}}>₹{calculateRowTotal(row).toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>Grand Total</td>
                <td colSpan="4"></td>
                <td>₹{calculateGrandTotal().toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <button className="btn btn-success" onClick={handleSaveChanges}>
        Update
      </button>
    </div>
  );
};

export default EditChallanStatus;
