import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import Swal from 'sweetalert2';  // <-- Import SweetAlert2

const Billing = () => {
    const email = sessionStorage.getItem('email');
    const [pendingData, setPendingData] = useState([]);
    const [bookedData, setBookedData] = useState([]);
    const [notRequiredData, setNotRequiredData] = useState([]);
    const [employeeData, setEmployeeData] = useState({});
    let EMPID = ''

    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
                    params: { email: email },
                });
                const employeeData = response.data[0];
                EMPID = response.data[0].empid;
                console.log(EMPID);
                
                setEmployeeData(employeeData);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };
        if (email) {
            fetchEmployeeData();
        }
    }, [email]);

    // Fetch billing data and filter for pending/booked
    const fetchData = async () => {
        try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/billing');
            const data = response.data;

            const pending = data.filter(item => item.status === null || item.status === 'Pending');
            const booked = data.filter(item => item.status === 1);
            const notRequired = data.filter(item => item.status === 0);

            setPendingData(pending);
            setBookedData(booked);
            setNotRequiredData(notRequired);
        } catch (error) {
            console.error("Error fetching billing data", error);
        }
    };

    // Fetch data when the component is mounted
    useEffect(() => {
        fetchData();
    }, []);
    

    const pendingColumns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'reportingDate',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                    });
                },
            },
            { Header: 'Client', accessor: 'CC' },
            { Header: 'Main Task', accessor: 'mainTask' },
            { Header: 'Sub Task', accessor: 'subTask' },
            { Header: 'Amount', accessor: 'billAmount' },
            { Header: 'Task Remarks', accessor: 'taskRemarks' },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }) => (value ? 'Booked' : 'Pending'),
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <>
                    <button
                        className="btn btn-success me-2"
                        onClick={() => handleBookAction(row.original)}
                    >
                        Book
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => handleNotRequiredAction(row.original)}
                    >
                        N/R
                    </button>
                </>
                ),
            },
        ],
        []
    );

    const bookedColumns = React.useMemo(
        () => [
            {
                Header: 'Date',
                accessor: 'reportingDate',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                    });
                },
            },
            { Header: 'Client', accessor: 'CC' },
            { Header: 'Main Task', accessor: 'mainTask' },
            { Header: 'Sub Task', accessor: 'subTask' },
            { Header: 'Amount', accessor: 'billAmount' },
            { Header: 'Task Remarks', accessor: 'taskRemarks' },
            { Header: 'Booking No.', accessor: 'bookingNumber' },
            { Header: 'Booking Date', accessor: 'bookingDate',
                Cell: ({ value }) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                    });
                }, 
            },
            { Header: 'Remarks', accessor: 'remarks' },
            {
                Header: 'Edited By',
                accessor: 'bookedByOn',
                Cell: ({ row }) => {
                    const { bookedBy, bookedOn } = row.original;
                    const date = new Date(bookedOn).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: '2-digit',
                    });
                    return `${bookedBy} on ${date}`;
                },
            },
            {
                Header: 'Action',
                accessor: 'editAction',
                Cell: ({ row }) => (
                    <button
                        className="btn btn-warning"
                        onClick={() => handleEditAction(row.original)}
                    >
                        Edit
                    </button>
                ),
            },
        ],
        []
    );

    const notRequiredColumns = React.useMemo(
        () => [
            { Header: 'Date', accessor: 'reportingDate', Cell: ({ value }) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: '2-digit',
                });
            }, },
            { Header: 'Client', accessor: 'CC' },
            { Header: 'Main Task', accessor: 'mainTask' },
            { Header: 'Sub Task', accessor: 'subTask' },
            { Header: 'Amount', accessor: 'billAmount' },
            { Header: 'Task Remarks', accessor: 'taskRemarks' },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <button
                        className="btn btn-success"
                        onClick={() => handleBookAction(row.original)}
                    >
                        Book
                    </button>
                ),
            },
        ],
        []
    );

    // Handle booking action
    const handleBookAction = async (row) => {
        const { id } = row;
        if (!EMPID) {
            Swal.fire('Error', 'Employee data is not available.', 'error');
            return;
        }
    
        // Ask for Booking Number, Date, and Remarks
        const { value: formData } = await Swal.fire({
            title: 'Enter Booking Details',
            html: `
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <label for="bookingNumber" class="form-label" style="font-weight: bold; font-size: 0.9em;">#</label>
                            <input id="bookingNumber" class="form-control" placeholder="Enter booking number" />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="bookingDate" class="form-label" style="font-weight: bold; font-size: 0.9em;">Booking Date</label>
                            <input type="date" id="bookingDate" class="form-control" />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="remarks" class="form-label" style="font-weight: bold; font-size: 0.9em;">Remarks</label>
                            <input id="remarks" class="form-control" placeholder="Enter booking remarks" />
                        </div>
                    </div>
                </div>
            `,
            showCancelButton: true,
            preConfirm: () => {
                const bookingNumber = document.getElementById('bookingNumber').value;
                const bookingDate = document.getElementById('bookingDate').value;
                const remarks = document.getElementById('remarks').value;
    
                if (!bookingNumber || !bookingDate || !remarks) {
                    Swal.showValidationMessage('All fields (Booking Number, Date, Remarks) are required');
                    return false;
                }
                return { bookingNumber, bookingDate, remarks };
            },
        });
    
        if (!formData) return; // Exit if no input was provided
    
        const { bookingNumber, bookingDate, remarks } = formData;
    
        try {
            // Send the request to update billing with remarks, booking number, and empid
            await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateBilling', {
                id,
                remarks,
                bookingNumber,
                bookingDate,
                empID: EMPID, // Use empid from employeeData
                status: 1, // 1 for booked
            });
    
            Swal.fire('Booked!', 'The billing row has been booked.', 'success');
            fetchData(); // Refresh data after booking
        } catch (error) {
            console.error('Error booking billing entry:', error);
        }
    };
    const handleNotRequiredAction = async (row) => {
        const { id } = row;

        const confirmResult = await Swal.fire({
            title: 'Mark as Not Required?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, mark as Not Required',
        });

        if (confirmResult.isConfirmed) {
            try {
                await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateBilling', {
                    id,
                    empID: EMPID,
                    status: 0, // Status 0 for Not Required
                });

                Swal.fire('Updated!', 'The billing row has been marked as Not Required.', 'success');
                fetchData();
            } catch (error) {
                console.error('Error updating billing entry:', error);
                Swal.fire('Error', 'Could not update the billing entry. Please try again.', 'error');
            }
        }
    };
    const handleEditAction = async (row) => {
        const { id, bookingNumber, bookingDate, remarks } = row;
        const formattedBookingDate = new Date(bookingDate).toISOString().slice(0, 10);

        const { value: formData } = await Swal.fire({
            title: 'Edit Booking Details',
            html: `
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4 mb-3">
                            <label for="editBookingNumber" class="form-label" style="font-weight: bold; font-size: 0.9em;">#</label>
                            <input id="editBookingNumber" class="form-control" value="${bookingNumber || ''}" />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="editBookingDate" class="form-label" style="font-weight: bold; font-size: 0.9em;">Booking Date</label>
                            <input type="date" id="editBookingDate" class="form-control" value="${formattedBookingDate || ''}" />
                        </div>
                        <div class="col-12 col-md-4 mb-3">
                            <label for="editRemarks" class="form-label" style="font-weight: bold; font-size: 0.9em;">Remarks</label>
                            <input id="editRemarks" class="form-control" value="${remarks || ''}" />
                        </div>
                    </div>
                </div>
            `,
            showCancelButton: true,
            preConfirm: () => {
                const bookingNumber = document.getElementById('editBookingNumber').value;
                const bookingDate = document.getElementById('editBookingDate').value;
                const remarks = document.getElementById('editRemarks').value;

                if (!bookingNumber || !bookingDate || !remarks) {
                    Swal.showValidationMessage('All fields (Booking Number, Date, Remarks) are required');
                    return false;
                }
                return { bookingNumber, bookingDate, remarks };
            },
        });

        if (!formData) return;

        const { bookingNumber: newBookingNumber, bookingDate: newBookingDate, remarks: newRemarks } = formData;

        try {
            await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateBilling', {
                id,
                remarks: newRemarks,
                bookingNumber: newBookingNumber,
                bookingDate: newBookingDate,
                empID: EMPID,
                status: 1,
            });

            Swal.fire('Updated!', 'The booking details have been updated.', 'success');
            fetchData(); // Refresh data after editing
        } catch (error) {
            console.error('Error updating booking entry:', error);
            Swal.fire('Error', 'Could not update the booking entry. Please try again.', 'error');
        }
    };
    const TableComponent = ({ columns, data }) => {
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data,
        });

        return (
            <table className="table table-striped table-bordered" {...getTableProps()} style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} key={column.id}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} key={row.id}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <div className="container mt-4">
            <h2 className="mt-5">Billings</h2>
            <Tabs>
                <TabList>
                    <Tab>Pending</Tab>
                    <Tab>Booked</Tab>
                    <Tab>Not Required</Tab>
                </TabList>

                <TabPanel>
                    <TableComponent columns={pendingColumns} data={pendingData} />
                </TabPanel>

                <TabPanel>
                    <TableComponent columns={bookedColumns} data={bookedData} />
                </TabPanel>

                <TabPanel>
                    <TableComponent columns={notRequiredColumns} data={notRequiredData} />
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Billing;
