// DateRangePickerComponent.js
import React from 'react';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/DateRangePicker/styles/index.css';

const getQuarterRange = (date) => {
    const currentMonth = date.getMonth();
    const startMonth = Math.floor(currentMonth / 3) * 3;
    const start = new Date(date.getFullYear(), startMonth, 1);
    const end = new Date(date.getFullYear(), startMonth + 3, 0);
    return [start, end];
};
const getPreviousQuarterRange = (date) => {
    const currentMonth = date.getMonth();
    const startMonth = Math.floor(currentMonth / 3) * 3 - 3; // Subtract 3 to go to the previous quarter
    const start = new Date(date.getFullYear(), startMonth, 1);
    const end = new Date(date.getFullYear(), startMonth + 3, 0);
    return [start, end];
};

const predefinedRanges = [
    { label: 'Today', closeOverlay: false, value: [new Date(), new Date()], placement: 'left' },
    { label: 'Yesterday', closeOverlay: false, value: [addDays(new Date(), -1), addDays(new Date(), -1)], placement: 'left' },
    { label: 'Last 7 days', closeOverlay: false, value: [subDays(new Date(), 6), new Date()], placement: 'left' },
    { label: 'This week', closeOverlay: false, value: [startOfWeek(new Date()), endOfWeek(new Date())], placement: 'left' },
    { label: 'Last week', closeOverlay: false,
        value: value => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
          ];
        },
        placement: 'left'
      },
    { label: 'Last 30 days', closeOverlay: false, value: [subDays(new Date(), 29), new Date()], placement: 'left' },
    { label: 'This month', closeOverlay: false, value: [startOfMonth(new Date()), new Date()], placement: 'left' },
    { label: 'Last month', closeOverlay: false, value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))], placement: 'left' },
    { label: 'This Quarter', closeOverlay: false, value: getQuarterRange(new Date()), placement: 'left' },
    { label: 'Last Quarter', closeOverlay: false, value: getPreviousQuarterRange(new Date()), placement: 'left' },
    { label: 'This year', closeOverlay: false, value: [new Date(new Date().getFullYear(), 0, 1), new Date()], placement: 'left' },
    { label: 'Last year', closeOverlay: false, value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)], placement: 'left' },
];

const DateRangePickerComponent = ({ value, onChange }) => {
    return (
        <DateRangePicker
            ranges={predefinedRanges}
            value={value}
            onChange={onChange}
            placeholder="Select Date Range"
            style={{ width: '100%', borderColor:'grey' }}
            />
    );
};

export default DateRangePickerComponent;
