//dailyworking.jsx
import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt , faPlus} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Select from 'react-select';

import Swal from 'sweetalert2';
  

const DailyWorking = () => {
  const [formData, setFormData] = useState({
    reportingDate: '',
    client: null,
    cc:'',
    mainTask: '',
    location: '',
    otherLocation: '',
    travelTime:'',
    billing:'',
    challan:''
  });
  const [salesRows, setSalesRows] = useState([{
    type:'sales',
    nature: '',
    taxableSales: '',
    igstSales: '',
    cgstSales: '',
    sgstSales: '',
    cessSales: '',
    fieldEnabled: {
      taxableSales: false,
      igstSales: false,
      cgstSales: false,
      sgstSales: false,
      cessSales: false,
    }
  }]);
  const [purchaseRows, setPurchaseRows] = useState([{ 
    type:'purchase',
    nature: '', 
    taxablePurchase: '', 
    igstPurchase: '', 
    cgstPurchase: '', 
    sgstPurchase: '', 
    cessPurchase: '',  
  }]);
  const salesNatureOptions = ['FCM-O/S', 'ZRS-O/S', 'Nil/EXEMP-O/S', 'Non GST-O/S', 'RCM-O/S', 'No Sales for the month'];
  const purchaseNatureOptions = ['Import', 'RCM on I/S', 'All other ITC', 'Others', 'No Purchase for the month'];
  const [employeeData, setEmployeeData] = useState({});
  const [paymentDue, setPaymentDue] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [clients, setClients] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [subtaskOptions, setSubtaskOptions] = useState([]); // Subtask options fetched from API
  const [subtasks, setSubTasks] = useState([{ subTask: '', NumberOfEntries: '', from: '', to: '',billing:'', remarks: '' }]);
  const [challanSubtasks, setChallanSubtasks] = useState([]);
  const [billingSubtasks, setBillingSubtasks] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [remainingVisits,setRemainingVisits] = useState()
  const [dailyWorkingDate,setDailyWorkingDate] = useState(null)
  const [dailyWorkingDateString,setDailyWorkingDateString] = useState()
  const [dailyWorkingTravelTimeString, setdailyWorkingTravelTimeString] = useState('');
  const [selectedSubtasks, setSelectedSubtasks] = useState([]);
  const [isMainTaskDisabled, setIsMainTaskDisabled] = useState(false);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate(); 
  const email = sessionStorage.getItem('email');
  const empid = employeeData.empid;

  const isMGEmployee =
  employeeData &&
  employeeData.empid &&
  typeof employeeData.empid === 'string' &&
  employeeData.empid.startsWith('MG');

  const isChallan = (Array.isArray(selectedSubtasks) ? selectedSubtasks : Object.values(selectedSubtasks)).some(selectedSubtask => 
    challanSubtasks.some(challanSubtask => 
      challanSubtask.SubTasks === selectedSubtask.SubTasks
    )
  );
  const isBilling = (Array.isArray(selectedSubtasks) ? selectedSubtasks : Object.values(selectedSubtasks)).some(selectedSubtask => 
    billingSubtasks.some(billingSubtask => 
      billingSubtask.SubTasks === selectedSubtask.SubTasks
    )
  );

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: email },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };
    if (email) {
      fetchEmployeeData();
    }
  }, [email]);
  

  useEffect(() => {
    const fetchemployeetasks = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasks',{empid:empid});
        setUsers(response.data);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching users!', 'error');
      }
    };

    fetchemployeetasks();
  }, [empid]);


  useEffect(() => {
    const fetchPaymemtDue = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getpaymentoverdue');

        setPaymentDue(response.data);
      } catch (error) {
        console.error('Failed to fetch payment overdue data:', error);
      }
    };

    fetchPaymemtDue();
  }, []);


  const renderTaskBadges = () => {
    return users.map(task => (
      <button 
        key={task.taskname} 
        className="badge badge-primary me-2 mb-2 task-badge  border-0" 
        onClick={() => navigate('../assignTasks')}
      >
        {task.CC}-{task.taskname}
      </button>
    ));
  };
  
  const renderPaymentBadges = () => {
    return paymentDue.map(task => (
      <button 
        key={task.CC} 
        className="badge badge-primary me-2 mb-2 task-badge  border-0" 
        onClick={() => navigate('../paymentoverdue')}
      >
        {task.CC}-{task.totalAmount}
      </button>
    ));
  };

  useEffect(() => {
    const fetchAssignClientsData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients', {
          params: { empid: empid },
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    if (empid) {
      fetchAssignClientsData();
    }
  }, [empid]);

  useEffect(() => {
    const fetchMainTasks = async () => {
      if (!formData.client) {
        setTasks([]);
        return;
      }
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getmaintasks');
        setTasks(response.data);
      } catch (error) {
        toast.error('Failed to fetch tasks');
      }
    };
  
    fetchMainTasks();
  }, [formData.client, clients]);
  
  // Prepare options for React-Select
  const mainTaskOptions = tasks.map((task) => ({
    value: task.MainTasks,
    label: task.MainTasks,
  }));


  useEffect(() => {
    
    const fetchSubTasks = async () => { 
      if (!formData.mainTask) {
        setSubtaskOptions([]);
        return;
      }
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks',{maintasks:formData.mainTask});
        setSubtaskOptions(response.data);
        const filteredChallanSubtasks = response.data.filter(subtask => subtask['Challan Amount'] === 1);
        const filteredBillingSubtasks = response.data.filter(subtask => subtask.billing === 1);
        
        setChallanSubtasks(filteredChallanSubtasks);
        setBillingSubtasks(filteredBillingSubtasks);
      } catch (error) {
        toast.error('Failed to fetch subtasks');
      }
    };

    fetchSubTasks();
  }, [formData.mainTask]);
  
  const subTaskOptions = subtaskOptions.map((task) => ({
    value: task.SubTasks,
    label: task.SubTasks,
  }));
  

  useEffect(() => {
    const fetchRemainingVisits = async () => {
      try {
        if (formData.client) {
          const visitResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients/${formData.client}/remainingVisits`, { params: { email } });
        setRemainingVisits(visitResponse.data);
        }
      } catch (error) {
        console.error("Error fetching remaining visits:", error);
      }
    };
  
    fetchRemainingVisits();
  }, [formData.client]);

  useEffect(() => {
    const hasSelectedSubtask = subtasks.some(subtask => subtask.subTask !== '');
    setIsMainTaskDisabled(hasSelectedSubtask);  // DISABLE MAIN TASK IF ANY SUBTASK IS SELECTED
  }, [subtasks]);
  useEffect(() => {
    const fetchDailyWorkingDate = async () => {
      try {
        const DateResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/latestDailyWorkingDate`, { params: { email } });
        const dailyWorking = DateResponse.data[0]?.date;

        setDailyWorkingDate(DateResponse.data);
        const formattedDate = moment(dailyWorking).format('YYYY-MM-DD');
        setDailyWorkingDateString(formattedDate);
      } catch (error) {
        console.error('Error fetching Date:', error);
      }
    };

    fetchDailyWorkingDate();
  }, [email]); 

  const clientOptions = clients.map(client => ({
    value: client.CC,
    label: `${client.CC} - ${client.TradeName}`
  }));
  const handleClientChange = (selectedOption) => {
    setFormData({
      ...formData,
      client: selectedOption ? selectedOption.value : ''
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addSalesRow = () => {
    setSalesRows((prevRows) => [
      ...prevRows,
      {
        type:'sales',
        nature: '',
        taxableSales: '',
        igstSales: '',
        cgstSales: '',
        sgstSales: '',
        cessSales: '',
        fieldEnabled: {
          taxableSales: false,
          igstSales: false,
          cgstSales: false,
          sgstSales: false,
          cessSales: false,
        }
      }
    ]);
  };

  const deleteSalesRow = (index) => {
    setSalesRows((prevRows) => prevRows.filter((_, rowIndex) => rowIndex !== index));
  };

  const handleSalesRowChange = (index, e) => {
    const { name, value } = e.target;

    setSalesRows((prevRows) => {
      const updatedRows = prevRows.map((row, rowIndex) => {
        if (rowIndex === index) {
          const updatedRow = { ...row, [name]: value };

          // Enable/disable logic based on nature selection
          let newFieldEnabled = {
            taxableSales: false,
            igstSales: false,
            cgstSales: false,
            sgstSales: false,
            cessSales: false,
          };

          // Set field enabling/disabling based on the nature selected
          if (updatedRow.nature === 'FCM-O/S' || updatedRow.nature === 'RCM-O/S') {
            newFieldEnabled = {
              taxableSales: true,
              igstSales: true,
              cgstSales: true,
              sgstSales: true,
              cessSales: true,
            };
          } else if (updatedRow.nature === 'ZRS-O/S') {
            newFieldEnabled = {
              taxableSales: true,
              igstSales: true,
              cgstSales: false,
              sgstSales: false,
              cessSales: true,
            };
          } else if (updatedRow.nature === 'Nil/EXEMP-O/S' || updatedRow.nature === 'Non GST-O/S') {
            newFieldEnabled = {
              taxableSales: true,
              igstSales: false,
              cgstSales: false,
              sgstSales: false,
              cessSales: false,
            };
          }

          return {
            ...updatedRow,
            fieldEnabled: newFieldEnabled,
          };
        }
        return row;
      });

      return updatedRows;
    });
  };

  // Get list of available nature options for the dropdown
  const getAvailableSalesNatures = (index) => {
    const selectedNatures = salesRows.map((row) => row.nature).filter(Boolean);
    return salesNatureOptions.filter(nature => !selectedNatures.includes(nature) || nature === salesRows[index].nature);
  };

  const handlePurchaseChange = (index, e) => {
    const { name, value } = e.target;
    const newPurchaseRows = [...purchaseRows];
    newPurchaseRows[index][name] = value;
    setPurchaseRows(newPurchaseRows);
  };

  const addPurchaseRow = () => {
    setPurchaseRows([...purchaseRows, { type:'purchase', nature: '', taxablePurchase: '', igstPurchase: '', cgstPurchase: '', sgstPurchase: '', cessPurchase: '' }]);
  };

  const deletePurchaseRow = (index) => {
    if (purchaseRows.length > 1) {
      const newPurchaseRows = purchaseRows.filter((_, i) => i !== index);
      setPurchaseRows(newPurchaseRows);
    }
  };

  // Get list of available nature options for the purchase dropdown
  const getAvailablePurchaseNatures = (index) => {
    const selectedNatures = purchaseRows.map((row) => row.nature).filter(Boolean);
    return purchaseNatureOptions.filter(nature => !selectedNatures.includes(nature) || nature === purchaseRows[index].nature);
  };

  const handleSubtaskChange = async (index, field, value) => {
    console.log(formData);
    
    const updatedSubtasks = [...subtasks];
    const currentDate = moment(); // Current date for comparison
    // Prevent future dates in `from` or `to`
    if ((field === 'from' || field === 'to') && moment(value).isAfter(currentDate)) {
      Swal.fire({
          title: 'Invalid Date',
          text: 'Date cannot be in the future.',
          icon: 'error',
          confirmButtonText: 'OK'
      });
      return; // Exit the function if date is in the future
  }

  // // Ensure `to` is not before `from`
  // if (field === 'to' && moment(value).isBefore(moment(updatedSubtasks[index].from))) {
  //     Swal.fire({
  //         title: 'Invalid Date Range',
  //         text: '`To` date cannot be earlier than `From` date.',
  //         icon: 'error',
  //         confirmButtonText: 'OK'
  //     });
  //     return; // Exit the function if `to` is before `from`
  // }
    // Handle `taskPeriodTo` field specifically
    if (field === "to" && (updatedSubtasks[index].subTask === "Sales Entry" || updatedSubtasks[index].subTask === "Purchases Entry")) {
    const selectedSubTask = updatedSubtasks[index].subTask;
    const CC = formData.client; // assuming you have a `selectedClient` state

    try {
      // Fetch max taskTo date from backend
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/maxTaskToDate', { params: { CC, subTask: selectedSubTask } });
      console.log(response);
      
      const maxTaskToDate = response.data.maxTaskTo;

      if (maxTaskToDate) {
        console.log(maxTaskToDate);
        
        const dbDate = moment(maxTaskToDate);
        const enteredDate = moment(value);

        // Compare the month and year of entered date with the max date
        if (dbDate.isBefore(enteredDate, 'month')) {
          console.log(true);
          
          const result = await Swal.fire({
            title: 'Incomplete Previous Month Entries',
            text: 'Have you completed entries for the previous month? If yes, continue.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Continue',
            cancelButtonText: 'No, I will complete first',
          });

          if (!result.isConfirmed) {
            Swal.fire('Please complete the entries for the previous month first.');
            return; // Exit without updating state if user selects "No"
          }
        }
      }
    } catch (error) {
      console.error('Error fetching max taskTo date:', error);
      updatedSubtasks[index][field] = value;
      setSubTasks(updatedSubtasks);
      return;
    }
  }

  // Update state only if the check passes or it's a field other than `taskPeriodTo`
    updatedSubtasks[index][field] = value;
    
    setSubTasks(updatedSubtasks);
    if(!isBilling){
      setFormData({ ...formData, ['billing']: '' });
    }
    if(!isChallan){
      setFormData({ ...formData, ['challan']: '' });
    }
  };

  const handleAddSubtaskRow = () => {
    setSubTasks([...subtasks, { subTask: '', NumberOfEntries: '', from: '', to: '', remarks: '' }]);
    if(!isBilling){
      setFormData({ ...formData, ['billing']: '' });
    }
    if(!isChallan){
      setFormData({ ...formData, ['challan']: '' });
    }
  };

  const handleRemoveSubtaskRow = (index) => {
    const updatedSubtasks = subtasks.filter((_, i) => i !== index);
    setSubTasks(updatedSubtasks);
    if(!isBilling){
      setFormData({ ...formData, ['billing']: '' });
    }
    if(!isChallan){
      setFormData({ ...formData, ['challan']: '' });
    }
  };

  const handleAddTask = () => {
  
    setFormData({
      reportingDate: '',
      client: null,
      mainTask: '',
      location: '',
      startTime:'',
      endTime:'',
      billing:'',
      challan:''
    });
    setSubTasks([{ subTask: '', NumberOfEntries: '', from: '', to: '', remarks: '' }]);
  };
 
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  };
  const handleSubmit = async () => {
    setIsDisabled(true);
    if ((formData.billing) && subtasks.length !== 1) {
      toast.error("When billing is provided, the number of subtasks must be exactly 1.");
      setIsDisabled(false);
      return;
    }
    if ((formData.challan) && subtasks.length !== 1) {
      toast.error("When challan is provided, the number of subtasks must be exactly 1.");
      setIsDisabled(false);
      return;
    }
  
    if (!formData.client ) {
      toast.error("Client Can't be empty");
      setIsDisabled(false);
      return;
    }
    if (!formData.location ) {
      toast.error("Location Can't be empty");
      setIsDisabled(false);
      return;
    }
    if (!formData.mainTask ) {
      toast.error("Main Task Can't be empty");
      setIsDisabled(false);
      return;
    }
    if (!formData.startTime ) {
      toast.error("Start Time Can't be empty");
      setIsDisabled(false);
      return;
    }
    if (!formData.endTime ) {
      toast.error("End Time Can't be empty");
      setIsDisabled(false);
      return;
    }

if(formData.endTime <= formData.startTime){
  toast.error("End Time Can't less then start time");
  setIsDisabled(false);
      return;
}
const travelTimeInMinutes = convertToMinutes(formData.travelTime??'');
setdailyWorkingTravelTimeString(travelTimeInMinutes)

let showAlert = false;
    const {
      startTime, endTime, location, otherLocation
    } = formData;

      const start = new Date(`1970-01-01T${startTime}:00`);
      const end = new Date(`1970-01-01T${endTime}:00`);

   
    const taskTime = (end - start) / (1000 * 60 * 60); // in hours
    const taskTimeInMinutes = (end - start) / (1000 * 60); // in minutes

    const totalEntries = subtasks.reduce((acc, subtask) => acc + Number(subtask.NumberOfEntries), 0);

    const efficiency = (totalEntries / taskTime).toFixed(2);

    const taskData = {
      ...formData,
      subtasks: subtasks.map(st => ({
        ...st,
        taskPeriodFrom: st.from,
        taskPeriodTo: st.to
      })),
      location: location === 'Any Other' ? otherLocation : location,
      efficiency,
    };
    const finalLocation = location === 'Any Other' ? otherLocation : location;
    // if (location === 'Client Site') {
    //   showAlert = true; 
    // }   
    // if (showAlert) {
    //   Swal.fire({
    //     title: 'Reminder',
    //     text: 'Please fill the visit tracker as you have selected "Client" as the location.',
    //     icon: 'info',
    //     confirmButtonText: 'OK'
    //   });
    // }
    setTaskList([...taskList, taskData]);


    try { 
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addEmployeeDailyWorking', {
        empid: empid,
        email: email,
        fullname: employeeData.FirstName + " " + employeeData.LastName,
        MainTask:formData.mainTask,
        travelTime:dailyWorkingTravelTimeString,
        location:finalLocation,
        taskTime:taskTime,
        taskTimeInMinutes:taskTimeInMinutes,
        totalEntries:totalEntries,
        reportingDate:dailyWorkingDateString,
        billing:formData.billing,
        challan:formData.challan,
        tasks: [taskData],
        salesRows,
        purchaseRows
      }); 
      
      
      toast.success('Tasks submitted successfully');
      setTaskList([]);
      setPurchaseRows([{ 
        type:'purchase',
        nature: '', 
        taxablePurchase: '', 
        igstPurchase: '', 
        cgstPurchase: '', 
        sgstPurchase: '', 
        cessPurchase: '', 
      }]);
      setSalesRows([{
        type:'sales',
        nature: '',
        taxableSales: '',
        igstSales: '',
        cgstSales: '',
        sgstSales: '',
        cessSales: '',
        fieldEnabled: {
          taxableSales: false,
          igstSales: false,
          cgstSales: false,
          sgstSales: false,
          cessSales: false,
        }
      }]);
      handleAddTask(); // Reset form after submission
      setIsDisabled(false);
    } catch (error) {
      console.error('Error submitting or updating tasks:', error);
      toast.error('Failed to submit or update tasks');
    } finally {
      setIsDisabled(false);
    }
  };

  

  const handleSubtaskSelectionChange = (index, value) => {
    const selectedSubtask = subtaskOptions.find(option => option.SubTasks === value);
    setSelectedSubtasks(prev => ({
      ...prev,
      [index]: selectedSubtask || {}
    }));
    handleSubtaskChange(index, 'subTask', value);
  };

  // Filter subtask options to exclude selected subtasks
  const getAvailableSubtaskOptions = (index) => {
    const selectedSubtaskValues = subtasks.map(st => st.subTask);
    return subtaskOptions.filter(option => !selectedSubtaskValues.includes(option.SubTasks) || option.SubTasks === subtasks[index].subTask);
  };

  // Utility function to check if a date is the last day of the month
const isLastDayOfMonth = (dateString) => {
  const date = new Date(dateString);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return date.getDate() === lastDay.getDate();
};

// Memoized condition check for showing sales fields
const shouldShowSalesFields = useMemo(() => {
  return subtasks.some(
    (subtask) => subtask.subTask === "Sales Entry" && isLastDayOfMonth(subtask.to)
  );
}, [subtasks]);

const shouldShowPurchaseFields = useMemo(() => {
  return subtasks.some(
    (subtask) => subtask.subTask === "Purchases Entry" && isLastDayOfMonth(subtask.to)
  );
}, [subtasks]);


  return (
    <div className="container mt-5">
     <div className="" style={{'display':'grid','gridTemplateColumns':'auto auto','border':'1px solid #178954','padding':'10px','borderRadius':'8px'}}>
      <div ><h2>Daily Tasks</h2>
      <h6 className="d-flex align-items-end">Please submit the task in order of their working and in AM/PM format.</h6>
      </div>
    </div>

      {/* <div className='d-flex justify-content-between mt-3'>
      <div className="alert alert-success" role="alert" style={{fontWeight:600}}>
      {renderTaskBadges()}
      </div>
      <div className="alert alert-success" role="alert" style={{fontWeight:600}}>
      {renderPaymentBadges()}
      </div>
      </div> */}
      <form className="mb-2 mt-1" style={{'border':'1px solid #178954','padding':'10px','borderRadius':'8px'}}>
      <div className="row">
      <div className="form-group col-md-3">
          <label>Reporting Date</label>
          <input
            type="text"
            name="reportingDate"
            onChange={handleChange}
            value={dailyWorkingDateString}
            className="form-control"
            disabled
          />
        </div>
        <div className="form-group col-md-3">
            <label>Client</label>
            <Select
              value={clientOptions.find(option => option.value === formData.client) || null}
              onChange={handleClientChange}
              options={clientOptions}
              
            />
          </div>
          
          <div className="form-group col-md-3">
            <label style={{fontSize:'14px'}} htmlFor="startTime">Start Time<b > (Exclude Travel Time)</b></label>
            <input
              type="time"
              className="form-control"
              id="startTime"
              name="startTime"  
              value={formData.startTime}
              onChange={handleChange}
            />
          </div>
          <div className="form-group col-md-3">
            <label style={{fontSize:'14px'}} htmlFor="endTime">End Time<b> (Exclude Travel Time)</b></label>
            <input
              type="time"
              className="form-control"
              id="endTime"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
              
            />
          </div>
        
</div>
<div className="row">
<div className="form-group col-md-3">
          <label>Main Task</label>
          <Select
            name="mainTask"
            value={mainTaskOptions.find(option => option.value === formData.mainTask)}
            onChange={(selectedOption) => handleChange({ target: { name: 'mainTask', value: selectedOption.value } })}
            options={mainTaskOptions}
            isDisabled={isMainTaskDisabled}
            placeholder="Main Task"
          />
          </div>
<div className="form-group col-md-3">
          <label>Location</label>
          <select
            name="location"
            value={formData.location}
            onChange={handleChange}
            className="form-control"
            
          >
            <option value="">Location</option>
            <option value="Office">Office</option>
            <option value="Home">Home</option>
            <option value="Client Visit - Operational">Client Visit - Operational</option>
            <option value="Client Visit - Reviewer">Client Visit - Reviewer</option>
            <option value="Client Visit - Casual">Client Visit - Casual</option>
            <option value="Any Other">Any Other</option>
          </select>
          
        </div>
        
<div className="form-group col-md-3">
  <label>Other Location</label>
            <input
              type="text"
              name="otherLocation"
              placeholder="Please Specify"
              value={formData.otherLocation}
              onChange={handleChange}
              disabled={formData.location !== 'Any Other'}
              className="form-control "
            />
          </div>
            {/* <div className="form-group col-md-3">
              <label htmlFor="remainingVisits">Remaining Visits</label>
              <input
                type="number"
                id="remainingVisits"
                className="form-control"
                value={remainingVisits?.remainingVisits ?? ''}
                disabled
              />
            </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="travelTime">Travel Time </label>
            <input
              type="text"
              id="travelTime"
              name="travelTime"
              onChange={handleChange}
              className="form-control"
              value={formData.travelTime}
              disabled={formData.location !== 'Client Visit - Operational' && 
                formData.location !== 'Client Visit - Reviewer' && 
                formData.location !== 'Client Visit - Casual'}
              placeholder='HH:MM'
            />
          </div>
          
          </div>      
        
     
        <div className="form-group">

          {subtasks.map((subtask, index) => (
            <div key={index} className="row ">
              <div className="col-md-3 mb-2" >
              <label>Sub Task</label>
              <Select
                  name={`subTask-${index}`}  // Optional: Use a unique name for each subtask if needed
                  value={subTaskOptions.find(option => option.value === subtask.subTask)}
                  onChange={(selectedOption) => handleSubtaskSelectionChange(index, selectedOption.value)}
                  options={subTaskOptions}
                  required
                  placeholder="Subtask"
                />
              </div>
              <div className="col-md-2">
              <label htmlFor="taskPeriodFrom">Task Period From</label>
            <input
              type="date"
              className="form-control"
              id="taskPeriodFrom"
              name="taskPeriodFrom"
              disabled={selectedSubtasks[index]?.from === 0}
              value={subtask.from}
           
              onChange={(e) => handleSubtaskChange(index, 'from', e.target.value)}
            />
              </div>
              <div className="col-md-2">
              <label htmlFor="taskPeriodTo">Task Period To</label>
                <input
                  type="date"
                  className="form-control"
                  id="taskPeriodTo"
              name="taskPeriodTo"
                  value={subtask.to}
                  disabled={selectedSubtasks[index]?.to === 0}
                  onChange={(e) => handleSubtaskChange(index, 'to', e.target.value)}
                />
              </div>
              
              <div className="col-md-2">
              <label>Number Of Entries</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Entries"
                  value={subtask.NumberOfEntries}
                  disabled={selectedSubtasks[index]?.NumberOfEntries === 0}
                  onChange={(e) => handleSubtaskChange(index, 'NumberOfEntries', e.target.value)}
                />
              </div>
              <div className="col-md-2">
              <label>Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Remarks"
                  value={subtask.remarks}
                  disabled={selectedSubtasks[index]?.remarks === 0}
                  onChange={(e) => handleSubtaskChange(index, 'remarks', e.target.value)}
                />
              </div>
              
              <div className="col-md-1 mt-4" style={{'padding-right':'2px','display':'flex' ,'align-items':'flex-start','gap':'5px'}}>
        {subtasks.length > 1 && (
          <button
            type="button"
            className="btn btn-success"
            onClick={() => handleRemoveSubtaskRow(index)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        )}
        {index === subtasks.length - 1 && (
          <button type="button" className="btn btn-success" onClick={handleAddSubtaskRow}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}
      </div>
            </div>
          ))}
           

        </div>
        </form>

        {shouldShowSalesFields && (
<div style={{ border: '1px solid #178954', padding: '10px', borderRadius: '8px', marginTop: '8px' }}>
        {salesRows.map((row, index) => (
          <div key={index} className="row align-items-end mb-2">
            {/* Nature Field */}
            <div className="col-md-2">
              <label htmlFor={`nature-${index}`}>Nature</label>
              <select
              className="form-control"
              id={`nature-${index}`}
              name="nature"
              value={row.nature} // Ensures the selected nature is displayed correctly
              onChange={(e) => handleSalesRowChange(index, e)}
            >
              <option value="">Select</option>
              {getAvailableSalesNatures(index).map((nature) => (
                <option key={nature} value={nature}>{nature}</option>
              ))}
            </select>
            </div>

            {/* Taxable Sales Field */}
            <div className="col-md-2">
              <label htmlFor={`taxableSales-${index}`}>TV of Sales</label>
              <input
                type="number"
                className="form-control"
                id={`taxableSales-${index}`}
                name="taxableSales"
                value={row.taxableSales}
                onChange={(e) => handleSalesRowChange(index, e)}
                disabled={!row.fieldEnabled.taxableSales}
              />
            </div>

            {/* IGST Sales Field */}
            <div className="col-md-2">
              <label htmlFor={`igstSales-${index}`}>IGST on Sales</label>
              <input
                type="number"
                className="form-control"
                id={`igstSales-${index}`}
                name="igstSales"
                value={row.igstSales}
                onChange={(e) => handleSalesRowChange(index, e)}
                disabled={!row.fieldEnabled.igstSales}
              />
            </div>

            {/* CGST Sales Field */}
            <div className="col-md-2">
              <label htmlFor={`cgstSales-${index}`}>CGST on Sales</label>
              <input
                type="number"
                className="form-control"
                id={`cgstSales-${index}`}
                name="cgstSales"
                value={row.cgstSales}
                onChange={(e) => handleSalesRowChange(index, e)}
                disabled={!row.fieldEnabled.cgstSales}
              />
            </div>

            {/* SGST Sales Field */}
            <div className="col-md-2">
              <label htmlFor={`sgstSales-${index}`}>SGST/UTGST on Sales</label>
              <input
                type="number"
                className="form-control"
                id={`sgstSales-${index}`}
                name="sgstSales"
                value={row.sgstSales}
                onChange={(e) => handleSalesRowChange(index, e)}
                disabled={!row.fieldEnabled.sgstSales}
              />
            </div>

            {/* Cess Sales Field */}
            <div className="col-md-1">
              <label htmlFor={`cessSales-${index}`}>Cess</label>
              <input
                type="number"
                className="form-control"
                id={`cessSales-${index}`}
                name="cessSales"
                value={row.cessSales}
                onChange={(e) => handleSalesRowChange(index, e)}
                disabled={!row.fieldEnabled.cessSales}
              />
            </div>
            <div className="col-md-1 mt-4" style={{'padding-right':'2px','display':'flex' ,'align-items':'flex-start','gap':'5px'}}>
        {salesRows.length > 1 && (
          <button
            type="button"
            className="btn btn-success"
            onClick={() => deleteSalesRow(index)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        )}
          <button type="button" className="btn btn-success" onClick={addSalesRow}>
            <FontAwesomeIcon icon={faPlus} />
          </button>

      </div>
          </div>
        ))}
      </div>
    )}
    {shouldShowPurchaseFields && (
        <div style={{ border: '1px solid #178954', padding: '10px', borderRadius: '8px', marginTop: '8px' }}>
          {purchaseRows.map((row, index) => (
            <div key={index} className="row">
              <div className="col-md-2">
                <label htmlFor={`nature${index}`}>Nature</label>
                <select
                  className="form-control"
                  id={`nature${index}`}
                  name="nature"
                  value={row.nature}
                  onChange={(e) => handlePurchaseChange(index, e)}
                >
                  <option value="">Select</option>
                  {getAvailablePurchaseNatures(index).map((nature, i) => (
                    <option key={i} value={nature}>{nature}</option>
                  ))}
                </select>
              </div>

              <div className="col-md-2">
                <label htmlFor={`taxablePurchase${index}`}>TV of Purchases</label>
                <input
                  type="number"
                  className="form-control"
                  id={`taxablePurchase${index}`}
                  name="taxablePurchase"
                  placeholder="Taxable Purchase Amount"
                  value={row.taxablePurchase}
                  onChange={(e) => handlePurchaseChange(index, e)}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor={`igstPurchase${index}`}>IGST on Purchases</label>
                <input
                  type="number"
                  className="form-control"
                  id={`igstPurchase${index}`}
                  name="igstPurchase"
                  placeholder="IGST Amount"
                  value={row.igstPurchase}
                  onChange={(e) => handlePurchaseChange(index, e)}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor={`cgstPurchase${index}`}>CGST on Purchases</label>
                <input
                  type="number"
                  className="form-control"
                  id={`cgstPurchase${index}`}
                  name="cgstPurchase"
                  placeholder="CGST Amount"
                  value={row.cgstPurchase}
                  onChange={(e) => handlePurchaseChange(index, e)}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor={`sgstPurchase${index}`}>SGST/UTGST</label>
                <input
                  type="number"
                  className="form-control"
                  id={`sgstPurchase${index}`}
                  name="sgstPurchase"
                  placeholder="SGST/UTGST Amount"
                  value={row.sgstPurchase}
                  onChange={(e) => handlePurchaseChange(index, e)}
                />
              </div>
              <div className="col-md-1">
                <label htmlFor={`cessPurchase${index}`}>Cess</label>
                <input
                  type="number"
                  className="form-control"
                  id={`cessPurchase${index}`}
                  name="cessPurchase"
                  placeholder="Cess Amount"
                  value={row.cessPurchase}
                  onChange={(e) => handlePurchaseChange(index, e)}
                />
              </div>
              <div className="col-md-1 mt-4" style={{'padding-right':'2px','display':'flex' ,'align-items':'flex-start','gap':'5px'}}>
        {purchaseRows.length > 1 && (
          <button
            type="button"
            className="btn btn-success"
            onClick={() => deletePurchaseRow(index)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        )}
          <button type="button" className="btn btn-success" onClick={addPurchaseRow}>
            <FontAwesomeIcon icon={faPlus} />
          </button>

      </div>
            </div>
          ))}
        </div>
      )}
          <div style={{'border':'1px solid #178954','padding':'10px','borderRadius':'8px', 'marginTop':'8px'}}>
      <div className="row">
          <div className="col-md-3">
          <label htmlFor="challan">Challan Amount</label>
          <input
            type="text"
            className="form-control"
            id="challan"
            name="challan"
            placeholder="Challan Amount"
            value={formData.challan}
            onChange={handleChange}
            disabled={!(isMGEmployee && isChallan)}
            />
        </div>

        <div className="col-md-3">
          <label htmlFor="billing">Billing Amount </label>
          <input
            type="text"
            className="form-control"
            id="billing"
            name="billing"
            placeholder="Billing Amount"
            value={formData.billing}
            onChange={handleChange}
            disabled={!(isMGEmployee && isBilling)}
            />
        </div>
          </div>
      </div>
          <div style={{'border':'1px solid #178954','padding':'10px','borderRadius':'8px', 'marginTop':'8px'}}>
          <button className="btn btn-success" onClick={handleSubmit} disabled={isDisabled}>
        Submit
      </button>
      </div>
      
      

      <ToastContainer />
    </div>
  );
};

export default DailyWorking;
