import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const EditTeamMember = () => {
  const { id } = useParams();
  
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getUser/${id}`);
        const data = response.data[0];

        // Convert ISO time to HH:mm format
        const formatTime = (isoString) => {
          if (!isoString) return '';
          const date = new Date(isoString);
          return date.toISOString().substring(11, 16); // Extract HH:mm
        };

        setFormData({
          ...data,
          ScheduleIn: formatTime(data.ScheduleIn),
          ScheduleOut: formatTime(data.ScheduleOut)
        });

      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Unable to fetch user data.',
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Convert time to ISO format
    const convertToISO = (time) => {
      if (!time) return '';
      const [hours, minutes] = time.split(':');
      return new Date(`1970-01-01T${hours}:${minutes}:00.000Z`).toISOString();
    };

    const updatedData = {
      ...formData,
      ScheduleIn: convertToISO(formData.ScheduleIn),
      ScheduleOut: convertToISO(formData.ScheduleOut)
    };

    Swal.fire({
      toast: true,
      position: 'top-end',
      icon: 'info',
      text: 'Updating...',
      showConfirmButton: false,
      timer: 3000
    });
  
    try {
      const response = await axios.put(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateUser/${id}`, updatedData);

      if (response.status === 200) {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: response.data.message || 'User updated successfully',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          navigate('../userlist');
        });
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: response.data.message || 'Update failed',
          showConfirmButton: false,
          timer: 3000
        });
      }
    } catch (error) {
      console.error('There was an error!', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 3000
      });
    }
  };
  
  return (
    <div className='mainscreenadmin'>
      <div className="container formcontainer">
        <form onSubmit={handleSubmit}>
        <div className="row">
                    <div className="col-md-3">
                    <button style={{cursor: 'pointer'}}
                        className="back-icon btn btn-success" 
                        onClick={() => navigate('../userlist')}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                    </div>
                    <div className="col-md-6 ">
                    <p className='para-heading'>Edit Team Member</p>
                      </div>
                    </div>
          
          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>First Name</label>
                <input type="text" className="form-control" placeholder="First Name" name="FirstName" value={formData.FirstName} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Last Name</label>
                <input type="text" className="form-control" placeholder="Last Name" name="LastName" value={formData.LastName} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Address</label>
                <input type="text" className="form-control" placeholder="Enter Address" name="Address" value={formData.Address} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Contact No</label>
                <input type="text" className="form-control" placeholder="Contact Number" name="ContactNo" value={formData.ContactNo} onChange={handleChange} required />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Email Address</label>
                <input type="email" className="form-control" placeholder="Email Address" name="Email" value={formData.Email} onChange={handleChange} disabled/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Password" name="Password" value={formData.Password} onChange={handleChange} disabled/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Monthly Remuneration</label>
                <input type="number" className="form-control" placeholder="Monthly Remuneration" name="MonthlyRemuneration" value={formData.MonthlyRemuneration} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Position</label>
                <input type="text" className="form-control" placeholder="Position" name="Position" value={formData.Position} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Monthly Leaves Allowed</label>
                <input type="number" className="form-control" placeholder="Monthly Leaves Allowed" name="MonthlyLeaves" value={formData.MonthlyLeaves} onChange={handleChange} required/>
              </div>
              <div className="col-md-6 inputdiv">
                <label>Team Member ID</label>
                <input type="text" className="form-control" placeholder="Team Member ID" name="empid" value={formData.empid} onChange={handleChange} required/>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Working Hours Type</label>
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>
                  <input type="radio" name="hoursType" value="fixed" checked={formData.hoursType === 'fixed'} onChange={handleChange} /> Fixed Hours
                </label>
              </div>
              <div className="col-md-6 inputdiv">
                <label>
                  <input type="radio" name="hoursType" value="floating" checked={formData.hoursType === 'floating'} onChange={handleChange} /> Floating Hours
                </label>
              </div>
            </div>
          </div>

          {formData.hoursType === 'fixed' && (
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 inputdiv">
                  <label>Schedule In</label>
                  <input type="time" className="form-control" placeholder="Schedule In" name="ScheduleIn" value={formData.ScheduleIn} onChange={handleChange} required/>
                </div>
                <div className="col-md-6 inputdiv">
                  <label>Schedule Out</label>
                  <input type="time" className="form-control" placeholder="Schedule Out" name="ScheduleOut" value={formData.ScheduleOut} onChange={handleChange} required/>
                </div>
              </div>
            </div>
          )}

          {formData.hoursType === 'floating' && (
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 inputdiv">
                  <label>Number of Hours</label>
                  <input type="number" className="form-control" placeholder="Number of Hours" name="floatingHours" value={formData.floatingHours} onChange={handleChange} required/>
                </div>
              </div>
            </div>
          )}
          
          <div className="form-group">
            <div className="row">
              
            </div>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn-success">Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTeamMember;
