import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure you have this for the modal
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
import '../styles/teamMember.css';
import moment from 'moment-timezone';

const TeamMemberProfile = () => {
  const [profileData, setProfileData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const email = sessionStorage.getItem('email'); // Get email from session storage
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email }
        });
        if (response.data && response.data.length > 0) {
          setProfileData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);

  const handleViewImage = async () => {
    if (profileData.SignImage) {
      try {
        // Convert binary data to Base64 string
        const base64 = Buffer.from(profileData.SignImage, 'binary').toString('base64');
        const imgSrc = `data:image/png;base64,${base64}`;

        setImageSrc(imgSrc);
        setShowModal(true);
      } catch (error) {
        console.error('There was an error fetching the sign image!', error);
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: 'An error occurred. Please try again.',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  };
  const scheduleInIST = profileData.ScheduleIn.replace('Z', '');
  const ScheduleInTime = moment(scheduleInIST).format('HH:mm');
  const scheduleOutIST = profileData.ScheduleOut.replace('Z', '');
  const ScheduleOutTime = moment(scheduleOutIST).format('HH:mm');
  return (
    <div className='mainscreenadmin mt-5'>
      <div className="container formcontainer">
        <p className='para-heading'>Profile</p>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6 inputdiv">
              <label>First Name</label>
              <input type="text" className="form-control" value={profileData.FirstName} readOnly />
            </div>
            <div className="col-md-6 inputdiv">
              <label>Last Name</label>
              <input type="text" className="form-control" value={profileData.LastName} readOnly />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 inputdiv">
              <label>Address</label>
              <input type="text" className="form-control" value={profileData.Address} readOnly />
            </div>
            <div className="col-md-6 inputdiv">
              <label>Contact No</label>
              <input type="text" className="form-control" value={profileData.ContactNo} readOnly />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 inputdiv">
              <label>Email Address</label>
              <input type="email" className="form-control" value={profileData.Email} readOnly />
            </div>
            <div className="col-md-6 inputdiv">
              <label>Password</label>
              <input type="password" className="form-control" value={profileData.Password} readOnly />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 inputdiv">
              <label>Monthly Remuneration</label>
              <input type="number" className="form-control" value={profileData.MonthlyRemuneration} readOnly />
            </div>
            <div className="col-md-6 inputdiv">
              <label>Position</label>
              <input type="text" className="form-control" value={profileData.Position} readOnly />
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 inputdiv">
              <label>Monthly Leaves Allowed</label>
              <input type="number" className="form-control" value={profileData.MonthlyLeaves} readOnly />
            </div>
            <div className="col-md-6 inputdiv">
              <label>Team Member ID</label>
              <input type="text" className="form-control" value={profileData.empid} readOnly />
            </div>
          </div>
        </div>

        {profileData.hoursType === 'fixed' && (
          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Schedule In</label>
                <input type="time" className="form-control" value={ScheduleInTime} readOnly />
              </div>
              <div className="col-md-6 inputdiv">
                <label>Schedule Out</label>
                <input type="time" className="form-control" value={ScheduleOutTime} readOnly />
              </div>
            </div>
          </div>
        )}

        {profileData.hoursType === 'floating' && (
          <div className="form-group">
            <div className="row">
              <div className="col-md-6 inputdiv">
                <label>Number of Hours</label>
                <input type="number" className="form-control" value={profileData.floatingHours} readOnly />
              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
};

export default TeamMemberProfile;
