import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [generatedOtp, setGeneratedOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const generateOtp = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const sendOtp = async () => {
    const otp = generateOtp();
    setGeneratedOtp(otp);

    try {
      const response = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/send-otp`, { email, otp });
      if (response.data.success) {
        setIsOtpSent(true);
        toast.success('OTP sent to your email');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error sending OTP');
    }
  };

  const verifyOtp = () => {
    if (otp === generatedOtp) {
      setIsOtpVerified(true);
      toast.success('OTP verified');
    } else {
      toast.error('Invalid OTP');
    }
  };

  const updatePassword = async () => {
    try {
      const response = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/update-password`, { email, password: newPassword });
      toast.success(response.data.message);
      
      // Reset states to initial values
      setEmail('');
      setOtp('');
      setGeneratedOtp('');
      setIsOtpSent(false);
      setIsOtpVerified(false);
      setNewPassword('');
      setShowPassword(false);
    } catch (error) {
      toast.error('Error updating password');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-md-center">
        <div className="col-md-6">
          <h2 className="mb-4">Forgot Password</h2>
          {!isOtpSent ? (
            <form>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="button" className="btn btn-success mt-3" onClick={sendOtp}>Send OTP</button>
            </form>
          ) : (
            <>
              <form>
                <div className="form-group">
                  <label htmlFor="otp">OTP</label>
                  <input
                    type="text"
                    className="form-control"
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <button type="button" className="btn btn-success mt-3" onClick={verifyOtp}>Verify OTP</button>
              </form>
              {isOtpVerified && (
                <form className="mt-3">
                  <div className="form-group">
                    <label htmlFor="new-password">New Password</label>
                    <div className="position-relative">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                      <span className="position-absolute" style={{ right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          onClick={toggleShowPassword}
                        />
                      </span>
                    </div>
                  </div>
                  <button type="button" className="btn btn-success mt-3" onClick={updatePassword}>Update Password</button>
                </form>
              )}
            </>
          )}
          <button className="btn btn-success mt-3" onClick={() => navigate('/')}>Back to Login</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
