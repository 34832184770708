import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DateRangePickerComponent from './CustomDateRangePicker'

const MyTimesheet = () => {
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [users, setUsers] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: '',
  });
  const email = sessionStorage.getItem('email');

  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]);
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    // Fetch users from backend
    axios
      .get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const fetchTimesheetData = () => {
    let route = '';
    let requestData = {};
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                console.error("Please select a valid date range");
                return;
            }

    if (startDate && endDate) {
      route = 'https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getEmployeeTimesheet';
      requestData = { 
        email, 
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'), 
      };
    } 

    if (route) {
      axios
        .post(route, requestData)
        .then((response) => {
          setTimesheetData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching timesheet data:', error);
        });
    } else {
      setTimesheetData([]);
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Please select a start date, end date, a user, or both.',
        showConfirmButton: false,
        timer: 2000,
        toast: true,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchTimesheetData();
  };

  const handlePreview = (row) => {
    // Navigate to timesheet detail page based on hoursType
    if (row.original.hoursType === 'fixed') {
      navigate(`/employeetimesheetfixed/${row.original.empid}`, {
        state: {
          empid: row.original.empid,
          timesheetDate: moment(row.original.timesheetDate).format('YYYY-MM-DD'),
          email: row.original.email,
        },
      });
    } else {
      navigate(`/employeetimesheetfloating/${row.original.empid}`, {
        state: {
          empid: row.original.empid,
          timesheetDate: moment(row.original.timesheetDate).format('YYYY-MM-DD'),
          email: row.original.email,
        },
      });
    }
  };

  const TimeDisplay = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timesheetDate',
        Cell: ({ value }) => moment(value).format('DD-MMM-YYYY'),
      },
      {
        Header: `D'IN`,
        accessor: 'dayInTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `D'Out`,
        accessor: 'dayOutTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `D'Time`,
        accessor: 'dayTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `ADJ D'Time`,
        accessor: 'actualDayTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: 'Prod Time',
        accessor: 'totalProductiveTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: 'UnP Time',
        accessor: 'totalUnproductiveTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `ADJ D'Time (%)`,
        accessor: 'actualDayTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const actualDayTime = row.original.actualDayTime || 0;
          return dayTime > 0 ? `${((actualDayTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
      {
        Header: 'Prod Time (%)',
        accessor: 'productiveTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const productiveTime = row.original.totalProductiveTime || 0;
          return dayTime > 0 ? `${((productiveTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
      {
        Header: 'UnP Time (%)',
        accessor: 'unproductiveTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const unproductiveTime = row.original.totalUnproductiveTime || 0;
          return dayTime > 0 ? `${((unproductiveTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
      {
        Header: 'Status',
        accessor: 'dailyWorkingChecked',
        Cell: ({ value }) => {
          if (value === null) return 'Pending';
          if (value === 1) return 'Accepted';
          return value;
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-around">
              <button className="btn btn-info btn-sm" onClick={() => handlePreview(row)}>
                View
              </button>
            </div>
          );
        },
      },
    ],
    []
  );
  

  const tableInstance = useTable({ columns, data: timesheetData });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="container">
      <h2 className="my-4 mt-5">Daily Time Summary </h2>
      <form className="mb-4" onSubmit={handleSubmit}>
        <div className="row">
        {/* <div className="col-md-6">
          <label htmlFor="startDate">Start Date:</label>
          <input
            type="date"
            id="startDate"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="endDate">End Date:</label>
          <input
            type="date"
            id="endDate"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div> */}
        <div className="col-md-3">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
        <div className="col-md-4 d-flex align-items-end">
        <button type="submit" className="btn btn-success mt-3">
          Get Timesheet
        </button>
        </div>
        </div>
      </form>

      {timesheetData.length > 0 ? (
        <table style={{fontSize:'14px'}} {...getTableProps()} className="table table-striped table-bordered">
          <thead className="thead-dark">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className='alert alert-info'> No data available</div>
      )}
    </div>
  );
};

export default MyTimesheet;
