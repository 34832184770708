import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Swal from 'sweetalert2';

library.add(faSync);

const AdminAccessRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false); // State to trigger re-fetch

//   const email=sessionStorage.getItem('email')

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/adminallclientaccess');
        setRequests(response.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, [refresh]); // Add refresh as a dependency

  const handleRefresh = () => {
    setRefresh(!refresh); // Toggle refresh to re-fetch data
  };

  const handleUpdateAccess = async (empid,clientId, access) => {
    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/adminapproveaccess', {empid,clientId, access });
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'success',
        text: `Access ${access === 1 ? 'Approved' : 'Denied'}`,
        showConfirmButton: false,
        timer: 3000
      });
      setRefresh(!refresh); // Trigger data re-fetch
    } catch (error) {
      console.error('Error updating access status:', error);
      Swal.fire({
        icon: 'error',
        text: 'Failed to update access status',
      });
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4>Admin Requests</h4>
        <button className="btn btn-secondary" onClick={handleRefresh}>
          <FontAwesomeIcon icon={faSync} /> Refresh
        </button>
      </div>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        requests.length === 0 ? (
          <p>No requests found.</p>
        ) : (
          <table className="table table-striped">
            <thead style={{fontSize:'15px',verticalAlign:'baseline'}}>
              <tr>
                <th>TM ID</th>
                <th>Full Name</th>
                <th>Client Code</th>
                <th>Client Name</th>
                <th>Access</th>
              </tr>
            </thead>
            <tbody style={{fontSize:'14px',verticalAlign:'baseline'}}>
              {requests.map((request, index) => (
                <tr key={index}>
                  <td>{request.empid[0]}</td>
                  <td>{request.FirstName} {request.LastName}</td>
                  <td>{request.cc}</td>
                  <td>{request.ClientName}</td>
                  <td>
                    <button
                      className="btn btn-success me-2"
                      style={{fontSize:'13px'}}
                      onClick={() => handleUpdateAccess(request.empid,request.cc, 1)}
                    >
                      Approve
                    </button>
                    <button
                      className="btn btn-danger"
                      style={{fontSize:'13px'}}
                      onClick={() => handleUpdateAccess(request.empid,request.cc, null)}
                    >
                      Deny
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      )}
    </div>
  );
};

export default AdminAccessRequests;
