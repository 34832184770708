import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import '../styles/TdsEditModal.css';

const EditStatusModal = ({ 
  request, 
  statusType, 
  dateType, 
  editedKey, 
  amountKey, 
  editedDate,
  onClose,
  handleRefresh 
}) => {
  const [status, setStatus] = useState('');
  const [date, setDate] = useState('');
  const [tableData, setTableData] = useState([{ section: '', code: '', actualAmount: '', percentage: '', FinalAmount: '', month: '' }]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [currentMonth] = useState(moment());

  useEffect(() => {
    // Fetch TDS Master Data for sections
    const fetchdate = request[dateType] ? moment(request[dateType]).format('YYYY-MM') : 'N/A';
    setDate(fetchdate);
    const fetchTDSMasterData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getTDSMasterData');
        const options = response.data.map((item) => {
          // Concatenate person names, separated by commas
          const personsNames = item.persons.map(person => person.name).join(', ');
        
          return {
            value: item.sectionNumber,
            label: `${item.sectionNumber}- ${personsNames ? `(${personsNames})` : ''}`,
            code: item.codeNumber,
            percentage: item.percentage, // Assume percentage is part of the data
            description: item.description, // Add description to the options
          };
        });
        setSectionOptions(options);
      } catch (error) {
        console.error("Error fetching TDS master data:", error);
      }
    };
    
    fetchTDSMasterData();
  }, []);

  useEffect(() => {
    // Reset table data to one row if status is not 'Paid'
    if (status !== 'Paid') setTableData([{ section: '', code: '', actualAmount: '', percentage: '', FinalAmount: '', month: '' }]);
  }, [status]);

  const handleAddRow = () => {
    setTableData([...tableData, { section: '', code: '', actualAmount: '', percentage: '', FinalAmount: '', month: '' }]);
  };

  const handleDeleteRow = (index) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, i) => i !== index));
    }
  };

  const handleChangeRowData = (index, field, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index][field] = value;

    // Calculate TDS amount if actual amount is updated
    if (field === 'actualAmount') {
      const percentage = updatedTableData[index].percentage || 0;
      updatedTableData[index].FinalAmount = (value * (percentage / 100)).toFixed(2); // Calculate TDS amount
    }

    setTableData(updatedTableData);
  };
  const handleSectionChange = (index, selectedOption) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].section = selectedOption.value;
    updatedTableData[index].code = selectedOption.code;
    updatedTableData[index].percentage = selectedOption.percentage;
    updatedTableData[index].FinalAmount = (updatedTableData[index].actualAmount * (selectedOption.percentage / 100)).toFixed(2);
    updatedTableData[index].personsNames = selectedOption.label.split('(')[1]?.replace(')', '') || '';
    updatedTableData[index].description = selectedOption.description; // Set the description from the selected option
    setTableData(updatedTableData);
  };
  

  const handleSubmit = async () => {
    const selectedMonth = moment(date);
    const lastFilledMonth = moment(request[dateType]);
    const dbstatusKey = request[statusType] || '';
  
    if (dbstatusKey === 'Filled' && selectedMonth.isBefore(lastFilledMonth)) {
      Swal.showValidationMessage('You cannot select an earlier month than the last "Filled" month.');
      return;
    }
  
    if ((dbstatusKey === 'Paid' || dbstatusKey === 'Paid by CA/TP') && selectedMonth.isBefore(lastFilledMonth)) {
      Swal.showValidationMessage('You cannot select an earlier month than the last "Paid" month.');
      return;
    }
  
    if (
      (selectedMonth.isSame(currentMonth, 'month') && selectedMonth.isSame(currentMonth, 'year')) ||
      selectedMonth.isAfter(currentMonth)
    ) {
      Swal.showValidationMessage('You cannot file a return for the current or future months as they are not yet completed.');
      return;
    }
  
    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updatetdsstatus', {
        email: request.email,
        empid: request.empid[0],
        FullName: request.FullName,
        CC: request.CC,
        status,
        date,
        tableData,
        amountKey,
        statusKey: statusType,
        dateType,
        editedKey,
        editedDate,
        TradeName: request.TradeName,
      });
  
      Swal.fire({
        text: `status has been updated.`,
        icon: 'success',
        toast: true,
        showConfirmButton: false,
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        willClose: handleRefresh
      });
  
    } catch (error) {
      Swal.fire('Error!', 'Failed to update the status.', 'error');
      console.error('Error updating status:', error);
    }
  };
  
  const statusOptions = statusType === 'tdsChallanFreqStatus' || statusType === 'tcsChallanFreqStatus'
    ? ['Amt Pending', 'Paid', 'Paid by CA/TP', 'To be paid', 'Not Required', 'Save as Draft']
    : [
      "Challan Unpaid",
      "Data not recd",
      "Filled",
      "Filed - WRT",
      "Submitted",
      "Submitted to CA/TP",
      "Suspended",
      "To be Filed",
      "Uploaded",
      "Verified"
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <label className="form-label float-start">Team Member</label>
          <input type="text" className="form-control" value={request.empid[0]} disabled />
        </div>
        <div className="col-md-6 mt-1">
          <label className="form-label float-start">Date</label>
          <input 
            type="month" 
            className="form-control" 
            value={date} 
            onChange={(e) => setDate(e.target.value)} 
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="form-label float-start">Status</label>
          <select 
            className="form-select" 
            value={status} 
            onChange={(e) => setStatus(e.target.value)} >
            <option value=" ">Select Option</option>
            {statusOptions.map((option) => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-1">
          <label className="form-label float-start">Last Edited</label>
          <input type="text" className="form-control" value={`${request[editedKey] || 'N/A'}/${moment(request[editedDate]).format('DD-MMM-YY') || 'N/A'}`} disabled />
        </div>
      </div>

      {(status === 'Paid' || status === 'Paid by CA/TP' || status === 'Save as Draft') && (
        <div className="table-responsive mt-2">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Section</th>
                <th>Code</th> 
                <th>Amount</th>
                <th>%</th>
                <th>TDS Amount</th>
                <th>Month</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td style={{ width: '30%' }}>
                    <Select
                      options={sectionOptions}
                      value={sectionOptions.find(option => option.value === row.sectionNumber)}
                      onChange={(selectedOption) => handleSectionChange(index, selectedOption)}
                    />
                    {row.description && (
                      <span className="description-tooltip">
                        <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
                        <span className="tooltip-text">{row.description}</span>
                      </span>
                    )}
                  </td>
                  <td style={{ width: '10%' }}>
                    <input 
                      type="text" 
                      className="form-control" 
                      value={row.code} 
                      onChange={(e) => handleChangeRowData(index, 'code', e.target.value)} 
                      disabled
                    />
                  </td>
                  <td>
                    <input 
                      type="number" 
                      className="form-control" 
                      value={row.actualAmount} 
                      onChange={(e) => handleChangeRowData(index, 'actualAmount', e.target.value)} 
                    />
                  </td>
                  <td style={{ width: '7%' }}>
                    <input 
                      type="number" 
                      className="form-control" 
                      value={row.percentage} 
                      onChange={(e) => handleChangeRowData(index, 'percentage', e.target.value)} 
                      disabled
                    />
                  </td>
                  <td>
                    <input 
                      type="number" 
                      className="form-control" 
                      value={row.FinalAmount} 
                      onChange={(e) => handleChangeRowData(index, 'FinalAmount', e.target.value)} 
                      disabled
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <input 
                      type="month" 
                      className="form-control" 
                      value={row.month} 
                      onChange={(e) => handleChangeRowData(index, 'month', e.target.value)} 
                    />
                  </td>
                  <td style={{ width: '10%' }}>
                    <button 
                      className="btn btn-danger me-1" 
                      onClick={() => handleDeleteRow(index)} 
                      disabled={tableData.length === 1}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button 
                      className="btn btn-primary" 
                      onClick={handleAddRow} 
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button onClick={handleSubmit} className="btn btn-success mt-2">Update</button>
    </div>
  );
};

export default EditStatusModal;
