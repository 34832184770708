import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';

const SOPForm = () => {
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const [clients, setClients] = useState([]);
  const [clientId, setClientId] = useState(null); // Store selected client ID
  const [formData, setFormData] = useState({}); // Store client details and SOP data

  // Fetch employee data
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
        fetchAssignedClients(employeeData.empid);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
    }
  }, [email]);

  // Fetch clients assigned to the employee
  const fetchAssignedClients = (empid) => {
    axios
      .get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${empid}`)
      .then((response) => {
        // Filter clients where visitRequired is 'Yes'
        const filteredClients = response.data.filter(client => client.sopRequired === 'Yes');
        const clientOptions = filteredClients.map(client => ({
          value: client.CC,
          label: `${client.CC} - ${client.TradeName}`,
        }));
        setClients(clientOptions);
      })
      .catch((error) => console.error('Error fetching clients:', error));
  };

  // Fetch client data and SOP details when a client is selected
  useEffect(() => {
    if (clientId) {
      const fetchClientData = async () => {
        try {
          const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/client/${clientId}`);
          if (response.status === 200) {
            setFormData(response.data);
          } else {
            Swal.fire({
              toast: true,
              position: 'top-end',
              icon: 'error',
              text: 'Failed to load client data.',
              showConfirmButton: false,
              timer: 3000,
            });
          }
        } catch (error) {
          console.error('There was an error fetching the client data!', error);
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            text: 'An error occurred. Please try again.',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      };

      fetchClientData();
    }
  }, [clientId]);

  // Handle client select change
  const handleClientChange = (selectedOption) => {
    setClientId(selectedOption.value);
  };

  return (
    <div className="container mt-5">
      <h4>Client's SOP</h4>
      <div className="form-group">
        <h6 htmlFor="clientSelect">Select Client</h6>
        <Select
          id="clientSelect"
          options={clients}
          onChange={handleClientChange}
          placeholder="Select a client..."
        />
      </div>

      {clientId && (
        <div className="mt-4">
          <div className="form-group">
            <h6> General Accounting</h6>
            <textarea className="form-control" value={formData.sopGeneralAccounting || ''} disabled />
          </div>
          <div className="form-group">
            <h6> Operation</h6>
            <textarea className="form-control" value={formData.sopOperation || ''} disabled />
          </div>
          <div className="form-group">
            <h6> Inventory</h6>
            <textarea className="form-control" value={formData.sopInventory || ''} disabled />
          </div>
          <div className="form-group">
            <h6> GST</h6>
            <textarea className="form-control" value={formData.sopGST || ''} disabled />
          </div>
          <div className="form-group">
            <h6> Income Tax</h6>
            <textarea className="form-control" value={formData.sopIncomeTax || ''} disabled />
          </div>
        </div>
      )}
    </div>
  );
};

export default SOPForm;
