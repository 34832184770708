import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AssignTasksByReport = () => {
  const location = useLocation();
  const { cc, tradeName, mainTask, subTask } = location.state || {};
  const navigate = useNavigate(); // Initialize the useNavigate hook
  // State for form inputs
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState('');
  const [deadline, setDeadline] = useState('');
  const [remarks, setRemarks] = useState('');
  const sessionEmail = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: sessionEmail },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (sessionEmail) {
      fetchEmployeeData();
    }
  }, [sessionEmail]);

  // Fetch members based on client cc when component mounts
  useEffect(() => {
    if (cc) {
      axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getEmployeesByCC/${cc}`)
        .then((response) => {
          setMembers(response.data); // Store the fetched members in state
        })
        .catch((error) => {
          console.error('Error fetching members:', error);
        });
    }
  }, [cc]);

  // Function to handle form submission
// Function to handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  const formData = {
    cc: cc,
    empid: selectedMember, // Selected member from the dropdown
    mainTask: mainTask,
    subTask: subTask,
    deadline: deadline || '',
    remarks: remarks || '',
    assignedBy: employeeData.FirstName, // Assigned by employee's first name
    email: sessionEmail // Session email
  };

  try {
    // Send the data to the backend to save
    const response = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/saveAssignedTask`, formData);

    if (response.status === 200) {
      console.log('Assigned Task saved successfully:', response.data);

      // Show success message using Swal
      Swal.fire({
        icon: 'success',
        title: 'Task assigned successfully!',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });

      // Navigate to ../clientReport after success message
      setTimeout(() => {
        navigate('../clientReport');
      }, 3000); // Wait for the Swal message to disappear before navigating
    } else {
      console.error('Error saving the assigned task');

      // Show error message using Swal
      Swal.fire({
        icon: 'error',
        title: 'Failed to save task!',
        text: 'Please try again.',
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });
    }
  } catch (error) {
    console.error('Error while saving the task:', error);

    // Show error message using Swal
    Swal.fire({
      icon: 'error',
      title: 'An error occurred!',
      text: 'Please check the console.',
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }
};


  return (
    <div className="container mt-5">
      <p className="para-heading">Assign Tasks</p>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="client" className="form-label">Client</label>
            <input
              type="text"
              className="form-control"
              id="client"
              value={`${cc} - ${tradeName}`}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="member" className="form-label">Member</label>
            <select
              className="form-control"
              id="member"
              value={selectedMember}
              onChange={(e) => setSelectedMember(e.target.value)}
              required
            >
              <option value="">Select Member</option>
              {members.map((member) => (
                <option key={member.empid} value={member.empid}>
                  {member.empid} - {member.FirstName} {member.LastName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="mainTask" className="form-label">Main Task</label>
            <input
              type="text"
              className="form-control"
              id="mainTask"
              value={mainTask}
              readOnly
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="subTask" className="form-label">Sub Task</label>
            <input
              type="text"
              className="form-control"
              id="subTask"
              value={subTask}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="deadline" className="form-label">Deadline</label>
            <input
              type="date"
              className="form-control"
              id="deadline"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="remarks" className="form-label">Remarks</label>
            <textarea
              className="form-control"
              id="remarks"
              rows="1"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              placeholder="Enter remarks"
            />
          </div>
        </div>

        <button type="submit" className="btn btn-success">Assign Task</button>
      </form>
    </div>
  );
};

export default AssignTasksByReport;
