import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/teamMember.css';
import axios from 'axios';
import Select from 'react-select';
const Swal = require('sweetalert2');

 const AddClient = () => {
            const [formData, setFormData] = useState({
                cc: '',
                clientName: '',
                pan: '',
                tradeName: '',
                gstin: '',
                gstId: '',
                gstPass: '',
                ewayId: '',
                ewayPass: '',
                irnId: '',
                irnPass: '',
                itId: '',
                itPass: '',
                tan: '',
                tracesId: '',
                tracesPass: '',
                email: '',
                password: '',
                numberofvisits: '',
                inweeks: '',
                Priority: '',
                timeLag: '',
                timeLagReporting:'',
                gstrf: '',
                gstar1: '',
                gstar2: '',
                gstar3: '',
                gsthly:'',
                itar: '',
                reportingFrequency: '',
                levelOfBOA: '',
                tdsApplicability: 'No',           // Added new field for TDS Applicability
                tdsApplicableReturn: '',          // Added new field for TDS Applicable Return
                tcsApplicability: 'No',           // Added new field for TCS Applicability
                tcsApplicableReturn: ''  ,
                ecomApplicabilty:'No',
                ecomDetails: [
                    { sno: 1, portal: '', id: '', password: '' }
                ],
                gstportalmobile:'',
               gstportalemail:'' ,
               einvoiceportalmobile:'',
               einvoiceportalemail:'',
               ewayportalemail:'',
               ewayportalmobile:'',
               ITportalemail:'',
               ITportalmobile:'',
               tcsChallan:'',
               tdsChallan:'',
               tdsReturnFreq:'',
               tcsReturnFreq:'',
               tdsChallanFreq:'',
               tcsChallanFreq:''  ,
               itChallan:'' ,
               itfreq:'',
               gstApplicable:'No',
               gstReconRequired:'No',
               visitRequired:'No',
               mobileNo:'',
               alternateMobile:'',
               gstChallan:'',
               gstChallanFrequency:'',
               ITDPass:'',
               stdTime:'',
               absoluteTime:'',
               stdTimeInWeeks:'',
               sopRequired: 'No',
               sopGeneralAccounting: '',
               sopOperation: '',
               sopInventory: '',
               sopGST: '',
               sopIncomeTax: '',
               needTransporter: 'No',
               transporterDetails: [
              { sno: 1, transporterName: '', gstin: '', address: '' }
              ],
              thirdPartyConsultant:'No',
              consultantName:'',
              consultantNo:''
            });

            const [activeTab, setActiveTab] = useState('basic'); // Control active tab
            const [isFormDisabled, setIsFormDisabled] = useState(true);
            const [isEcomFormDisabled, setIsEcomFormDisabled] = useState(true);
            const [transporters, setTransporters] = useState([]);

            useEffect(() => {
              const fetchTransporters = async () => {
                  try {
                      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getTransporters');
                      if (Array.isArray(response.data)) {
                          setTransporters(response.data);
                      } else {
                          setTransporters([]);
                      }
                  } catch (error) {
                      console.error('Error fetching transporters:', error);
                      setTransporters([]);
                      Swal.fire({
                          icon: 'error',
                          title: 'Error fetching transporters',
                          text: 'Please try again later.',
                          toast: true,
                          position: 'top-end',
                          showConfirmButton: false,
                          timer: 1500,
                          timerProgressBar: true,
                      });
                  }
              };
      
              fetchTransporters();
          }, []);

            const handleChange = (e) => {
                const { name, value } = e.target;
            
                // Update the form data with the current change
                setFormData(prevState => ({ ...prevState, [name]: value }));
            
                // Check the applicability conditions based on the current input
                if ((name === "tdsApplicability" && value === "No" && formData.tcsApplicability === "No") || 
                    (name === "tcsApplicability" && value === "No" && formData.tdsApplicability === "No")) {
                    // Disable form and clear fields if both TDS and TCS are No
                    setIsFormDisabled(true);
                } else {
                    setIsFormDisabled(false);
                }
               
                if (name === "ecomApplicabilty" && value === "No" && formData.ecomApplicabilty === "No")  {
                    // Disable form and clear fields if both TDS and TCS are No
                    setIsEcomFormDisabled(true);
                } else {
                    setIsEcomFormDisabled(false);
                }

            };
            const handleEcomChange = (index, e) => {
                const { name, value } = e.target;
                const updatedEcomDetails = [...formData.ecomDetails];
                updatedEcomDetails[index][name] = value;
                setFormData(prevState => ({
                    ...prevState,
                    ecomDetails: updatedEcomDetails,
                }));
            };

            const addEcomRow = () => {
                setFormData(prevState => ({
                    ...prevState,
                    ecomDetails: [
                        ...prevState.ecomDetails,
                        { sno: prevState.ecomDetails.length + 1, portal: '', id: '', password: '' }
                    ]
                }));
            };
        
            const handleDeleteRow = (index) => {
                const updatedEcomDetails = formData.ecomDetails.filter((_, i) => i !== index);
        
                // Re-sort the `sno` after deletion
                const reSortedEcomDetails = updatedEcomDetails.map((item, idx) => ({
                    ...item,
                    sno: idx + 1, // Reset the `sno` in sequential order starting from 1
                }));
        
                setFormData(prevState => ({
                    ...prevState,
                    ecomDetails: reSortedEcomDetails,
                }));
            };

            const handleTransporterChange = (index, selectedOption) => {
              const updatedTransporterDetails = [...formData.transporterDetails];
              const selectedTransporter = transporters.find(
                transporter => transporter.gstin === selectedOption.value
              );
            
              // Update transporter details with the selected GSTIN
              updatedTransporterDetails[index] = {
                ...updatedTransporterDetails[index],
                gstin: selectedTransporter.gstin,
                transporterName: selectedTransporter.transporterName,
                address: selectedTransporter.address,
              };
            
              setFormData(prevState => ({
                ...prevState,
                transporterDetails: updatedTransporterDetails,
              }));
            };
            

      
          const addTransporterRow = () => {
              setFormData(prevState => ({
                  ...prevState,
                  transporterDetails: [
                      ...prevState.transporterDetails,
                      { sno: prevState.transporterDetails.length + 1, transporterName: '', gstin: '', address: '' }
                  ]
              }));
          };
      
          const handleTransporterDeleteRow = (index) => {
              const updatedTransporterDetails = formData.transporterDetails.filter((_, i) => i !== index);
              const reSortedTransporterDetails = updatedTransporterDetails.map((item, idx) => ({
                  ...item,
                  sno: idx + 1,
              }));
      
              setFormData(prevState => ({
                  ...prevState,
                  transporterDetails: reSortedTransporterDetails,
              }));
          };
            const handleSubmit = async (e) => {
                e.preventDefault();

                const showError = (message) => {
                  Swal.fire({
                      toast: true,
                      icon: 'error',
                      title: message,
                      position: 'top-end',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true,
                      customClass: {
                          popup: 'small-swal' // You can add a custom class for further styling
                      }
                  });
              };
              
              if (!formData.cc) {
                  showError('Please fill CC');
                  return;
              } else if (!formData.tradeName) {
                  showError('Please fill Trade Name');
                  return;
              } else if (!formData.clientName) {
                  showError('Please fill Client Name');
                  return;
              }

                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'success',
                    text: 'Please Wait!',
                    showConfirmButton: false,
                    timer: 3000
                });

                try {
                    const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addclient', formData);

                    if (response.status === 200) {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            text: response.data,
                            showConfirmButton: false,
                            timer: 3000
                        });
                        setFormData({
                            cc: '',
                            clientName: '',
                            pan: '',
                            tradeName: '',
                            gstin: '',
                            gstId: '',
                            gstPass: '',
                            ewayId: '',
                            ewayPass: '',
                            irnId: '',
                            irnPass: '',
                            itId: '',
                            itPass: '',
                            tan: '',
                            tracesId: '',
                            tracesPass: '',
                            email: '',
                            password: '',
                            numberofvisits: '',
                            inweeks: '',
                            Priority: '',
                            timeLag: '',
                            gstrf: '',
                            gstar1: '',
                            gstar2: '',
                            gstar3: '',
                            itar: '',
                            reportingFrequency: '',
                            levelOfBOA: '',
                            tdsApplicability: 'No',           
                            tdsApplicableReturn: '',          
                            tcsApplicability: 'No',           
                            tcsApplicableReturn: '', 
                            ecomApplicabilty:'No' ,
                            gstportalmobile:'',
                            gstportalemail:'' ,
                            einvoiceportalmobile:'',
                            einvoiceportalemail:'',
                            ewayportalemail:'',
                            ewayportalmobile:'' ,
                            ITportalmobile:'',
                            ITportalemail:'' ,
                            tcsChallan:'',
                            tdsChallan:'',
                            tdsReturnFreq:'',
                            tcsReturnFreq:'',
                            tdsChallanFreq:'',
                            tcsChallanFreq:'' ,
                            itChallan:'',
                            itfreq:'',
                            mobileNo:'',
                            alternateMobile:'',
                            gstChallan:'',
                            gstChallanFrequency:'',
                            ITDPass:''
                        });
                    } else {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'error',
                            text: response.data,
                            showConfirmButton: false,
                            timer: 3000
                        });
                    }
                } catch (error) {
                    console.error('There was an error!', error);
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'error',
                        text: 'An error occurred. Please try again.',
                        showConfirmButton: false,
                        timer: 3000
                    });
                }
            };

            const handleTabClick = (tab) => {
                setActiveTab(tab);
            };

            const availableTransporters = transporters.filter(
              (transporter) => !(formData.transporterDetails || []).some((detail) => detail.gstin === transporter.gstin)
            );

            return (
                <div className='mainscreenadmin mt-5'>
                    <div className="container formcontainer">

                        <h4 className='text-center mb-2'>Add Client {formData.cc}</h4>
                        {/* Tabs Navigation */}
                        <ul className="nav nav-tabs d-flex flex-nowrap" role="tablist" style={{ whiteSpace: 'nowrap' }}>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'basic' ? 'active' : ''}`} onClick={() => handleTabClick('basic')} href="#basic" role="tab" data-bs-toggle="tab">
                            Basic Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'visit' ? 'active' : ''}`} onClick={() => handleTabClick('visit')} href="#visit" role="tab" data-bs-toggle="tab">
                            Service Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'ecom' ? 'active' : ''}`} onClick={() => handleTabClick('ecom')} href="#ecom" role="tab" data-bs-toggle="tab">
                            E-Commerce Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'gst' ? 'active' : ''}`} onClick={() => handleTabClick('gst')} href="#gst" role="tab" data-bs-toggle="tab">
                            GST Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'eway' ? 'active' : ''}`} onClick={() => handleTabClick('eway')} href="#eway" role="tab" data-bs-toggle="tab">
                            E-Way & IRN Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'traces' ? 'active' : ''}`} onClick={() => handleTabClick('traces')} href="#traces" role="tab" data-bs-toggle="tab">
                            TDS/TCS Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'tax' ? 'active' : ''}`} onClick={() => handleTabClick('tax')} href="#tax" role="tab" data-bs-toggle="tab">
                            Tax Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'transporter' ? 'active' : ''}`} onClick={() => handleTabClick('transporter')} href="#transporter" role="tab" data-bs-toggle="tab">
                            Transporter Info
                        </a>
                    </li>
                    <li className="nav-item nav-itemmm">
                        <a className={`nav-link ${activeTab === 'SOP' ? 'active' : ''}`} onClick={() => handleTabClick('SOP')} href="#SOP" role="tab" data-bs-toggle="tab">
                            SOP Info
                        </a>
                    </li>
                </ul>
                        <form onSubmit={handleSubmit} className="tab-content mt-3">
                            {/* Basic Info Section */}
                            <div className="d-flex justify-content-end">
  <button type="submit" className="btn btn-success">Submit</button>
</div>
                            <div className={`tab-pane fade ${activeTab === 'basic' ? 'show active' : ''}`} id="basic" role="tabpanel">
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-6 inputdiv">
                                            <label>Client Code (CC)</label>
                                            <input type="text" className="form-control" placeholder="Client Code" name="cc" value={formData.cc} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>Priority (P)</label>
                                            <input type="text" className="form-control" placeholder="Priority" name="Priority" value={formData.Priority} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6 inputdiv">
                                            <label>Client Name</label>
                                            <input type="text" className="form-control" placeholder="Client Name" name="clientName" value={formData.clientName} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>Trade Name</label>
                                            <input type="text" className="form-control" placeholder="Trade Name" name="tradeName" value={formData.tradeName} onChange={handleChange} />
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 inputdiv">
                                            <label>Email</label>
                                            <input type="text" className="form-control" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" name="password" value={formData.password} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6 inputdiv">
                                            <label>Mobile No.</label>
                                            <input type="mobile" className="form-control" placeholder="Mobile" name="mobileNo" value={formData.mobileNo} onChange={handleChange}  />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>Alternate Mobile</label>
                                            <input type="mobile" className="form-control" placeholder="Alternate Mobile" name="alternateMobile" value={formData.alternateMobile} onChange={handleChange}  />
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                {/* Navigation buttons */}
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-success" onClick={() => handleTabClick('visit')}>Next</button>
                                </div>
                            </div>

                            {/* Visit Info Section */}
                            <div className={`tab-pane fade ${activeTab === 'visit' ? 'show active' : ''}`} id="visit" role="tabpanel">
  <div className="form-group">
    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>Visit Required</label>
        <select className="form-control" name="visitRequired" value={formData.visitRequired} onChange={handleChange}>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </div>

    {/* Conditional Rendering of Visit Fields */}
    {formData.visitRequired === 'Yes' && (
      <>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>Number of Visits</label>
            <input type="number" className="form-control" placeholder="Number of Visits" name="numberofvisits" value={formData.numberofvisits} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>In Weeks</label>
            <input type="number" className="form-control" placeholder="In Weeks" name="inweeks" value={formData.inweeks} onChange={handleChange} />
          </div>
        </div>
      </>
    )}
    <div className="row">
          <div className="col-md-6 inputdiv">
            <label>Standard Hours Required</label>
            <input type="text" className="form-control" placeholder="Standard Hours Required" name="stdTime" value={formData.stdTime} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>Absolute Hours Required</label>
            <input type="text" className="form-control" placeholder="Absolute Hours Required" name="absoluteTime" value={formData.absoluteTime} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>In Weeks (Standard and Absolute Hours)</label>
            <input type="number" className="form-control" placeholder="In Weeks" name="stdTimeInWeeks" value={formData.stdTimeInWeeks} onChange={handleChange} />
          </div>
        </div>
    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>Level of BOA (L)</label>
        <input type="text" className="form-control" placeholder="Level of BOA" name="levelOfBOA" value={formData.levelOfBOA} onChange={handleChange} />
      </div>
      <div className="col-md-6 inputdiv">
        <label>Reporting Frequency (R)</label>
        <input type="text" className="form-control" placeholder="Reporting Frequency" name="reportingFrequency" value={formData.reportingFrequency} onChange={handleChange} />
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>Time Lag for Updation (In Days)</label>
        <input type="text" className="form-control" placeholder="Time Lag" name="timeLag" value={formData.timeLag} onChange={handleChange} />
      </div>
      <div className="col-md-6 inputdiv">
          <label>Time Lag for Reporting (In Days)</label>
          <input type="text" className="form-control" placeholder="Time Lag" name="timeLagReporting" value={formData.timeLagReporting} onChange={handleChange} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>Third Party Consultant</label>
        <select className="form-control" name="thirdPartyConsultant" value={formData.thirdPartyConsultant} onChange={handleChange}>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
    </div>
    {formData.thirdPartyConsultant === 'Yes' && (
      <>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>Consultant Name</label>
            <input type="text" className="form-control" placeholder="Consultant Name" name="consultantName" value={formData.consultantName} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>Consultant Contact No.</label>
            <input type="mobile" className="form-control" placeholder="Consultant Contact No." name="consultantNo" value={formData.consultantNo} onChange={handleChange} />
          </div>
        </div>
      </>
    )}
  </div>

  {/* Navigation buttons */}
  <div className="d-flex justify-content-between">
    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('basic')}>Previous</button>
    <button type="button" className="btn btn-success" onClick={() => handleTabClick('ecom')}>Next</button>
  </div>
</div>


 {/* ecom Credentials Section */}
 <div className={`tab-pane fade ${activeTab === 'ecom' ? 'show active' : ''}`} id="ecom" role="tabpanel">
            <div className="form-group">
                <div className="row">
                    <div className="col-md-6 inputdiv">
                        <label>Supplies through E-commerce</label>
                        <select className="form-control" name="ecomApplicabilty" value={formData.ecomApplicabilty} onChange={handleChange}>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>
            </div>

            {/* Conditional Rendering based on ecomApplicabilty value */}
            {formData.ecomApplicabilty === 'Yes' && (
                <div className="form-group">
                    <label>E-commerce Details</label>   
                    {formData.ecomDetails.map((row, index) => (
                        <div className="row mb-2" key={index}>
                            <div className="col-md-1">
                                <input type="text" className="form-control" value={row.sno} disabled />
                            </div>
                            <div className="col-md-3">
                                <select
                                    className="form-control"
                                    name="portal"
                                    value={row.portal}
                                    onChange={(e) => handleEcomChange(index, e)}
                                >
                                    <option value="">Select Portal</option>
                                    <option value="Amazon">Amazon</option>
                                    <option value="Flipkart">Flipkart</option>
                                    <option value="Meesho">Meesho</option>
                                    <option value="Shopify">Shopify</option>
                                    <option value="Gloroad">Gloroad</option>
                                    <option value="Myntra">Myntra</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="id"
                                    placeholder="ID"
                                    value={row.id}
                                    onChange={(e) => handleEcomChange(index, e)}
                                />
                            </div>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="password"
                                    placeholder="Password"
                                    value={row.password}
                                    onChange={(e) => handleEcomChange(index, e)}
                                />
                            </div>
                            <div className="col-md-2">
                            <button type="button" className="btn btn-success" onClick={addEcomRow}>
                        Add
                    </button>
                                {/* Hide Delete button if there's only one row */}
                                {formData.ecomDetails.length > 1 && (
                                    <button type="button" className="btn btn-danger " onClick={() => handleDeleteRow(index)}>
                                        Delete
                                    </button>
                                )}
                            
                            </div>
                        </div>
                    ))}
                </div>
            )}
                                {/* Navigation buttons */}
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('visit')}>Previous</button>
                                    <button type="button" className="btn btn-success" onClick={() => handleTabClick('gst')}>Next</button>
                                </div>
                            </div>

                            {/* GST Section */}
<div className={`tab-pane fade ${activeTab === 'gst' ? 'show active' : ''}`} id="gst" role="tabpanel">
  <div className="form-group">
    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>GST Applicable</label>
        <select className="form-control" name="gstApplicable" value={formData.gstApplicable} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
      {formData.gstApplicable === 'Yes' && (
          <div className="col-md-6 inputdiv">
            <label>GSTIN</label>
            <input type="text" className="form-control" placeholder="GSTIN" name="gstin" value={formData.gstin} onChange={handleChange} />
          </div>
      )}
    </div>

    {/* Conditional Rendering of GST Fields */}
    {formData.gstApplicable === 'Yes' && (
      <>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>GST ID</label>
            <input type="text" className="form-control" placeholder="GST ID" name="gstId" value={formData.gstId} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>GST Password</label>
            <input type="password" className="form-control" placeholder="GST Password" name="gstPass" value={formData.gstPass} onChange={handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>Email ID at GST Portal</label>
            <input type="text" className="form-control" placeholder="Email ID at GST Portal" name="gstportalemail" value={formData.gstportalemail} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>Mobile No. at GST Portal</label>
            <input type="text" className="form-control" placeholder="Mobile No. at GST Portal" name="gstportalmobile" value={formData.gstportalmobile} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
        <label>GST Reconcilation Required</label>
        <select className="form-control" name="gstReconRequired" value={formData.gstReconRequired} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
        </div>
        <div class="border-top border-success p-1 mt-2"></div>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>GST Challan</label>
            <input type="text" className="form-control" placeholder="GST Challan" name="gstChallan" value={formData.gstChallan} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>GST Challan Frequency</label>
            <input type="text" className="form-control" placeholder="GST Challan Frequency" name="gstChallanFrequency" value={formData.gstChallanFrequency} onChange={handleChange} />
          </div>
        </div>
        <div class="border-top border-success p-1 mt-2"></div>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>GST R1/IFF	</label>
            <input type="text" className="form-control" placeholder="GST R1/IFF	" name="gstar1" value={formData.gstar1} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>GST 3B/CMP-08</label>
            <input type="text" className="form-control" placeholder="GST 3B/CMP-08" name="gstar2" value={formData.gstar2} onChange={handleChange} />
          </div>
        </div>

        <div className="row">
        <div className="col-md-6 inputdiv">
            <label>GST Return Frequency</label>
            <input type="text" className="form-control" placeholder="GST Return Frequency" name="gstrf" value={formData.gstrf} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>GST HLY</label>
            <input type="text" className="form-control" placeholder="GST HLY" name="gsthly" value={formData.gsthly} onChange={handleChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>GST YLY</label>
            <input type="text" className="form-control" placeholder="GST YLY" name="gstar3" value={formData.gstar3} onChange={handleChange} />
          </div>
        </div>
      </>
    )}
  </div>

  {/* Navigation buttons */}
  <div className="d-flex justify-content-between">
    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('ecom')}>Previous</button>
    <button type="button" className="btn btn-success" onClick={() => handleTabClick('eway')}>Next</button>
  </div>
</div>

                            {/*E-Way/Invoice Credentials Section */}
                            <div className={`tab-pane fade ${activeTab === 'eway' ? 'show active' : ''}`} id="eway" role="tabpanel">
                                <div className="form-group">
                                        <div className="row">
                                        <div className="col-md-6 inputdiv">
                                                <label>E-Way Bill ID</label>
                                                <input type="text" className="form-control" placeholder="E-Way Bill ID" name="ewayId" value={formData.ewayId} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                                <label>E-Way Bill Password</label>
                                                <input type="password" className="form-control" placeholder="E-Way Bill Password" name="ewayPass" value={formData.ewayPass} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 inputdiv">
                                                <label>Email ID at Eway Portal</label>
                                                <input type="text" className="form-control" placeholder="Email ID at Eway Portal" name="ewayportalemail" value={formData.ewayportalemail} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 inputdiv">
                                                <label>Mobile No. at Eway Portal</label>
                                                <input type="text" className="form-control" placeholder="Mobile No. at Eway Portal" name="ewayportalmobile" value={formData.ewayportalmobile} onChange={handleChange} />
                                            </div>
                                            </div>
                                            <div class="border-top border-success p-1 mt-2"></div>
                                        <div className="row">
                                        <div className="col-md-6 inputdiv">
                                                <label>IRN ID</label>
                                                <input type="text" className="form-control" placeholder="IRN ID" name="irnId" value={formData.irnId} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 inputdiv">
                                                <label>IRN Password</label>
                                                <input type="password" className="form-control" placeholder="IRN Password" name="irnPass" value={formData.irnPass} onChange={handleChange} />
                                            </div>
                                        </div>
                                        
                                            <div className="row">
                                            <div className="col-md-6 inputdiv">
                                                <label>Email ID at E-invoice Portal</label>
                                                <input type="text" className="form-control" placeholder="Email ID at invoice Portal" name="einvoiceportalemail" value={formData.einvoiceportalemail} onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 inputdiv">
                                                <label>Mobile No. at E-invoice Portal</label>
                                                <input type="text" className="form-control" placeholder="Mobile No. at invoice Portal" name="einvoiceportalmobile" value={formData.einvoiceportalmobile} onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    

                                {/* Navigation buttons */}
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('gst')}>Previous</button>
                                    <button type="button" className="btn btn-success" onClick={() => handleTabClick('traces')}>Next</button>
                                </div>
                            </div>

                            {/* Tax Credentials Section */}
                            <div className={`tab-pane fade ${activeTab === 'tax' ? 'show active' : ''}`} id="tax" role="tabpanel">
                                <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6 inputdiv">
                                            <label>PAN</label>
                                            <input type="text" className="form-control" placeholder="PAN" name="pan" value={formData.pan} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>IT Applicable Returns</label>
                                            <input type="text" className="form-control" placeholder="IT Applicable Returns" name="itar" value={formData.itar} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6 inputdiv">
                                            <label>IT Challan</label>
                                            <input type="text" className="form-control" placeholder="IT Challan" name="itChallan" value={formData.itChallan} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>IT Frequency</label>
                                            <input type="text" className="form-control" placeholder="IT Frequency" name="itfreq" value={formData.itfreq} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="border-top border-success p-1 mt-2"></div>
                                    <div className="row">
                                        <div className="col-md-6 inputdiv">
                                            <label>IT ID</label>
                                            <input type="text" className="form-control" placeholder="Income Tax ID" name="itId" value={formData.itId} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>IT Password</label>
                                            <input type="password" className="form-control" placeholder="Income Tax Password" name="itPass" value={formData.itPass} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div class="border-top border-success p-1 mt-2"></div>
                                    <div className="row">
                                        <div className="col-md-6 inputdiv">
                                            <label>Email ID at IT Portal </label>
                                            <input type="text" className="form-control" placeholder="Email ID at IT Portal" name="ITportalemail" value={formData.ITportalemail} onChange={handleChange} />
                                        </div>
                                        <div className="col-md-6 inputdiv">
                                            <label>Mobile No. IT Portal</label>
                                            <input type="text" className="form-control" placeholder="Mobile No. IT Portal" name="ITportalmobile" value={formData.ITportalmobile} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                {/* Navigation buttons */}
                                <div className="d-flex justify-content-between">
                                    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('traces')}>Previous</button>
                                    <button type="button" className="btn btn-success" onClick={() => handleTabClick('transporter')}>Next</button>
                                </div>
                            </div>

                            {/* Traces Credentials Section */}
                            <div className={`tab-pane fade ${activeTab === 'traces' ? 'show active' : ''}`} id="traces" role="tabpanel">
  <div className="form-group">
    <div className="row">
      <div className="col-md-6 inputdiv">
        <label>TDS Applicability</label>
        <select className="form-control" name="tdsApplicability" value={formData.tdsApplicability} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
      <div className="col-md-6 inputdiv">
        <label>TCS Applicability</label>
        <select className="form-control" name="tcsApplicability" value={formData.tcsApplicability} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
    </div>

    {/* Conditional Rendering Based on TDS or TCS Applicability */}
    {(formData.tdsApplicability === 'Yes' || formData.tcsApplicability === 'Yes') && (
      <>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>TDS Applicable Return</label>
            <input type="text" className="form-control" placeholder="TDS Applicable Return" name="tdsApplicableReturn" value={formData.tdsApplicableReturn} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>TCS Applicable Return</label>
            <input type="text" className="form-control" placeholder="TCS Applicable Return" name="tcsApplicableReturn" value={formData.tcsApplicableReturn} onChange={handleChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>TDS Return Frequency</label>
            <input type="text" className="form-control" placeholder="TDS Return Frequency" name="tdsReturnFreq" value={formData.tdsReturnFreq} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>TCS Return Frequency </label>
            <input type="text" className="form-control" placeholder="TCS Return Frequency" name="tcsReturnFreq" value={formData.tcsReturnFreq} onChange={handleChange} />
          </div>
        </div>

        <div class="border-top border-success p-1 mt-2"></div>

        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>TDS Challan</label>
            <select className="form-control" name="tdsChallan" value={formData.tdsChallan} onChange={handleChange}>
              <option value=" ">Select a option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="col-md-6 inputdiv">
            <label>TCS Challan</label>
            <select className="form-control" name="tcsChallan" value={formData.tcsChallan} onChange={handleChange}>
              <option value=" ">Select a option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>


        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>TDS Challan Frequency</label>
            <input type="text" className="form-control" placeholder="TDS Challan Frequency" name="tdsChallanFreq" value={formData.tdsChallanFreq} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>TCS Challan Frequency</label>
            <input type="text" className="form-control" placeholder="TCS Challan Frequency" name="tcsChallanFreq" value={formData.tcsChallanFreq} onChange={handleChange} />
          </div>
        </div>
        <div class="border-top border-success p-1 mt-2"></div>
        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>TAN</label>
            <input type="text" className="form-control" placeholder="TAN" name="tan" value={formData.tan} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>ITD Password</label>
            <input type="password" className="form-control" placeholder="ITD Password" name="ITDPass" value={formData.ITDPass} onChange={handleChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 inputdiv">
            <label>Traces ID</label>
            <input type="text" className="form-control" placeholder="Traces ID" name="tracesId" value={formData.tracesId} onChange={handleChange} />
          </div>
          <div className="col-md-6 inputdiv">
            <label>Traces Password</label>
            <input type="password" className="form-control" placeholder="Traces Password" name="tracesPass" value={formData.tracesPass} onChange={handleChange} />
          </div>
        </div>
      </>
    )}
  </div>

  {/* Navigation buttons */}
  <div className="d-flex justify-content-between">
    <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('eway')}>Previous</button>
    <button type="button" className="btn btn-success" onClick={() => handleTabClick('tax')}>Next</button>
  </div>
</div>

<div className={`tab-pane fade ${activeTab === 'transporter' ? 'show active' : ''}`} id="transporter" role="tabpanel">
            <div className="form-group">
            <div className="row">
      <div className="col-md-6 inputdiv">

                <label>Need Transporter?</label>
                <select className="form-control" name="needTransporter" value={formData.needTransporter} onChange={(e) => setFormData({ ...formData, needTransporter: e.target.value })}>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                </select>
            </div>
            </div>
            </div>

            {formData.needTransporter === 'Yes' && (
                      <div className="form-group">
                          <label>Transporter Details</label>
                          <table className="table">
                              <thead>
                                  <tr>
                                      <th>S.No</th>
                                      <th>GSTIN</th>
                                      <th>Transporter Name</th>
                                      <th>Address</th>
                                      <th>Actions</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {formData.transporterDetails.map((row, index) => (
                                      <tr key={index}>
                                          <td>
                                              <input type="text" className="form-control" value={row.sno} disabled />
                                          </td>
                                          <td>
                                              <Select
                                                  name="gstin"
                                                  value={availableTransporters.find(transporter => transporter.gstin === row.gstin)}
                                                  onChange={(selectedOption) => handleTransporterChange(index, selectedOption)}
                                                  options={availableTransporters.map(transporter => ({
                                                      value: transporter.gstin,
                                                      label: transporter.gstin,
                                                  }))}
                                                  
                                              />
                                          </td>
                                          <td>
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  name="transporterName"
                                                  value={row.transporterName}
                                                  readOnly
                                              />
                                          </td>
                                          <td>
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  name="address"
                                                  value={row.address}
                                                  readOnly
                                              />
                                          </td>
                                          <td>
                                              <button type="button" className="btn btn-success" onClick={addTransporterRow}>Add</button>
                                              {formData.transporterDetails.length > 1 && (
                                                  <button type="button" className="btn btn-danger" onClick={() => handleTransporterDeleteRow(index)}>Delete</button>
                                              )}
                                          </td>
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      </div>
                  )}
                  {/* Navigation buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('tax')}>Previous</button>
        <button type="button" className="btn btn-success" onClick={() => handleTabClick('SOP')}>Next</button>
      </div>
        </div>

{/* SOP Section */}
<div className={`tab-pane fade ${activeTab === 'SOP' ? 'show active' : ''}`} id="SOP" role="tabpanel">
  <div className="form-group">
    <div className="row">
      <div className="col-md-12 inputdiv">
        <label>SOP Required</label>
        <select className="form-control" name="sopRequired" value={formData.sopRequired} onChange={handleChange}>
          <option value="No">No</option>
          <option value="Yes">Yes</option>
        </select>
      </div>
    </div>

    {/* Conditional Rendering of SOP Fields */}
    {formData.sopRequired === 'Yes' && (
  <>
      <div className="inputdiv">
        <label>General Accounting</label>
        <textarea 
          className="form-control" 
          placeholder="Enter SOP for General Accounting" 
          name="sopGeneralAccounting" 
          value={formData.sopGeneralAccounting} 
          onChange={handleChange} 
          rows="4"
        />
      </div>
      <div className=" inputdiv">
        <label>Operation</label>
        <textarea 
          className="form-control" 
          placeholder="Enter SOP for Operation" 
          name="sopOperation" 
          value={formData.sopOperation} 
          onChange={handleChange} 
          rows="4"
        />
      </div>
      <div className=" inputdiv">
        <label>Inventory</label>
        <textarea 
          className="form-control" 
          placeholder="Enter SOP for Inventory" 
          name="sopInventory" 
          value={formData.sopInventory} 
          onChange={handleChange} 
          rows="4"
        />
      </div>
      <div className="inputdiv">
        <label>GST</label>
        <textarea 
          className="form-control" 
          placeholder="Enter SOP for GST" 
          name="sopGST" 
          value={formData.sopGST} 
          onChange={handleChange} 
          rows="4"
        />
      </div>
      <div className=" inputdiv">
        <label>Income Tax</label>
        <textarea 
          className="form-control" 
          placeholder="Enter SOP for Income Tax" 
          name="sopIncomeTax" 
          value={formData.sopIncomeTax} 
          onChange={handleChange} 
          rows="4"
        />
      </div>
  </>
)}

  </div>

      {/* Navigation buttons */}
      <div className="d-flex justify-content-between">
        <button type="button" className="btn btn-secondary" onClick={() => handleTabClick('transporter')}>Previous</button>
        <button type="submit" className="btn btn-success"onClick={() => handleTabClick('tax')}>Submit</button>
      </div>
      </div>
    
                        </form>
                    </div>
                </div>
            );
        };

        export default AddClient;
