import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DateRangePickerComponent from './CustomDateRangePicker'
const Swal = require('sweetalert2');

const TeamDailyTaskReport = () => {
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');
    const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
    const [tasks, setTasks] = useState([]);
    const [clientNames, setClientNames] = useState({});
    const [timesheetReportingDates, setTimesheetReportingDates] = useState([]); // Array to store multiple reporting dates
    const userEmail = sessionStorage.getItem('email');
    const navigate = useNavigate();

    const fetchTasks = async () => {
        const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                console.error("Please select a valid date range");
                return;
            }
        try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/teamDailyWorking', {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
            });
            const tasks = response.data;

            setTasks(tasks);

            const clientNamesTemp = {};
            for (const task of tasks) {
                const tradeName = await getClient(task.CC);
                clientNamesTemp[task.CC] = tradeName;
            }

            setClientNames(clientNamesTemp);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    const fetchTimesheetReportingDates = async () => {
        try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/timeheetDateforvalidation', {
                email: userEmail,
            });
    
            const reportingDates = response.data; // Expecting an array of objects with timesheetDate property
            console.log("Raw reporting dates:", reportingDates); // Log raw data for debugging
    
            // Convert all reporting dates to DD-MM-YYYY format
            const formattedReportingDates = reportingDates.map(item => {
                const date = item.timesheetDate; // Access the timesheetDate property
                const parsedDate = moment(date); // Parse the date using moment
                if (parsedDate.isValid()) { // Check if the parsed date is valid
                    return parsedDate.format('DD-MM-YYYY'); // Format if valid
                } else {
                    console.error("Invalid date format:", date); // Log invalid dates for debugging
                    return null; // Return null for invalid dates
                }
            });
    
            setTimesheetReportingDates(formattedReportingDates); // Set the array of formatted reporting dates
            console.log("Formatted reporting dates:", formattedReportingDates); // Log the formatted dates
    
        } catch (error) {
            console.error("Error fetching timesheet reporting dates:", error);
        }
    };
    

    const getClient = async (client) => {
        try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getClientByCode', {
                params: {
                    client,
                },
            });

            if (response.data.length > 0) {
                return response.data[0].TradeName;
            } else {
                return 'Client not found';
            }
        } catch (error) {
            console.error('Error fetching client:', error);
            return 'Error fetching client';
        }
    };

    // const handleStartDateChange = (event) => {
    //     setStartDate(event.target.value);
    // };

    // const handleEndDateChange = (event) => {
    //     setEndDate(event.target.value);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchTasks();
        fetchTimesheetReportingDates(); // Fetch the timesheet reporting dates after submitting the form
    };

    const TimeDisplay = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    };

    const groupTasks = (tasks) => {
        const groupedTasks = {};

        tasks.forEach((task) => {
            const key = task.mid;

            if (!groupedTasks[key]) {
                groupedTasks[key] = {
                    fullname:task.fullname[0],
                    mid: task.mid,
                    reportingDate: task.reportingDate,
                    mainTask: task.mainTask[0],
                    clientCode: task.CC,
                    subTasks: [],
                    remarks: [],
                    taskTimeInMinutes: task.taskTimeInMinutes,
                    travelTime: task.travelTime,
                    taskPeriods: [],
                    enteries:[]
                };
            }

            groupedTasks[key].subTasks.push(task.subTask);
            groupedTasks[key].remarks.push(task.remarks);
            groupedTasks[key].enteries.push(task.NumberOfEntries);
            const taskFromDate = moment(task.taskfrom);
            const taskToDate = moment(task.taskto);

            if (!taskFromDate.isValid() || taskFromDate.year() < 2000) {
                groupedTasks[key].taskPeriods.push('No Period');
            } else {
                groupedTasks[key].taskPeriods.push(
                    `${taskFromDate.format('DD-MMM-YY')} - ${taskToDate.format('DD-MMM-YY')}`
                );
            }
        });

        return Object.values(groupedTasks).sort((a, b) => {
            return moment(b.reportingDate).diff(moment(a.reportingDate));
        });
    };

    const handleEdit = (taskid) => {
        navigate('../editDailyWorking', { state: { taskid } });
    };
    const truncateRemarks = (remarks, maxLength = 10) => {
        // Join remarks into a single string
        const fullRemarks = remarks.join(' '); 
        // Check if the length exceeds the maxLength
        return fullRemarks.length > maxLength 
            ? `${fullRemarks.substring(0, maxLength)}...` // Truncate and add ellipsis
            : fullRemarks; // Return full remarks if within limit
    };  
    const handleDelete = async (taskId) => {
        try {
            // Show a confirmation dialog
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this task? This action cannot be undone!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
            }).then(async (result) => {
                // If the user confirmed, proceed with the deletion
                if (result.isConfirmed) {
                    const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deletedwrtask', { id: taskId });
    
                    if (response.status === 200) {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            fetchTasks()
                        });
                    } else {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'info',
                            text: response.data,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }
            });
        } catch (error) {
            toast.error('Error deleting task:', error);
        }
    };

    const groupedTasks = groupTasks(tasks);

    return (
        <div className="container">
            <h2 className="mt-5">Team Group Summary</h2>
            <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-3">
                    <label>Date Range:</label>
                    {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
                        <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
                    {/* </Stack> */}
                </div>
                <div className="col-md-4 d-flex align-items-end">
                    <button type="submit" className="btn btn-success">
                        Get Tasks
                    </button>
                </div>
            </div>
            </form>

            <div className="mt-4">
                {groupedTasks.length > 0 ? (
                    <table className="table table-striped mt-4">
                        <thead style={{ fontSize: '14px', verticalAlign: 'baseline' }}>
                            <tr>
                                <th style={{width:'100px'}}>Date</th>
                                <th>Member</th>
                                <th style={{width:'200px'}}>Client</th>
                                <th>Main Task </th>
                                <th>Sub Task </th>
                                <th>Task Period</th>
                                <th>Enteries</th>
                                <th>Task Time</th>
                                <th>Travel Time</th>
                                <th>Remarks</th>
                                {/* <th>Actions</th> */}
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
    {groupedTasks.map((task, index) => {
        // Convert task.reportingDate to DD-MM-YYYY
        const taskReportingDate = moment(task.reportingDate[0]).format('DD-MM-YYYY');

        // Check if taskReportingDate exists in timesheetReportingDates array
        const isEditable = !timesheetReportingDates.some(date => {
            const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
            return formattedDate === taskReportingDate;
        });

        return (
            <tr key={index}>
                <td>{moment(task.reportingDate[0]).format('DD-MMM-YYYY')}</td>
                <td>{task.fullname}</td>
                <td>{clientNames[task.clientCode] || 'Loading...'}</td>
                <td>{task.mainTask}</td>
                <td>{task.subTasks.join(',')}</td>
                <td>{task.taskPeriods.join(',')}</td>
                <td>{task.enteries.join(',')}</td>
                <td>{TimeDisplay(task.taskTimeInMinutes)}</td>
                <td>{task.travelTime}</td>
                <td>
                                        <span
                                            data-bs-toggle="tooltip"
                                            title={task.remarks.join(' ')} // Full remarks text
                                            className="d-inline-block"
                                        >
                                            {truncateRemarks(task.remarks)} {/* Truncated remarks */}
                                        </span>
                                    </td>
                {/* <td>{isEditable ? (
                        <>
                            <button className="btn btn-success btn-sm " onClick={() => handleEdit(task.mid)}><FontAwesomeIcon icon={faEdit} /></button>
                            <button className="btn btn-danger btn-sm" onClick={() => handleDelete(task.mid)}> <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </>
                    ) : null}
                </td> */}
            </tr>
        );
    })}
</tbody>


                    </table>
                ) : (
                    <p>No tasks found for the selected date range.</p>
                )}
            </div>
        </div>
    );
};

export default TeamDailyTaskReport;
