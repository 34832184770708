import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTable } from 'react-table';

const TransporterDetails = () => {
  const [transporterName, setTransporterName] = useState('');
  const [gstin, setGstin] = useState('');
  const [address, setAddress] = useState('');
  const [transporters, setTransporters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [employeeData, setEmployeeData] = useState(null);
  const [editId , setEditId] = useState(null); // Track the index of the row being edited
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);
  let isMGEmployee=null;
  if(email){
     isMGEmployee =
    employeeData &&
    employeeData.empid &&
    typeof employeeData.empid === 'string' &&
    employeeData.empid.startsWith('MG');}
    else{
      isMGEmployee=null;
    }

  useEffect(() => {
    const fetchTransporters = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getTransporters');
        setTransporters(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching transporters:', error);
        setTransporters([]);
        Swal.fire({
          icon: 'error',
          title: 'Error fetching transporters',
          text: 'Please try again later.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });
      }
    };

    fetchTransporters();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const gstinExists = transporters.some((transporter, index) => transporter.gstin === gstin && index !== editId );

    if (gstinExists) {
      Swal.fire({
        icon: 'warning',
        title: 'GSTIN already exists',
        text: 'Please enter a unique GSTIN.',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      return;
    }

    try {
      const newTransporter = {
        transporterName,
        gstin,
        address,
        empId: employeeData,
      };

      if (editId !== null) {
        try {
            const updatedTransporter = {
                id: editId, // Include the id
                transporterName,
                gstin,
                address,
                empId: employeeData,
            };

            // Send the updated data to the backend
            const response = await axios.put('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateTransporter', updatedTransporter);

            // Show success message
            Swal.fire({
                icon: 'success',
                title: response.data.message,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            });

            // Update the transporter in the state with the new data using editId
            const updatedTransporters = transporters.map((transporter) =>
                transporter.id === editId ? { ...transporter, ...updatedTransporter } : transporter
            );
            setTransporters(updatedTransporters);

            // Reset form fields and editId
            setTransporterName('');
            setGstin('');
            setAddress('');
            setEmployeeData(null);
            setEditId(null); // Reset editId after submit
        } catch (error) {
            console.error('Error updating transporter:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error updating transporter',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true,
            });
        }
    } else {
        // Add new transporter
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addTransporter', newTransporter);
        Swal.fire({
          icon: 'success',
          title: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });

        setTransporters((prev) => [...prev, newTransporter]);
      }

      setTransporterName('');
      setGstin('');
      setAddress('');
    } catch (error) {
      console.error('Error adding transporter:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error adding transporter',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteTransporter/${id}`);
  
          // Show success message
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
  
          // Remove the deleted transporter from the state
          const updatedTransporters = transporters.filter((transporter) => transporter.id !== id);
          setTransporters(updatedTransporters);
  
        } catch (error) {
          console.error('Error deleting transporter:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error deleting transporter',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
        }
      }
    });
  };
  

  const handleEdit = (row) => {
    // Set the editId to the row id being clicked
    setEditId(row.id);

    // Populate the form fields with the data of the selected row
    setTransporterName(row.transporterName);
    setGstin(row.gstin);
    setAddress(row.address);
    setEmployeeData(row.empId);
};


  

  const filteredTransporters = transporters.filter(transporter =>
    transporter.transporterName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transporter.gstin.toLowerCase().includes(searchTerm.toLowerCase()) ||
    transporter.address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = React.useMemo(
    () => [
      { Header: 'Transporter Name', accessor: 'transporterName' },
      { Header: 'GSTIN/Transporter ID', accessor: 'gstin' },
      { Header: 'Address', accessor: 'address' },
      // Conditionally render the Action column based on isMGEmployee
      ...(isMGEmployee ? [
        {
          Header: 'Action',
          Cell: ({ row }) => (
            <div>
              {/* Edit Button */}
              <button
                className="btn btn-warning"
                onClick={() => handleEdit(row.original)} // Passing the full row data
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
  
              {/* Delete Button */}
              <button
                className="btn btn-danger ms-2" // Add some margin for spacing between buttons
                onClick={() => handleDelete(row.original.id)} // Passing the transporter ID for deletion
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ),
        }
      ] : []), // If isMGEmployee is true, include the Action column
    ],
    [isMGEmployee] // Re-run this effect if isMGEmployee changes
  );
  

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredTransporters });

  return (
    <div className="container mt-5">
      <h4 className="mb-4">{editId  !== null ? 'Edit Transporter' : 'Add Transporter'}</h4>
      <form onSubmit={handleSubmit} className="mb-4">
        <div className="row mb-3 align-items-end">
          <div className="col-md-3">
            <label htmlFor="transporterName">Transporter Name</label>
            <input
              type="text"
              className="form-control"
              id="transporterName"
              value={transporterName}
              onChange={(e) => setTransporterName(e.target.value)}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="gstin">GSTIN/Transporter ID</label>
            <input
              type="text"
              className="form-control"
              id="gstin"
              value={gstin}
              onChange={(e) => setGstin(e.target.value)}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              className="form-control"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="col-md-3">
            <label>&nbsp;</label>
            <button type="submit" className="btn btn-success form-control">
              {editId  !== null ? 'Update' : 'Submit'}
            </button>
          </div>
        </div>
      </form>

      <h4 className="mt-5">Transporter List</h4>
      <div className="mb-4">
        <input
          type="text"
          className="form-control"
          placeholder="Search transporters..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <table {...getTableProps()} className="table table-striped" style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TransporterDetails;
