//dailyworking.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt , faPlus} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Select from 'react-select';

import Swal from 'sweetalert2';
  

const EditDailyWorking = () => {
    const [formData, setFormData] = useState({
        reportingDate: '',
        client: null,
        cc:'',
        mainTask: '',
        location: '',
        otherLocation: '',
        travelTime:'',
        billing:'',
        challan:'',
        startTime:'',
        endTime:'',
        email:'',
        empid:'',
        fullname:'',

      });
      
    
      const [employeeData, setEmployeeData] = useState({});
      const [taskList, setTaskList] = useState([]);
      const [clients, setClients] = useState([]);
      const [tasks, setTasks] = useState([]);
      const [subtaskOptions, setSubtaskOptions] = useState([]); // Subtask options fetched from API
      const [subtasks, setSubTasks] = useState([{ subTask: '', NumberOfEntries: '', from: '', to: '',billing:'', remarks: '' }]);
      const [isDisabled, setIsDisabled] = useState(false);
      const [remainingVisits,setRemainingVisits] = useState()
      const [dailyWorkingDate,setDailyWorkingDate] = useState(null)
      const [dailyWorkingDateString,setDailyWorkingDateString] = useState()
      const [dailyWorkingTravelTimeString, setdailyWorkingTravelTimeString] = useState('');
      const [selectedSubtasks, setSelectedSubtasks] = useState([]);
      const [isMainTaskDisabled, setIsMainTaskDisabled] = useState(false);
      const [users, setUsers] = useState([]);
      const navigate = useNavigate(); 
      const location = useLocation();
      const { taskid } = location.state;
      // console.log(taskid,'taskidtaskidtaskidtaskid');
      
      const email = sessionStorage.getItem('email');
      const empid = employeeData.empid;
    
      const isMGEmployee =
      employeeData &&
      employeeData.empid &&
      typeof employeeData.empid === 'string' &&
      employeeData.empid.startsWith('MG');
    
      const isChallan =(Array.isArray(selectedSubtasks) ? selectedSubtasks : Object.values(selectedSubtasks)).some(subtask => 
        subtask?.SubTasks === 'GST Payment Challan '   || 
        subtask?.SubTasks === 'TCS Payment Challan' || 
        subtask?.SubTasks === 'TDS Payment Challan'     || 
        subtask?.SubTasks === 'Income Tax Payment Challan'
      ) ;
      const billingFieldShow = 
      (Array.isArray(selectedSubtasks) ? selectedSubtasks : Object.values(selectedSubtasks)).some(subtask => 
        subtask?.SubTasks === 'GSTR-9C Filling'   || 
        subtask?.SubTasks === 'GST-TCS Rerurn Filling' || 
        subtask?.SubTasks === 'GSTR-9 Filing'     || 
        subtask?.SubTasks === 'GSTR-10 Filing'    || 
        subtask?.SubTasks === 'TDS Return Filing' || 
        subtask?.SubTasks === 'TCS Return Filing' || 
        subtask?.SubTasks === 'ITR Return Filing'
      ) ||
      formData?.mainTask === 'Registration' ||
      formData?.mainTask === 'MCA' ||
      formData?.mainTask === 'Consultancy';
    
      useEffect(() => {
        const fetchEmployeeData = async () => {
          try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
              params: { email: email },
            });
            const employeeData = response.data[0];
            setEmployeeData(employeeData);
          } catch (error) {
            console.error('Error fetching employee data:', error);
          }
        };
    
        if (email) {
          fetchEmployeeData();
        }
      }, [email]);
      
    
      useEffect(() => {
        const fetchemployeetasks = async () => {
          try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasks',{empid:empid});
            
            setUsers(response.data);
          } catch (error) {
            Swal.fire('Error', 'There was an error fetching users!', 'error');
          }
        };
    
        fetchemployeetasks();
      }, [empid]);
    
     

    
      useEffect(() => {
        const fetchAssignClientsData = async () => {
          try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients', {
              params: { empid: empid },
            });
            setClients(response.data);
          } catch (error) {
            console.error('Error fetching clients:', error);
          }
        };
    
        if (empid) {
          fetchAssignClientsData();
        }
      }, [empid]);
    
      useEffect(() => {
        const fetchMainTasks = async () => {
          if (!formData.client) {
            setTasks([]);
            return;
          }
          try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getmaintasks');
            setTasks(response.data);
          } catch (error) {
            toast.error('Failed to fetch tasks');
          }
        };
    
        fetchMainTasks();
      }, [formData.client, clients]);
    
    
      useEffect(() => {
        
        const fetchSubTasks = async () => { 
          if (!formData.mainTask) {
            setSubtaskOptions([]);
            return;
          }
          try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks',{maintasks:formData.mainTask});
            setSubtaskOptions(response.data);
          } catch (error) {
            toast.error('Failed to fetch subtasks');
          }
        };
    
        fetchSubTasks();
      }, [formData.mainTask]);
    
      const convertTimeString = (dateString) => {
        const date = new Date(dateString); // Parse the ISO string
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Get hours
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Get minutes
        return `${hours}:${minutes}`; // Return in HH:MM format
    };
    
    
      useEffect(() => {
        const fetchRemainingVisits = async () => {
          try {
            if (formData.client) {
              const visitResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients/${formData.client}/remainingVisits`, { params: { email } });
            setRemainingVisits(visitResponse.data);
            }
          } catch (error) {
            console.error("Error fetching remaining visits:", error);
          }
        };
      
        fetchRemainingVisits();
      }, [formData.client]);
    
      useEffect(() => {
        const hasSelectedSubtask = subtasks.some(subtask => subtask.subTask !== '');
        setIsMainTaskDisabled(hasSelectedSubtask);  // DISABLE MAIN TASK IF ANY SUBTASK IS SELECTED
      }, [subtasks]);

      useEffect(() => {
        const fetchDailyWorkingDate = async () => {
          try {
            const DateResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/latestDailyWorkingDate`, { params: { email } });
            const dailyWorking = DateResponse.data[0]?.date;
    console.log(dailyWorking,'dailyyyyd');
    
            setDailyWorkingDate(DateResponse.data);
            const formattedDate = moment(dailyWorking).format('YYYY-MM-DD');
            setDailyWorkingDateString(formattedDate);
          } catch (error) {
            console.error('Error fetching Date:', error);
          }
        };
    
        fetchDailyWorkingDate();
      }, [email]); 

      useEffect(() => {
        const fetchMidData = async () => {
            try {
                const midDataResponse = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/editDailyWorkingData`, { mid: taskid });
                console.log(midDataResponse, 'midDataResponse');
    
                // Extracting the data from the response
                const data = midDataResponse.data;
                console.log(data, 'data');
    
                if (data && data.length > 0) {

                    // Populate the form fields using the first row of the response data
                    const firstRow = data[0]; // Assuming first row is used for form data

                    const validLocations = ['Office', 'Client Site', 'Home'];

                // Check if the location is valid, otherwise set it to 'Any Other'
                const locationValue = validLocations.includes(firstRow.location) ? firstRow.location : 'Any Other';
                const otherLocationValue = locationValue === 'Any Other' ? firstRow.location : '';


                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        reportingDate: moment(firstRow.reportingDate[0]).format('YYYY-MM-DD'), 
                        client: firstRow.client || '', 
                        mainTask: firstRow.mainTask[0] || '', 
                        location: locationValue,
                        billing: firstRow.billing[0] || '', 
                        challan: firstRow.challan || '',
                        travelTime: firstRow.travelTime[0] || '',
                        startTime:convertTimeString(firstRow.startTime) || '',
                        endTime:convertTimeString(firstRow.endTime) || '',
                        otherLocation: otherLocationValue,  
                        email:firstRow.email,
                        fullname:firstRow.fullname,
                        empid:firstRow.empid,
                    }));
    
                   
                    const subtasks = data.map((row, index) => ({
                        subTask: row.subTask || '', 
                        from: row.taskfrom || '', 
                        to: row.taskto || '',
                        NumberOfEntries: row.NumberOfEntries || '',   
                        remarks: row.remarks[1] || '', 
                    }));
    
                    // Set subtasks state with the array of subtasks
                    setSubTasks(subtasks);
                }
            } catch (error) {
                console.error('Error fetching mid data:', error);
            }
        };
    
        if (taskid) {
            fetchMidData();
        }
    }, [taskid]);
        
    
      const clientOptions = clients.map(client => ({
        value: client.CC,
        label: `${client.CC} - ${client.TradeName}`
      }));
      const handleClientChange = (selectedOption) => {
        setFormData({
          ...formData,
          client: selectedOption ? selectedOption.value : ''
        });
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubtaskChange = (index, field, value) => {
        const updatedSubtasks = [...subtasks];
        updatedSubtasks[index][field] = value;
        
        setSubTasks(updatedSubtasks);
      };
    
      const handleAddSubtaskRow = () => {
        setSubTasks([...subtasks, { subTask: '', NumberOfEntries: '', from: '', to: '', remarks: '' }]);
      };
    
      const handleRemoveSubtaskRow = (index) => {
        const updatedSubtasks = subtasks.filter((_, i) => i !== index);
        setSubTasks(updatedSubtasks);
      };
    
      const handleAddTask = () => {
      
        setFormData({
          reportingDate: '',
          client: null,
          mainTask: '',
          location: '',
          startTime:'',
          endTime:'',
          billing:'',
          challan:''
        });
        setSubTasks([{ subTask: '', NumberOfEntries: '', from: '', to: '', remarks: '' }]);
      };
      const handleBack  =  () => {
        navigate(-1)
        }
      const convertToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return (hours * 60) + minutes;
      };
    
      const handleSubmit = async () => {
        setIsDisabled(true);
      
        if (!formData.client ) {
          toast.error("Client Can't be empty");
          setIsDisabled(false);
          return;
        }
        if (!formData.location ) {
          toast.error("Location Can't be empty");
          setIsDisabled(false);
          return;
        }
        if (!formData.mainTask ) {
          toast.error("Main Task Can't be empty");
          setIsDisabled(false);
          return;
        }
        if (!formData.startTime ) {
          toast.error("Start Time Can't be empty");
          setIsDisabled(false);
          return;
        }
        if (!formData.endTime ) {
          toast.error("End Time Can't be empty");
          setIsDisabled(false);
          return;
        }
    
    if(formData.endTime <= formData.startTime){
      toast.error("End Time Can't less then start time");
      setIsDisabled(false);
          return;
    }
    const travelTimeInMinutes = convertToMinutes(formData.travelTime??'');
    setdailyWorkingTravelTimeString(travelTimeInMinutes)
    
    let showAlert = false;
        const {
          startTime, endTime, location, otherLocation
        } = formData;
    
          const start = new Date(`1970-01-01T${startTime}:00`);
          const end = new Date(`1970-01-01T${endTime}:00`);
    
       
        const taskTime = (end - start) / (1000 * 60 * 60); // in hours
        const taskTimeInMinutes = (end - start) / (1000 * 60); // in minutes
    
        const totalEntries = subtasks.reduce((acc, subtask) => acc + Number(subtask.NumberOfEntries), 0);
    
        const efficiency = (totalEntries / taskTime).toFixed(2);
    
        const taskData = {
          ...formData,
          subtasks: subtasks.map(st => ({
            ...st,
            taskPeriodFrom: st.from,
            taskPeriodTo: st.to
          })),
          location: location === 'Any Other' ? otherLocation : location,
          efficiency,
        };
        console.log(taskData,'taskkdata');
        
        const finalLocation = location === 'Any Other' ? otherLocation : location;
        if (location === 'Client Site') {
          showAlert = true; 
        }   
        if (showAlert) {
          Swal.fire({
            title: 'Reminder',
            text: 'Please fill the visit tracker as you have selected "Client" as the location.',
            icon: 'info',
            confirmButtonText: 'OK'
          });
        }
        setTaskList([...taskList, taskData]);
    
    
        try {
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateEmployeeDailyWorking', {
            mid:taskid,
            client:formData.client,
            startTime:formData.startTime,
            endTime:formData.endTime,
            empid: formData.empid[0],
            email: formData.email[0],
            fullname: formData.fullname[0],
            MainTask:formData.mainTask,
            travelTime:dailyWorkingTravelTimeString,
            location:finalLocation,
            taskTime:taskTime,
            taskTimeInMinutes:taskTimeInMinutes,
            totalEntries:totalEntries,
            reportingDate:formData.reportingDate,
            billing:formData.billing,
            efficiency:efficiency,
            challan:formData.challan,
            tasks: [subtasks]
          }); 
                    
          // toast.success('');
          Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'success',
            text:"Tasks submitted successfully",
            showConfirmButton: false,
            timer: 3000,
        }).then(() => {
           navigate(-1)
        });
          setTaskList([]);
          handleAddTask(); // Reset form after submission
          setIsDisabled(false);
        } catch (error) {
          console.error('Error submitting or updating tasks:', error);
          toast.error('Failed to submit or update tasks');
        } finally {
          setIsDisabled(false);
        }
      };
          
      const handleSubtaskSelectionChange = (index, value) => {
        const selectedSubtask = subtaskOptions.find(option => option.SubTasks === value);
        setSelectedSubtasks(prev => ({
          ...prev,
          [index]: selectedSubtask || {}
        }));
        handleSubtaskChange(index, 'subTask', value);
      };
    
      // Filter subtask options to exclude selected subtasks
      const getAvailableSubtaskOptions = (index) => {
        const selectedSubtaskValues = subtasks.map(st => st.subTask);
        return subtaskOptions.filter(option => !selectedSubtaskValues.includes(option.SubTasks) || option.SubTasks === subtasks[index].subTask);
      };
    
    
      return (
        <div className="container mt-5">
         <div className="" style={{'display':'grid','gridTemplateColumns':'auto auto'}}>
          <div><h2>Edit Daily Working</h2>

          </div>
        <div className="alert alert-danger" role="alert" style={{fontWeight:600}}>
          <h6 className="mb-1">Point to remember</h6>
          <p className="small mb-1">1. Please submit the task in order of their working.</p>
          <p className="small mb-0">2. Please enter the time in 24 HR format or check the AM/PM before submitting.</p>
        </div>
    
     
    
        </div>
          <form className="mb-4">
          <div className="row">
          <div className="form-group col-md-3">
              <label>Reporting Date</label>
              <input
                type="date"
                name="reportingDate"
                onChange={handleChange}
                value={formData.reportingDate}
                className="form-control"
                disabled
              />
            </div>
            <div className="form-group col-md-3">
                <label>Client</label>
                <Select
                  value={clientOptions.find(option => option.value === formData.client) || null}
                  onChange={handleClientChange}
                  options={clientOptions}
                  
                />
              </div>
              
              <div className="form-group col-md-3">
                <label style={{fontSize:'14px'}} htmlFor="startTime">Start Time<b > (Exclude Travel Time)</b></label>
                <input
                  type="time"
                  className="form-control"
                  id="startTime"
                  name="startTime"  
                  value={(formData.startTime)}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group col-md-3">
                <label style={{fontSize:'14px'}} htmlFor="endTime">End Time<b> (Exclude Travel Time)</b></label>
                <input
                  type="time"
                  className="form-control"
                  id="endTime"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  
                />
              </div>
            
    </div>
    <div className="row">
    <div className="form-group col-md-3">
              <label>Location</label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="form-control"
                
              >
                <option value="">Location</option>
                <option value="Office">Office</option>
                <option value="Home">Home</option>
                <option value="Client Site">Client Site</option>
                <option value="Any Other">Any Other</option>
              </select>
              
            </div>
            
    <div className="form-group col-md-3">
      <label>Other Location</label>
                <input
                  type="text"
                  name="otherLocation"
                  placeholder="Please Specify"
                  value={formData.otherLocation}
                  onChange={handleChange}
                  disabled={formData.location !== 'Any Other'}
                  className="form-control "
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="remainingVisits">Remaining Visits</label>
                <input
                  type="number"
                  id="remainingVisits"
                  className="form-control"
                  value={remainingVisits?.remainingVisits ?? ''}
                  disabled
                />
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="travelTime">Travel Time </label>
                <input
                  type="text"
                  id="travelTime"
                  name="travelTime"
                  onChange={handleChange}
                  className="form-control"
                  value={formData.travelTime}
                  disabled={formData.location !== 'Client Site'}
                  placeholder='HH:MM'
                />
              </div>
              </div>      
            <div className="row">
              
            <div className="form-group col-md-3">
              <label>Main Task</label>
              <select
                name="mainTask"
                value={formData.mainTask}
                onChange={handleChange}
                className="form-control"
                disabled={isMainTaskDisabled}
              >
                <option value="">Main Task</option>
                {tasks.map((task) => (
                  <option key={task.MainTasks} value={task.MainTasks}>
                    {task.MainTasks}
                  </option>
                ))}
              </select>
              </div>
            
              <div className="col-md-3">
              <label htmlFor="challan">Challan Amount</label>
              <input
                type="text"
                className="form-control"
                id="challan"
                name="challan"
                placeholder="Challan Amount"
                value={formData.challan}
                onChange={handleChange}
                disabled={!(isMGEmployee && isChallan)}
              />
            </div>
    
            <div className="col-md-3">
              <label htmlFor="billing">Billing Amount </label>
              <input
                type="text"
                className="form-control"
                id="billing"
                name="billing"
                placeholder="Billing Amount"
                value={formData.billing}
                onChange={handleChange}
                disabled={!(isMGEmployee && billingFieldShow)}
              />
            </div>
              </div>
         
            <div className="form-group">
    
              {subtasks.map((subtask, index) => (
                <div key={index} className="row ">
                  <div className="col-md-3 mb-2" >
                  <label>Sub Task</label>
                    <select
                      className="form-control"
                      value={subtask.subTask}
                      onChange={(e) => handleSubtaskSelectionChange(index, e.target.value)}
                      required
                    >
                      <option value="">Subtask</option>
                      {getAvailableSubtaskOptions(index).map((option) => (
                        <option key={option.id} value={option.SubTasks}>
                          {option.SubTasks}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-2">
                  <label htmlFor="taskPeriodFrom">Task Period From</label>
                <input
                  type="date"
                  className="form-control"
                  id="taskPeriodFrom"
                  name="taskPeriodFrom"
                  disabled={selectedSubtasks[index]?.from === 0}
                  value={subtask.from}
               
                  onChange={(e) => handleSubtaskChange(index, 'from', e.target.value)}
                />
                  </div>
                  <div className="col-md-2">
                  <label htmlFor="taskPeriodTo">Task Period To</label>
                    <input
                      type="date"
                      className="form-control"
                      id="taskPeriodTo"
                  name="taskPeriodTo"
                      value={subtask.to}
                      disabled={selectedSubtasks[index]?.to === 0}
                      onChange={(e) => handleSubtaskChange(index, 'to', e.target.value)}
                    />
                  </div>
                  
                  <div className="col-md-2">
                  <label>Number Of Entries</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Entries"
                      value={subtask.NumberOfEntries}
                      disabled={selectedSubtasks[index]?.NumberOfEntries === 0}
                      onChange={(e) => handleSubtaskChange(index, 'NumberOfEntries', e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                  <label>Remarks</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Remarks"
                      value={subtask.remarks}
                      onChange={(e) => handleSubtaskChange(index, 'remarks', e.target.value)}
                    />
                  </div>
                  
                  <div className="col-md-1 mt-4" style={{'padding-right':'2px','display':'flex' ,'align-items':'flex-start','gap':'5px'}}>
            {subtasks.length > 1 && (
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleRemoveSubtaskRow(index)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            {index === subtasks.length - 1 && (
              <button type="button" className="btn btn-success" onClick={handleAddSubtaskRow}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            )}
          </div>
                </div>
              ))}
               
    
            </div>
          </form>
          <button className="btn btn-success m-1" onClick={handleSubmit} disabled={isDisabled}>
            Update
          </button>
          <button className="btn btn-success" onClick={handleBack} >
            Back
          </button>
          <ToastContainer />
        </div>
      );
    
};

export default EditDailyWorking;
