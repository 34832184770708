import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/Timesheet.css';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
import '../styles/Timesheet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft } from '@fortawesome/free-solid-svg-icons';
const moment = require('moment');
const momentTimezone = require('moment-timezone');
const TimesheetPreviewFloating = () => {
  const navigate = useNavigate();
const location = useLocation();
const { empid, timesheetDate, email } = location.state;
const weekdate = new Date(timesheetDate);
const [currentDate, setCurrentDate] = useState({ day: '', month: '', year: '', formattedDate: '' });
const [todayDate, setTodayDate] = useState('');
const [weekStartEnd, setWeekStartEnd] = useState({ startDate: '', endDate: '' });
//   const email = sessionStorage.getItem('email'); 
  const [employeeData, setemployeeData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: ''
  });
  const [timesheetData,setTimesheetData] = useState([])
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email }
        });
        if (response.data && response.data.length > 0) {
          setemployeeData(response.data[0]);


       // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    
    fetchProfileData();
  }, []);   


  useEffect(() => {
    const fetchTimesheetData = async () => {
      try {
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/timesheetPreviewData`, {
          params: { empid,timesheetDate }
        });
        if (response.data && response.data.length > 0) {
            setTimesheetData(response.data[0]);


       // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    
    fetchTimesheetData();
  }, []);


  const TimeDisplay = (totalMinutes ) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
    
  };
  const getWeekStartEnd = (date) => {
    const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
  };

  const calculateDateDetails = (date) => {
    const day = date.toLocaleString('default', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const monthOfYear = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${dayOfMonth}-${monthOfYear}-${year}`;
    
    setCurrentDate({ day, month, year, formattedDate });
    const { startDate, endDate } = getWeekStartEnd(date);
    setWeekStartEnd({ startDate: startDate.toDateString(), endDate: endDate.toDateString() });
  };  
  useEffect(() => {
    calculateDateDetails(weekdate);
  }, [timesheetDate]);
  return (
    <div className="timesheetcontainer">
      <div className="timesheet">
      <div className="header">
                    <button style={{cursor: 'pointer'}}
                        className="back-icon btn btn-success" 
                        onClick={() => navigate(-1)}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button> </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan="6" className='headoftimesheet'>Daily Timesheet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name of Team Member:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={(employeeData.FirstName) +' '+(employeeData.LastName)} disabled/></td>
              <td>Week Starting: Ending:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={`${weekStartEnd.startDate} - ${weekStartEnd.endDate}`} disabled/></td>
            </tr>
        
            <tr>
              <td>Monthly Hours:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={employeeData.floatingHours} disabled/></td>
              <td>Week Number:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheetData.weekNumber} disabled/></td>
            </tr>
            <tr>
              
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th colSpan="4" className='headoftimesheet'>Timesheet Entries</th>
            </tr>
            <tr>
              <td >#</td>
              <td>Timesheet Date</td>
              <td colSpan={3}><input type="date" name='date' className="form-control timesheet-form-control" value={timesheetDate}disabled/></td>
            </tr>
            <tr>
              <td>A</td>
              <td>Day In Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.dayInTime)} disabled/></td>
            </tr>
            <tr>
              <td>B</td>
              <td>Day Out Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.dayOutTime)} disabled/></td>
            </tr>
            <tr>
              <td>C</td>
              <td>Day Time (B-A)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.dayTime)}disabled/></td>
            </tr>
            <tr>
              <td>D</td>
              <td>Adjustment </td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.adjustdmentFloating)}disabled/></td>
            </tr>
            <tr>
              <td>D</td>
              <td>Actual Day Time (C) </td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.actualDayTime)} disabled/></td>
            </tr>
            <tr>
              <td>E</td>
              <td>No. of Visits for the day</td>
              <td colSpan={3}><input type="number" className="form-control timesheet-form-control" value={(timesheetData.numOfClientVisit)} disabled/></td>
            </tr>
            
            <tr>
              <td>F</td>
              <td>Travel Time(Clients Only)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.travelTime)} disabled/></td>
            </tr>
            <tr>
            <td>#</td>
              <td>Name of Team Member:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={(employeeData.FirstName) +' '+(employeeData.LastName)} disabled/></td>
              </tr>
            <tr>
              <td>G</td>
              <td>Lunch Time</td>
              <td colSpan={3}><input type="text" name='lunchTime' className="form-control timesheet-form-control"   placeholder='HH:MM' value={TimeDisplay(timesheetData.lunchTime)} disabled/></td>
            </tr>
        
            <tr>
              <td>H</td>
              <td>Other Unproductive Time</td>
              <td colSpan={3}><input type="text" name='otherUnproductiveTime' className="form-control timesheet-form-control"  placeholder='HH:MM' value={TimeDisplay(timesheetData.otherUnproductiveTime)} disabled/></td>
            </tr>
            <tr>
              <td>I</td>
              <td>Total Unproductive Time (F+G+H)</td>
              <td colSpan={3}><input type="text" style={{ fontWeight: 'bold' }} className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.totalUnproductiveTime)} disabled/></td>
            </tr>
           
            <tr>
              <td>J</td>
              <td>Total Productive Time (C-I)</td>
              <td colSpan={3}><input type="text" style={{ fontWeight: 'bold' }} className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.totalProductiveTime)}  disabled/></td>
            </tr>
            <tr>
              <td>K</td>
              <td>Total Task Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheetData.totalTaskTime)}  disabled/></td>
            </tr>
            <tr>
              <td>L</td>
              <td>Submitted By</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheetData.submittedBy}  disabled/></td>
            </tr>
            <tr>
              <td>M</td>
              <td>Submission Date</td>
              <td colSpan={3}><input type="date" className="form-control timesheet-form-control" value={moment(timesheetData.submissionDate).format('YYYY-MM-DD')} disabled/></td>
            </tr>
            <tr>
              <th colSpan="4" className='headoftimesheet'>For Office use</th>
            </tr>
           
            <tr>
              <td>N</td>
              <td>Daily Working Checked:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheetData.dailyWorkingChecked === null ?'Pending':timesheetData.dailyWorkingChecked === 0 ? 'Rejected' : 'Approved'}disabled/></td>
            </tr>
            <tr>
              <td>O</td>
              <td>Accepted By</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheetData.acceptedBy} disabled/></td>
            </tr>
            <tr>
              <td>P</td>
              <td>Accepted Date</td>
              <td colSpan={3}><input type="date" className="form-control timesheet-form-control" value={moment(timesheetData.acceptedDate).format('YYYY-MM-DD')} disabled/></td>
            </tr>
            <tr className='no-print'>
              <td colSpan="4">
                <button type="submit" className="btn btn-success w-100 no-print" onClick={()=>{
                  window.print()
                }} >Print</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TimesheetPreviewFloating;
