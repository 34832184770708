import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import DateRangePickerComponent from './CustomDateRangePicker';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DayStatusReport = () => {
  const [users, setUsers] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [data, setData] = useState([]);

  // Fetch employee data
  useEffect(() => {
    axios
      .get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.empid,
          label: `${user.empid} - ${user.FirstName} ${user.LastName}`,
        }));
        setUsers(options);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      });
  }, []);

  // Fetch day status data
  const fetchDayStatusData = () => {
    const [startDate, endDate] = dateRange;
  
    if (selectedEmployee && startDate && endDate) {
      const formattedStartDate = new Date(startDate).toISOString().split('T')[0];
      const formattedEndDate = new Date(endDate).toISOString().split('T')[0];
  
      axios
        .get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getDayStatus', {
          params: {
            empid: selectedEmployee.value,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
        })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.error('Error fetching day status:', error);
        });
    } else {
      Swal.fire('Please select an employee and date range');
    }
  };

  // Export to Excel function
  const exportToExcel = () => {
    const worksheetData = [
      ['Employee:', selectedEmployee ? selectedEmployee.label : ''],
      ['Date Range:', dateRange[0] && dateRange[1] ? `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}` : ''],
      [],
      ['Date', 'Day In', 'Day Out', 'Day Time', 'Day Status', 'Remarks'],
      ...data.map((row) => [
        new Date(row.date).toLocaleDateString('en-GB'),
        row.intime ? row.intime.slice(11, 16) : '',
        row.outtime ? row.outtime.slice(11, 16) : '',
        row.intime && row.outtime
          ? `${Math.floor((new Date(row.outtime) - new Date(row.intime)) / 3600000)}:${String(Math.floor(((new Date(row.outtime) - new Date(row.intime)) % 3600000) / 60000)).padStart(2, '0')}`
          : '',
        row.dayStatus || 'Working',
        row.holidayReason || '',
      ]),
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'DayStatusReport');
  
    // Export to Excel
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'DayStatusReport.xlsx');
  };
  

  // Define columns for React-Table
  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ value }) => {
          const date = new Date(value);
          return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        },
      },
      {
        Header: 'Day In',
        accessor: 'intime',
        Cell: ({ value }) => {
          if (!value) return ''; // Return blank if the value is null or undefined
          
          const [hour, minute] = value.slice(11, 16).split(':');
          return `${hour}:${minute}`;
        }
      },
      {
        Header: 'Day Out',
        accessor: 'outtime',
        Cell: ({ value }) => {
          if (!value) return ''; // Return blank if the value is null or undefined
          
          const [hour, minute] = value.slice(11, 16).split(':');
          return `${hour}:${minute}`;
        }
      },
      {
        Header: 'Day Time',
        id: 'dayTime',
        Cell: ({ row }) => {
          const intime = row.original.intime;
          const outtime = row.original.outtime;
          
          if (!intime || !outtime) return ''; // Return blank if either time is missing
          
          const inDate = new Date(intime);
          const outDate = new Date(outtime);
          
          const diffMilliseconds = outDate - inDate;
          const diffMinutes = Math.floor(diffMilliseconds / 60000);
          const hours = Math.floor(diffMinutes / 60);
          const minutes = diffMinutes % 60;
          
          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        }
      },
      {
        Header: 'Day Status',
        accessor: 'dayStatus',
        Cell: ({ value }) => {
          let statusClass = 'text-success'; // Default to success for "Working"
          
          if (value === 'Absent') {
            statusClass = 'text-danger'; // Red for "Absent"
          } else if (value === 'Day Off' || value === 'Holiday') {
            statusClass = 'text-info'; // Blue for "Day Off" or "Holiday"
          }
          
          return <h6 className={`font-weight-bold ${statusClass}`}>{value || 'Working'}</h6>;
        },
      },
      { Header: 'Remarks', accessor: 'holidayReason' },
    ],
    []
);

  
  

const tableInstance = useTable(
  { columns, data, initialState: { pageSize: 31 }, },
  useGlobalFilter,
  useSortBy,
  usePagination
);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  page,
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setGlobalFilter,
  state: { pageIndex, globalFilter },
} = tableInstance;

return (
  <div className="container mt-5">
    <h3>Day Status Report</h3>

    <div className="row mb-3">
      <div className="col-md-6">
        <label htmlFor="employeeSelect">Employee</label>
        <Select
          id="employeeSelect"
          options={users}
          value={selectedEmployee}
          onChange={setSelectedEmployee}
          placeholder="Select an Employee"
        />
      </div>
      <div className="col-md-6">
        <label>Date Range:</label>
        <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
      </div>
      <div className="col-md-4 d-flex align-items-end mt-2">
        <button className="btn btn-success" onClick={fetchDayStatusData}>
          Get Data
        </button>
        <button className="btn btn-secondary ms-2" onClick={exportToExcel}>
          Export to Excel
        </button>
      </div>
    </div>

    <input
      className="form-control mb-3"
      value={globalFilter || ''}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Search in table..."
    />

    <table {...getTableProps()} className="table table-striped mt-3">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>

    <div className="d-flex justify-content-between align-items-center mt-2">
      <button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className="btn btn-secondary"
      >
        {'<<'}
      </button>
      <button
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        className="btn btn-secondary mx-2"
      >
        Previous
      </button>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <button
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className="btn btn-secondary mx-2"
      >
        Next
      </button>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className="btn btn-secondary"
      >
        {'>>'}
      </button>
    </div>
  </div>
);
};

export default DayStatusReport;
