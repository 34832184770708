import React, { useState, useEffect } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import Select from "react-select";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// Options for "Person" field in the form
const personOptions = [
  { value: 'IND', label: 'IND' },
  { value: 'HUF', label: 'HUF' },
  { value: 'COMP', label: 'COMP' },
  { value: 'CO-OP', label: 'CO-OP' },
  { value: 'FIRM', label: 'FIRM' },
  { value: 'AOP', label: 'AOP' },
  { value: 'BOI', label: 'BOI' }, 
  { value: 'LA', label: 'LA' },
  { value: 'AJP', label: 'AJP' }
];

// Global filter component
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => (
  <span>
    Search:{" "}
    <input
      value={globalFilter || ""}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder="Type to search..."
      className="form-control mb-3"
      style={{ width: "300px", display: "inline-block" }}
    />
  </span>
);

const TDSMasterList = () => {
  const [formData, setFormData] = useState({
    sectionNumber: '',
    codeNumber: '',
    description: '',
    thresholdLimit: '',
    person: [],
    percentage: '',
    remarks: ''
  });
  const [tableData, setTableData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePersonChange = (selectedOptions) => {
    setFormData({
      ...formData,
      person: selectedOptions
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = isEditing
        ? await axios.put(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateTDSMaster/${editId}`, formData)
        : await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/addTDSMaster`, formData);
      if (response.status === 200) {
        setFormData({
          sectionNumber: '',
          codeNumber: '',
          description: '',
          thresholdLimit: '',
          person: [],
          percentage: '',
          remarks: ''
        });
        setIsEditing(false);
        setEditId(null);
        fetchTableData();
      } else {
        console.error("Failed to add or update TDS Master data.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchTableData = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getTDSMasterData');
      if (response.status === 200) {
        // Combine TDSMaster and TDSPerson data for display
        const combinedData = response.data.map(item => ({
          ...item,
          person: Array.isArray(item.persons) // Check if 'persons' is an array
            ? item.persons
                .map(person =>
                  personOptions.find(option => option.value === person.name)?.label || person.name
                )
                .join(', ') // Join names with commas
            : "" // Default to an empty string if 'persons' is undefined or not an array
        }));
        setTableData(combinedData);
      } else {
        console.error("Failed to fetch table data.");
      }
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };
  
  const handleEdit = (row) => {
    setFormData({
      sectionNumber: row.sectionNumber,
      codeNumber: row.codeNumber,
      description: row.description,
      thresholdLimit: row.thresholdLimit,
      person: personOptions.filter(option => row.person.includes(option.label)),
      percentage: row.percentage,
      remarks: row.remarks
    });
    setIsEditing(true);
    setEditId(row.id);
  };

  const handleDelete = async (id) => {
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    });

    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteTDSMaster/${id}`);
        fetchTableData();
        Swal.fire('Deleted!', 'The entry has been deleted.', 'success');
      } catch (error) {
        console.error("Error deleting entry:", error);
        Swal.fire('Error!', 'Failed to delete the entry.', 'error');
      }
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const columns = React.useMemo(
    () => [
      { Header: "Section Number", accessor: "sectionNumber" },
      { Header: "Code Number", accessor: "codeNumber" },
      { Header: "Description", accessor: "description" },
      { Header: "Threshold Limit", accessor: "thresholdLimit" },
      { Header: "Person", accessor: "person" },
      { Header: "Percentage", accessor: "percentage" },
      { Header: "Remarks", accessor: "remarks" },
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <div>
            <button
              className="btn btn-sm btn-success me-2"
              onClick={() => handleEdit(row.original)}
            >
              <FontAwesomeIcon icon={faEdit}/>
            </button>
            <button
              className="btn btn-sm btn-danger"
              onClick={() => handleDelete(row.original.id)}
            >
              <FontAwesomeIcon icon={faTrash}/>
            </button>
          </div>
        )
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageSize: 5 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="container mt-5">
      <h3>TDS Master List</h3>
      {/* Form */}
      <form onSubmit={handleSubmit} className="formcontainer mb-4">
      <div className="row">
          <div className="col-md-2 mb-3">
            <label htmlFor="sectionNumber" className="form-label">Section Number</label>
            <input
              type="text"
              className="form-control"
              id="sectionNumber"
              name="sectionNumber"
              value={formData.sectionNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 mb-3">
            <label htmlFor="codeNumber" className="form-label">Code Number</label>
            <input
              type="text"
              className="form-control"
              id="codeNumber"
              name="codeNumber"
              value={formData.codeNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="col-md-2 mb-3">
            <label htmlFor="percentage" className="form-label">Percentage</label>
            <input
              type="number"
              className="form-control"
              id="percentage"
              name="percentage"
              value={formData.percentage}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className="row">
        
          <div className="col-md-6 mb-3">
            <label htmlFor="person" className="form-label">Person</label>
            <Select
              isMulti
              options={personOptions}
              value={formData.person}
              onChange={handlePersonChange}
              name="person"
              required
            />
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor="thresholdLimit" className="form-label">Threshold Limit</label>
            <input
              type="number"
              className="form-control"
              id="thresholdLimit"
              name="thresholdLimit"
              value={formData.thresholdLimit}
              onChange={handleChange}
            />
          </div>
          
          <div className="col-md-3 mb-3">
            <label htmlFor="remarks" className="form-label">Remarks</label>
            <input
              type="text"
              className="form-control"
              id="remarks"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
            />
          </div>
        </div>

        <button type="submit" className="btn btn-success">{isEditing ? "Update" : "Submit"}</button>
      </form>
      <div className="formcontainer">
        <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        <div className="table-responsive">
          <table {...getTableProps()} className="table table-striped">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div>
            <button className="btn btn-secondary me-2" onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </button>
            <button className="btn btn-secondary" onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </div>
          <span>
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <select
            className="form-select w-auto"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default TDSMasterList;