import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { utils, writeFile } from 'xlsx';
import DateRangePickerComponent from './CustomDateRangePicker'

const MemberProductivityAnalysis = () => {
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [timesheetData, setTimesheetData] = useState([]);
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: '',
  });
  const email = sessionStorage.getItem('email');

  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };
  const formatDate = (date) => new Date(date).toLocaleDateString();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]);
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);

 // Validation and loading state can be added in the existing component:

const [isLoading, setIsLoading] = useState(false);

const fetchTimesheetData = async () => {
  const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                console.error("Please select a valid date range");
                return;
            }
  // Validation for date range
  if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Date Range',
      text: 'End date should be after the start date.',
    });
    return;
  }

  let route = '';
  let requestData = {};

  if (startDate && endDate && employeeData.empid) {
    route = 'https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getTimesheetsByEmpIdAndDate';
    requestData = { empid: employeeData.empid, 
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'), };
  }

  if (route) {
    setIsLoading(true);
    try {
      const response = await axios.post(route, requestData);
      setTimesheetData(response.data);
    } catch (error) {
      console.error('Error fetching timesheet data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to fetch timesheet data.',
      });
    } finally {
      setIsLoading(false);
    }
  } else {
    Swal.fire({
      icon: 'warning',
      title: 'Incomplete Inputs',
      text: 'Please select a start date and end date',
      timer: 2000,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
    });
  }
};

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchTimesheetData();
  };

  const TimeDisplay = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  // Calculate totals and averages
  const calculateAggregates = () => {
    const totalRows = timesheetData.length;
  
    const totalDayIn = timesheetData.reduce((sum, row) => sum + row.dayInTime, 0);
    const totalDayOut = timesheetData.reduce((sum, row) => sum + row.dayOutTime, 0);
    const totalDayTime = timesheetData.reduce((sum, row) => sum + row.dayTime, 0);
    const totalActualDayTime = timesheetData.reduce((sum, row) => sum + row.actualDayTime, 0);
    const totalProductiveTime = timesheetData.reduce((sum, row) => sum + row.totalProductiveTime, 0);
    const totalUnproductiveTime = timesheetData.reduce((sum, row) => sum + row.totalUnproductiveTime, 0);
  
    // Calculate averages
    const avgDayIn = totalDayIn / totalRows;
    const avgDayOut = totalDayOut / totalRows;
  
    const avgActualDayTimePercent = totalDayTime > 0 
      ? ((totalActualDayTime / totalDayTime) * 100).toFixed(2)
      : 0;
  
    const avgProductiveTimePercent = totalDayTime > 0 
      ? ((totalProductiveTime / totalDayTime) * 100).toFixed(2)
      : 0;
  
    const avgUnproductiveTimePercent = totalDayTime > 0 
      ? ((totalUnproductiveTime / totalDayTime) * 100).toFixed(2)
      : 0;
  
    return {
      totalRows,
      avgDayIn: isNaN(avgDayIn) ? 0 : avgDayIn,
      avgDayOut: isNaN(avgDayOut) ? 0 : avgDayOut,
      totalDayTime,
      totalActualDayTime,
      totalProductiveTime,
      totalUnproductiveTime,
      avgActualDayTimePercent: isNaN(avgActualDayTimePercent) ? 0 : avgActualDayTimePercent,
      avgProductiveTimePercent: isNaN(avgProductiveTimePercent) ? 0 : avgProductiveTimePercent,
      avgUnproductiveTimePercent: isNaN(avgUnproductiveTimePercent) ? 0 : avgUnproductiveTimePercent,
    };
  };
  
  const {
    totalRows,
    avgDayIn,
    avgDayOut,
    totalDayTime,
    totalActualDayTime,
    totalProductiveTime,
    totalUnproductiveTime,
    avgActualDayTimePercent,
    avgProductiveTimePercent,
    avgUnproductiveTimePercent,
  } = calculateAggregates();
  

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'timesheetDate',
        Cell: ({ value }) => moment(value).format('DD-MMM-YY'),
      },
      {
        Header:  `D'IN`,
        accessor: 'dayInTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `D'Out`,
        accessor: 'dayOutTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `D'Time`,
        accessor: 'dayTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `ADJ D'Time `,
        accessor: 'actualDayTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: 'Prod Time',
        accessor: 'totalProductiveTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: 'UnP Time',
        accessor: 'totalUnproductiveTime',
        Cell: ({ value }) => TimeDisplay(value),
      },
      {
        Header: `ADJ D'Time (%)`,
        accessor: 'actualDayTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const actualDayTime = row.original.actualDayTime || 0;
          return dayTime > 0 ? `${((actualDayTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
      {
        Header: 'Prod Time (%)',
        accessor: 'productiveTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const productiveTime = row.original.totalProductiveTime || 0;
          return dayTime > 0 ? `${((productiveTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
      {
        Header: 'UnP Time (%)',
        accessor: 'unproductiveTimePercent',
        Cell: ({ row }) => {
          const dayTime = row.original.dayTime || 0;
          const unproductiveTime = row.original.totalUnproductiveTime || 0;
          return dayTime > 0 ? `${((unproductiveTime / dayTime) * 100).toFixed(2)}%` : '0%';
        },
      },
    ],
    []
  );

  const data = React.useMemo(() => timesheetData, [timesheetData]);
  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleExport = () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
    const exportData = rows.map((row) => ({
      Date: moment(row.original.timesheetDate).format('DD-MMM-YYYY'),
      'Day In': TimeDisplay(row.original.dayInTime),
      'Day Out': TimeDisplay(row.original.dayOutTime),
      'Day Time': TimeDisplay(row.original.dayTime),
      'Actual Day Time': TimeDisplay(row.original.actualDayTime),
      'Productive Time': TimeDisplay(row.original.totalProductiveTime),
      'Unproductive Time': TimeDisplay(row.original.totalUnproductiveTime),
      'Actual Day Time (%)': row.original.dayTime > 0 ? `${((row.original.actualDayTime / row.original.dayTime) * 100).toFixed(2)}%` : '0%',
      'Productive Time (%)': row.original.dayTime > 0 ? `${((row.original.totalProductiveTime / row.original.dayTime) * 100).toFixed(2)}%` : '0%',
      'Unproductive Time (%)': row.original.dayTime > 0 ? `${((row.original.totalUnproductiveTime / row.original.dayTime) * 100).toFixed(2)}%` : '0%',
    }));
  
    // Adding the aggregate row data at the bottom
    exportData.push({
      Date: `Total Rows: ${totalRows}`,
      'Day In': avgDayIn ? TimeDisplay(avgDayIn) : '',
      'Day Out': avgDayOut ? TimeDisplay(avgDayOut) : '',
      'Day Time': totalDayTime ? TimeDisplay(totalDayTime) : '',
      'Actual Day Time': totalActualDayTime ? TimeDisplay(totalActualDayTime) : '',
      'Productive Time': totalProductiveTime ? TimeDisplay(totalProductiveTime) : '',
      'Unproductive Time': totalUnproductiveTime ? TimeDisplay(totalUnproductiveTime) : '',
      'Actual Day Time (%)': avgActualDayTimePercent ? `${avgActualDayTimePercent}%` : '',
      'Productive Time (%)': avgProductiveTimePercent ? `${avgProductiveTimePercent}%` : '',
      'Unproductive Time (%)': avgUnproductiveTimePercent ? `${avgUnproductiveTimePercent}%` : '' 
    });
  
    // Creating a worksheet
    const worksheet = utils.aoa_to_sheet([
      [{ v: 'Productivity Analysis', s: { font: { bold: true, sz: 14 } } }],
      [],
      [`Selected User: ${selectedUser || 'All Users'}`],
      [`Start Date: ${formatDate(startDate)}`],
      [`End Date: ${formatDate(endDate)}`],
      [],
      ['Date', 'Day In', 'Day Out', 'Day Time', 'Actual Day Time', 'Productive Time', 'Unproductive Time', 'Actual Day Time (%)', 'Productive Time (%)', 'Unproductive Time (%)'],
    ]);
    // Append the table data rows to the worksheet
    utils.sheet_add_json(worksheet, exportData, { origin: -1, skipHeader: true });
  
    // Creating a workbook and adding the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Productivity Analysis');
  
    // Export the workbook to an Excel file
    writeFile(workbook, 'Productivity_Analysis.xlsx');
  };

  return (
    <div className="container mt-5">
      <h2>Productivity Analysis</h2>
      <form onSubmit={handleSubmit}>
        <div className='row'>
        <div className="col-md-3">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
    <div className="col-md-4 d-flex align-items-end justify-content-between">
        <button type="submit" className="btn btn-success">
          Get Data
        </button>
        </div>
        </div>
        <button className="btn btn-secondary mt-2" onClick={handleExport}>
                            Export to Excel
        </button>
      </form>

      {timesheetData.length > 0 && (
        <div className="table-responsive mt-1">
          <table className="table table-striped table-bordered table-hover mt-4" {...getTableProps()}>
          <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody style={{ fontSize: '13px', verticalAlign: 'baseline' }} {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
              {/* Summary Row */}
              <tr>
                <td>Total Rows: {totalRows}</td>
                <td>Avg D'In: {TimeDisplay(avgDayIn)}</td>
                <td>Avg D'Out: {TimeDisplay(avgDayOut)}</td>
                <td>Total D'Time: {TimeDisplay(totalDayTime)}</td>
                <td>Total ADJ D'Time: {TimeDisplay(totalActualDayTime)}</td>
                <td>Total Prod Time: {TimeDisplay(totalProductiveTime)}</td>
                <td>Total UnP Time: {TimeDisplay(totalUnproductiveTime)}</td>
                <td>ADJ D'Time(%): {avgActualDayTimePercent}</td>
                <td>Prod Time(%): {avgProductiveTimePercent}</td>
                <td>UnP Time(%) : {avgUnproductiveTimePercent}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MemberProductivityAnalysis;
