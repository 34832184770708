import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import { useTable, usePagination, useGlobalFilter } from 'react-table';

const LeaveApplicationForm = () => {
  const sessionEmail = sessionStorage.getItem('email');
  const [formdata, setFormData] = useState({});
  const [formState, setFormState] = useState({
    name: '',
    fromdate: '',
    todate: '',
    reason: '',
    otherReason: '',
    location: '',
    sessionEmail: sessionEmail,
  });

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getPendingLeaveRequests', { email: email });
        setRequests(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching leave requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const formatDate = (dateString) => {
    return moment(dateString).tz('Asia/Kolkata').format('DD-MMMM-YYYY');
  };

  const reasons = [
    "Medical Issues - Self",
    "Medical Issues - Family Members",
    "Casualty of close relatives",
    "Marriage Functions of close relatives",
    "Marriage Functions in friends circle",
    "Examination - specify in text box",
    "Vacation with family",
    "Any Other",
  ];

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: sessionEmail },
        });
        const employeeData = response.data[0];
        setFormData(employeeData);
        setFormState((prevState) => ({
          ...prevState,
          name: `${employeeData?.FirstName} ${employeeData?.LastName}`,
        }));
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    if (sessionEmail) {
      fetchEmployeeData();
    }
  }, [sessionEmail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const fullname = `${formdata?.FirstName} ${formdata?.LastName}`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitLeaveForm', formState);
      Swal.fire({
        icon: 'success',
        title: response.data,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      }).then(() => {
        setRequests((prevRequests) => [
          ...prevRequests,
          {
            fullname,
            fromdate: formState.fromdate,
            todate: formState.todate,
            reasonforleave: formState.reason,
            otherreason: formState.otherReason,
            location: formState.location,
            status: null,
          },
        ]);

        setFormState({
          ...formState,
          fromdate: '',
          todate: '',
          reason: '',
          otherReason: '',
          location: '',
          sessionEmail: sessionEmail,
        });
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error submitting form',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (result.isConfirmed) {
      try {
        await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deleteLeaveRequest/${id}`);
        setRequests((prevRequests) => prevRequests.filter((request) => request.id !== id));
        Swal.fire('Deleted!', 'Your request has been deleted.', 'success');
      } catch (error) {
        Swal.fire('Error!', 'There was a problem deleting the request.', 'error');
      }
    }
  };

  
  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'fullname' },
      { Header: 'From Date', accessor: 'fromdate', Cell: ({ value }) => formatDate(value) },
      { Header: 'To Date', accessor: 'todate', Cell: ({ value }) => formatDate(value) },
      { Header: 'Reason', accessor: 'reasonforleave' },
      { Header: 'Other Reason', accessor: 'otherreason' },
      { Header: 'Location', accessor: 'location' },
      { Header: 'Status', accessor: 'status', Cell: ({ value }) => (value === null ? 'Pending' : value === 0 ? 'Deny' : 'Accepted') },
      {
        Header: 'Delete',
        Cell: ({ row }) => (
          <button className="btn btn-danger btn-sm" onClick={() => handleDelete(row.original.ID)}>
            Delete
          </button>
        ),
      },
    ],
    []
  );
  const data = useMemo(() => requests, [requests]);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <div className="container">
        <h2 className="mt-5">Leave Application Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col">
              <label htmlFor="name">Name*</label>
              <input type="text" className="form-control" id="name" name="name" value={fullname} disabled />
            </div>
            <div className="col">
              <label htmlFor="date">From*</label>
              <input type="date" className="form-control" id="fromdate" name="fromdate" value={formState.fromdate} onChange={handleChange} required/>
            </div>
            <div className="col">
              <label htmlFor="date">To*</label>
              <input type="date" className="form-control" id="todate" name="todate" value={formState.todate} onChange={handleChange} required/>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              <label htmlFor="reason">Reason for Leave*</label>
              <select className="form-control" id="reason" name="reason" value={formState.reason} onChange={handleChange} required>
                <option value="">Select Reason</option>
                {reasons.map((reason, index) => (
                  <option key={index} value={reason}>{reason}</option>
                ))}
              </select>
            </div>
            {(formState.reason === 'Any Other' || formState.reason === 'Examination - specify in text box') && (
              <div className="col">
                <label htmlFor="otherReason">Any Other Reason</label>
                <input type="text" className="form-control" id="otherReason" name="otherReason" value={formState.otherReason} onChange={handleChange} />
              </div>
            )}
            <div className="col">
              <label htmlFor="location">Location of leave*</label>
              <input type="text" className="form-control" id="location" name="location" value={formState.location} onChange={handleChange} required/>
            </div>
          </div>

          <button type="submit" className="btn btn-success">Send for approval</button>
        </form>
      </div>
      <div className="container mt-4">
        <p className="para-heading">Leave Requests</p>

        {loading ? (
          <div className="text-center my-4">
            <div className="spinner-border mt-5" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                value={globalFilter || ''}
                onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                placeholder="Search leave requests"
              />
            </div>
            <table className="table table-striped" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pagination d-flex justify-content-between align-items-center">
              <div className="pagination-controls">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                  {'<<'}
                </button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  {'<'}
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                  {'>>'}
                </button>
              </div>
              <div className="pagination-info text-center">
                Page <strong>{pageIndex + 1} of {pageOptions.length}</strong>
              </div>
              <div className="pagination-size ml-auto">
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LeaveApplicationForm;
