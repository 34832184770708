import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DayIn from './dayIn';
import DayOut from './dayOut';

const DayInOut = () => {
  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
      <h2 className="mb-4">Day In / Day Out Tracker</h2>
      <div className="alert alert-danger" role="alert">
      <li className="small mb-0" style={{fontWeight:600}}> Please enter the time in 24 HR format or check the AM/PM before submitting.</li>
    </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>Day In</Tab>
          <Tab>Day Out</Tab>
        </TabList>

        <TabPanel>
          <DayIn />
        </TabPanel>
        <TabPanel>
          <DayOut />
        </TabPanel>
      </Tabs>
      
    </div>
  );
};

export default DayInOut;
