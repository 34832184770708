import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/selectTask.css';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
const Swal = require('sweetalert2');

const AssignTask = () => {
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [mainTasks, setMainTasks] = useState([]); // State for Main Tasks
  const [tasks, setTasks] = useState([]); // State for Sub Tasks
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedMainTask, setSelectedMainTask] = useState(null); // State for selected Main Task
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [taskSelection, setTaskSelection] = useState(null);
  const [remarks, setRemarks] = useState(''); // New field for remarks
  const [deadline, setdeadline] = useState(''); // New field for remarks
  const sessionEmail = sessionStorage.getItem('email');
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: sessionEmail },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (sessionEmail) {
      fetchEmployeeData();
    }
  }, [sessionEmail]);


  const empid=employeeData.empid

  useEffect(() => {
    const fetchemployeetasks = async () => {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/showSpecificEmployeeTasks',{empid:empid});
        
        setUsers(groupTasks(response.data));
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching users!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchemployeetasks();
  }, [empid]);
  // Function to handle row completion
  const handleComplete = (rowData) => {
    navigate('../PendingTaskDailyWorking', { state: { rowData } }); // Navigate and pass row data using location.state
  };

  const groupTasks = (users) => {
    const groupedData = users.reduce((acc, user) => {
      // Create a key that groups by empid, clientid, mainTask, and remarks
      const key = `${user.empid}-${user.clientid}-${user.mainTask}-${user.remarks || '-'}`;
  
      if (!acc[key]) {
        acc[key] = {
          empid: user.empid[0],
          FirstName: user.FirstName,
          LastName: user.LastName,
          clientid: user.clientid,
          TradeName: user.TradeName,
          mainTask: user.mainTask ?user.mainTask:'-',
          taskname: [user.taskname],
          AssignedBy: user.AssignedBy,
          deadline:user.deadline?moment(user.deadline).format('DD-MM-YYYY'):'-',
          remarks: user.remarks || '-'
        };
      } else {
        acc[key].taskname.push(user.taskname);
      }
  
      return acc;
    }, {});
  
    return Object.values(groupedData);
  };
  

  const columns = useMemo(
    () => [
      // { Header: 'Team Member ID', accessor: 'empid' },
      // { Header: 'Team Member Name', accessor: (row) => `${row.FirstName} ${row.LastName}` },
      { Header: 'CC', accessor: 'clientid' },
      { Header: 'Trade Name', accessor: 'TradeName' },
      { Header: 'Main Task', accessor: 'mainTask' },
      { Header: 'Sub Task', accessor: (row) => row.taskname.join(', ') },
      { Header: 'Remarks', accessor: (row) => row.remarks || '-' },
      { Header: 'Deadline', accessor: 'deadline'},
      { Header: 'Assigned By', accessor: 'AssignedBy' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <button
            className="btn btn-success"
            onClick={() => handleComplete(row.original)} // Pass the row data when the button is clicked
          >
            Complete
          </button>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0, pageSize: 5 }, // Default page size
    },
    useGlobalFilter,
    usePagination
  );

  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectemployee')
      .then(response => {
        const employeeOptions = response.data.map(employee => ({
          value: employee.empid,
          label: `${employee.empid} ${employee.FirstName} ${employee.LastName}`
        }));
        setEmployees(employeeOptions);
      })
      .catch(error => toast.error('Failed to fetch employees'));

    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients')
      .then(response => {
        const clientOptions = response.data.map(client => ({
          value: client.CC,
          label: `${client.CC} ${client.TradeName}`
        }));
        setClients(clientOptions);
      })
      .catch(error => toast.error('Failed to fetch clients'));
  }, []);

  // Fetch Main Tasks
  useEffect(() => {
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getmaintasks')
      .then(response => {
        const mainTaskOptions = response.data.map(task => ({
          value: task.MainTasks,
          label: task.MainTasks
        }));
        setMainTasks(mainTaskOptions);
      })
      .catch(error => toast.error('Failed to fetch main tasks'));
  }, []);

  // Fetch Sub Tasks when Main Task is selected
  useEffect(() => {
    if (selectedMainTask) {
      axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', { maintasks: selectedMainTask.value })
        .then(response => {
          const subTaskOptions = response.data.map(task => ({
            value: task.SubTasks,
            label: task.SubTasks
          }));
          setTasks(subTaskOptions);
        })
        .catch(error => toast.error('Failed to fetch sub-tasks'));
    }
  }, [selectedMainTask]);

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
    setSelectedClient(null); // Reset client and tasks when changing employee
    setSelectedTasks([]);
  };

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption);
    setSelectedTasks([]); // Reset tasks when changing client
  };

  const handleTaskChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedTasks(prev => [...prev, selectedOption]);
      setTasks(prev => prev.filter(task => task.value !== selectedOption.value));
      setTaskSelection(null); // Clear selection
    }
  };

  const handleRemoveTask = (taskId) => {
    const removedTask = selectedTasks.find(task => task.value === taskId);
    setSelectedTasks(prev => prev.filter(task => task.value !== taskId));
    setTasks(prev => [...prev, removedTask]);
  };

  const renderTaskBadges = () => {
    return selectedTasks.map(task => (
      <span key={task.value} className="badge badge-primary me-2 mb-2 task-badge">
        {task.label}
        <button
          type="button"
          className="btn btn-danger btn-sm ms-2 "
          onClick={() => handleRemoveTask(task.value)}
        >
          ×
        </button>
      </span>
    ));
  };

  const handleSave = () => {
    if (!selectedEmployee || !selectedClient || !selectedMainTask || selectedTasks.length === 0) {
      toast.error('Please select an employee, a client, a main task, and at least one sub-task');
      return;
    }

    const data = {
      email: sessionEmail,
      empid: selectedEmployee.value,
      clientCode: selectedClient.value,
      mainTask: selectedMainTask.label, // Include Main Task
      tasks: selectedTasks.map(task => ({
        taskname: task.label,
      })),
      remarks, 
      deadline,
    };

    axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/savetaskdetails', data)
      .then(response => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: response.data,
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
          window.location.reload(); 
        });
      })
      .catch(error => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: error.message,
          showConfirmButton: false,
          timer: 3000
        });
      });
  };

  return (
    <>
    <div className="container mt-5">
      <ToastContainer />
      <p className='para-heading'>Assign Tasks</p>
      <div className="row">
      <div className="form-conrol col-md-4">
        <label htmlFor="employeeSelect" className="form-label">Select Team Member</label>
        <Select
          id="employeeSelect"
          value={selectedEmployee}
          onChange={handleEmployeeChange}
          options={employees}
          placeholder="Select Team Member"
        />
      </div>
    
        <div className="form-conrol col-md-4">
          <label htmlFor="clientSelect" className="form-label">Select Client</label>
          <Select
            id="clientSelect"
            value={selectedClient}
            onChange={handleClientChange}
            options={clients}
            placeholder="Select Client"
          />
        </div>
       
        <div className="form-conrol col-md-4">
        <label htmlFor="deadline" className="form-label">Deadline</label>
        <input
          type="date"
          className="form-control"
          id="deadline"
          value={deadline}
          onChange={(e) => setdeadline(e.target.value)}
        />
      </div>
      </div>
      <div className="row">
        <div className="form-conrol col-md-4">
            <label htmlFor="mainTaskSelect" className="form-label">Select Main Task</label>
            <Select
              id="mainTaskSelect"
              value={selectedMainTask}
              onChange={(option) => {
                setSelectedMainTask(option);
                setSelectedTasks([]); // Reset selected sub-tasks when main task changes
              }}
              options={mainTasks}
              placeholder="Select Main Task"
            />
          </div>
         
          <div className="form-conrol col-md-4">
              <label htmlFor="subTaskSelect" className="form-label">Select Sub Tasks</label>
              <Select
                id="subTaskSelect"
                value={taskSelection}
                onChange={handleTaskChange}
                options={tasks}
                placeholder="Select Sub Task"
              />
            </div>
    
    <div className="form-conrol col-md-4">
        <label htmlFor="remarks" className="form-label">Remarks</label>
        <input
          type="text"
          className="form-control"
          id="remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          placeholder="Enter remarks (optional)"
        />
      </div>
      {selectedTasks.length > 0 && (
        <div className="mb-3">
          <label className="form-label">Selected Sub Tasks:</label>
          <div>{renderTaskBadges()}</div>
        </div>
      )}
      </div>
      <button className="btn btn-success mt-3" onClick={handleSave}>Assign Task </button>
    </div>
    
    <div className="table-container mt-5">
      <p className="para-heading">Assigned Tasks</p>
      <div className="search-bar mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        />
      </div>
      <table {...getTableProps()} className="table table-striped">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between align-items-center">
        <div>
        <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
        <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
        </div>
        <div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        </div>
        <div>
        <select
        className="form-select"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[5, 10, 15, 20].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
        </div>
      </div>
    </div>
  </>
  );
};

export default AssignTask;
