import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import Swal for error handling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx'; // Import XLSX for Excel export

const AssignClients = () => {
  const [members, setMembers] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMemberSelected, setIsMemberSelected] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    '1 - Team Member': true,
    '2 - Team Member': true,
    '3 - Team Member': true,
    '4 - Reviewer': true,
    '5 - Reviewer': true,
    '6 - Reviewer': true,
    '7 - Reviewer': true,
    '8 - Reviewer': true,
    '9 - Reviewer': true,
  });

  // Fetch members from the backend
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users');
        const memberOptions = response.data.map(member => ({
          value: member.empid,
          label: `${member.empid} - ${member.FirstName} ${member.LastName}`,
        }));
        setMembers(memberOptions);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching users!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Fetch clients from the backend
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients');
        const clientOptions = response.data.map(client => ({
          value: client.CC,
          label: `${client.CC} - ${client.TradeName}`,
        }));
        setClients(clientOptions);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching clients!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Fetch data based on selected member or client
  // Fetch data based on selected member or client
const handleGetData = async () => {
  // Reset filters to the default state (all columns selected)
  setSelectedColumns({
    '1 - Team Member': true,
    '2 - Team Member': true,
    '3 - Team Member': true,
    '4 - Reviewer': true,
    '5 - Reviewer': true,
    '6 - Reviewer': true,
    '7 - Reviewer': true,
    '8 - Reviewer': true,
    '9 - Reviewer': true,
  });

  if (selectedMember) {
    setIsMemberSelected(true);
    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${selectedMember.value}`);
      setClientData(response.data);
      setFilteredData(response.data); // Set initial filtered data to all fetched data
    } catch (error) {
      Swal.fire('Error', 'Error fetching client data for the selected member!', 'error');
    }
  } else if (selectedClient) {
    setIsMemberSelected(false);
    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-members?cc=${selectedClient.value}`);
      setClientData(response.data);
      setFilteredData(response.data); // Set initial filtered data to all fetched data
    } catch (error) {
      Swal.fire('Error', 'Error fetching member data for the selected client!', 'error');
    }
  } else {
    Swal.fire('Warning', 'Please select either a member or a client!', 'warning');
  }
};


  // Handle column change for filtering Sequence
  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns(prevColumns => ({
      ...prevColumns,
      [name]: checked,
    }));
  };

  // Select all Sequence
  const handleSelectAll = () => {
    const updatedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true;
      return acc;
    }, {});
    setSelectedColumns(updatedColumns);
  };

  // Unselect all Sequence
  const handleUnselectAll = () => {
    const updatedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false;
      return acc;
    }, {});
    setSelectedColumns(updatedColumns);
  };

  // Filter the client data based on selected Sequence
  useEffect(() => {
    const filtered = clientData.filter(data => {
      const responsibilityLabel = 
        data.responsibility === 1 ? '1 - Team Member' :
        data.responsibility === 2 ? '2 - Team Member' :
        data.responsibility === 3 ? '3 - Team Member' :
        data.responsibility === 4 ? '4 - Reviewer' :
        data.responsibility === 5 ? '5 - Reviewer' :
        data.responsibility === 6 ? '6 - Reviewer' :
        data.responsibility === 7 ? '7 - Reviewer' :
        data.responsibility === 8 ? '8 - Reviewer' :
        data.responsibility === 9 ? '9 - Reviewer' : null;

      return selectedColumns[responsibilityLabel];
    });

    setFilteredData(filtered);
  }, [selectedColumns, clientData]);

  // Function to export data to Excel
  const exportToExcel = () => {
    // Create a workbook
    const wb = XLSX.utils.book_new();
    
    // Define the header based on whether a member is selected
    const header = ['P', 'CC', 'Trade Name', 
        ...(isMemberSelected ? ['Sequence'] : ['Member', 'Sequence'])
    ];

    // Prepare data for export
    const data = filteredData.map(data => ([
        data.Priority,
        data.CC,
        data.TradeName,
        isMemberSelected ? 
            ([1, 2, 3].includes(data.responsibility) ? 
                `${data.responsibility} - Team Member` : 
                ([4, 5, 6,7, 8, 9].includes(data.responsibility) ? 
                    `${data.responsibility} - Reviewer` : 
                    `${data.responsibility}`)) 
            : ( // For Responsibility Column
                ([1, 2, 3].includes(data.responsibility) ? 
                    `${data.responsibility} - Team Member` : 
                    ([4, 5, 6, 7, 8, 9].includes(data.responsibility) ? 
                        `${data.responsibility} - Reviewer` : 
                        `${data.responsibility}`))
            )
    ]));

    // Create a row for the selected member or client
    const selectedInfoRow = ['Selected:', isMemberSelected ? selectedMember.label : selectedClient.label];

    // Combine header, selected info, and data
    const exportData = [selectedInfoRow, header, ...data];

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(exportData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Assigned Clients');

    // Export the workbook
    XLSX.writeFile(wb, 'assigned_clients.xlsx');
};

  return (
    <div className="container mt-5">
      <h3>Assigned Team</h3>
      
      {/* Bootstrap Grid System for layout */}
      <div className="row mb-4">
        <div className="col-md-6">
          {/* Member Select */}
          <Select
            options={members}
            value={selectedMember}
            onChange={(member) => {
              setSelectedMember(member);
              setSelectedClient(null); // Reset client when member is selected
            }}
            placeholder="Select a member"
            isLoading={loading} // Show loading state
            isDisabled={!!selectedClient} // Disable if a client is selected
            isClearable
          />
        </div>

        <div className="col-md-6">
          {/* Client Select */}
          <Select
            options={clients}
            value={selectedClient}
            onChange={(client) => {
              setSelectedClient(client);
              setSelectedMember(null); // Reset member when client is selected
            }}
            placeholder="Select a client"
            isLoading={loading} // Show loading state
            isDisabled={!!selectedMember} // Disable if a member is selected
            isClearable
          />
        </div>
      </div>

      {/* Buttons and Filter Dropdown in a Flex Row */}
<div className="d-flex justify-content-between align-items-center mt-4">
  {/* Buttons Group */}
  <div className="d-flex">
    <button className="btn btn-success me-2" onClick={handleGetData}>
      Get Data
    </button>

    <button className="btn btn-secondary" onClick={exportToExcel}>
      Export to Excel
    </button>
  </div>

  {/* Dropdown for Responsibility Filter */}
  <div className="dropdown ms-auto">
  <button className="btn btn-secondary dropdown-toggle" type="button" id="filterDropdown" data-bs-toggle="dropdown" aria-expanded="false">
            <FontAwesomeIcon icon={faFilter} /> Filter Sequence
          </button>
          <ul className="dropdown-menu" aria-labelledby="filterDropdown">
            <li className="dropdown-item" onClick={handleSelectAll}>
              <a className="dropdown-item" href="#">
                Select All
              </a>
            </li>
            <li className="dropdown-item" onClick={handleUnselectAll}>
              <a className="dropdown-item" href="#">
                Unselect All
              </a>
            </li>
            <li role="separator" className="dropdown-divider"></li>
            {Object.keys(selectedColumns).map((column) => (
              <li key={column} className="dropdown-item">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name={column}
                    checked={selectedColumns[column]}
                    onChange={handleColumnChange}
                    id={`checkbox-${column}`}
                  />
                  <label className="form-check-label" htmlFor={`checkbox-${column}`}>
                    {column}
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>


      {/* Data Table */}
      <div className="mt-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>P</th>
              <th>CC</th>
              <th>Trade Name</th>
              {isMemberSelected ? (
                <th>Sequence</th>
              ) : (
                <>
                  <th>Member</th>
                  <th>Sequence</th>
                </>
              )}
            </tr>
          </thead>
            <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((data, index) => (
                            <tr key={index}>
                                <td>{data.Priority}</td>
                                <td>{data.CC}</td>
                                <td>{data.TradeName}</td>
                                {isMemberSelected ? (
                                    <td>
                                        {([1, 2, 3].includes(data.responsibility)) 
                                            ? `${data.responsibility} - Team Member`
                                            : ([4, 5, 6, 7, 8, 9].includes(data.responsibility)) 
                                                ? `${data.responsibility} - Reviewer`
                                                : `${data.responsibility}`
                                        }
                                    </td>
                                ) : (
                                    <>
                                        <td>{`${data.empid} ${data.FirstName} ${data.LastName}`}</td> {/* Adjust according to your data structure */}
                                        <td>
                                            {([1, 2, 3].includes(data.responsibility)) 
                                                ? `${data.responsibility} - Team Member`
                                                : ([4, 5, 6,7, 8, 9].includes(data.responsibility)) 
                                                    ? `${data.responsibility} - Reviewer`
                                                    : `${data.responsibility}`
                                            }
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={isMemberSelected ? 3 : 4} className="text-center">No data available</td>
                        </tr>
                    )}
                </tbody>

        </table>
      </div>
    </div>
  );
};

export default AssignClients;
