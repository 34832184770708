import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/Timesheet.css';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
const moment = require('moment');
const momentTimezone = require('moment-timezone');
const Timesheet = () => {
  const email = sessionStorage.getItem('email'); // Get email from session storage
  const [currentDate, setCurrentDate] = useState({ day: '', month: '', year: '', formattedDate: '' });
  const [todayDate, setTodayDate] = useState('');
  const [weekNumber, setWeekNumber] = useState(0);
  const [weekStartEnd, setWeekStartEnd] = useState({ startDate: '', endDate: '' });
  const [employeeData, setemployeeData] = useState({
    FirstName: '',
    LastName: '',
    Address: '',
    ContactNo: '',
    Email: '',
    Password: '',
    MonthlyRemuneration: '',
    Position: '',
    MonthlyLeaves: '',
    SignImage: null,
    empid: '',
    hoursType: '',
    floatingHours: '',
    ScheduleIn: '',
    ScheduleOut: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [scheduleOfficeHours, setScheduleOfficeHours] = useState(0);
  const [scheduleOfficeHoursString, setScheduleOfficeHoursString] = useState(0);
  const [timeSheetDate, setTimeSheetDate] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [openingSurplus, setOpeningSurplus] = useState(0);
  const [openingDeficiency, setOpeningDeficiency] = useState(0);
  const [openingSurplusString, setOpeningSurplusString] = useState('');
  const [openingDeficiencyString, setOpeningDeficiencyString] = useState('');
  const [totalRequiredTime, setTotalRequiredTime] = useState(0);
  const [totalRequiredTimeString, setTotalRequiredTimeString] = useState('');
  const [dayOutTime, setDayOutTime] = useState(0);
  const [dayInTime, setDayInTime] = useState(0);
  const [dayOutTimeString, setDayOutTimeString] = useState('');
  const [dayInTimeString, setDayInTimeString] = useState('');
  const [dayTime,setDayTime] = useState(0);
  const [dayTimeString, setDayTimeString] = useState('');
  const [lessOneHourSheetNotMade, setLessOneHourSheetNotMade] = useState(0);
  const [lessOneHourSheetNotMadeString, setLessOneHourSheetNotMadeString] = useState('');
  const [lessOneHourNonInfo,setLessOneHourNonInfo] = useState(0);
  const [lessOneHourNonInfoString,setLessOneHourNonInfoString] = useState('');
  const [actualDayTime, setActualDayTime] = useState(0);
  const [actualDayTimeString, setActualDayTimeString] = useState('');
  const [travelTime, setTravelTime] = useState(0);
  const [travelTimeString, setTravelTimeString] = useState('');
  const [numOfClientVisit,setNumOfClientVisit] = useState(0);
  const [lunchTime, setLunchTime] = useState(0);
  const [lunchTimeString, setLunchTimeString] = useState('');
  const [otherUnproductiveTime, setOtherUnproductiveTime] = useState(0);
  const [otherUnproductiveTimeString, setOtherUnproductiveTimeString] = useState('');
  const [totalUnproductiveTime, setTotalUnproductiveTime] = useState(0);
  const [totalUnproductiveTimeString, setTotalUnproductiveTimeString] = useState('');
  const [totalProductiveTime, setTotalProductiveTime] = useState(0);
  const [totalProductiveTimeString, setTotalProductiveTimeString] = useState('');
  const [closingSurplus, setclosingSurplus] = useState(0);
  const [closingDeficiency, setclosingDeficiency] = useState(0);
  const [closingSurplusString, setclosingSurplusString] = useState('');
  const [closingDeficiencyString, setclosingDeficiencyString] = useState('');
  const [totalTaskTime, setTotalTaskTime] = useState(0);
  const [totalTaskTimeString, setTotalTaskTimeString] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Set the current date in the format you prefer (e.g., 'YYYY-MM-DD')
    const today = moment().format('YYYY-MM-DD');
    setTodayDate(today);
}, []);

useEffect(() => {
  // Fetch the available dates from the backend
  axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkLastTimesheetMade', { params: { email } })
      .then(response => {
          if (response.data.result === 1) {
              setAvailableDates(response.data.dates);
          } else {
              setAvailableDates([]);
          }
      })
      .catch(error => {
          console.error('Error fetching available dates:', error);
      });
}, []);
  
  const calculateDateDetails = (date) => {
    const day = date.toLocaleString('default', { weekday: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfMonth = date.getDate().toString().padStart(2, '0');
    const monthOfYear = (date.getMonth() + 1).toString().padStart(2, '0');
    const formattedDate = `${dayOfMonth}-${monthOfYear}-${year}`;
    
    setCurrentDate({ day, month, year, formattedDate });
    setWeekNumber(getWeekNumber(date));
    const { startDate, endDate } = getWeekStartEnd(date);
    setWeekStartEnd({ startDate: startDate.toDateString(), endDate: endDate.toDateString() });
  };
  
  const TimeDisplay = (totalMinutes ) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.round(totalMinutes % 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(Math.abs(minutes)).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
    
  };
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email }
        });
        if (response.data && response.data.length > 0) {
          setemployeeData(response.data[0]);


       // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };
    
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (employeeData) {
      const scheduleIn = momentTimezone.utc(employeeData.ScheduleIn);
      const scheduleOut = momentTimezone.utc(employeeData.ScheduleOut);
      const duration = moment.duration(scheduleOut.diff(scheduleIn));
      const totalHours = duration.asHours();
      setScheduleOfficeHours(totalHours*60);
      setScheduleOfficeHoursString(TimeDisplay(totalHours*60));
    }
  }, [employeeData])

  let dayTimeScope;
  const handleChange = async (event) => {
    const { name, value } = event.target;
    if(name === "date"){
    const selectedDate = event.target.value;
    const weekdate = new Date(selectedDate);
    calculateDateDetails(weekdate);
    setTimeSheetDate(selectedDate); // Update state with the selected date

    try {
        // Fetch inTime and outTime
        const timeResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetch-time', {
            params: {
                date: selectedDate,
                email: email
            }
        });
        if (timeResponse.data) {
            const dayOutTime = moment.utc(timeResponse.data.outTime).format('HH:mm');
            const dayInTime = moment.utc(timeResponse.data.inTime).format('HH:mm');

            const [dayOutTimehours, dayOutTimeminutes] = dayOutTime.split(':').map(Number);
            const dayOutTotalMinutes = dayOutTimehours * 60 + dayOutTimeminutes;
            setDayOutTime(dayOutTotalMinutes)
            setDayOutTimeString(TimeDisplay(dayOutTotalMinutes))

            const [dayInTimehours, dayInTimeminutes] = dayInTime.split(':').map(Number);
            const dayIntotalMinutes = dayInTimehours * 60 + dayInTimeminutes;
            setDayInTime(dayIntotalMinutes)
            setDayInTimeString(TimeDisplay(dayIntotalMinutes))

            setDayTime(dayOutTotalMinutes-dayIntotalMinutes);
            setDayTimeString(TimeDisplay(dayOutTotalMinutes-dayIntotalMinutes))
            dayTimeScope = dayOutTotalMinutes-dayIntotalMinutes;
            if (isNaN(dayOutTotalMinutes)) {
              Swal.fire({
                  icon: 'warning',
                  title: 'Day Out Required',
                  text: 'Please Day Out First',
              }).then((result) => {
                  if (result.isConfirmed) {
                      navigate('/userDashboard/employeedayinout');
                  }
              });
          }
        } else {
            setDayOutTime(0);
            setDayInTime(0);
            setDayTime(0);
            setDayOutTimeString(TimeDisplay(0))
            setDayInTimeString(TimeDisplay(0))
            setDayTimeString(TimeDisplay(0))
            dayTimeScope=0
        }
        

        // Fetch sum of travelTime and count of clientid
        const visitSummaryResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/visit-summary', {
            email: email,
            date: selectedDate
        });
        
        if (visitSummaryResponse.data) {
            // Set travel time and number of client visits
            setTravelTime((visitSummaryResponse.data.totalTravelTime));
            setTravelTimeString(TimeDisplay(visitSummaryResponse.data.totalTravelTime)) // Assuming it's in minutes
            setNumOfClientVisit(visitSummaryResponse.data.clientCount);
        } else {
            setTravelTime(0);
            setTravelTimeString(TimeDisplay(0)) // Assuming it's in minutes
            setNumOfClientVisit(0);
        }

        let actualDayTimeValue;
        const lateEarlyInfoResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checklateearlyinfo', {
          email: email,
          date: selectedDate
         });
          let lessOneHourNonInfoValue;
          if(lateEarlyInfoResponse.data){
          if( lateEarlyInfoResponse.data.lateinformed === 0 || lateEarlyInfoResponse.data.earlyinformed === 0){
              dayTimeScope > 240 ? lessOneHourNonInfoValue = Math.round(dayTimeScope/10) : lessOneHourNonInfoValue = Math.round(dayTimeScope/5)
              setLessOneHourNonInfo(lessOneHourNonInfoValue)
              setLessOneHourNonInfoString(TimeDisplay(lessOneHourNonInfoValue))
              actualDayTimeValue = dayTimeScope - lessOneHourNonInfoValue;
              setActualDayTime(dayTimeScope - lessOneHourNonInfoValue)
              setActualDayTimeString(TimeDisplay(dayTimeScope - lessOneHourNonInfoValue))
            }
            else{
              lessOneHourNonInfoValue = 0;
              setLessOneHourNonInfo(0)
              setLessOneHourNonInfoString(TimeDisplay(0))
              actualDayTimeValue = dayTimeScope - lessOneHourNonInfoValue;
              setActualDayTime(dayTimeScope - lessOneHourNonInfoValue)
              setActualDayTimeString(TimeDisplay(dayTimeScope - lessOneHourNonInfoValue))
            }
          }
          else{
            lessOneHourNonInfoValue = 0;
            setLessOneHourNonInfo(0)
            setLessOneHourNonInfoString(TimeDisplay(0))
            actualDayTimeValue = dayTimeScope - lessOneHourNonInfoValue;
              setActualDayTime(dayTimeScope - lessOneHourNonInfoValue)
              setActualDayTimeString(TimeDisplay(dayTimeScope - lessOneHourNonInfoValue))
          }

          const deficiencyResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchdeficiency', {
            email: email,
          });

          if(deficiencyResponse.data){          
            setOpeningSurplusString(TimeDisplay(deficiencyResponse.data.closingSurplus))
            setOpeningDeficiencyString(TimeDisplay(deficiencyResponse.data.closingDeficiency))
            setOpeningSurplus(Number(deficiencyResponse.data.closingSurplus));
            setOpeningDeficiency(Number(deficiencyResponse.data.closingDeficiency));
            let openingDeficiency = Number(deficiencyResponse.data.closingDeficiency);
            let openingSurplus = Number(deficiencyResponse.data.closingSurplus);
            let totalrequiredfortheday = openingDeficiency + scheduleOfficeHours - openingSurplus;
            
            setTotalRequiredTimeString(TimeDisplay(totalrequiredfortheday))
            setTotalRequiredTime(totalrequiredfortheday)  
            if(totalrequiredfortheday>=actualDayTimeValue){
              setclosingDeficiency(totalrequiredfortheday - actualDayTimeValue)
              setclosingDeficiencyString(TimeDisplay(totalrequiredfortheday - actualDayTimeValue))
              setclosingSurplus(0)
              setclosingSurplusString(TimeDisplay(0))
            }
            else{
              setclosingSurplus(actualDayTimeValue-totalrequiredfortheday)
              setclosingSurplusString(TimeDisplay(actualDayTimeValue-totalrequiredfortheday))
              setclosingDeficiency(0)
              setclosingDeficiencyString(TimeDisplay(0))
            }
            }
            else{   
              setOpeningSurplusString(TimeDisplay(0))
              setOpeningDeficiencyString(TimeDisplay(0))
              setOpeningSurplus(Number(0));
              setOpeningDeficiency(Number(0));
              let totalrequiredfortheday =  scheduleOfficeHours ;
              setTotalRequiredTimeString(TimeDisplay(totalrequiredfortheday))
            setTotalRequiredTime(totalrequiredfortheday)  
            if(totalrequiredfortheday>=actualDayTimeValue){
              setclosingDeficiency(totalrequiredfortheday - actualDayTimeValue)
              setclosingDeficiencyString(TimeDisplay(totalrequiredfortheday - actualDayTimeValue))
              setclosingSurplus(0)
              setclosingSurplusString(TimeDisplay(0))
            }
            else{
              setclosingSurplus(actualDayTimeValue-totalrequiredfortheday)
              setclosingSurplusString(TimeDisplay(actualDayTimeValue-totalrequiredfortheday))
              setclosingDeficiency(0)
              setclosingDeficiencyString(TimeDisplay(0))
            }
            }

        const taskTimeResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/task-summary', {
                  email: email,
                  date: selectedDate
              }
          );
          let totalTaskTimeScope;
          if(taskTimeResponse.data){
            totalTaskTimeScope = taskTimeResponse.data.totalTaskTime;
            setTotalTaskTime(totalTaskTimeScope)
            setTotalTaskTimeString(TimeDisplay(totalTaskTimeScope));
          }
  

          
            } catch (error) {
                console.error('Error fetching time data or summary:', error);
            }
            
          } 
          if(name === 'lunchTime') {
          let lunchTimeValue = event.target.value;
          setLunchTimeString(lunchTimeValue)
          setLunchTime(convertToMinutes(lunchTimeValue))
          let lunchTimeValueMinutes = convertToMinutes(lunchTimeValue);
          const TravelTime = Number(travelTime);
          let OtherUnproductiveTimeValue = dayTime -  lunchTimeValueMinutes - TravelTime - totalTaskTime;
          setOtherUnproductiveTime(OtherUnproductiveTimeValue);
          setOtherUnproductiveTimeString(TimeDisplay(OtherUnproductiveTimeValue))

          // Calculate total unproductive time
          const TotalUnproductiveTime = TravelTime + OtherUnproductiveTimeValue + lunchTimeValueMinutes;
          
          // Set the state with the calculated total unproductive time
          setTotalUnproductiveTime(TotalUnproductiveTime);
          setTotalUnproductiveTimeString(TimeDisplay(TotalUnproductiveTime))              
          setTotalProductiveTime(dayTime-TotalUnproductiveTime)
          setTotalProductiveTimeString(TimeDisplay(dayTime-TotalUnproductiveTime))
          }
          }

          const getWeekStartEnd = (date) => {
            const firstDayOfWeek = new Date(date.setDate(date.getDate() - date.getDay() + 1));
            const lastDayOfWeek = new Date(firstDayOfWeek);
            lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
            return { startDate: firstDayOfWeek, endDate: lastDayOfWeek };
          };

          const getWeekNumber = (date) => {
            const startOfYear = new Date(date.getFullYear(), 0, 1);
            const pastDaysOfYear = (date - startOfYear) / 86400000;
            return Math.ceil((pastDaysOfYear + startOfYear.getDay()) / 7);
          };
          const fullname=employeeData.FirstName +' '+ employeeData.LastName
          const ScheduleIn = momentTimezone.utc(employeeData.ScheduleIn).format('hh:mm A');
          const ScheduleOut = momentTimezone.utc(employeeData.ScheduleOut).format('hh:mm A');
          
          const scheduleTime=ScheduleIn +'-'+ ScheduleOut
        
          const handleSubmit = async (event) => {
            event.preventDefault();

            if(!timeSheetDate){
              Swal.fire({
                icon: 'error',
                title: 'Date is required',
                text: 'Please enter a date',
              });
              return
            }
            const isValidFormatLunchTime = /^([01]\d|2[0-3]):([0-5]\d)$/.test(lunchTimeString);
            if(!isValidFormatLunchTime){
              Swal.fire({
                icon: 'error',
                title: 'Invalid Format for Lunch Time',
                text: 'Please enter time in HH:MM format',
              });
              return
            }
            const isValidFormatOtherUnproductive = /^([01]\d|2[0-3]):([0-5]\d)$/.test(otherUnproductiveTimeString);
            if(!isValidFormatOtherUnproductive){
              Swal.fire({
                icon: 'error',
                title: 'Invalid Format for Other Unproductive Time',
                text: 'Please enter time in HH:MM format',
              });
              return
            }
            
            const payload = {
              empid:employeeData.empid,
              email: email,
              fullname:fullname,
              hoursType:employeeData.hoursType,
              timeSheetDate: timeSheetDate,
              openingDeficiency:openingDeficiency,
              closingDeficiency:closingDeficiency,
              openingSurplus:openingSurplus,
              closingSurplus:closingSurplus,
              weekNumber:weekNumber,
              scheduleOfficeHours:scheduleOfficeHours,
              dayOutTime:dayOutTime,
              dayInTime:dayInTime,
              dayTime:dayTime,
              totalRequiredTime:totalRequiredTime,
              lessOneHourNonInfo:lessOneHourNonInfo,
              lessOneHourSheetNotMade:lessOneHourSheetNotMade,
              actualDayTime:actualDayTime,
              numOfClientVisit:numOfClientVisit,
              travelTime:travelTime,
              lunchTime:lunchTime,
              otherUnproductiveTime:otherUnproductiveTime,
              totalUnproductiveTime:totalUnproductiveTime,
              totalProductiveTime:totalProductiveTime,
              totalTaskTime:totalTaskTime,
              submittedBy:employeeData.empid
            };
        
            try {
              const insertTimesheetResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/insert-timesheet', payload);
              const responseMessage = insertTimesheetResponse.data.message;
              
              // Determine icon type based on the message content
              const iconType = responseMessage.includes('Timesheet already exists')
                ? 'error'
                : 'success';
            
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: iconType,
                text: responseMessage,
                showConfirmButton: false,
                timer: 3000
              });
              if(iconType==='success'){
                setOpeningDeficiencyString('')
                setOpeningSurplusString('')
                setScheduleOfficeHoursString('')
                setTotalRequiredTimeString('')
                setDayInTimeString('')
                setDayOutTimeString('')
                setDayTimeString('')
                setLessOneHourNonInfoString('')
                setLessOneHourSheetNotMadeString('')
                setActualDayTimeString('')
                setclosingDeficiencyString('')
                setclosingSurplusString('')
                setNumOfClientVisit(0)
                setTravelTimeString('')
                setLunchTimeString('')
                setOtherUnproductiveTimeString('')
                setTotalUnproductiveTimeString('')
                setTotalProductiveTimeString('')
              }
            } catch (error) {
              console.error('There was an error submitting the timesheet data!', error);
            
              Swal.fire({
                toast: true,
                position: 'top-end',
                icon: 'error',
                text: 'An error occurred while submitting. Please try again.',
                showConfirmButton: false,
                timer: 3000
              });
            }
          };
        

  return (
    <div className="timesheetcontainer">
      <div className="timesheet">
        <div className="header">
          <h3 className="headoftimesheet"></h3>
        </div>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th colSpan="3" className='headoftimesheet'>Daily Timesheet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name of Team Member:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={fullname} disabled/></td>
            </tr>
            {employeeData.hoursType==='fixed'&&
            <tr>
              <td>Schedule Time:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={scheduleTime} disabled/></td>
            </tr>}
            <tr>
              <td>Week Starting: Ending:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={`${weekStartEnd.startDate} - ${weekStartEnd.endDate}`} disabled/></td>
            </tr>
            <tr>
              <td>Week Number:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={weekNumber} disabled/></td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th colSpan="3" className='headoftimesheet'>Timesheet Entries</th>
            </tr>
            <tr>
              <td>#</td>
              <td>Date</td>
              <td colSpan={3}>
                <select 
                                name='date' 
                                className="form-control timesheet-form-control" 
                                value={timeSheetDate} 
                                onChange={handleChange} 
                                required
                            >
                                <option value="">Select Date</option>
                                {availableDates.length === 0 ? (
                                    <option value="">No dates available</option>
                                ) : (
                                    availableDates.map(date => (
                                        <option key={date} value={date}>
                                            {new Date(date).toLocaleDateString('en-CA')}
                                        </option>
                                    ))
                                )}
                            </select></td>
            </tr>
            <tr>
              <td>A</td>
              <td>Opening Deficiency</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={openingDeficiencyString} disabled/></td>
            </tr>
            <tr>
              <td>B</td>
              <td>Schedule Office Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={scheduleOfficeHoursString} disabled/></td>
            </tr>
            <tr>
              <td>C</td>
              <td>Opening Surplus (K)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={openingSurplusString} disabled/></td>
            </tr>
            <tr>
              <td>D</td>
              <td>Time Required For the day (A+B-C)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={totalRequiredTimeString} disabled/></td>
            </tr>
            <tr>
              <td>E</td>
              <td>Day In Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={dayInTimeString} disabled/></td>
            </tr>
            <tr>
              <td>F</td>
              <td>Day Out Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={dayOutTimeString} disabled/></td>
            </tr>
            <tr>
              <td>G</td>
              <td>Day Time (F-E)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={dayTimeString} disabled/></td>
            </tr>
            <tr>
              <td>H</td>
              <td>Less: 10% or 20% for Non Info of late coming or early exit</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={lessOneHourNonInfoString} disabled /></td>
            </tr>
            <tr>
              <td>I</td>
              <td>Actual Day Time (G-H) </td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={actualDayTimeString} disabled/></td>
            </tr>
            <tr>  
              <td>J</td>
              <td>Closing Deficiency (HH:MM) (D-I)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={closingDeficiencyString} disabled/></td>
            </tr>
            <tr>
              <td>K</td>
              <td>Closing Surplus (HH:MM) (I-D)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={closingSurplusString} disabled/></td>
            </tr>
            <tr>
              <td>L</td>
              <td>No. of Visits for the day</td>
              <td colSpan={3}><input type="number" className="form-control timesheet-form-control" value={numOfClientVisit} disabled/></td>
            </tr>
        
            <tr>
              <td>M</td>
              <td>Travel Time(Clients Only)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={travelTimeString} disabled/></td>
            </tr>
            <tr>
              <td>N</td>
              <td>Lunch Time</td>
              <td colSpan={3}><input type="text" name='lunchTime' className="form-control timesheet-form-control" value={lunchTimeString} onChange={handleChange} placeholder='HH:MM' required/></td>
            </tr>
        
            <tr>
              <td>O</td>
              <td>Other Unproductive Time</td>
              <td colSpan={3}><input type="text" name='otherUnproductiveTime' className="form-control timesheet-form-control" value={otherUnproductiveTimeString} placeholder='HH:MM' disabled /></td>
            </tr>
            <tr>
              <td>P</td>
              <td>Total Unproductive Time (M+N+O)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={totalUnproductiveTimeString} disabled/></td>
            </tr>
           
            <tr>
              <td>Q</td>
              <td>Total Productive Time (G-P)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={totalProductiveTimeString}  disabled/></td>
            </tr>
            <tr>
              <td>R</td>
              <td>Total Task Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={totalTaskTimeString}  disabled/></td>
            </tr>
            <tr>
              <td>S</td>
              <td>Code of Team Member</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={employeeData.empid}  disabled/></td>
            </tr>
            <tr>
              <td>T</td>
              <td>Date</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={todayDate} disabled/></td>
            </tr>
            <tr>
              <td colSpan="4">
                <button type="submit" className="btn btn-success w-100" onClick={handleSubmit}>Submit</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Timesheet;
