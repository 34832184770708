import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt , faPlus, faCheck} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const PaymentOverdueForm = () => {
  const [clients, setClients] = useState([]);
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const [rows, setRows] = useState([
    {
      id: 1, 
      client: null, 
      billAmount: "", 
      taxAmount: "", 
      totalAmount: "", 
      receivedAmount: "", 
      reason: null, 
      totalSubmitted: false,
      isreceived: false
    }
  ]);

  const isMGEmployee = employeeData?.empid?.startsWith('MG');

  const reasonOptions = [
    { value: 'Recd full payment', label: 'Recd full payment' },
    { value: 'Recd part payment', label: 'Recd part payment' },
    { value: 'Recd only tax amount', label: 'Recd only tax amount' },
    { value: 'Will pay with next GSTR', label: 'Will pay with next GSTR' },
    { value: 'Will pay with next TDS/TCS challan', label: 'Will pay with next TDS/TCS challan' },
    { value: 'Will pay in next week', label: 'Will pay in next week' },
    { value: 'Will pay in 3-4 days', label: 'Will pay in 3-4 days' },
    { value: 'Will pay in month', label: 'Will pay in month' },
    { value: 'No response from client', label: 'No response from client' },
    { value: 'Made reminder one', label: 'Made reminder one' },
    { value: 'Made reminder two', label: 'Made reminder two' },
    { value: 'Made reminder three', label: 'Made reminder three' }
  ];

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/selectclients');
        setClients(clientsResponse.data);
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch clients', 'error');
      }
    };

    const fetchEmployeeData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        setEmployeeData(response.data[0]);
      } catch (error) {
        console.error('Error fetching employee data', error);
      }
    };

    fetchClients();
    fetchEmployeeData();
  }, [email]);

  const fetchPaymentOverdue = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getpaymentoverdue');
      const fetchedRows = response.data.map((payment, index) => ({
        id: index + 1,
        client: { value: payment.CC, label: `${payment.CC} - ${payment.TradeName}` },
        billAmount: payment.billAmount,
        taxAmount: payment.taxAmount,
        totalAmount: payment.totalAmount,
        receivedAmount: payment.receivedAmount,
        DueSubmittedBy:payment.DueSubmittedBy,
        DueSubmittedDate:payment.DueSubmittedDate,
        receivedSubmittedBy:payment.receivedSubmittedBy,
        ReceivedSubmittedDate:payment.ReceivedSubmittedDate,
        reason: { value: payment.reason, label: payment.reason },
        totalSubmitted: true,
        isreceived: !!(payment.receivedAmount && payment.reason) // Mark as received if both receivedAmount and reason are present
      }));
      setRows(fetchedRows);
    } catch (error) {
      console.error('Failed to fetch payment overdue data:', error);
    }
  };

  useEffect(() => {
    fetchPaymentOverdue();
  }, []);

  const getAvailableClients = (rowId) => {
    const selectedClients = rows
      .filter(row => row.id !== rowId)
      .map(row => row.client?.value);
    return clients
      .filter(client => !selectedClients.includes(client.CC))
      .map(client => ({ value: client.CC, label: `${client.CC} - ${client.TradeName}` }));
  };

  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      client: null,
      billAmount: "",
      taxAmount: "",
      totalAmount: "",
      receivedAmount: "",
      reason: null,
      totalSubmitted: false,
      isreceived: false
    };
    setRows([...rows, newRow]);

  };

  const handleClientChange = (selectedClient, rowId) => {
    setRows(rows.map(row => row.id === rowId ? { ...row, client: selectedClient } : row));
  };

  const handleInputChange = (e, rowId, field) => {
    const value = e.target.value;
    setRows(rows.map(row => {
      const billAmount = field === 'billAmount' ? value : Number(row.billAmount);
      const taxAmount = field === 'taxAmount' ? value : (row.taxAmount);
      const totalAmount = parseFloat(billAmount) + parseFloat(taxAmount);
      return row.id === rowId
        ? { ...row, [field]: value, totalAmount: isNaN(totalAmount) ? "" : totalAmount }
        : row;
    }));
  };

  const handleReasonChange = (selectedReason, rowId) => {
    setRows(rows.map(row => row.id === rowId ? { ...row, reason: selectedReason } : row));
  };

  const handleDeleteRow = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    const clientCC = row.client?.value;
    if (!clientCC) {
      Swal.fire('Error', 'Client not selected', 'error');
      return;
    }

    try {
      await axios.delete(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deletepaymentoverdue/${clientCC}`);
      setRows(rows.filter(row => row.id !== rowId));
      Swal.fire({
        toast: true,
        text: `Row deleted successfully`,
        icon: 'success',
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire('Error', 'Failed to delete row', 'error');
    }
  };

  const handleSubmitReceived = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    const dataToSubmit = {
      CC: row.client?.value || '',
      receivedAmount: row.receivedAmount || '',
      reason: row.reason?.value || '',
      empid: employeeData?.empid || '',
    };

    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitreceivedamount', dataToSubmit);
      setRows(rows.map(r => r.id === rowId ? { ...r, isreceived: true } : r));
      Swal.fire({
        toast: true,
        text: `Received amount submitted successfully`,
        icon: 'success',
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      fetchPaymentOverdue();
    } catch (error) {
      Swal.fire('Error', 'Failed to submit received amount', 'error');
    }
  };

  const handleSubmitTotal = async (rowId) => {
    const row = rows.find(row => row.id === rowId);
    if (!row.client || !row.billAmount || !row.taxAmount) {
      Swal.fire('Error', 'Please select a client and enter bill and tax amounts', 'error');
      return;
    }

    const TradeName = row.client.label.split(' - ')[1];

    const dataToSubmit = {
      CC: row.client.value,
      billAmount: row.billAmount,
      taxAmount: row.taxAmount,
      totalAmount: row.totalAmount,
      receivedAmount: row.receivedAmount,
      reason: row.reason?.value || null,
      empid: employeeData?.empid || '',
      TradeName: TradeName
    };

    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/submitpaymentoverdue', dataToSubmit);
      setRows(rows.map(r => r.id === rowId ? { ...r, totalSubmitted: true } : r));
      addRow();
      fetchPaymentOverdue();
      Swal.fire({
        toast: true,
        text: `Data submitted successfully`,
        icon: 'success',
        position: 'top-end',
        timer: 1000,
        timerProgressBar: true,
        showConfirmButton: false,
      });
    } catch (error) {
      Swal.fire('Error', 'Failed to submit data', 'error');
    }
  };

  return (
    <div className="container">
      <h3 className="mt-5">Payment Overdue Form</h3>

      <table className="table table-bordered 0" >
        <thead className="thead-light">
          <tr>
            <th style={{ width: '190px' }} >Select Client</th>
            <th style={{ width: '120px' }} >Bill Amt</th>
            <th style={{ width: '120px' }} >Tax Amt</th>
            <th style={{ width: '120px' }} >Total</th>
            <th>#</th>
            <th style={{ width: '90px' }}>Sub.By <br />Dated</th>
            <th  style={{ width: '160px' }}>Reason</th>
            <th style={{ width: '120px' }} > Recd Amt</th>
            <th>#</th>
            <th style={{ width: '90px' }}>Sub.By<br />Dated</th>
            <th style={{ width: '10px' }}>Delete</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
          {rows.map((row) => (
            <tr key={row.id}>
              <td >
                <Select
                  value={row.client}
                  onChange={(selectedClient) => handleClientChange(selectedClient, row.id)}
                  options={getAvailableClients(row.id)}
                  style={{fontSize:'1px'}}
                  isDisabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.billAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'billAmount')}
                  disabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.taxAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'taxAmount')}
                  disabled={!isMGEmployee || row.totalSubmitted || row.isreceived}
                  className="form-control"
                />
              </td> 
              <td>
                <input
                  type="number"
                  value={row.totalAmount}
                  disabled
                  className="form-control"
                />
              </td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => handleSubmitTotal(row.id)}
                  disabled={!isMGEmployee  || row.totalSubmitted}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
              <td>
  {row.DueSubmittedBy ? (
    <>
      <div>{row.DueSubmittedBy}</div>
      <div>{moment(row.DueSubmittedDate).format('DD-MM-YY')}</div>
    </>
  ) : (
    ''
  )}
</td>

              <td>
                <Select
                  value={row.reason}
                  onChange={(selectedReason) => handleReasonChange(selectedReason, row.id)}
                  options={reasonOptions}
                  isDisabled={row.isreceived}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.receivedAmount}
                  onChange={(e) => handleInputChange(e, row.id, 'receivedAmount')}
                  disabled={row.isreceived}
                  className="form-control"
                />
              </td>
              <td>
                <button
                  className="btn btn-success"
                  onClick={() => handleSubmitReceived(row.id)}
                  disabled={row.isreceived || !row.receivedAmount || !row.reason}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
              </td>
              <td>
  {row.receivedSubmittedBy ? (
    <>
      <div>{row.receivedSubmittedBy}</div>
      <div>{moment(row.ReceivedSubmittedDate).format('DD-MM-YY')}</div>
    </>
  ) : (
    ''
  )}
</td>


              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteRow(row.id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
          <button className="btn btn-primary mt-3" onClick={addRow}>
        Add New Row
      </button>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentOverdueForm;
