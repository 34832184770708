import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const AdminRequests = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [lateAndEarlyRequests, setLateAndEarlyRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const leaveResponse = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getLeaveRequests', { email: email });
        setLeaveRequests(leaveResponse.data);

        const lateAndEarlyResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getUserLateEarlyRequests`, {
          params: { email: email }
        });
        setLateAndEarlyRequests(lateAndEarlyResponse.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);


  const formatDateTime = (dateString) => {
    const date = moment(dateString).subtract(330, 'minutes');
    return date.tz('Asia/Kolkata').format('DD-MMMM-YYYY HH:mm');
  };

  const formatDate = (dateString) => {
    return moment(dateString).tz('Asia/Kolkata').format('DD-MMMM-YYYY');
  };

  const renderLeaveRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'15px',verticalAlign:'baseline'}}>  
        <tr>
          <th>Name</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Reason</th>
          <th>Other Reason</th>
          <th>Location</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Done By</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'14px',verticalAlign:'baseline'}}>
        {leaveRequests.map((request, index) => (
          <tr key={index}>
            <td>{request.fullname}</td>
            <td>{formatDate(request.fromdate)}</td>
            <td>{formatDate(request.todate)}</td>
            <td>{request.reasonforleave}</td>
            <td>{request.otherreason ? request.otherreason : '-'}</td>  
            <td>{request.location}</td>
            <td>{formatDateTime(request.submission_time)}</td>
            <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
            <td>{request.acceptedBy}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderLateAndEarlyRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'15px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Type</th>
          <th>Time Duration</th>
          <th>Reason</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Done By</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'14px',verticalAlign:'baseline'}}>
        {lateAndEarlyRequests.map((request, index) => (
          <tr key={index}>
            <td>{request.fullname}</td>
            <td>{formatDate(request.date)}</td>
            <td>{request.type}</td>
            <td>{request.timeDuration}</td>
            <td>{request.reason==='Any Other'?request.otherReason:request.reason}</td>
            <td>{formatDateTime(request.submission_time)}</td>
            <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
            <td>{request.acceptedBy}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="container mt-5" style={{ maxWidth:'1440px' }}>
      <p className='para-heading'>Your Requests</p>
      
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Tabs>
          <TabList>
            <Tab>Leave Requests</Tab>
            <Tab>Late Entry & Early Exit Requests</Tab>
          </TabList>

          <TabPanel>
            {leaveRequests.length === 0 ? <p>No leave requests found.</p> : renderLeaveRequestsTable()}
          </TabPanel>
          <TabPanel>
            {lateAndEarlyRequests.length === 0 ? <p>No late entry or early exit requests found.</p> : renderLateAndEarlyRequestsTable()}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default AdminRequests;
