import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/adminDashboard.css';
import AddTeamMember from './addTeamMember';
import UserList from './showUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt,faUserPlus, faList, faHouse, faListCheck, faBars,faCircleDot } from '@fortawesome/free-solid-svg-icons';
import RoleSelector from './chooseRole';
import AddClient from './addClient';
import ClientList from './showClients';
import EmployeeClientSelector from './assignClient';
import Showassignclient from './showassignclients';
import AssignTask from './assignTasks';
import AdminRequests from './adminLeaveRequests';
import AdminAccessRequests from './adminaccessrequests';
import ShowEmployeeTasks from './showEmployeeTasks';
import CompletedTaskForAdmin from './completedTaskForAdmin';
import EditTeamMember from './editTeamMember';
import EditClient from './editClient';
import TimesheetListForAdmin from './timesheetListForAdmin';
import FixedTimesheetApproval from './fixedTimesheetApproval';
import FloatingTimesheetApproval from './floatingTimesheetApproval';
import ClientCompletedTaskForAdmin from './clientCompletedTaskForAdmin';
import DayInOutReport from './dayInOutReport';
import VisitReport from './visitReport';
import PendingTaskDailyWorking from './pendingTaskDailyWorking';
const Swal = require('sweetalert2');


const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [activeLink, setActiveLink] = useState('Dashboard');
  const adminName = "Admin"; // Replace this with dynamic admin name if available

  

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  const handleLogout = () => {
    Swal.fire({
      title: 'Do you want to log out?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      icon: 'warning'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem('email');
        navigate('/');
      }
    });
  };
  return (
    <>
      <div className="top-navbar">
        <div className="top-navbar-content">
        <div className="toggle-btn" onClick={toggleNavbar}>
          <FontAwesomeIcon icon={faBars} />
          </div>
          <span className="admin-welcome">{adminName}</span>
          <button onClick={handleLogout} className="logout-btn">
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </button>
        </div>
      </div>
      <div className='admin-dashboard'>
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="logo-section mt-5">
        </div>
        <ul className="nav flex-column">
            <Link to="" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Dashboard' ? 'active' : ''}`} onClick={() => handleLinkClick('Dashboard')}>
              <FontAwesomeIcon icon={faHouse} />
              {!isCollapsed && <span>Dashboard</span>}
            </li>
            </Link>
            <Link to="assigntasks" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Assign Tasks' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Tasks')}>
            <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Assignments</span>}
            </li>
            </Link>
            <li className="nav-heading">{isCollapsed ? <FontAwesomeIcon icon={faCircleDot}/> : 'Approvals'}</li>
            <Link to="employeeleavereq" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Requests' ? 'active' : ''}`} onClick={() => handleLinkClick('Requests')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Leave / Late-Early Requests</span>}
            </li>
            </Link>
            <Link to="adminaccessreq" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Access Requests' ? 'active' : ''}`} onClick={() => handleLinkClick('Access Requests')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Access Requests</span>}
            </li>
            </Link>
            <li className="nav-heading">{isCollapsed ? <FontAwesomeIcon icon={faCircleDot}/> : ' MG - Reports'}</li>
            <Link to="dayinoutreport" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Day In / Day Out' ? 'active' : ''}`} onClick={() => handleLinkClick('Day In / Day Out')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Day In / Day Out Report</span>}
            </li>
            </Link>
            <Link to="completedTaskForAdmin" className='colourlink'>
            <li className={`nav-item ${activeLink === "Team Member's Completed Tasks" ? 'active' : ''}`} onClick={() => handleLinkClick("Team Member's Completed Tasks")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>Daily Working Report</span>}
            </li>
            </Link>
            <Link to="visitreport" className='colourlink'>
            <li className={`nav-item ${activeLink === "Visit Report" ? 'active' : ''}`} onClick={() => handleLinkClick("Visit Report")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>Visit Report</span>}
            </li>
            </Link>
            <Link to="timesheetListForAdmin" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Team Member Timesheets' ? 'active' : ''}`} onClick={() => handleLinkClick('Team Member Timesheets')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Timesheets</span>}
            </li>
            </Link>
            <Link to="clientCompletedTask" className='colourlink'>
            <li className={`nav-item ${activeLink === "Client's Completed Tasks" ? 'active' : ''}`} onClick={() => handleLinkClick("Client's Completed Tasks")}>
              <FontAwesomeIcon icon={faListCheck} />
              {!isCollapsed && <span>Client's Completed Tasks</span>}
            </li>
            </Link>
            
            <Link to="showassignclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Assign Client List' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Client List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Assign Client List</span>}
            </li>
            </Link>
            
            <li className="nav-heading">{isCollapsed ? <FontAwesomeIcon icon={faCircleDot}/> : 'Client Functions'}</li>     
            <Link to="addclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Add Client' ? 'active' : ''}`} onClick={() => handleLinkClick('Add Client')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Add Client</span>}
            </li>
            </Link>
            <Link to="clientlist" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Client List' ? 'active' : ''}`} onClick={() => handleLinkClick('Client List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Client List</span>}
            </li>
            </Link>
            <li className="nav-heading">{isCollapsed ? <FontAwesomeIcon icon={faCircleDot}/> : 'Team Member Functions'}</li>
            <Link to="addteammember" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Add Team Member' ? 'active' : ''}`} onClick={() => handleLinkClick('Add Team Member')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Add Team Member</span>}
            </li>
            </Link>
            <Link to="userlist" className='colourlink'>
            <li className={`nav-item ${activeLink === 'User List' ? 'active' : ''}`} onClick={() => handleLinkClick('User List')}>
            <FontAwesomeIcon icon={faList} />
              {!isCollapsed && <span>Team Member List</span>}
            </li>
            </Link>   
            <Link to="assignclient" className='colourlink'>
            <li className={`nav-item ${activeLink === 'Assign Client' ? 'active' : ''}`} onClick={() => handleLinkClick('Assign Client')}>
              <FontAwesomeIcon icon={faUserPlus} />
              {!isCollapsed && <span>Assign Client</span>}
            </li>
            </Link>
            
            
            
        </ul>
      </div>
      <div className="content-area">
        <Routes>
          <Route path="/" element={<HomeScreen adminName={adminName} />} />
          <Route path="/addteammember" element={<AddTeamMember />} />
          <Route path="/userlist" element={<UserList />} />
          <Route path="/addclient" element={<AddClient />} />
          <Route path="/clientlist" element={<ClientList />} />
          <Route path="/assignclient" element={<EmployeeClientSelector />} />
          <Route path="/showassignclient" element={<Showassignclient />} />
          <Route path="/assignTasks" element={<AssignTask />} />
          <Route path="/employeeleavereq" element={<AdminRequests/>}/>
          <Route path="/adminaccessreq" element={<AdminAccessRequests/>}/>
          <Route path="/showemployeetasks" element={<ShowEmployeeTasks/>}/>
          <Route path="/completedTaskForAdmin" element={<CompletedTaskForAdmin/>}/>
          <Route path="/edit-user/:id" element={<EditTeamMember />} />
          <Route path="/edit-client/:clientId" element={<EditClient />} />
          <Route path='/timesheetListForAdmin' element={<TimesheetListForAdmin/>}/>
          <Route path='/fixedTimesheetApproval' element={<FixedTimesheetApproval/>}/>
          <Route path='/floatingTimesheetApproval' element={<FloatingTimesheetApproval/>}/>
          <Route path='/clientCompletedTask' element={<ClientCompletedTaskForAdmin/>}/>
          <Route path='/dayinoutreport' element={<DayInOutReport/>}/>
          <Route path='/visitreport' element={<VisitReport/>}/>
          <Route path='/PendingTaskDailyWorking' element={<PendingTaskDailyWorking/>}/>
        </Routes>
      </div>
    </div>
    </>
  );
};

const HomeScreen = ({ adminName }) => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [lateEarlyRequest, setLateEarlyRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [inOutTimes, setInOutTimes] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  useEffect(() => {
    // Fetch leave requests
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLeaveRequests')
      .then(response => {
        const acceptedLeaves = response.data.filter(leave => {
          const leaveStartDate = new Date(leave.fromdate);
          const leaveEndDate = new Date(leave.todate);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset to midnight for date comparison
          return leave.status !== 0 && (leaveStartDate >= today || leaveEndDate >= today);
        });
        setLeaveRequests(acceptedLeaves);
      })
      .catch(error => {
        console.error('Error fetching leave requests:', error);
      });

      axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLateEarlyRequests')
      .then(response => {
        const acceptedRequest = response.data.filter(request => {
          const requestDate = new Date(request.date);
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Reset to midnight for date comparison
          return request.status !== 0 && (requestDate >= today);
        });
        setLateEarlyRequests(acceptedRequest);
      })
      .catch(error => {
        console.error('Error fetching leave requests:', error);
      });

    // Fetch employee data
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users')
      .then(response => {
        setEmployees(response.data);
      })
      .catch(error => {
        console.error('Error fetching employees:', error);
      });

    // Fetch intime and outtime for employees
    axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getEmployeeInOutTimes', {
      params: { date: currentDate }
    }).then(response => {
        setInOutTimes(response.data);
      })
      .catch(error => {
        console.error('Error fetching in/out times:', error);
      });
  }, []);
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-IN', { year: 'numeric', month: 'long', day: 'numeric' }).format(date);
  };
  // Function to format time in HH:MM AM/PM format without timezone conversion
const formatTimeInAmPm = (timeString) => {
  if (!timeString) return 'Not done yet';

  const [datePart, timePart] = timeString.split('T'); // Split date and time
  let [hours, minutes] = timePart.split(':'); // Extract hours and minutes

  const isPM = hours >= 12;
  hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format

  return `${hours}:${minutes} ${isPM ? 'PM' : 'AM'}`;
};

// Function to get intime and outtime for an employee
const getInOutTimesForEmployee = (empid) => {
  const times = inOutTimes.find(time => time.empid === empid);
  if (times) {
    const intime = times.intime ? formatTimeInAmPm(times.intime) : 'Not done yet';
    const outtime = times.outtime ? formatTimeInAmPm(times.outtime) : 'Not done yet';
    const inplace = times.inplace ? times.inplace : 'Not done yet';
    const outplace = times.outplace ? times.outplace : 'Not done yet';
    return { intime, inplace, outtime, outplace };
  }
  return { intime: 'Not done yet', outtime: 'Not done yet', inplace:'Not done yet', outplace:'Not done yet' };
};

  return (
    <div className="home-screen">
      <div className="container mt-5">
      <div className="card shadow">
        <div className="card-body">
        <h4 className="card-title mb-4">Team Member Attendance</h4>
        <table className="table table-striped table-hover align-middle">
        <thead className="" style={{fontSize:'15px',verticalAlign:'baseline'}}>
          <tr>
            <th>TM Code</th>
            <th>Name</th>
            <th>In Time</th>
            <th>In Location</th>
            <th>Out Time</th>
            <th>Out Location</th>
          </tr>
        </thead>
        <tbody style={{fontSize:'14px',verticalAlign:'baseline'}}>
          {employees.map((employee, index) => {
            const { intime, inplace, outtime, outplace } = getInOutTimesForEmployee(employee.empid);
            return (
              <tr key={index}>
                <td>{employee.empid}</td>
                <td>{employee.FirstName} {employee.LastName}</td>
                <td>{intime}</td>
                <td>{inplace}</td>
                <td>{outtime}</td>
                <td>{outplace}</td>
              </tr>
            );
          })}
        </tbody>
        </table>
        </div>
      </div>
    </div>


      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h5>On Leaves</h5>
              <ul className="list-group">
              {leaveRequests.map((request, index) => (
              <li key={index} className="list-group-item">
              {request.fullname} - {request.fromdate === request.todate 
                ? formatDate(new Date(request.fromdate)) 
                : `${formatDate(new Date(request.fromdate))} to ${formatDate(new Date(request.todate))}`}  
                  - {request.status === 1 ? 'Accepted': 'Pending'}
            </li>
               ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h5>Late Entry or Early Exit</h5>
              <ul className="list-group">
                {lateEarlyRequest.map((request, index) => (
                <li key={index} className="list-group-item">
                {request.fullname} on {formatDate(new Date(request.date))} - {request.type} by {request.timeDuration} Hours
                - {request.status === 1 ? 'Accepted': 'Pending'}
                </li>
                ))}
              </ul>
            </div>
          </div>
          </div>
        </div>
    </div>
  );
};

export default AdminDashboard;
