import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
const Swal = require('sweetalert2');

const DayIn = () => {
  const [dayIn, setDayIn] = useState(null);
  const [locationIn, setLocationIn] = useState(null);
  const [placeIn, setPlaceIn] = useState('');
  const [employeeData, setEmployeeData] = useState(null);
  const [isOnTime, setIsOnTime] = useState(null);
  const [lateMinutes, setLateMinutes] = useState(null);
  const [requestInfo, setRequestInfo] = useState(null);
  const [customPlace, setCustomPlace] = useState('');
  const [timesheetNotMade, setTimesheetNotMade] = useState(false);
  const [timesheetNotMadeDate, setTimesheetNotMadeDate] = useState([]);
  const [enteredTime, setEnteredTime] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [users, setUsers] = useState([]);
  const [holidayReason, setHolidayReason] = useState('');

  
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users');
        setUsers(response.data);
      } catch (error) {
        console.error('There was an error fetching users!', error);
      }
    };

    fetchUsers();
  }, []);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        const employeeData = response.data[0];
        // EMPID = response.data[0].empid
        // console.log(EMPID);
        
        setEmployeeData(employeeData);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
      fetchAvailableDates();
    }
  }, [email]);
  let isMGEmployee=null;
  if(email){
     isMGEmployee =
    employeeData &&
    employeeData.empid &&
    typeof employeeData.empid === 'string' &&
    employeeData.empid.startsWith('MG');}
    else{
      isMGEmployee=null;
    }
    // let EMPID=null;
    // if(email){
    //   EMPID = 
    //   employeeData &&
    //   employeeData.empid &&
    //   typeof employeeData.empid === 'string' &&
    //   employeeData.empid.startsWith('AC-001');}
    //   else{
    //     EMPID=null;
    //   }

  const fetchAvailableDates = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getDatesBetweenLastAndCurrent', {
        params: { email },
      });
      if (response.data.length > 0) {
        setAvailableDates(response.data);
        setSelectedDate(response.data[0]); // Pre-select the first date
      } else {
        const currentDate = moment().format('YYYY-MM-DD'); // Get current date in YYYY-MM-DD format
        setSelectedDate(currentDate); // Set selected date to current date
      }
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };
  

  const checkDayIn = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkdayin', {
        params: { email, date: selectedDate },
      });
      
      if (response.data.exists) {
        toast.info('You have already performed Day In for this date.');
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking Day In status:', error);
      toast.error('Failed to check Day In status.');
      return false;
    }
  };
  
  const checkLastTimesheetMade = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkLastTimesheetMade', {
        params: { email }
      });
      
      if (response.data.result === 1) {
        setTimesheetNotMade(true);
        setTimesheetNotMadeDate(response.data.dates);
        return true;
      }
      
      return false;
    } catch (error) {
      console.error('Error checking last timesheet status:', error);
      return false;
    }
  };

  const getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          callback(location);
        },
        (error) => {
          console.error('Error getting geolocation:', error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const handleDayIn = async () => {
    const alreadyDayIn = await checkDayIn();
    if (alreadyDayIn) {    
      setIsOnTime(null);
      setLateMinutes(null);
      return;
    }

    const timesheetNotMade = await checkLastTimesheetMade();
    if (timesheetNotMade) {
      setIsOnTime(null);
      setLateMinutes(null);
      return;
    }

    let now;

    if (selectedDate) {
      // Combine selectedDate with enteredTime if provided
      if (enteredTime) {
        now = moment.tz(`${selectedDate} ${enteredTime}`, 'Asia/Kolkata');
        if (!now.isValid()) {
          toast.error('Invalid time format. Please use HH:mm.');
          return;
        }
      } else {
        // Use the date part only if no time is entered
        now = moment.tz(selectedDate, 'Asia/Kolkata');
      }
    } else {
      // Fallback to the current date and time in the timezone if no date is selected
      now = moment().tz('Asia/Kolkata');
    }

    setDayIn(now.toDate());

    getLocation(async (location) => {
      const selectedPlace = placeIn === 'Any Other' ? customPlace : placeIn;
      setLocationIn(selectedPlace);
      await processDayIn(now, location.latitude, location.longitude, selectedPlace);
    });
  };

  const handleMarkAbsent = async () => {
    const { empid, FirstName, LastName, hoursType, ScheduleIn } = employeeData;
    const fullname = `${FirstName} ${LastName}`;
  
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to mark ${fullname} as absent for ${selectedDate}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, mark absent!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/markAbsent', {
            date: selectedDate,
            email,
            status: 'Absent',
            fullname,
            empid,
          });
  
          // After marking absent, move to the next date
          const nextDateIndex = availableDates.indexOf(selectedDate) + 1;
          if (nextDateIndex < availableDates.length) {
            setSelectedDate(availableDates[nextDateIndex]);
          } else {
            setSelectedDate(''); // No more dates available
          }
  
          toast.success('Employee marked as absent.');
        } catch (error) {
          console.error('Error marking absent:', error);
          toast.error('Failed to mark absent.');
        }
      }
    });
  };
  const handleMarkDayOff = async () => {
    const { empid, FirstName, LastName, hoursType, ScheduleIn } = employeeData;
    const fullname = `${FirstName} ${LastName}`;
  
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to mark ${selectedDate} as Day Off?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, mark Day Off!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/markAbsent', {
            date: selectedDate,
            email,
            status: 'Day Off',
            fullname,
            empid,
          });
  
          // After marking absent, move to the next date
          const nextDateIndex = availableDates.indexOf(selectedDate) + 1;
          if (nextDateIndex < availableDates.length) {
            setSelectedDate(availableDates[nextDateIndex]);
          } else {
            setSelectedDate(''); // No more dates available
          }
  
          toast.success('Marked Date as Day Off.');
        } catch (error) {
          console.error('Error marking Day Off:', error);
          toast.error('Failed to mark Day Off.');
        }
      }
    });
  };

  const handleMarkSunday = async () => {
    const { empid, FirstName, LastName, hoursType, ScheduleIn } = employeeData;
    const fullname = `${FirstName} ${LastName}`;
  
    Swal.fire({
      title: 'Are you sure?',
      text: `Do you really want to mark ${selectedDate} as Sunday?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, mark Sunday!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/markAbsent', {
            date: selectedDate,
            email,
            status: 'Sunday',
            fullname,
            empid,
          });
  
          // After marking absent, move to the next date
          const nextDateIndex = availableDates.indexOf(selectedDate) + 1;
          if (nextDateIndex < availableDates.length) {
            setSelectedDate(availableDates[nextDateIndex]);
          } else {
            setSelectedDate(''); // No more dates available
          }
  
          toast.success('Marked Date as Day Off.');
        } catch (error) {
          console.error('Error marking Day Off:', error);
          toast.error('Failed to mark Day Off.');
        }
      }
    });
  };
  

  const processDayIn = async (intime, latitude, longitude, place) => {
    if (!employeeData) return;

    const { empid, FirstName, LastName, hoursType, ScheduleIn } = employeeData;
    const fullname = `${FirstName} ${LastName}`;
    const intimeIST = moment(intime).tz('Asia/Kolkata');
    const intimeTimeStr = intimeIST.format('HH:mm:ss');
    const dateStr = intimeIST.format('YYYY-MM-DD');
    const formattedIntime = `${dateStr}T${intimeTimeStr}`;

    let ontime = 0;
    let finalLate = 0;
    let diffMinutes = 0;
    let lateinformed = null;

    try {
      if (hoursType === 'fixed' && ScheduleIn) {
        const scheduleInTime = ScheduleIn.split('T')[1].split('.')[0];
        const intimeTime = intimeTimeStr;

        const scheduleTime = moment(`1970-01-01T${scheduleInTime}`);
        const intimeTimeObj = moment(`1970-01-01T${intimeTime}`);

        diffMinutes = Math.floor(intimeTimeObj.diff(scheduleTime, 'minutes'));

        if (diffMinutes > 15) {
          setIsOnTime(false);
          ontime = 0;
          setLateMinutes(diffMinutes);
          
          const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getlateearlyrequests', {
            params: { email, date: selectedDate },  // Ensure 'selectedDate' is formatted as 'YYYY-MM-DD'
          });
          
          // Filter the requests to get only those with type 'Late Entry'
          const lateEntryRequests = response.data.filter(req => req.type === 'Late Entry');       
          
          let difftime = 0;

          if (lateEntryRequests.length > 0) {
            const request = lateEntryRequests[0];
            if (request.type === 'Late Entry') {
              lateinformed = 1;
              difftime = parseInt(request.difftime, 10);

              if (diffMinutes <= difftime) {
                finalLate = diffMinutes;
              } else {
                finalLate = difftime + 3 * (diffMinutes - difftime);
              }
              setRequestInfo('A Late Entry request has been fetched; the time will be adjusted accordingly.');
            } else {
              finalLate = 3 * diffMinutes;
              lateinformed = 0;
              setRequestInfo('No Pre Informed request found; hence, the time will be adjusted accordingly.');
            }
          } else {
            finalLate = 3 * diffMinutes;
            lateinformed = 0;
            setRequestInfo('No Pre Informed request found; hence, the time will be adjusted accordingly.');
          }
        } else {
          ontime = 1;
          setIsOnTime(true);
          setRequestInfo('You are on Time');
        }
      } else {
        setIsOnTime(true);
        ontime = 1;
        setRequestInfo('You are on Time');
      }

      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/dayinout', {
        empid,
        fullname,
        email,
        date: dateStr,
        intime: formattedIntime,
        inlatitude: latitude,
        inlongitude: longitude,
        inplace: place,
        hoursType,
        ontime,
        lateinformed,
        status:'Working',
        ...(hoursType === 'fixed' ? {
          lateby: diffMinutes,
          finalLate: finalLate
        } : {})
      });
    } catch (error) {
      console.error('Error processing Day In:', error);
      setRequestInfo('Error processing Day In');
    }
  };

  const handleMarkHoliday = async () => {
    if (holidayReason === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please provide a holiday reason!',
        position: 'top-end',  // Top right corner
        showConfirmButton: false,
        timer: 3000,  // Auto-close after 3 seconds
        toast: true
      });
      return;
    }
  
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to mark ${selectedDate} as a holiday?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, mark it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Send the entire user array along with status, holidayReason, and selectedDate
          const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/insertUserDetails', {
            users: users,             // Send all users
            status: 'Holiday',        // Set status as "Holiday"
            holidayReason: holidayReason, // Include the holiday reason
            selectedDate: selectedDate    // Include the selected date
          });

          // Success message
          Swal.fire({
            icon: 'success',
            title: 'Holiday Marked!',
            text: `Holiday successfully marked for ${selectedDate}.`,
            position: 'top-end',  // Top right corner
            showConfirmButton: false,
            timer: 3000,  // Auto-close after 3 seconds
            toast: true
          }).then(() => {
            window.location.reload(); 
          });
        } catch (error) {
          console.error('There was an error marking the holiday!', error);
          
          // Error message
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to mark holiday for the users.',
            position: 'top-end',  // Top right corner
            showConfirmButton: false,
            timer: 3000,  // Auto-close after 3 seconds
            toast: true
          });
        }
      }
    });
  };
  

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
      <h2 className="mb-4">Day In Tracker</h2>
      <div className="alert alert-danger" role="alert">
      <li className="small mb-0" style={{fontWeight:600}}> Please enter the time in 24 HR format or check the AM/PM before submitting.</li>
    </div>
      </div>
      <div className="form-group">
  <label htmlFor="dateInput"><strong>Date</strong></label>
  {/* {!EMPID ? ( */}
    <select
    id="dateSelect"
    className="form-control"
    value={selectedDate}
    onChange={(e) => setSelectedDate(e.target.value)}
    disabled={true} // First date is pre-selected and cannot be changed
    required
  >
    {availableDates.map((date, index) => (
      <option key={index} value={date}>
        {moment(date).format('YYYY-MM-DD')}
      </option>
    ))}
  </select>
  {/* ) : (<input
    type="date"
    id="dateInput"
    className="form-control"
    value={selectedDate}
    onChange={(e) => setSelectedDate(e.target.value)}
    required
  />
  )} */}
</div>

      <div className="form-group">
        <label htmlFor="timeSelect"><strong>Enter Time (HH:mm)</strong></label>
        <input
          type="time"
          id="timeSelect"
          className="form-control"
          value={enteredTime}
          onChange={(e) => setEnteredTime(e.target.value)}
        />
      </div>

      <div className="form-group">
        <label htmlFor="placeSelect"><strong>Place</strong></label>
        <select
          id="placeSelect"
          className="form-control"
          value={placeIn}
          onChange={(e) => setPlaceIn(e.target.value)}
        >
          <option value="">Select Place</option>
          <option value="Office">Office</option>
          <option value="Client">Client</option>
          <option value="Home">Home</option>
          <option value="Any Other">Any Other</option>
        </select>
      </div>

      {placeIn === 'Any Other' && (
        <div className="form-group">
          <label htmlFor="customPlaceInput"><strong>Specify Location</strong></label>
          <input
            type="text"
            id="customPlaceInput"
            className="form-control"
            value={customPlace}
            onChange={(e) => setCustomPlace(e.target.value)}
          />
        </div>
      )}
          {isMGEmployee && <>
          <div className="form-group">
            <label htmlFor="holidayReason"><strong>Holiday Reason</strong></label>
            <input
                type="text"
                id="holidayReason"
                className="form-control mb-4"
                value={holidayReason}
                onChange={(e) => setHolidayReason(e.target.value)}
                disabled={enteredTime !== '' || placeIn !== ''}
              />
          </div>
          </>}

          <div classname='mt-2'>
          <button
          className="btn btn-success me-2" // Add margin-end (right) class
          onClick={handleDayIn}
          disabled={!placeIn}
        >
          Day In
        </button>

        <button
          className="btn btn-info me-2"
          onClick={handleMarkSunday}
          disabled={availableDates.length === 0 || enteredTime}
        >
          Mark Sunday
        </button>

        <button
          className="btn btn-info me-2"
          onClick={handleMarkDayOff}
          disabled={availableDates.length === 0 || enteredTime}
        >
          Mark Leave
        </button>

        <button
          className="btn btn-danger me-2"
          onClick={handleMarkAbsent}
          disabled={availableDates.length === 0 || enteredTime}
        >
          Mark Absent
        </button>

        {isMGEmployee && (
          <>
            <button
              className="btn btn-success me-2"
              onClick={handleMarkHoliday}
              disabled={enteredTime !== '' || placeIn !== ''}
            >
              Mark Holiday
            </button>
          </>
        )}
        </div>



  


      {dayIn && (
        <div className="mt-2">
          <p>
            <strong>Hello, {employeeData?.FirstName} {employeeData?.LastName}</strong>
          </p>
          <p>
            <strong>Day In:</strong> {dayIn.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })}
          </p>
          {locationIn && (
            <>
              <p>
                <strong>Place:</strong> {locationIn === 'Any Other' ? customPlace : locationIn}
              </p>
            </>
          )}
          {isOnTime !== null && (
            <>
              {isOnTime ? (
                <p><strong>You are on time</strong></p>
              ) : (
                <div className="alert alert-danger mt-3">
                  <strong>
                    You left early by {Math.floor(lateMinutes / 60)} hours and {lateMinutes % 60} minutes
                  </strong>
                  <br />
                  <strong>{requestInfo}</strong>
                  </div>
              )}
            </>
          )}
        </div>
      )}
    
      {requestInfo && (
        <div className="alert alert-info mt-3">
          {requestInfo}
        </div>
      )}

        {timesheetNotMade && (
            <div className="alert alert-danger mt-3">
              <strong>
                        You have not created the timesheet for the following dates:
                    </strong>
                    <ul>
                        {timesheetNotMadeDate.map((date, index) => (
                            <li key={index}>{moment(date).format('DD-MMM-YYYY')}</li>
                        ))}
                    </ul>
            </div>
          )}
    </div>
  );
};

export default DayIn;
