import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip'; // Correct import
import DateRangePickerComponent from './CustomDateRangePicker'

const MemberClientCompletedTask = () => {
    // const [startDate, setStartDate] = useState('');
    // const [endDate, setEndDate] = useState('');
    const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
    const [selectedClient, setSelectedClient] = useState(null);
    const [clients, setClients] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [clientNames, setClientNames] = useState({});
    const email = sessionStorage.getItem('email');
    const [employeeData, setEmployeeData] = useState({});
  
    useEffect(() => {
      const fetchEmployeeData = async () => {
        try {
          const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
            params: { email: email },
          });
          const employeeData = response.data[0];
          setEmployeeData(employeeData);
          fetchAssignedClients(employeeData.empid)
        } catch (error) {
          console.error('Error fetching employee data:', error);
        }
      };
  
      if (email) {
        fetchEmployeeData();
      }
    }, [email]);

    const fetchAssignedClients = (empid) => {
        axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${empid}`)
          .then((response) => {
            console.log(response.data);
            
            const clientOptions = response.data.map(client => ({
              value: client.CC,
              label: `${client.CC} - ${client.TradeName}`,
            }));
            setClients(clientOptions);
          })
          .catch(error => console.error('Error fetching clients:', error));
      };

    const TimeDisplay = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    };

    const getClient = async (client) => {
        try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getClientByCode', {
                params: { client }
            });
            if (response.data.length > 0) {
                return response.data[0].TradeName;
            } else {
                return 'Client not found';
            }
        } catch (error) {
            console.error('Error fetching client:', error);
            return 'Error fetching client';
        }
    };

    const fetchTasks = async () => {
        const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
        if (!startDate || !endDate) {
            console.error("Please select a valid date range");
            return;
        }
        try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/completedClientTasksByAdmin', {
                client: selectedClient.value,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
            });
            const tasks = response.data;
            setTasks(response.data);
            const clientNamesTemp = {};
            for (const task of tasks) {
                const tradeName = await getClient(task.CC);
                clientNamesTemp[task.CC] = tradeName;
            }
            setClientNames(clientNamesTemp);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    // Group tasks by mid
    const groupTasksByMid = (tasks) => {
        return tasks.reduce((acc, task) => {
            const { mid } = task;
            if (!acc[mid]) {
                acc[mid] = [];
            }
            acc[mid].push(task);
            return acc;
        }, {});
    };

    const handleClientChange = (selectedOption) => {
        setSelectedClient(selectedOption);
    };

    // const handleStartDateChange = (event) => {
    //     setStartDate(event.target.value);
    // };

    // const handleEndDateChange = (event) => {
    //     setEndDate(event.target.value);
    // };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchTasks();
    };

    const exportToExcel = () => {
        const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
        const groupedTasks = groupTasksByMid(tasks);
        const exportData = [];

        // Prepare data for export (one row per MID group)
        Object.entries(groupedTasks).forEach(([mid, tasksInGroup]) => {
            exportData.push({
                "Reporting Date": moment(tasksInGroup[0].reportingDate[0]).format('DD-MMM-YYYY'),
                "Member": tasksInGroup[0].fullname[0],
                "Main Task Name": tasksInGroup[0].mainTask[0],
                "Sub Task": tasksInGroup.map((task) => task.subTask).join(', '), // Combine subtasks into a single string
                "Task Period": tasksInGroup
                    .map((task) => (task.taskto && moment(task.taskto).year() > 2000 ? moment(task.taskto).format('DD-MMM-YYYY') : 'No Period'))
                    .join(', '), // Combine task periods
                "Task Time": TimeDisplay(tasksInGroup[0].taskTimeInMinutes), // Combine task times
                "Travel Time": tasksInGroup[0].travelTime, // Combine travel times
                "Worked At": tasksInGroup[0].location, // Combine locations
                "Remarks": tasksInGroup.map((task) => task.remarks).join(', '),
            });
        });

        // Create workbook and worksheet
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet([
            ["Client Name:", selectedClient.label],
            ["Date Range:", `${moment(startDate).format('DD-MMM-YYYY')} - ${moment(endDate).format('DD-MMM-YYYY')}`],
            ["", ""], // Empty row for spacing
            ["Reporting Date", "Member", "Main Task Name", "Sub Task", "Task Period", "Task Time", "Travel Time", "Worked At", "Remarks"]
        ]);

        // Append task data after the input fields
        XLSX.utils.sheet_add_json(worksheet, exportData, { skipHeader: true, origin: -1 });

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Tasks");

        // Write the Excel file
        XLSX.writeFile(workbook, `ClientTasks_${selectedClient.label}.xlsx`);
    };

    // Render grouped tasks
    const groupedTasks = groupTasksByMid(tasks);

    return (
        <div className="container">
            <h2 className="my-4 mt-5">Client - DTR</h2>
            <form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-md-6">
              <label htmlFor="clientSelect">Select Client</label>
              <Select
                id="clientSelect"
                options={clients}
                 value={selectedClient}
                onChange={setSelectedClient}
                isClearable
                placeholder="Select a client..."
              />
            </div>
            {/* <div className="col-md-4">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={handleStartDateChange}
                required
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                id="endDate"
                className="form-control"
                value={endDate}
                onChange={handleEndDateChange}
                required
              />
            </div> */}
            <div className="col-md-6">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
          </div>
                {/* <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="startDate">Start Date:</label>
                        <input
                            type="date"
                            id="startDate"
                            className="form-control"
                            value={startDate}
                            onChange={handleStartDateChange}
                            required
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="endDate">End Date:</label>
                        <input
                            type="date"
                            id="endDate"
                            className="form-control"
                            value={endDate}
                            onChange={handleEndDateChange}
                            required
                        />
                    </div>
                </div> */}
                <button type="submit" className="btn btn-success mt-3">
                    Get Tasks
                </button>
                <button type="button" className="btn btn-secondary mt-3 ms-2" onClick={exportToExcel}>
                    Export to Excel
                </button>
            </form>

            <div className="mt-4">
                {Object.keys(groupedTasks).length > 0 ? (
                    <table className="table table-striped mt-4">
                        <thead style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
                            <tr>
                                <th>Date</th>
                                <th>Member</th>
                                <th>Main Task Name</th>
                                <th>Sub Tasks</th>
                                <th>Task Periods</th>
                                <th>Enteries</th>
                                <th>Task Time</th>
                                <th>Travel Time</th>
                                <th>Location</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '11px', verticalAlign: 'baseline' }}>
                            {Object.entries(groupedTasks).map(([mid, groupedTasks], index) => (
                                <tr key={index}>
                                    <td>{moment(groupedTasks[0].reportingDate[0]).format('DD-MMM-YYYY')}</td>
                                    <td>{groupedTasks[0].fullname[0]}</td>
                                    <td>{groupedTasks[0].mainTask[0]}</td>
                                    <td>{groupedTasks.map((task) => task.subTask).join(', ')}</td>
                                    <td>
                                        {groupedTasks.map((task) => {
                                            const taskToDate = moment(task.taskto);
                                            const taskFromDate = moment(task.taskfrom);

                                            if (task.taskto && taskToDate.year() > 2000 && task.taskfrom && taskFromDate.year() > 2000) {
                                            const formattedTaskTo = taskToDate.format('DD-MMM-YY');
                                            const formattedTaskFrom = taskFromDate.format('DD-MMM-YY');
                                            return (
                                                <div key={task.taskto}>
                                                {formattedTaskFrom} - {formattedTaskTo}
                                                </div>
                                            );
                                            }

                                            return (
                                            <div key={task.taskto}>
                                                No Period
                                            </div>
                                            );
                                        })}
                                        </td>
                                        <td>
                                        {groupedTasks.map((task) => (
                                        <div key={task.NumberOfEntries}>{task.NumberOfEntries}</div>
                                        ))}
                                    </td>
                                    <td>{TimeDisplay(groupedTasks[0].taskTimeInMinutes)}</td>
                                    <td>{groupedTasks[0].travelTime}</td>
                                    <td>{groupedTasks[0].location}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faInfoCircle}
                                            data-tooltip-id="remarks-tooltip"
                                            data-tooltip-content={groupedTasks.map((task) => task.remarks).join(', ')}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <Tooltip id="remarks-tooltip" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No tasks found for the selected client and date range.</p>
                )}
            </div>
        </div>
    );
};

export default MemberClientCompletedTask;
