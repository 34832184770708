import React from 'react';
import UserSessionTimeout from './userSessionTimeout';


const SessionWrapper = ({ children }) => {
  UserSessionTimeout(60 * 60 * 1000); // 5 minutes timeout

  return <>{children}</>;
};

export default SessionWrapper;
