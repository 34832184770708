import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure you have this for the modal
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState({});
  const email = sessionStorage.getItem('email'); // Get email from session storage
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        if (!email) {
          console.error('No email found in session storage');
          return;
        }
        const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
          params: { email },
        });
        if (response.data && response.data.length > 0) {
          setEmployeeData(response.data[0]); // Assuming response.data is an array with a single user object
        } else {
          console.error('No user data found for the provided email');
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/users');
        setUsers(response.data);
      } catch (error) {
        console.error('There was an error fetching users!', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const columns = useMemo(() => [
    { Header: 'Team Member ID', accessor: 'empid' },
    { Header: 'First Name', accessor: 'FirstName' },
    { Header: 'Last Name', accessor: 'LastName' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Position', accessor: 'position' },
    // {
    //   Header: 'Sign Image',
    //   accessor: 'id',
    //   Cell: ({ value }) => (
    //     <button className='btn btn-info' onClick={() => handleShowImage(value)} style={{fontSize:'13px'}}>
    //       <i className='bi bi-eye'></i> View
    //     </button>
    //   )
    // },
    {
      Header: 'Status',
      accessor: 'isActive',
      Cell: ({ value }) => (value === 1 ? 'Active' : 'Inactive')
    },
    {
      Header: 'Actions',
      accessor: 'actions', // Changed accessor to 'actions'
      Cell: ({ row }) => (
        <>
          <button
            className={`btn ${row.original.isActive ? 'btn-danger' : 'btn-success'} me-2`}
            style={{fontSize:'13px'}}
            onClick={() => handleToggleStatus(row.original.id, row.original.email, row.original.isActive)}
          >
            {row.original.isActive ? "D'ACT" : 'ACT'}
          </button>
          <button
            className='btn btn-success'
            style={{fontSize:'13px'}}
            onClick={() => handleEditUser(row.original)}
          >
            Edit
          </button>
        </>
      )
    }
  ], []);

  const handleToggleStatus = async (userId, userEmail, isActive) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to ${isActive === 1 ? 'deactivate' : 'activate'} this user?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    });

    if (result.isConfirmed) {
      try {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deactivateUser', { email: userEmail });

        // Update the user list to reflect the change
        setUsers(prevUsers =>
          prevUsers.map(user =>
            user.email === userEmail ? { ...user, isActive: isActive === 1 ? 0 : 1 } : user
          )
        );

        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: isActive === 1 ? 'User deactivated' : 'User activated',
          showConfirmButton: false,
          timer: 3000
        });
      } catch (error) {
        console.error('There was an error toggling the user status!', error);
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: 'An error occurred. Please try again.',
          showConfirmButton: false,
          timer: 3000
        });
      }
    }
  };

  const handleEditUser = (user) => {
    if(employeeData){
      navigate(`/userDashboard/edit-user/${user.id}`, { state: user });
    }
    else{
      navigate(`/adminDashboard/edit-user/${user.id}`, { state: user });
    }
  };

  const handleShowImage = async (userId) => {
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getSignImage', { id: userId }, { responseType: 'arraybuffer' });

      const base64 = Buffer.from(response.data, 'binary').toString('base64');
      const imgSrc = `data:image/png;base64,${base64}`;

      setImageSrc(imgSrc);
      setShowModal(true);
    } catch (error) {
      console.error('There was an error fetching the sign image!', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 3000
      });
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0, pageSize: 5 }, // Default page size
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <div className='container'>
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h4 className='text-center mt-5'>Team List</h4>
          <input
            type="text"
            placeholder="Search..."
            className="form-control mb-3"
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <div className="table-responsive">
            <table {...getTableProps()} className='table table-striped'>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}
              style={{fontSize:'14px',verticalAlign:'baseline'}}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Pagination controls */}
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </button>
              <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                className="form-select"
                value={pageSize}
                onChange={e => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 15, 20].map(size => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}

      {/* Modal */}
      {showModal && (
        <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Sign Image</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body text-center">
                <img src={imageSrc} alt="Sign" className="img-fluid" />
              </div>
              <div className="modal-footer">
                <a href={imageSrc} download={`signImage_${users.id}.png`} className="btn btn-success">
                  Download
                </a>
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
