import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip'; // Correct import
import Swal from 'sweetalert2';
import DateRangePickerComponent from './CustomDateRangePicker'

const MemberVisitReport = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [tasks, setTasks] = useState([]);
  const [clientsData, setClientsData] = useState({ NumberOfVisits: 0, InWeeks: 0 });
  const [requiredVisits, setRequiredVisits] = useState(0);
  const [uniqueReportingDates, setUniqueReportingDates] = useState(0);
  const [actualVisits, setActualVisits] = useState(0);
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: email },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
        fetchAssignedClients(employeeData.empid)
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
    }
  }, [email]);
  

  const fetchAssignedClients = (empid) => {
    axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${empid}`)
      .then((response) => {
        console.log(response.data);
  
        // Filter clients where visitRequired is 'Yes'
        const filteredClients = response.data.filter(client => client.visitRequired === 'Yes');
        
        const clientOptions = filteredClients.map(client => ({
          value: client.CC,
          label: `${client.CC} - ${client.TradeName}`,
        }));
        setClients(clientOptions);
      })
      .catch(error => console.error('Error fetching clients:', error));
  };
  

  useEffect(() => {
    if (selectedClient) {
      fetchClientData(selectedClient.value);
    }
  }, [selectedClient]);

  const fetchClientData = async (clientId) => {
    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/client/${clientId}`);
      if (response.status === 200) {
        setClientsData(response.data);
      } else {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'error',
          text: 'Failed to load client data.',
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error('There was an error fetching the client data!', error);
      Swal.fire({
        toast: true,
        position: 'top-end',
        icon: 'error',
        text: 'An error occurred. Please try again.',
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const TimeDisplay = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  const fetchTasks = async () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                console.error("Please select a valid date range");
                return;
            }
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getVisitReportByClientAndDate', {
        client: selectedClient.value,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });
      setActualVisits(0);
      setTasks(Array.isArray(response.data) ? response.data : []);
      calculateRequiredVisits();

      const formattedDates = response.data.map(task => moment(task.reportingDate[0]).format('YYYY-MM-DD'));
      const uniqueDates = [...new Set(formattedDates)];
      setActualVisits(uniqueDates.length); 
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const calculateRequiredVisits = () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
    const { NumberOfVisits, InWeeks } = clientsData;

    if (NumberOfVisits > 0 && InWeeks > 0) {
      const perWeekVisit = NumberOfVisits / InWeeks;

      const startMoment = moment(startDate);
      const endMoment = moment(endDate);

      const startWeek = startMoment.week();
      const endWeek = endMoment.week();

      const isEndOfWeek = endMoment.day() === 0;
      const totalWeeks = endWeek - startWeek + (isEndOfWeek ? 1 : 0);

      const requiredVisits = totalWeeks * perWeekVisit;
      setRequiredVisits(requiredVisits.toFixed(2));
    }
  };

  // const handleStartDateChange = (event) => {
  //   setStartDate(event.target.value);
  // };

  // const handleEndDateChange = (event) => {
  //   setEndDate(event.target.value);
  // };

  const groupTasksByMid = (tasks) => {
    if (!Array.isArray(tasks)) return {};
  
    return tasks.reduce((acc, task) => {
      const { mid } = task;
      if (!acc[mid]) {
        acc[mid] = [];
      }
      acc[mid].push(task);
      return acc;
    }, {});
  };
  
  const groupedTasks = groupTasksByMid(tasks);

  // Calculate the difference between actual visits and required visits
  const visitsDifference = actualVisits - requiredVisits;

  return (
            <div className="container">
              <h2 className="my-4 mt-5">Visit Report</h2>
              <form>
              <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="clientSelect">Select Client</label>
              <Select
                id="clientSelect"
                options={clients}
                 value={selectedClient}
                onChange={setSelectedClient}
                isClearable
                placeholder="Select a client..."
              />
            </div>
            {/* <div className="col-md-4">
              <label htmlFor="startDate">Start Date:</label>
              <input
                type="date"
                id="startDate"
                className="form-control"
                value={startDate}
                onChange={handleStartDateChange}
                required
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="endDate">End Date:</label>
              <input
                type="date"
                id="endDate"
                className="form-control"
                value={endDate}
                onChange={handleEndDateChange}
                required
              />
            </div> */}
            <div className="col-md-6">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
          </div>
        </div>

                <div className="row mt-3">
          <div className="col-md-2">
            <label htmlFor="numberOfVisits">Number of Visits:</label>
            <input
              type="text"
              id="numberOfVisits"
              className="form-control"
              value={clientsData.NumberOfVisits}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inWeeks">In Weeks:</label>
            <input
              type="text"
              id="inWeeks"
              className="form-control"
              value={clientsData.InWeeks}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="requiredVisits">Required Visits:</label>
            <input
              type="text"
              id="requiredVisits"
              className="form-control"
              value={requiredVisits}
              readOnly
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="actualVisits">Actual Visits:</label>
            <input
              type="text"
              id="actualVisits"
              className="form-control"
              value={actualVisits}
              readOnly
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="visitDifference">Visit Difference:</label>
            <input
              type="text"
              id="visitDifference"
              className="form-control"
              value={
                visitsDifference > 0
                  ? `Exceeds by ${visitsDifference}`
                  : visitsDifference < 0
                  ? `Short by ${Math.abs(visitsDifference)}`
                  : 'Completed All Visits'
              }
              readOnly
            />
          </div>
        </div>



        <button type="button" className="btn btn-success mt-3" onClick={fetchTasks}>
          Get Report
        </button>
      </form>

      <hr />
      <div className="mt-4">
        {/* {uniqueReportingDates > 0 && (
          <p><strong>Number of Unique Reporting Dates:</strong> {uniqueReportingDates}</p>
        )} */}
        {Array.isArray(tasks) && Object.keys(groupedTasks).length > 0 ? (
          <table className="table table-striped mt-4">
            <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
              <tr>
                <th>Date</th>
                <th>Member</th>
                <th>Main Task Name</th>
                <th>Sub Tasks</th>
                <th>Task Periods</th>
                <th>Enteries</th>
                <th>Task Time</th>
                <th>Travel Time</th>
                <th>Location</th>
                <th>Remarks</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: '11px', verticalAlign: 'baseline' }}>
              {Object.entries(groupedTasks).map(([mid, groupedTasks], index) => (
                <tr key={index}>
                  <td>{moment(groupedTasks[0].reportingDate[0]).format('DD-MMM-YYYY')}</td>
                  <td>{groupedTasks[0].fullname[0]}</td>
                  <td>{groupedTasks[0].mainTask[0]}</td>
                  <td>{groupedTasks.map((task) => task.subTask).join(', ')}</td>
                  <td>
                    {groupedTasks.map((task) => {
                      const taskToDate = moment(task.taskto);
                      const taskFromDate = moment(task.taskfrom);

                      if (task.taskto && taskToDate.year() > 2000 && task.taskfrom && taskFromDate.year() > 2000) {
                        const formattedTaskTo = taskToDate.format('DD-MMM-YY');
                        const formattedTaskFrom = taskFromDate.format('DD-MMM-YY');
                        return (
                          <div key={task.taskto}>
                            {formattedTaskFrom} - {formattedTaskTo}
                          </div>
                        );
                      }

                      return (
                        <div key={task.taskto}>
                          No Period
                        </div>
                      );
                    })}
                  </td>
                  <td>
                    {groupedTasks.map((task) => (
                      <div key={task.NumberOfEntries}>{task.NumberOfEntries}</div>
                    ))}
                  </td>
                  <td>{TimeDisplay(groupedTasks[0].taskTimeInMinutes)}</td>
                  <td>{groupedTasks[0].travelTime}</td>
                  <td>{groupedTasks[0].location}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      data-tooltip-id="remarks-tooltip"
                      data-tooltip-content={groupedTasks.map((task) => task.remarks).join(', ')}
                      style={{ cursor: 'pointer' }}
                    />
                    <Tooltip id="remarks-tooltip" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No tasks found for the selected client and date range.</p>
        )}
      </div>
    </div>
  );
};

export default MemberVisitReport;