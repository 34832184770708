import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';

const GSTReconPage = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [reconType, setReconType] = useState('');
  const [monthData, setMonthData] = useState([
    { id: 1, monthYear: '2024-04', opening: {}, gstr1: {}, gstr3b: {}, closing: {}, submitted: false, collapsed: false }
  ]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients');
      const clientOptions = response.data.map(client => ({
        value: client.CC,
        label: `${client.CC}-${client.TradeName}`
      }));
      setClients(clientOptions);
    } catch (error) {
      console.error('Error fetching clients:', error);
      Swal.fire('Error', 'Failed to fetch clients', 'error');
    }
  };

  const handleReconTypeChange = (selectedOption) => {
    setReconType(selectedOption.value);
    setMonthData([{ id: 1, monthYear: '2024-04', opening: {}, gstr1: {}, gstr3b: {}, closing: {}, submitted: false, collapsed: false }]);
  };

  const calculateClosingAndTotal = (id) => {
    setMonthData(prevData =>
      prevData.map(month => {
        if (month.id === id) {
          const closing = {};
          const fields = ['taxableValue', 'igst', 'cgst', 'sgst', 'cess'];

          fields.forEach(field => {
            const openingValue = parseFloat(month.opening[field] || 0);
            const gstr1Value = parseFloat(month.gstr1[field] || 0);
            const gstr3bValue = parseFloat(month.gstr3b[field] || 0);
            closing[field] = (openingValue + gstr1Value - gstr3bValue) || 0;
          });

          closing.total = (parseFloat(closing.igst || 0) +
            parseFloat(closing.cgst || 0) +
            parseFloat(closing.sgst || 0) +
            parseFloat(closing.cess || 0)) || '';

          ['opening', 'gstr1', 'gstr3b', 'closing'].forEach(section => {
            month[section].total = (parseFloat(month[section].igst || 0) +
              parseFloat(month[section].cgst || 0) +
              parseFloat(month[section].sgst || 0) +
              parseFloat(month[section].cess || 0)) || '';
          });

          return { ...month, closing };
        }
        return month;
      })
    );
  };

  const handleInputChange = (id, section, field, value) => {
    setMonthData(prevData =>
      prevData.map(month =>
        month.id === id
          ? { ...month, [section]: { ...month[section], [field]: value } }
          : month
      )
    );
    calculateClosingAndTotal(id);
  };

  const handleSubmit = (id) => {
    
    // Display success message upon submission
    Swal.fire('Submitted', `Details for month ${monthData[id - 1].monthYear} submitted successfully`, 'success');
    const currentMonth = monthData[id - 1];
    const dataToSend = { 
      client: selectedClient.value, // Include selected client
      monthYear: currentMonth.monthYear,
      // Send opening values for the first month only
      opening: id === 1 ? currentMonth.opening : undefined,
      gstr1: currentMonth.gstr1,
      gstr3b: currentMonth.gstr3b
    };

    // Send data to backend
    console.log(dataToSend);
    
    axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/gstRecon', dataToSend)
      .then(() => console.log('Data submitted:', dataToSend))
      .catch(err => console.error('Error submitting data:', err));

    // Update monthData to mark the current month as submitted
    setMonthData(prevData =>
      prevData.map(month => month.id === id ? { ...month, submitted: true } : month)
    );
};


  const handleAddMonth = () => {
    const lastMonth = monthData[monthData.length - 1];
    const nextMonth = new Date(lastMonth.monthYear);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const formattedNextMonth = nextMonth.toISOString().slice(0, 7);
    setMonthData([
      ...monthData,
      { id: lastMonth.id + 1, monthYear: formattedNextMonth, opening: { ...lastMonth.closing }, gstr1: {}, gstr3b: {}, closing: {}, submitted: false, collapsed: false }
    ]);
  };

  const toggleCollapse = (id) => {
    setMonthData(prevData =>
      prevData.map(month =>
        month.id === id ? { ...month, collapsed: !month.collapsed } : month
      )
    );
  };

  // Check if all previous months are submitted
  const canAddNextMonth = () => {
    return monthData.every(month => month.submitted);
  };

  return (
    <div className="container mt-5">
      <h3 className="mb-2">GST Reconciliation</h3>
      <div className='row mb-2'>
      <div className="col-md-6">
        <label>Select Client:</label>
        <Select options={clients} onChange={setSelectedClient} placeholder="Choose a client..." />
      </div>

      {selectedClient && (
        <div className="col-md-6">
          <label>Select Reconciliation Type:</label>
          <Select
            options={[
              { value: 'R1/IFF Vs. 3B', label: 'R1/IFF Vs. 3B' },
              { value: 'DTR Vs. Compliances', label: 'DTR Vs. Compliances' }
            ]}
            onChange={handleReconTypeChange}
            placeholder="Choose reconciliation type..."
            />
        </div>
      )}
      </div>

      {selectedClient && reconType === 'R1/IFF Vs. 3B' && (
        <>
          {monthData.map(({ id, monthYear, opening, gstr1, gstr3b, closing, submitted, collapsed }) => (
            <div key={id} className="mb-4">
              <h6
                style={{ cursor: 'pointer'}}
                onClick={() => submitted && toggleCollapse(id)}
              >
                {new Date(monthYear).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
              </h6>

              <table className="table table-bordered" style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
                <thead>
                  <tr>
                    <th>Particulars</th>
                    <th>Taxable Value</th>
                    <th>IGST</th>
                    <th>CGST</th>
                    <th>SGST</th>
                    <th>Cess</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {['opening', 'gstr1', 'gstr3b'].map((section, index) => (
                    <tr key={section} style={{ display: collapsed && submitted ? 'none' : '' }}>
                      <td>
                        {section === 'opening' ? 'Opening' : section === 'gstr1' ? 'GSTR-1/IFF' : 'GSTR-3B'}
                      </td>
                      {['taxableValue', 'igst', 'cgst', 'sgst', 'cess', 'total'].map(field => (
                        <td key={field}>
                          <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            value={eval(section)[field] || ''}
                            onChange={e => section !== 'closing' && field !== 'total' && handleInputChange(id, section, field, e.target.value)}
                            readOnly={section === 'closing' || field === 'total' || submitted || (section === 'opening' && id !== 1)}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>

                {/* Always show the closing row */}
                <tbody>
                  <tr>
                    <td>Closing</td>
                    {['taxableValue', 'igst', 'cgst', 'sgst', 'cess', 'total'].map(field => (
                      <td key={field}>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          value={closing[field] || ''}
                          readOnly
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>

              {!collapsed && !submitted && (
                <button className="btn btn-primary mb-2" onClick={() => handleSubmit(id)}>Submit</button>
              )}
            </div>
          ))}

          {/* Add Next Month Button */}
          <button
            className="btn btn-secondary"
            onClick={handleAddMonth}
            disabled={!canAddNextMonth()}
          >
            Add Next Month
          </button>
        </>
      )}
    </div>
  );
};

export default GSTReconPage;
