import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';

const PendingTaskDailyWorking = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { rowData } = location.state??'';
  
  const email = sessionStorage.getItem('email');
  const [formData, setFormData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    location: '',
    otherLocation: '',
    travelTime: '',
    billing:'',
    challan:'',
    tasks: rowData?.taskname
    ? rowData.taskname.map((subtask) => ({
        subtasks: subtask || '',
        taskPeriodFrom: subtask.taskPeriodFrom || '',
        taskPeriodTo: subtask.taskPeriodTo || '',
        NumberOfEntries: subtask.NumberOfEntries || '',
        remarks:subtask.remarks || ''
      }))
    : [], 
});
const isChallan =(Array.isArray(rowData.taskname) ? rowData.taskname : Object.values(rowData.taskname)).some(subtask => 
  subtask?.taskname === 'GST Payment Challan '   || 
  subtask?.taskname === 'TCS Payment Challan' || 
  subtask?.taskname === 'TDS Payment Challan'     || 
  subtask?.taskname === 'Income Tax Payment Challan'
) ;

const billingFieldShow = 
(Array.isArray(rowData.taskname) ? rowData.taskname : Object.values(rowData.taskname)).some(subtask => {
  // Split the taskname by commas if it's a string
  const taskNames = subtask ? subtask.split(',').map(name => name.trim()) : [];
  

  // Check if any of the split task names match the conditions
  return taskNames.includes('GSTR-9C Filling')   || 
         taskNames.includes('GST-TCS Return Filling') || 
         taskNames.includes('GSTR-9 Filing')     || 
         taskNames.includes('GSTR-10 Filing')    || 
         taskNames.includes('TDS Return Filing') || 
         taskNames.includes('TCS Return Filing') || 
         taskNames.includes('ITR Return Filing');
}) ||
rowData?.mainTask === 'Registration' ||
rowData?.mainTask === 'MCA' ||
rowData?.mainTask === 'Consultancy';






  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  };

  let showBilling = false;

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubtaskChange = (index, field, value) => {
    const updatedSubtasks = formData.tasks.map((subtask, i) =>
      i === index ? { ...subtask, [field]: value } : subtask
    );
    setFormData((prevData) => ({
      ...prevData,
      tasks: updatedSubtasks,
    }));
  };

  const handleSubmit = async (e) => {  
    e.preventDefault()
    const { startTime, endTime, location, otherLocation } = formData;
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);

    const taskTime = (end - start) / (1000 * 60 * 60); // in hours
    const taskTimeInMinutes = (end - start) / (1000 * 60); // in minutes

    const totalEntries = formData.tasks.reduce(
      (acc, subtask) => acc + (Number(subtask.NumberOfEntries) || 0), 
      0
    );
    const finalLocation = location === 'Any Other' ? otherLocation : location;
    const efficiency = (totalEntries / taskTime).toFixed(2);
    const travelTimeInMinutes = convertToMinutes(formData.travelTime??'');


    try {
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/pendingtaskdailyworking', {
        empid: rowData.empid,
        email: email,
        fullname: rowData.FirstName + " " + rowData.LastName,
        MainTask:rowData.mainTask,
        travelTime:travelTimeInMinutes,
        location:finalLocation,
        taskTime:taskTime,
        taskTimeInMinutes:taskTimeInMinutes,
        totalEntries:totalEntries,
        reportingDate:formData.date,
        billing:formData.billing,
        tasks: formData.tasks,
        client: rowData.clientid,
        remarks:rowData.remarks,
        endTime:formData.endTime,
        startTime:formData.startTime,
        date:formData.date,
        efficiency:efficiency,
        challan:formData.challan
      }).then(response => {
        Swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: response.data || 'Data saved successfully',
          showConfirmButton: false,
          timer: 1500
        }).then(() => {
         navigate('../assignTasks')
        });
      
      }); 
    } catch (error) {
      console.error('Error submitting or updating tasks:', error);
      toast.error('Failed to submit or update tasks');
    } 
  };

  return (
    <div className="container mt-5">
      <h1>Pending Task Working</h1>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-md-3">
            <label>Date</label>
            <input
              type="date"
              className="form-control"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>Client</label>
            <input
              type="text"
              className="form-control"
              value={`${rowData?.clientid} - ${rowData?.TradeName}` || ''}
              disabled
            />
          </div>
          <div className="form-group col-md-3">
            <label>Start Time <b>Do not include Travel Time</b></label>
            <input
              type="time"
              className="form-control"
              name="startTime"
              value={formData.startTime}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group col-md-3">
            <label>End Time <b>Do not include Travel Time</b></label>
            <input
              type="time"
              className="form-control"
              name="endTime"
              value={formData.endTime}
              onChange={handleInputChange}
              required
            />
          </div>
         
        </div>

        <div className="row">
        <div className="form-group col-md-3">
            <label>Location</label>
            <select
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              className="form-control"
              required
            >
              <option value="">Select Location</option>
              <option value="Office">Office</option>
              <option value="Home">Home</option>
              <option value="Client Site">Client Site</option>
              <option value="Any Other">Any Other</option>
            </select>
          </div>
          <div className="form-group col-md-3">
                <label>Other Location</label>
                <input
                  type="text"
                  name="otherLocation"
                  placeholder="Please Specify"
                  value={formData.otherLocation}
                  onChange={handleInputChange}
                  className="form-control"
                  disabled={!(formData.location === 'Any Other')}
                />
             </div>
             <div className="form-group col-md-3">
       
                <label>Travel Time</label>
                <input
                  type="text"
                  name="travelTime"
                  className="form-control"
                  value={formData.travelTime}
                  onChange={handleInputChange}
                  placeholder="HH:MM"
                  disabled={!(formData.location === 'Client Site')}
                />

            </div>
          <div className="form-group col-md-3">
     
            <label>Remarks</label>
            <input
              type="text"
              className="form-control"
              value={rowData?.remarks || ''}
              disabled
            />
          </div>
         
     
          
        </div>

        <div className="row">
        <div className="form-group col-md-3">
        <label>Main Task</label>
            <input
              type="text"
              className="form-control"
              value={rowData?.mainTask || ''}
              disabled
            />
                 </div>
       
          <div className="form-group col-md-3">
          <label htmlFor="challan">Challan Amount</label>
          <input
            type="text"
            className="form-control"
            id="challan"
            name="challan"
            placeholder="Challan Amount"
            value={formData.challan}
            onChange={handleInputChange}
            disabled={!isChallan}
          />
        </div>
            <div className='form-group col-md-3'>
             
          <label htmlFor="billing">Billing</label>
          <input
            type="text"
            className="form-control"
            id="billing"
            name="billing"
            placeholder="Billing"
            value={formData.billing}
            onChange={handleInputChange}
            disabled={!billingFieldShow}
          />
        </div>
        </div>
        {formData.tasks.map((subtask, index) => (
          <div className="row" key={index}>
            <div className="col-md-3">
              <label>Sub Tasks</label>
              <input
                type="text"
                className="form-control"
                value={subtask.subtasks || ''}
                disabled
              />
            </div>
            <div className="col-md-2">
              <label>Task Period From</label>
              <input
                type="date"
                className="form-control"
                value={subtask.taskPeriodFrom || ''}
                onChange={(e) => handleSubtaskChange(index, 'taskPeriodFrom', e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label>Task Period To</label>
              <input
                type="date"
                className="form-control"
                value={subtask.taskPeriodTo || ''}
                onChange={(e) => handleSubtaskChange(index, 'taskPeriodTo', e.target.value)}
              />
            </div>
            <div className="col-md-2">
              <label>Number of Entries</label>
              <input
                type="number"
                className="form-control"
                value={subtask.NumberOfEntries || ''}
                onChange={(e) => handleSubtaskChange(index, 'NumberOfEntries', e.target.value)}
              />
            </div>
            <div className="col-md-3">
              <label>Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Remarks"
                  value={subtask.remarks || ''}
                  onChange={(e) => handleSubtaskChange(index, 'remarks', e.target.value)}
                />
              </div>
          </div>
        ))}

        <div className="mt-3" >
          <button type="submit" className="btn btn-success ">Submit</button>
          <button type="button" className="btn btn-secondary m-1" onClick={() => navigate('../assignTasks')}>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default PendingTaskDailyWorking;
