import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

const AdminRequests = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [lateAndEarlyRequests, setLateAndEarlyRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [employeeData, setEmployeeData] = useState(null);
  const email = sessionStorage.getItem('email');

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email },
        });
        setEmployeeData(response.data[0]);
        
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
    }
  }, [email]);
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const leaveResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLeaveRequests');
        setLeaveRequests(leaveResponse.data);

        const lateAndEarlyResponse = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getallLateEntryAndEarlyExitRequests');
        setLateAndEarlyRequests(lateAndEarlyResponse.data);
      } catch (error) {
        console.error('Error fetching requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const updateRequestStatus = async (id, status, type) => {
    try {
      if (type === 'Leave') {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateLeaveRequestStatus', { id, status, empid:employeeData?.empid||'admin' });
        setLeaveRequests(prevRequests =>
          prevRequests.map(request =>
            request.ID === id ? { ...request, status } : request
          )
        );
      } else {
        await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateLateEntryAndEarlyExitRequestStatus', { id, status, empid:employeeData?.empid||'admin' });
        setLateAndEarlyRequests(prevRequests =>
          prevRequests.map(request =>
            request.id === id ? { ...request, status } : request
          )
        );
      }
    } catch (error) {
      console.error('Error updating request status:', error);
    }
  };

  const formatDateTime = (dateString) => {
    const date = moment(dateString).subtract(330, 'minutes');
    return date.tz('Asia/Kolkata').format('DD-MMMM-YYYY HH:mm');
  };

  const formatDate = (dateString) => {
    return moment(dateString).tz('Asia/Kolkata').format('DD-MMMM-YYYY');
  };

  const pendingLeaveRequests = leaveRequests.filter(request => request.status === null);
  const renderPendingLeaveRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Reason</th>
          <th>Location</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {leaveRequests.filter(request => request.status === null).map((request, index) => (
          <tr key={index}>
            <td>{request.fullname}</td>
            <td>{formatDate(request.fromdate)}</td>
            <td>{formatDate(request.todate)}</td>
            <td>{request.reasonforleave === 'Any Other' ? request.otherreason: request.reasonforleave }</td>
            <td>{request.location}</td>
            <td>{formatDateTime(request.submission_time)}</td>
            <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
            <td>
              <button 
                className="btn btn-success me-2" 
                onClick={() => updateRequestStatus(request.ID, 1, 'Leave')}
                disabled={request.status === 1}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
              <button 
                className="btn btn-danger" 
                onClick={() => updateRequestStatus(request.ID, 0, 'Leave')}
                disabled={request.status === 0}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const acceptedLeaveRequests = leaveRequests.filter(request => request.status === 1);
  const renderAcceptedLeaveRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Reason</th>
          <th>Location</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Edited By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {leaveRequests.filter(request => request.status === 1).map((request, index) => (
          <tr key={index}>
            <td>{request.fullname}</td>
            <td>{formatDate(request.fromdate)}</td>
            <td>{formatDate(request.todate)}</td>
            <td>{request.reasonforleave === 'Any Other' ? request.otherreason: request.reasonforleave }</td>
            <td>{request.location}</td>
            <td>{formatDateTime(request.submission_time)}</td>
            <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
            <td>{request.acceptedBy}</td>
            <td>
              <button 
                className="btn btn-success me-2" 
                onClick={() => updateRequestStatus(request.ID, 1, 'Leave')}
                disabled={request.status === 1}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
              <button 
                className="btn btn-danger" 
                onClick={() => updateRequestStatus(request.ID, 0, 'Leave')}
                disabled={request.status === 0}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const rejectedLeaveRequests = leaveRequests.filter(request => request.status === 0);
  const renderRejectedLeaveRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>From Date</th>
          <th>To Date</th>
          <th>Reason</th>
          <th>Location</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Edited By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {leaveRequests.filter(request => request.status === 0).map((request, index) => (
          <tr key={index}>
            <td>{request.fullname}</td>
            <td>{formatDate(request.fromdate)}</td>
            <td>{formatDate(request.todate)}</td>
            <td>{request.reasonforleave === 'Any Other' ? request.otherreason: request.reasonforleave }</td>
            <td>{request.location}</td>
            <td>{formatDateTime(request.submission_time)}</td>
            <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
            <td>{request.acceptedBy}</td>
            <td>
              <button 
                className="btn btn-success me-2" 
                onClick={() => updateRequestStatus(request.ID, 1, 'Leave')}
                disabled={request.status === 1}
              >
                <FontAwesomeIcon icon={faThumbsUp} />
              </button>
              <button 
                className="btn btn-danger" 
                onClick={() => updateRequestStatus(request.ID, 0, 'Leave')}
                disabled={request.status === 0}
              >
                <FontAwesomeIcon icon={faThumbsDown} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const pendingLateEarlyRequests = lateAndEarlyRequests.filter(request => request.status === null);
  const renderPendingLateAndEarlyRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Type</th>
          <th>Duration</th>
          <th>Reason</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {lateAndEarlyRequests
          .filter(request => request.status === null) // Filter requests with status === null
          .map((request, index) => (
            <tr key={index}>
              <td>{request.fullname}</td>
              <td>{formatDate(request.date)}</td>
              <td>{request.type}</td>
              <td>{request.timeDuration}</td>
              <td>{request.reason === 'Any Other' ? request.otherReason : request.reason}</td>
              <td>{formatDateTime(request.submission_time)}</td>
              <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
              <td>
                <button 
                  className="btn btn-success me-2" 
                  onClick={() => updateRequestStatus(request.id, 1, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 1}
                >
                  <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <button 
                  className="btn btn-danger" 
                  onClick={() => updateRequestStatus(request.id, 0, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 0}
                >
                  <FontAwesomeIcon icon={faThumbsDown} />
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
  const acceptedLateEarlyRequests = lateAndEarlyRequests.filter(request => request.status === 1);
  const renderAcceptedLateAndEarlyRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Type</th>
          <th>Duration</th>
          <th>Reason</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Edited By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {lateAndEarlyRequests
          .filter(request => request.status === 1) // Filter requests with status === null
          .map((request, index) => (
            <tr key={index}>
              <td>{request.fullname}</td>
              <td>{formatDate(request.date)}</td>
              <td>{request.type}</td>
              <td>{request.timeDuration}</td>
              <td>{request.reason === 'Any Other' ? request.otherReason : request.reason}</td>
              <td>{formatDateTime(request.submission_time)}</td>
              <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
              <td>{request.acceptedBy}</td>
              <td>
                <button 
                  className="btn btn-success me-2" 
                  onClick={() => updateRequestStatus(request.id, 1, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 1}
                >
                  <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <button 
                  className="btn btn-danger" 
                  onClick={() => updateRequestStatus(request.id, 0, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 0}
                >
                  <FontAwesomeIcon icon={faThumbsDown} />
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
  const rejectedLateEarlyRequests = lateAndEarlyRequests.filter(request => request.status === 0);
  const renderRejectedLateAndEarlyRequestsTable = () => (
    <table className="table table-striped">
      <thead style={{fontSize:'14px',verticalAlign:'baseline'}}>
        <tr>
          <th>Name</th>
          <th>Date</th>
          <th>Type</th>
          <th>Duration</th>
          <th>Reason</th>
          <th>Request Date & Time</th>
          <th>Status</th>
          <th>Edited By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody style={{fontSize:'12px',verticalAlign:'baseline'}}>
        {lateAndEarlyRequests
          .filter(request => request.status === 0) // Filter requests with status === null
          .map((request, index) => (
            <tr key={index}>
              <td>{request.fullname}</td>
              <td>{formatDate(request.date)}</td>
              <td>{request.type}</td>
              <td>{request.timeDuration}</td>
              <td>{request.reason === 'Any Other' ? request.otherReason : request.reason}</td>
              <td>{formatDateTime(request.submission_time)}</td>
              <td>{request.status === null ? 'Pending' : request.status === 0 ? 'Denied' : 'Accepted'}</td>
              <td>{request.acceptedBy}</td>
              <td>
                <button 
                  className="btn btn-success me-2" 
                  onClick={() => updateRequestStatus(request.id, 1, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 1}
                >
                  <FontAwesomeIcon icon={faThumbsUp} />
                </button>
                <button 
                  className="btn btn-danger" 
                  onClick={() => updateRequestStatus(request.id, 0, 'LateEntryAndEarlyExit')}
                  disabled={request.status === 0}
                >
                  <FontAwesomeIcon icon={faThumbsDown} />
                </button>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  );
  

  return (
    <div className="container mt-4" style={{ maxWidth:'1440px' }}>
      <p className='para-heading mt-5'>Team Member Requests</p>
      
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Tabs>
          <TabList>
            <Tab>Pending Leaves</Tab>
            <Tab>Pending Late & Early</Tab>
            <Tab>Accepted Leaves</Tab>
            <Tab>Denied Leaves</Tab>
            <Tab>Accepted Late & Early</Tab>
            <Tab>Denied Late & Early</Tab>
          </TabList>

          <TabPanel>
            {pendingLeaveRequests.length === 0 ? <p>No leave requests found.</p> : renderPendingLeaveRequestsTable()}
          </TabPanel>
          <TabPanel>
            {pendingLateEarlyRequests.length === 0 ? <p>No late entry or early exit requests found.</p> : renderPendingLateAndEarlyRequestsTable()}
          </TabPanel>
          <TabPanel>
            {acceptedLeaveRequests.length === 0 ? <p>No leave requests found.</p> : renderAcceptedLeaveRequestsTable()}
          </TabPanel>
          <TabPanel>
            {rejectedLeaveRequests.length === 0 ? <p>No leave requests found.</p> : renderRejectedLeaveRequestsTable()}
          </TabPanel>
          <TabPanel>
            {acceptedLateEarlyRequests.length === 0 ? <p>No late entry or early exit requests found.</p> : renderAcceptedLateAndEarlyRequestsTable()}
          </TabPanel>
          <TabPanel>
            {rejectedLateEarlyRequests.length === 0 ? <p>No late entry or early exit requests found.</p> : renderRejectedLateAndEarlyRequestsTable()}
          </TabPanel>
        </Tabs>
      )}
    </div>
  );
};

export default AdminRequests;
