import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, usePagination, useSortBy } from 'react-table';
import DateRangePickerComponent from './CustomDateRangePicker'
import moment from 'moment';

const MemberClientTimeReport = () => {
  const email = sessionStorage.getItem('email');
  const [employeeData, setEmployeeData] = useState({});
  const [clients, setClients] = useState([]); // Store assigned clients
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
  const [reportData, setReportData] = useState([]);

  // Fetch employee data and assigned clients
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata', {
          params: { email: email },
        });
        const employeeData = response.data[0];
        setEmployeeData(employeeData);
        fetchAssignedClients(employeeData.empid);
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    if (email) {
      fetchEmployeeData();
    }
  }, [email]);

  // Fetch assigned clients for the logged-in employee
  const fetchAssignedClients = (empid) => {
    axios
      .get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/assigned-clients?empid=${empid}`)
      .then((response) => {
        const clientOptions = response.data.map((client) => ({
          value: client.CC,
          label: `${client.CC} - ${client.TradeName}`,
        }));
        setClients(clientOptions); // Set assigned clients
      })
      .catch((error) => console.error('Error fetching clients:', error));
  };

  // Function to fetch the responsible member
  const fetchResponsibleMember = async (CC) => {
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC });
      return response.data.responsibleMember?.firstname || 'N/A';
    } catch (error) {
      console.error('Error fetching responsible member:', error);
      return 'N/A';
    }
  };

  // Function to fetch data including responsible members, filtering by assigned clients
  const handleFetchData = async () => {
    const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
    if (!startDate || !endDate) {
      console.error("Please select a valid date range");
      return;
    }
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/client-time-report', {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      });

      const data = response.data;

      // Only include report data for assigned clients
      const filteredData = data.filter((item) =>
        clients.some((client) => client.value === item.CC)
      );

      // Fetch responsible members for each CC
      const enrichedData = await Promise.all(
        filteredData.map(async (item) => {
          const responsibleMember = await fetchResponsibleMember(item.CC);
          return { ...item, responsibleMember }; // Append responsible member's name to the data
        })
      );

      setReportData(enrichedData); // Set the enriched data in state
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const TimeDisplay = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  // Define columns for React-Table, including the "Member" column
  const columns = useMemo(
    () => [
      {
        Header: 'Sr. No',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'Member',
        accessor: 'responsibleMember',
      },
      {
        Header: 'P',
        accessor: 'Priority',
      },
      {
        Header: 'L',
        accessor: 'levelOfBOA',
      },
      {
        Header: 'CC',
        accessor: 'CC',
      },
      {
        Header: 'Trade Name',
        accessor: 'TradeName',
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell: ({ value }) => TimeDisplay(value),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    gotoPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: reportData,
      initialState: { pageSize: 10 },
    },
    useSortBy, // Add sorting capability
    usePagination
  );

  return (
    <div className="container mt-5">
      <h2>Time Absorption</h2>

      <div className="row">
    <div className="col-md-3">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
    <div className="col-md-4 d-flex align-items-end">
        <button type="submit" className="btn btn-success" onClick={handleFetchData}>
            Get Tasks
        </button>
    </div>
</div>

      {reportData.length > 0 && (
        <>
          <table style={{ fontSize: '14px', verticalAlign: 'baseline' }} className="table table-striped mt-2" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())} // Add sort toggle props to headers
                      className={column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''}
                    >
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button className="btn btn-secondary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </button>
              <button className="btn btn-secondary mx-2" onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>
            </div>
            <div>
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
              </span>
            </div>
            <div>
              <select
                className="form-select"
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[5, 10, 15, 20, 50].map((size) => (
                  <option key={size} value={size}>
                    Show {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}

      {reportData.length === 0 && <p>No data available.</p>}
    </div>
  );
};

export default MemberClientTimeReport;
