import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEyeSlash, faEye, faSync, faEdit } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';

import ReactDOM from 'react-dom/client';
import MemoEditModal from './MemoEditModal';
const Swal = require('sweetalert2');

library.add(faFilter, faEyeSlash, faEye, faSync, faEdit);

const GSTCredentialsHY = () => {
  const [requests, setRequests] = useState([]);
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [amount, setAmount] = useState('');
  const [memoData, setMemoData] = useState([]);
  const [memoCount, setMemoCount] = useState(0);
  const [selectedColumns, setSelectedColumns] = useState({

    ResponsibleMember:true,
    TradeName: true,
    gstrf:true,
    GSTID: true,
    GSTPass: true,
    gstar3:true,
    gstar3EditedBy:true,
    memo:true,
    gsthly:true,
    gsthlyEditedBy:true

    });
  const [refresh, setRefresh] = useState(false);
  const [visibleRequests, setVisibleRequests] = useState({});

  const email = sessionStorage.getItem('email');
const FullName = requests[0]?.FirstName +' '+ requests[0]?.LastName
useEffect(() => {
  const fetchUserClientRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/usermyclientdetails', { email });
      const requestsData = response.data;

      // Filter the requests where gstApplicable is 'yes'
      const filteredGSTRequests = requestsData.filter(request => 
        (request.gstar3 && request.gstar3.trim() !== '') || (request.gsthly && request.gsthly.trim() !== '')
      );
      setRequests(filteredGSTRequests);
      setFilteredRequests(filteredGSTRequests);
     

      // Fetch responsible members for each request's CC
      const memberRequests = filteredGSTRequests.map(request => 
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC: request.CC })
      );

      const members = await Promise.all(memberRequests);
      const responsibleMembersData = members.reduce((acc, memberResponse, index) => {
        acc[filteredGSTRequests[index].CC] = memberResponse.data.responsibleMember;
        return acc;
      }, {});

      setResponsibleMembers(responsibleMembersData);

      // Fetch memo counts for each request's CC
      const countAdjMemo = filteredGSTRequests.map(request => 
        axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/countmemos?CC=${request.CC}`)
      );


      const memoResponses = await Promise.all(countAdjMemo);
   


      // Extract memo counts
      const memoCounts = memoResponses.map(response => response.data.count);
      setMemoCount(memoCounts);

    } catch (error) {
      console.error('Error fetching client details or responsible members:', error);
      // Optionally set an error state here
    } finally {
      setLoading(false);
    }
  };

  fetchUserClientRequests();
}, [email, refresh]);



useEffect(() => {
  setFilteredRequests(
    requests.filter((request) =>
      Object.values(request).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
  );
}, [searchQuery, requests]);

  const handleSelectAll = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = true; // Set all columns to true
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleSelectNone = () => {
    const newSelectedColumns = Object.keys(selectedColumns).reduce((acc, column) => {
      acc[column] = false; // Set all columns to false
      return acc;
    }, {});
    setSelectedColumns(newSelectedColumns);
  };
  
  const handleColumnChange = (e) => {
    const { name, checked } = e.target;
    setSelectedColumns(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };
  

  const toggleVisibility = (requestCC) => {
    setVisibleRequests((prevVisibleRequests) => ({
      ...prevVisibleRequests,
      [requestCC]: !prevVisibleRequests[requestCC],
    }));
  };

  const handleRefresh = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };
  const handleGST = () => {
    window.open('https://services.gst.gov.in/services/login', '_blank', 'noopener,noreferrer,width=1800,height=1800');

  };

  const handleEditStatus = (request, statusKey,dateKey,editedKey,editedDate) => {
    let statusOptions;

    const lastEditedBy = request[editedKey] || 'N/A';
    const lastEditedDate = request[editedDate] ? moment(request[editedDate]).format('DD-MMM-YY') : 'N/A';
    const fetchdate = request[dateKey] ? moment(request[dateKey]).format('YYYY-MM') : 'N/A';  
    if (statusKey === 'gstar1Status') {
      statusOptions = `
       <option value="">Select Option</option>
          <option value="Data not recd/updated">Data not recd</option>
            <option value="Filled">Filled</option>
            <option value="Submitted">Submitted</option>
            <option value="Submitted to CA/TP">Submitted to CA/TP</option>
            <option value="Suspended">Suspended</option>  
            <option value="To be Filed">To be Filed</option>
            <option value="Uploaded">Uploaded</option>
            <option value="Verified">Verified</option>
            `;
    } else {
      statusOptions = `
       <option value="">Select Option</option>
          <option value="Challan Unpaid">Challan Unpaid</option>
          <option value="Data not recd">Data not recd</option>
            <option value="Filled">Filled</option>
            <option value="Filed - WRT">Filed - WRT</option>
            <option value="Submitted">Submitted</option>
            <option value="Submitted to CA/TP">Submitted to CA/TP</option>
            <option value="Suspended">Suspended</option> 
            <option value="To be Filed">To be Filed</option>
            <option value="Uploaded">Uploaded</option>
            <option value="Verified">Verified</option>
      `;
    }
    Swal.fire({
      title: `Edit Status`,
      html: `
        <div class=" row mb-3">
        <div class="col-md-6">
          <label class="form-label float-start">Team Member</label>
          <input type="text" class="form-control" value="${request.empid[0]}" disabled>
          </div>
           <div class="col-md-6">
            <label class="form-label float-start">Date</label>
            <input type="month" id="status-date" class="form-control" value="${fetchdate}">
          </div>
        </div>
         <div class=" row mb-3">
      <div class="col-md-6">
          <label class="form-label float-start">Status</label>
          <select id="status-select" class="form-select">
          ${statusOptions}
          </select>
        </div>
        <div class="col-md-6">
           <label class="form-label float-start">Last Edited</label>
          <input type="text" id="lastEditedBy" class="form-control" value=${lastEditedBy}/${lastEditedDate} disabled>
          </div>
</div>
      `,
      showCloseButton: true,
      confirmButtonText: 'Update',
      confirmButtonColor: '#198754', 
      width:'50%',
      
      preConfirm: () => {
        const newStatus = document.getElementById('status-select').value;
        const date = document.getElementById('status-date').value;

        const selectedMonth = moment(date); // Selected date from the input field
        const lastFilledMonth = moment(fetchdate); // Last filled date from the request
  
        // Validation 1: If status is "Filled" and the selected date is before the last filled date
        if (newStatus === 'Filled') {
          if (selectedMonth.isBefore(lastFilledMonth)) {
            Swal.showValidationMessage('You cannot select an earlier month than the last "Filled" month.');
            return false;
          }
        }
  
        // Validation 2: If the status is anything other than "Filled" and the selected month is before the "Filled" month, disallow status change
        if (selectedMonth.isBefore(lastFilledMonth) && newStatus !== 'Filled') {
          Swal.showValidationMessage('You cannot select any other status for previous months once the status is "Filled".');
          return false;
        }

        return { newStatus, date };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { newStatus, date } = result.value;
  
        // Make API call to update status
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/gstarupdateStatus', {
          email,
          empid: requests[0].empid[0],
          FullName: FullName,
          CC: request.CC,
          statusKey, // 'gstar1status', 'gstar2status', or 'gstar3status'
          dateKey,
          editedKey,
          newStatus, // new status value
          date, // date value,
          editedDate
        })
        .then(() => {
          Swal.fire({
            text: 'The status has been updated.',
            icon: 'success',
            toast: true,
            showConfirmButton: false,
            position: 'top-end',
            timer: 1000,
            timerProgressBar: true,
            willClose: () => {
              handleRefresh();
            },
          });
        })
        .catch((error) => {
          Swal.fire('Error!', 'Failed to update the status.', 'error');
          console.error('Error updating status:', error);
        });
      }
    });
  };
  
  // Function to show the edit modal with SweetAlert2
  const handleEdit = (request) => {
    Swal.fire({
      title: 'Edit GST Credentials',
      html: `
        <div class="mb-3">
          <label class="form-label float-start">Team Member</label>
          <input type="email" class="form-control" value="${request.empid[0]}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">GST ID</label>
          <input type="text" id="edit-gstid" class="form-control" value="${request.GSTID}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Current GST Password</label>
          <input type="text" id="edit-gstpass" class="form-control" value="${request.GSTPass}" disabled>
        </div>
        <div class="mb-3">
          <label class="form-label float-start">Set New GST Password</label>
          <input type="text" id="new-gstpass" class="form-control" placeholder="Enter new password">
        </div>
      `,
      
      showCloseButton: true,
      confirmButtonText: 'Update',
      confirmButtonColor: '#198754', // Bootstrap 5 success color
      preConfirm: () => {
        const gstid = document.getElementById('edit-gstid').value;
        const currentGstpass = document.getElementById('edit-gstpass').value;
        const newGstpass = document.getElementById('new-gstpass').value;

        if (!gstid || !currentGstpass || !newGstpass) {
          Swal.showValidationMessage('All fields are required');
        }

        return { gstid, currentGstpass, newGstpass };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const { gstid, currentGstpass, newGstpass } = result.value;

        // Make API call to update GSTID and GSTPass with the new password
        axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updategstcredentials', {
            email,
            empid:requests[0].empid[0],
            FullName:FullName,
            CC: request.CC,
            TradeName:request.TradeName,
            GSTID: gstid,
            currentGstpass:currentGstpass,
            GSTPass: newGstpass, // send the new GST password
          })
          .then(() => {
            Swal.fire({
              text: 'The credentials have been updated.',
              icon: 'success',
              toast: true,
              showConfirmButton: false, 
              position: 'top-end', // Positioning the toast
              timer: 1000, // Duration for the toast to appear
              timerProgressBar: true, // Show a progress bar
              willClose: () => {
                handleRefresh();
              }
            });
          })
          .catch((error) => {
            Swal.fire('Error!', 'Failed to update the credentials.', 'error');
            console.error('Error updating GST credentials:', error);
          });
      }
    });
  };

  const handleMemoEdit = (request) => {
    Swal.fire({
        title: 'Edit Memo Details',
        html: '<div id="modal-root"></div>', 
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        width: '80%',
       
        didOpen: () => {
            const modalRoot = document.getElementById('modal-root');
            const root = ReactDOM.createRoot(modalRoot);
            root.render(<MemoEditModal CC={request.CC} empid={request.empid} onClose={Swal.close} />);
        },
        showCloseButton: true, 
       
        
    });
};


  return (
    <div className="container mt-5" style={{ marginLeft: '1px', maxWidth: '2000px' }}>
      <div className="d-flex justify-content-between align-items-center ">
        <h2>GST Compliance H & Y<p className='text align-items mt-2' style={{fontSize:'15px',fontWeight:'400'}}> Number of Clients - {requests.length}</p></h2>
        <div className="d-flex align-items-center">
          <button className="btn btn-secondary me-2" onClick={handleGST}>
             GST Login
          </button>
          <button className="btn btn-secondary me-2" onClick={handleRefresh}>
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button>
         <div className="dropdown">
  <button
    className="btn btn-secondary dropdown-toggle"
    type="button"
    id="filterDropdown"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <FontAwesomeIcon icon={faFilter} /> Filter Columns
  </button>
  <ul className="dropdown-menu" aria-labelledby="filterDropdown">
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectAll"
          checked={Object.values(selectedColumns).every(Boolean)}
          onChange={handleSelectAll}
        />
        <label className="form-check-label" htmlFor="selectAll">
          Select All
        </label>
      </div>
    </li>
    <li className="dropdown-item">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="selectNone"
          checked={Object.values(selectedColumns).every(value => !value)}
          onChange={handleSelectNone}
        />
        <label className="form-check-label" htmlFor="selectNone">
          Select None
        </label>
      </div>
    </li>
    {Object.keys(selectedColumns).map((column) => (
      <li key={column} className="dropdown-item">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name={column}
            checked={selectedColumns[column]}
            onChange={handleColumnChange}
            id={`checkbox-${column}`}
          />
          <label className="form-check-label" htmlFor={`checkbox-${column}`}>
            {column}
          </label>
        </div>
      </li>
    ))}
  </ul>
  </div>
  </div>
</div>

      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border mt-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : filteredRequests.length === 0 ? (
        <p>No Clients found.</p>
      ) : (
        <table className="table table-striped">
          <thead style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
            <tr>
            {selectedColumns.ResponsibleMember && <th>Member <br />P L CC</th>}

              {selectedColumns.TradeName && <th>Trade Name <br />GSTIN</th>}


              {selectedColumns.GSTID && <th>ID</th>}


              {selectedColumns.GSTPass && <th>Pass</th>}
              <th>Edit</th>
              {selectedColumns.memo && <th>ADJ </th>}
              {selectedColumns.gstrf && <th>RF</th>}  
              
              {selectedColumns.gsthly && <th>Hly </th>}
              
              {selectedColumns.gsthlyEditedBy && <th>Status </th>}
             <th>Edit</th>
              {selectedColumns.gstar3 && <th>Yly </th>}
              
              {selectedColumns.gstar3EditedBy && <th>Status </th>}
             <th>Edit</th>
        
            </tr>
          </thead>
          <tbody style={{ fontSize: '15px', verticalAlign: 'baseline' }}>
  {filteredRequests.map((request, index) => {
    // Get responsible member by CC
    const responsibleMember = responsibleMembers[request.CC];

    return (
      <tr key={index}>
        {/* Render Responsible Member */}
        {selectedColumns.ResponsibleMember && (
  <td>
    {responsibleMember ? (
      <>
        {responsibleMember.firstname}
        <br />
        {request.Priority} {request.levelOfBOA} {request.CC}
      </>
    ) : (
      'N/A'
    )}
  </td>
)}


        {selectedColumns.TradeName && <td>{request.TradeName} <br />{request.GSTIN}</td>}

        

        {selectedColumns.GSTID && (
          <td>
            {visibleRequests[request.CC] ? (
              request.GSTID
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(request.CC)} />
            )}
          </td>
        )}

        {selectedColumns.GSTPass && (
          <td>
            {visibleRequests[request.CC] ? (
              <>
                {request.GSTPass}
              
                  <br />{selectedColumns.LastEdited && (
          
          <td>
            {request.lastGstEditedBy && request.lastGstDate
              ? `${request.lastGstEditedBy}/${moment(request.lastGstDate).format('DD-MMM')}`
              : '-'}
          </td>
        )}

               
              </>
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} onClick={() => toggleVisibility(request.CC)} />
            )} 
          </td> 
        )}

        
        <td> <FontAwesomeIcon icon={faEdit}  onClick={() => handleEdit(request)} className="ms-2 text-success cursor-pointer"/></td>

        <td>
          {/* Use the index to access the memo count */}
          <span style={{
        fontWeight: '600',
        color: memoCount[index] === 0 ? '#006400' : 'red', // Green if count is 0, red otherwise
      }}
>
          {memoCount[index] !== undefined ? memoCount[index] : 0}
          </span>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleMemoEdit(request)}
            className="ms-2 text-success cursor-pointer"
          />
        </td>
        {selectedColumns.gstrf && <td>{request.gstrf}</td>}
        {selectedColumns.gsthly && <td>{request.gsthly}
         
         </td>}
         {selectedColumns.gsthlyEditedBy && (
         <td>
           
         <span style={{
       fontWeight:'600',
       color:
         request.gsthlyStatus === 'Filled' || request.gsthlyStatus === 'Filed - WRT'
           ? '#006400'
           : request.gsthlyStatus === 'To be Filed'
           ? 'Red'
           : '#F9A602', // Default color if none of the statuses match
     }}  >
             {request.gsthlyStatus && request.gsthlyStatus
               ? `${moment(request.gsthlyDate).format('MM/YY')} ${request.gsthlyStatus}`
               : ''}
           </span> 
           <br />
           {request.gsthlyEditedBy && request.gsthlyEditedByDate
             ? `${request.gsthlyEditedBy}`
             : '-'}
         </td>
       )}
         <td>{request.gsthly && (
          
          <FontAwesomeIcon icon={faEdit} className="ms-2 text-success cursor-pointer" onClick={() => handleEditStatus(request, 'gsthlyStatus', 'gsthlyDate', 'gsthlyEditedBy','gsthlyEditedByDate')} />
 
      )}</td>
       


        {selectedColumns.gstar3 && <td>{request.gstar3}
         
          </td>}
          {selectedColumns.gstar3EditedBy && (
          <td>
            
          <span style={{
        fontWeight:'600',
        color:
          request.gstar3Status === 'Filled' 
            ? '#006400'
            : request.gstar3Status === 'To be Filed'
            ? 'Red'
            : '#F9A602', // Default color if none of the statuses match
      }}  >
              {request.gstar3Status && request.gstar3Status
                ? `${moment(request.gstar3Date).format('MM/YY')} ${request.gstar3Status}`
                : ''}
            </span> 
            <br />
            {request.gstar3EditedBy && request.gstar3EditedByDate
              ? `${request.gstar3EditedBy}`
              : '-'}
          </td>
        )}
          <td>{request.gstar3 && (
           
           <FontAwesomeIcon icon={faEdit} className="ms-2 text-success cursor-pointer" onClick={() => handleEditStatus(request, 'gstar3Status', 'gstar3Date', 'gstar3EditedBy','gstar3EditedByDate')} />
  
       )}</td>
      </tr>
    );
  })}
</tbody>

        </table>
      )}
    </div>
  );
};

export default GSTCredentialsHY;
