import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import Select from 'react-select'; // Import react-select

const ClientVisitTracker = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null); // Set to null initially for react-select
  const [visitData, setVisitData] = useState({
    NumberOfVisits: 0,
    InWeeks: 0,
    startDate: '',
    endDate: '',
    remainingVisits: 0,
    StartedVisited: 0,
    remarks:''
  });
  const [visitDate, setVisitDate] = useState('');

  const email = sessionStorage.getItem('email');

  // Fetch clients when component mounts
  useEffect(() => {
    fetchClients();
  }, []);

  // Handle client selection
  useEffect(() => {
    if (selectedClient && visitData.endDate) {
      const currentDate = new Date();
      const endDate = new Date(visitData.endDate);

      if (currentDate > endDate) {
        Swal.fire({
          title: 'Period Ended',
          text: 'Your InWeeks days are over. Please enter the start date again.',
          icon: 'info',
          confirmButtonText: 'OK'
        }).then(() => {
          setVisitData(prevState => ({
            ...prevState,
            StartedVisited: 0,
            startDate: '',
            endDate: '',
            remainingVisits: prevState.NumberOfVisits,
            remarks:''
          }));
        });
      }
    }
  }, [selectedClient, visitData.endDate]);

  // Fetch clients from the server
  const fetchClients = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients', { email });
      const clientOptions = response.data.map(client => ({
        value: client.CC,
        label: `${client.CC}-${client.TradeName}`
      }));
      setClients(clientOptions);
    } catch (error) {
      console.error('Error fetching clients:', error);
      Swal.fire('Error', 'Failed to fetch clients', 'error');
    }
  };

  // Handle client selection change
  const handleClientChange = async (selectedOption) => {
    const clientCode = selectedOption.value;
    setSelectedClient(selectedOption);

    try {
      const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients/${clientCode}`);
      const client = response.data;
      const formattedStartDate = client.startDate ? new Date(client.startDate).toISOString().split('T')[0] : '';
      const formattedEndDate = client.endDate ? new Date(client.endDate).toISOString().split('T')[0] : '';
      setVisitData({
        NumberOfVisits: client.NumberOfVisits,
        InWeeks: client.InWeeks,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        remainingVisits: client.NumberOfVisits,
        StartedVisited: client.StartedVisited,
      });

      if (client.StartedVisited === 1) {
        // Fetch remaining visits from visitTracker
        const visitResponse = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients/${clientCode}/remainingVisits`, { params: { email } });
        setVisitData(prevState => ({
          ...prevState,
          remainingVisits: visitResponse.data.remainingVisits,
        }));
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
      Swal.fire('Error', 'Failed to fetch client data', 'error');
    }
  };

  // Handle visitDate change and calculate startDate
  useEffect(() => {
    if (visitDate) {
      const visitMoment = moment(visitDate, 'YYYY-MM-DD', true);
      if (visitMoment.isValid()) {
        const mondayOfWeek = visitMoment.startOf('isoWeek').format('YYYY-MM-DD');
        setVisitData(prevState => ({
          ...prevState,
          startDate: mondayOfWeek,
        }));
      }
    }
  }, [visitDate]);

  // Handle marking a visit
  const handleMarkVisit = async () => {
    if (visitData.StartedVisited === 0 && !visitData.startDate) {
      Swal.fire('Error', 'Please enter a start date', 'error');
      return;
    }

    try {
      const visitMoment = moment(visitDate, 'YYYY-MM-DD', true);
      if (!visitMoment.isValid()) {
        Swal.fire('Error', 'Invalid date format for Mark Visit Date', 'error');
        return;
      }
      const dateCount = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/checkvisitdate/${selectedClient.value}`, { params: { email, visitDate } });

      if (dateCount.data.client.visittracker <= 0) {
        Swal.fire('Error', 'Please Fill daily working first for this visit', 'error');
        return;
      }

      const response = await axios.post(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients/${selectedClient.value}/markVisit`, {
        visitDate: visitMoment.format('YYYY-MM-DD'),
        startDate: visitData.StartedVisited === 0 ? visitData.startDate : undefined,
        email,
        remarks:visitData.remarks
      });

      Swal.fire('Success', response.data.message, 'success');
      setVisitData(prevState => ({
        ...prevState,
        remainingVisits: response.data.remainingVisits,
        remarks:''
      }));
      setVisitDate('');
      handleClientChange(selectedClient); // Refresh data
    } catch (error) {
      console.error('Error marking visit:', error.response?.data || error.message || error);
      Swal.fire('Error', 'Failed to mark visit', 'error');
    }
  };

  return (
    <div className="container">
      <h2 className="mt-5">Track Visits</h2>
      <div className="form-group">
        <label htmlFor="clientSelect">Select Client</label>
        <Select
          id="clientSelect"
          options={clients}
          value={selectedClient}
          onChange={handleClientChange}
          placeholder="Select a client"
        />
      </div>
      {selectedClient && (<>
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="numberOfVisits">Number of Visits (Original)</label>
            <input
              type="number"
              id="numberOfVisits"
              className="form-control"
              value={visitData.NumberOfVisits}
              disabled
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="remainingVisits">Remaining Visits</label>
            <input
              type="number"
              id="remainingVisits"
              className="form-control"
              value={visitData.remainingVisits}
              disabled
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inWeeks">In Weeks</label>
            <input type="number" id="inWeeks" className="form-control" value={visitData.InWeeks} disabled />
          </div>
          {visitData.StartedVisited === 1 && (
            <>
              <div className="form-group col-md-4">
                <label htmlFor="startDate">Start Date</label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  value={visitData.startDate}
                  disabled
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="endDate">End Date</label>
                <input
                  type="date"
                  id="endDate"
                  className="form-control"
                  value={visitData.endDate}
                  disabled
                />
              </div>
            </>
          )}

          <div className="form-group col-md-4">
            <label htmlFor="visitDate">Mark Visit Date</label>
            <input
              type="date"
              id="visitDate"
              className="form-control"
              value={visitDate}
              onChange={(e) => setVisitDate(e.target.value)}
            />
          </div>
          <div className="col-md-4">
              <label>Remarks</label>
                <input
                  type="text"
                  className="form-control"
                  id='remarks'
                  placeholder="Remarks"
                  name='remarks'
                  value={visitData.remarks}
                  onChange={(e) => setVisitData(prevState => ({
                    ...prevState,
                    remarks: e.target.value
                  }))}
                
                />
              </div>

        </div>
          <button className="btn btn-success mt-3" onClick={handleMarkVisit}>
            Mark Visit
          </button></>
      )}
    </div>
  );
};

export default ClientVisitTracker;
