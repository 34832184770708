import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../styles/Timesheet.css';
import Swal from 'sweetalert2';
import { Buffer } from 'buffer';
const moment = require('moment');
const momentTimezone = require('moment-timezone');

const FloatingTimesheetApproval = () => {
    const [employeeData, setemployeeData] = useState({
        FirstName: '',
        LastName: '',
        Address: '',
        ContactNo: '',
        Email: '',
        Password: '',
        MonthlyRemuneration: '',
        Position: '',
        MonthlyLeaves: '',
        SignImage: null,
        empid: '',
        hoursType: '',
        floatingHours: '',
        ScheduleIn: '',
        ScheduleOut: ''
      });
    const { state } = useLocation();
    const { timesheet } = state || {}; // Get the timesheet data from the passed state
    const [showModal, setShowModal] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    

    const TimeDisplay = (totalMinutes ) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
      
        return `${formattedHours}:${formattedMinutes}`;
        
      };

    useEffect(() => {
        const fetchProfileData = async () => {
            const email = timesheet.email;
          try {
            if (!email) {
              console.error('No email found in session storage');
              return;
            }
            const response = await axios.get(`https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getemployeedata`, {
              params: { email }
            });
            if (response.data && response.data.length > 0) {
              setemployeeData(response.data[0]);
    
    
           // Assuming response.data is an array with a single user object
            } else {
              console.error('No user data found for the provided email');
            }
          } catch (error) {
            console.error('There was an error!', error);
          }
        };
        
        fetchProfileData();
      }, []);
  

      const handleSubmit = (approvalStatus) => {
        Swal.fire({
          title: approvalStatus ? 'Are you sure you want to approve?' : 'Are you sure you want to disapprove?',
          text: "This action cannot be undone.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: approvalStatus ? '#28a745' : '#dc3545',
          cancelButtonColor: '#6c757d',
          confirmButtonText: approvalStatus ? 'Approve' : 'Disapprove',
          cancelButtonText: 'Cancel'
        }).then((result) => {
          if (result.isConfirmed) {
            // Proceed with the API request
            const requestData = {
              empid: timesheet.empid,
              timesheetDate: timesheet.timesheetDate,
              dailyWorkingChecked: approvalStatus,
              acceptedBy:employeeData.empid,
              acceptingDate: moment().format('YYYY-MM-DD')  // Set current date
            };
      
            axios.put('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateTimesheetStatus', requestData)
              .then((response) => {
                Swal.fire(
                  'Success!',
                  approvalStatus ? 'Timesheet approved successfully.' : 'Timesheet disapproved successfully.',
                  'success'
                );
                // Additional code to handle success response
              })
              .catch((error) => {
                Swal.fire(
                  'Error!',
                  'There was an error updating the timesheet status.',
                  'error'
                );
                console.error('Error updating timesheet status:', error);
              });
          }
        });
      };

  return (
    <div className="timesheetcontainer">
      <div className="timesheet">
        <div className="header">
          <h3 className="headoftimesheet"></h3>
        </div>
        <table className="table table-bordered mt-5">
          <thead>
            <tr>
              <th colSpan="3" className='headoftimesheet'>Daily Timesheet</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name of Team Member:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheet.fullName} disabled/></td>
            </tr>
            <tr>
              <td>Monthly Hours:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={employeeData.floatingHours} disabled/></td>
            </tr>
            <tr>
              <td>Week Number:</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheet.weekNumber} disabled/></td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th colSpan="3" className='headoftimesheet'>Timesheet Entries</th>
            </tr>
            <tr>
              <td>#</td>
              <td>Date</td>
              <td colSpan={3}><input type="text" name='date' className="form-control timesheet-form-control" value={moment(timesheet.timesheetDate).format('DD-MM-YYYY')} disabled/></td>
            </tr>
            <tr>
            <td>A</td>
            <td>Day In Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.dayInTime)} disabled/></td>
            </tr>
            <tr>
            <td>B</td>
            <td>Day Out Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.dayOutTime)} disabled/></td>
            </tr>
            <tr>
            <td>C</td>
            <td>Day Time (B-A)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.dayTime)} disabled/></td>
            </tr>
            <tr>
            <td>D</td>
            <td>Actual Day Time (C) </td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.actualDayTime)} disabled/></td>
            </tr>
            <tr>
            <td>E</td>
            <td>No. of Visits for the day</td>
              <td colSpan={3}><input type="number" className="form-control timesheet-form-control" value={timesheet.numOfClientVisit} disabled/></td>
            </tr>
        
            <tr>
            <td>F</td>
            <td>Travel Time(Clients Only)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.travelTime)} disabled/></td>
            </tr>
            <tr>
              <td>G</td>
              <td>Lunch Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.lunchTime)} disabled/></td>
            </tr>
        
            <tr>
              <td>H</td>
              <td>Other Unproductive Time</td>
              <td colSpan={3}><input type="text"  className="form-control timesheet-form-control" value={TimeDisplay(timesheet.otherUnproductiveTime)} disabled /></td>
            </tr>
            <tr>
            <td>I</td>
            <td>Total Unproductive Time (F+G+H)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.totalUnproductiveTime)} disabled/></td>
            </tr>
           
            <tr>
            <td>J</td>
            <td>Total Productive Time (C-I)</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.totalProductiveTime)}  disabled/></td>
            </tr>
            <tr>
              <td>K</td>
              <td>Total Task Time</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={TimeDisplay(timesheet.totalTaskTime)} disabled/></td>
            </tr>
            <tr>
              <td>L</td>
              <td>Submitted By</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={timesheet.submittedBy} disabled/></td>
            </tr>
            <tr>
              <td>M</td>
              <td>Date</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={moment.utc(timesheet.submissionDate).format('DD-MM-YYYY')} disabled/></td>
            </tr>
            <tr>
              <td>N</td>
              <td>Manager Code</td>
              <td colSpan={3}><input type="text" className="form-control timesheet-form-control" value={employeeData.empid} disabled/></td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-success me-2 w-50" onClick={() => handleSubmit(1)}>Approve</button>
                <button type="button" className="btn btn-danger w-50" onClick={() => handleSubmit(0)}>Disapprove</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FloatingTimesheetApproval;
