import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlus, faShuffle, faRightLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const TaskManager = () => {
  const [tasks, setTasks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showEditTaskForm, setShowEditTaskForm] = useState(false);
  const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);
  const [showEditSubTaskForm, setShowEditSubTaskForm] = useState(false);
  const [showChangeGroupForm, setShowChangeGroupForm] = useState(false);

  const [mainTaskOptions, setMainTaskOptions] = useState([]);
  const [selectedMainTask, setSelectedMainTask] = useState(null);
  const [newMainTask, setNewMainTask] = useState('');
  const [oldMainTask, setOldMainTask] = useState('');
  
  const [subTaskOptions, setSubTaskOptions] = useState([]);
  const [selectedSubTask, setSelectedSubTask] = useState(null);
  const [newSubTaskName, setNewSubTaskName] = useState('');
  const [subTasks, setSubTasks] = useState([{ name: '', entries: false, period: false, billing: false, challan: false, remarks:false }]);

  const [changeGroupMainTask, setChangeGroupMainTask] = useState('');
  const [changeGroupSubTask, setChangeGroupSubTask] = useState('');
  const [newMainTaskForChangeGroup, setNewMainTaskForChangeGroup] = useState(null);
  const [subTaskToggles, setSubTaskToggles] = useState({
    entries: false,
    period: false,
    billing: false,
    challan: false,
    remarks: false
  });
  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getCompanytasks');
        setTasks(response.data);
        console.log(response.data);
        

        const uniqueMainTasks = [...new Set(response.data.map(task => task.MainTasks))];
        const options = uniqueMainTasks.map(task => ({ value: task, label: task }));
        setMainTaskOptions([...options]);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  const fetchSubTasks = async (mainTask) => {
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', {
        maintasks: mainTask,
      });
      const options = response.data.map(subtask => ({ value: subtask.SubTasks, label: subtask.SubTasks }));
      setSubTaskOptions(options);
    } catch (error) {
      console.error('Error fetching subtasks:', error);
    }
  };

  const handleEditTask = async () => {
    // Check if the old and new task names are the same (case-insensitive)
    console.log(oldMainTask)
    console.log(newMainTask)

    if (oldMainTask.value.trim().toLowerCase() === newMainTask.trim().toLowerCase()) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'New task name must be different from the old one',
        showConfirmButton: false,
        timer: 1500
      });
      return; // Stop execution if the names are the same
    }
  
    try {
      await axios.put('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateMainTask', {
        oldMainTask: oldMainTask,
        newMainTask: newMainTask
      });
  
      setShowEditTaskForm(false);
      setOldMainTask('');
      setNewMainTask('');
  
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getCompanytasks');
      setTasks(response.data);
  
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Main Task updated successfully',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error updating main task:', error);
    }
  };
  

  const handleEditSubTask = async () => {
    // Check if the old and new subtask names are the same (case-insensitive)
    const finalSubTaskName = newSubTaskName.trim() === '' 
    ? selectedSubTask.value 
    : newSubTaskName.trim();

    if (selectedSubTask.value.trim().toLowerCase() === newSubTaskName.trim().toLowerCase()) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'New subtask name must be different from the old one',
        showConfirmButton: false,
        timer: 1500,
      });
      return; // Stop execution if the names are the same
    }
  
    try {
      await axios.put('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/updateSubTask', {
        mainTask: selectedMainTask.value,         // Main Task value
        oldSubTask: selectedSubTask.value,        // Old Sub Task value
        newSubTask: finalSubTaskName,               // New Sub Task name
        entries: subTaskToggles.entries,          // Toggle: Entries
        period: subTaskToggles.period,            // Toggle: Period
        billing: subTaskToggles.billing,          // Toggle: Billing
        challan: subTaskToggles.challan, 
        remarks: subTaskToggles.remarks         // Toggle: Challan Amount
      });
  
      // Reset form state
      setShowEditSubTaskForm(false);
      setSelectedMainTask(null);
      setSelectedSubTask(null);
      setNewSubTaskName('');
      setSubTaskToggles({
        entries: false,
        period: false,
        billing: false,
        challan: false
      });
  
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getCompanytasks');
      setTasks(response.data);
  
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'SubTask updated successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error updating subtask:', error);
    }
  };  

  const handleCreateTask = async () => {
    try {
      // Check if selectedMainTask is 'new' and validate if the newMainTask already exists
      if (selectedMainTask?.value === 'new') {
        const mainTaskExists = mainTaskOptions.some(
          (option) => option.value.toLowerCase() === newMainTask.toLowerCase()
        );
  
        if (mainTaskExists) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'This main task already exists!',
          });
          return; // Exit the function if the task already exists
        }
      }
  
      // Check if there is at least one subtask with a name
      if (subTasks.length === 0 || subTasks.every(subTask => subTask.name === '')) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Please add at least one subtask!',
        });
        return;
      }
  
      // Transform subtask data for submission
      const transformedSubTasks = subTasks.map(subTask => ({
        name: subTask.name,
        entries: subTask.entries ? 1 : 0,
        period: subTask.period ? 1 : 0,
        billing: subTask.billing ? 1 : 0,
        challan: subTask.challan ? 1 : 0,
        remarks: subTask.remarks ? 1 : 0,
      }));
  
      const mainTaskToSubmit = selectedMainTask?.value === 'new' ? newMainTask : selectedMainTask?.value;
  
      // Make the API call to create the main task and subtasks
      await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/createMainTask', {
        mainTask: mainTaskToSubmit,
        subTasks: transformedSubTasks,
      });
  
      // Reset form and fetch updated tasks
      setShowCreateTaskForm(false);
      setSelectedMainTask(null);
      setNewMainTask('');
      setSubTasks([{ name: '', entries: false, period: false, billing: false, challan: false, remarks:false }]);
  
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getCompanytasks');
      setTasks(response.data);
  
      // Show success message
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Main Task and Subtasks created successfully',
        showConfirmButton: false,
        timer: 1500
      });
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };
  

  const handleChangeGroup = async () => {
    
    try {
      await axios.put('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/changeSubTaskGroup', {
        oldMainTask: changeGroupMainTask,
        subTask: changeGroupSubTask,
        newMainTask: newMainTaskForChangeGroup.value,
      });

      setShowChangeGroupForm(false);
      setChangeGroupMainTask('');
      setChangeGroupSubTask('');
      setNewMainTaskForChangeGroup(null);

      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getCompanytasks');
      setTasks(response.data);

      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'SubTask group updated successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error('Error updating subtask group:', error);
    }
  };

  const handleAddSubtask = () => {
    setSubTasks([...subTasks, { name: '', entries: false, period: false, billing: false, challan: false, remarks:false }]);
  };

  const handleRemoveSubtask = (index) => {
    const updatedSubTasks = subTasks.filter((_, i) => i !== index);
    setSubTasks(updatedSubTasks);
  };

  const handleSubtaskChange = (index, field, value) => {
    const updatedSubTasks = subTasks.map((subTask, i) =>
      i === index ? { ...subTask, [field]: value } : subTask
    );
    setSubTasks(updatedSubTasks);
  };

  const filteredTasks = tasks.filter(task =>
    task.MainTasks.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.SubTasks.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const data = React.useMemo(() => filteredTasks, [filteredTasks]);

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="d-flex justify-content-between">
            <span>Main Task</span>
            <span>Rename</span>
          </div>
        ),
        accessor: 'MainTasks',
        Cell: ({ row }) => (
          <div className="d-flex justify-content-between align-items-center">
            <span>{row.original.MainTasks}</span>
            <button
              className="btn btn-warning btn-sm"
              onClick={() => handleEditMainTaskFromTable(row.original.MainTasks)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        ),
      },
      {
        Header: () => (
          <div className="d-flex justify-content-between">
            <span>Sub Task</span>
            <span>Rename</span>
          </div>
        ),
        accessor: 'SubTasks',
        Cell: ({ row }) => (
          <div className="d-flex align-items-center justify-content-between">
            <span>{row.original.SubTasks}</span>
            <button
              className="btn btn-warning btn-sm ms-3"
              onClick={() => handleEditSubTaskFromTable(
                row.original.MainTasks,        // Main Task
                row.original.SubTasks,         // Sub Task
                row.original.NumberOfEntries,          // Toggle: Entries
                row.original.from,           // Toggle: Period
                row.original.billing,          // Toggle: Billing
                row.original['Challan Amount'],           // Toggle: Challan
                row.original.remarks
              )}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </div>
        ),
      },
      {
        Header: 'Group',
        accessor: 'changeGroup',
        Cell: ({ row }) => (
            <FontAwesomeIcon className="btn btn-primary" icon={faRightLeft} onClick={() => handleChangeGroupFromTable(row.original.MainTasks, row.original.SubTasks)}/>
        ),
      },
      {
        Header: 'Entries',
        accessor: 'NumberOfEntries',
        Cell: ({ value }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`entries-switch-${value}`}
              checked={value === 1}
              disabled
            />
          </div>
        ),
      },
      {
        Header: 'Period',
        accessor: 'from',
        Cell: ({ value }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`period-switch-${value}`}
              checked={value === 1}
              disabled
            />
          </div>
        ),
      },
      {
        Header: 'Billing',
        accessor: 'billing',
        Cell: ({ value }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`period-switch-${value}`}
              checked={value === 1}
              disabled
            />
          </div>
        ),
      },
      {
        Header: 'Challan',
        accessor: 'Challan Amount',
        Cell: ({ value }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`period-switch-${value}`}
              checked={value === 1}
              disabled
            />
          </div>
        ),
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
        Cell: ({ value }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id={`period-switch-${value}`}
              checked={value === 1}
              disabled
            />
          </div>
        ),
      },
      
    ],
    []
  );
  const handleEditMainTaskFromTable = (mainTask) => {
    setShowEditSubTaskForm(false)
    setOldMainTask({ value: mainTask, label: mainTask });
    setShowEditTaskForm(true); // Show the Edit Main Task form
    setNewMainTask(''); // Clear the input field
    setShowCreateTaskForm(false)
    setShowChangeGroupForm(false)
  };

  const handleEditSubTaskFromTable = (mainTask, subTask, entries, period, billing, challanAmount, remarks) => {
    setShowEditTaskForm(false)
    setSelectedMainTask({ value: mainTask, label: mainTask }); // Set previous main task
    setSelectedSubTask({ value: subTask, label: subTask });
    setSubTaskToggles({
      entries: entries || false,
      period: period || false,
      billing: billing || false,
      challan: challanAmount || false,
      remarks: remarks || false
    });    // Set previous subtask
    setShowEditSubTaskForm(true);                              // Show Edit SubTask form
    fetchSubTasks(mainTask);                                   // Fetch SubTasks for selected Main Task
    setNewSubTaskName('');     
    setShowCreateTaskForm(false)
    setShowChangeGroupForm(false)                                // Clear new subtask name input
  };

  const handleChangeGroupFromTable = (mainTask, subTask) => {
    setShowEditTaskForm(false);
    setShowEditSubTaskForm(false);
    setShowCreateTaskForm(false);
    
    setChangeGroupMainTask(mainTask); // Set the current Main Task
    setChangeGroupSubTask(subTask);   // Set the current Sub Task
    setShowChangeGroupForm(true);     // Show Change Group form
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Task Manager</h2>

      <div className="mb-3">

        <button
          className="btn btn-success me-3"
          onClick={() => {
            setShowCreateTaskForm(!showCreateTaskForm);
            if (showEditTaskForm) setShowEditTaskForm(false);
            if (showEditSubTaskForm) setShowEditSubTaskForm(false);
            setShowChangeGroupForm(false);
          }}
        >
          Create Task
        </button>
      </div>

      {showEditTaskForm && (
  <div className="mb-3">
    <Select
      className="mb-2"
      options={mainTaskOptions}
      value={oldMainTask} // Prefilled with the old value
      isDisabled={true} // Disable the Select input
      placeholder="Select Main Task"
      onChange={(selected) => {
        setOldMainTask(selected);
      }}
    />
    <input
      type="text"
      className="form-control mb-2"
      placeholder="Enter New Main Task Name"
      value={newMainTask}
      onChange={(e) => setNewMainTask(e.target.value)}
    />
    <button className="btn btn-success" onClick={handleEditTask}>
      Update
    </button>
    <button
      className="btn btn-secondary ms-2"
      onClick={() => setShowEditTaskForm(false)}
    >
      Cancel
    </button>
  </div>
)}


{showCreateTaskForm && (
  <div className="mb-3">
    <Select
      className='mb-2'
      options={[{ value: 'new', label: 'New' }, ...mainTaskOptions]}  // Prepend 'New' option
      onChange={(selected) => setSelectedMainTask(selected)}
      placeholder="Select Main Task"
    />
    {selectedMainTask?.value === 'new' && (
      <input
        type="text"
        className="form-control mb-2"
        placeholder="Enter New Main Task"
        value={newMainTask}
        onChange={(e) => setNewMainTask(e.target.value)}
      />
    )}
    <div>
      {subTasks.map((subTask, index) => (
        <div key={index} className="input-group mb-2">
          <input
            type="text"
            className="form-control"
            placeholder="Enter SubTask Name"
            value={subTask.name}
            onChange={(e) => handleSubtaskChange(index, 'name', e.target.value)}
          />
          <div className="input-group-text">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={subTask.entries}
                onChange={(e) => handleSubtaskChange(index, 'entries', e.target.checked)}
              />
              <label className="form-check-label">Entries</label>
            </div>
          </div>
          <div className="input-group-text">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={subTask.period}
                onChange={(e) => handleSubtaskChange(index, 'period', e.target.checked)}
              />
              <label className="form-check-label">Period</label>
            </div>
          </div>
          <div className="input-group-text">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={subTask.billing}
                onChange={(e) => handleSubtaskChange(index, 'billing', e.target.checked)}
              />
              <label className="form-check-label">Billing</label>
            </div>
          </div>
          <div className="input-group-text">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={subTask.challan}
                onChange={(e) => handleSubtaskChange(index, 'challan', e.target.checked)}
              />
              <label className="form-check-label">Challan</label>
            </div>
          </div>
          <div className="input-group-text">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={subTask.remarks}
                onChange={(e) => handleSubtaskChange(index, 'remarks', e.target.checked)}
              />
              <label className="form-check-label">Remarks</label>
            </div>
          </div>

          {/* Conditionally render the delete button only if there is more than one subtask */}
          {subTasks.length > 1 && (
            <button
              className="btn btn-danger"
              onClick={() => handleRemoveSubtask(index)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}

          <button
            className="btn btn-outline-primary"
            onClick={handleAddSubtask}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      ))}
    </div>
    <button className="btn btn-success" onClick={handleCreateTask}>
      Create
    </button>
    <button
      className="btn btn-secondary ms-2"
      onClick={() => setShowCreateTaskForm(false)}
    >
      Cancel
    </button>
  </div>
)}

{/* Edit SubTask Form */}
{showEditSubTaskForm && (
  <div>
    {/* Main Task Select (disabled) */}
    <Select
      className='mb-2'
      options={mainTaskOptions}
      onChange={(selected) => {
        setSelectedMainTask(selected);
        fetchSubTasks(selected.value); // Fetch subtasks for the selected main task
      }}
      value={selectedMainTask}
      placeholder="Select Main Task"
      isDisabled={true} // This will disable the Main Task select
    />

    {/* Sub Task Select */}
    <Select
      className='mb-2'
      options={subTaskOptions}
      onChange={selected => setSelectedSubTask(selected)}
      value={selectedSubTask}
      placeholder="Select Sub Task"
      isDisabled={true} // This will disable the Sub Task select
    />

<div className="d-flex align-items-center mb-2">
  {/* Input for New Sub Task Name */}
  <input
    type="text"
    className="form-control me-3"
    placeholder="Enter New Sub Task Name"
    value={newSubTaskName}
    onChange={e => setNewSubTaskName(e.target.value)}
    style={{ flex: 1 }} // This allows the input field to take available space
  />

  {/* Toggles Container */}
  <div className="d-flex align-items-center">
    {/* Toggle for Entries */}
    <div className="form-check form-switch me-2">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={subTaskToggles.entries}
        onChange={(e) =>
          setSubTaskToggles(prevState => ({
            ...prevState,
            entries: e.target.checked
          }))
        }
      />
      <label className="form-check-label">Entries</label>
    </div>

    {/* Toggle for Period */}
    <div className="form-check form-switch me-2">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={subTaskToggles.period}
        onChange={(e) =>
          setSubTaskToggles(prevState => ({
            ...prevState,
            period: e.target.checked
          }))
        }
      />
      <label className="form-check-label">Period</label>
    </div>

    {/* Toggle for Billing */}
    <div className="form-check form-switch me-2">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={subTaskToggles.billing}
        onChange={(e) =>
          setSubTaskToggles(prevState => ({
            ...prevState,
            billing: e.target.checked
          }))
        }
      />
      <label className="form-check-label">Billing</label>
    </div>

    {/* Toggle for Challan */}
    <div className="form-check form-switch me-2">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={subTaskToggles.challan}
        onChange={(e) =>
          setSubTaskToggles(prevState => ({
            ...prevState,
            challan: e.target.checked
          }))
        }
      />
      <label className="form-check-label">Challan</label>
    </div>

    {/* Toggle for Remarks */}
    <div className="form-check form-switch me-2">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        checked={subTaskToggles.remarks}
        onChange={(e) =>
          setSubTaskToggles(prevState => ({
            ...prevState,
            remarks: e.target.checked
          }))
        }
      />
      <label className="form-check-label">Remarks</label>
    </div>
  </div>
</div>


    {/* Update Button */}
    <button className="btn btn-success mb-2" onClick={handleEditSubTask}>
      Update
    </button>

    {/* Cancel Button */}
    <button
      className="btn btn-secondary ms-2 mb-2"
      onClick={() => setShowEditSubTaskForm(false)}
    >
      Cancel
    </button>
  </div>
)}




      {/* Change Group Form */}
      {showChangeGroupForm && (
  <div className="mb-4">
    <h4>Change Group</h4>
    <div className="mb-2">
      <input
        type="text"
        className="form-control mb-2"
        value={changeGroupMainTask}
        readOnly
        placeholder="Current Main Task"
      />
      <input
        type="text"
        className="form-control mb-2"
        value={changeGroupSubTask}
        readOnly
        placeholder="Current Sub Task"
      />
      <Select
        options={mainTaskOptions.filter(
          (option) => option.value.toLowerCase() !== changeGroupMainTask.toLowerCase()
        )}
        value={newMainTaskForChangeGroup}
        onChange={(selected) => setNewMainTaskForChangeGroup(selected)}
        placeholder="Select New Main Task"
      />
    </div>
    <button className="btn btn-success" onClick={handleChangeGroup}>
      Update
    </button>
    <button
      className="btn btn-secondary ms-2"
      onClick={() => setShowChangeGroupForm(false)}
    >
      Cancel
    </button>
  </div>
)}


      {/* Search Input */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Tasks Table */}
      <table className="table table-bordered" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TaskManager;
