import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Stack from 'rsuite/Stack'; 
import DateRangePickerComponent from './CustomDateRangePicker'
const Swal = require('sweetalert2');

const MyCompletedTask = () => {
    const [dateRange, setDateRange] = useState([null, null]); // State for date range selection
    const [tasks, setTasks] = useState([]);
    const [clientNames, setClientNames] = useState({});
    const [timesheetReportingDates, setTimesheetReportingDates] = useState([]); 
    const userEmail = sessionStorage.getItem('email');
    const navigate = useNavigate();

    const fetchTasks = async () => {
        try {
            const [startDate, endDate] = dateRange; // Destructure start and end dates from dateRange
            if (!startDate || !endDate) {
                toast.error("Please select a valid date range");
                return;
            }

            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/completedDayTasksByUser', {
                email: userEmail,
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
            });

            const tasks = response.data;
            setTasks(tasks);

            const clientNamesTemp = {};
            for (const task of tasks) {
                const tradeName = await getClient(task.CC);
                clientNamesTemp[task.CC] = tradeName;
            }

            setClientNames(clientNamesTemp);
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    };

    const fetchTimesheetReportingDates = async () => {
        try {
            const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/timeheetDateforvalidation', {
                email: userEmail,
            });
    
            const reportingDates = response.data; // Expecting an array of objects with timesheetDate property
            const formattedReportingDates = reportingDates.map(item => {
                const date = item.timesheetDate;
                const parsedDate = moment(date);
                return parsedDate.isValid() ? parsedDate.format('DD-MM-YYYY') : null;
            });
    
            setTimesheetReportingDates(formattedReportingDates); 
        } catch (error) {
            console.error("Error fetching timesheet reporting dates:", error);
        }
    };

    const getClient = async (client) => {
        try {
            const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getClientByCode', {
                params: { client },
            });
            return response.data.length > 0 ? response.data[0].TradeName : 'Client not found';
        } catch (error) {
            console.error('Error fetching client:', error);
            return 'Error fetching client';
        }
    };

    const handleDateRangeChange = (value) => {
        setDateRange(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        fetchTasks();
        fetchTimesheetReportingDates(); 
    };

    const TimeDisplay = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    };

    const groupTasks = (tasks) => {
        const groupedTasks = {};

        tasks.forEach((task) => {
            const key = task.mid;

            if (!groupedTasks[key]) {
                groupedTasks[key] = {
                    mid: task.mid,
                    reportingDate: task.reportingDate,
                    mainTask: task.mainTask[0],
                    clientCode: task.CC,
                    subTasks: [],
                    remarks: [],
                    taskTimeInMinutes: task.taskTimeInMinutes,
                    travelTime: task.travelTime,
                    taskPeriods: [],
                    entries: [],
                };
            }

            groupedTasks[key].subTasks.push(task.subTask);
            groupedTasks[key].remarks.push(task.remarks);
            groupedTasks[key].entries.push(task.NumberOfEntries);
            const taskFromDate = moment(task.taskfrom);
            const taskToDate = moment(task.taskto);

            groupedTasks[key].taskPeriods.push(
                taskFromDate.isValid() && taskFromDate.year() >= 2000
                    ? `${taskFromDate.format('DD-MMM-YY')} - ${taskToDate.format('DD-MMM-YY')}`
                    : 'No Period'
            );
        });

        return Object.values(groupedTasks).sort((a, b) => {
            return moment(b.reportingDate).diff(moment(a.reportingDate));
        });
    };

    const handleEdit = (taskid) => {
        navigate('../editDailyWorking', { state: { taskid } });
    };

    const truncateRemarks = (remarks, maxLength = 10) => {
        const fullRemarks = remarks.join(' '); 
        return fullRemarks.length > maxLength ? `${fullRemarks.substring(0, maxLength)}...` : fullRemarks;
    };  

    const handleDelete = async (taskId) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to delete this task? This action cannot be undone!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/deletedwrtask', { id: taskId });
                    if (response.status === 200) {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'success',
                            text: response.data.message,
                            showConfirmButton: false,
                            timer: 1000,
                        }).then(() => {
                            fetchTasks()
                        });
                    } else {
                        Swal.fire({
                            toast: true,
                            position: 'top-end',
                            icon: 'info',
                            text: response.data,
                            showConfirmButton: false,
                            timer: 3000,
                        });
                    }
                }
            });
        } catch (error) {
            toast.error('Error deleting task:', error);
        }
    };

    const groupedTasks = groupTasks(tasks);

    return (
        <div className="container">
            <h2 className="mt-5">Daily Task Report</h2>
            <form onSubmit={handleSubmit}>
            <div className="row">
    <div className="col-md-3">
        <label>Date Range:</label>
        {/* <Stack direction="column" spacing={8} alignItems="flex-start"> */}
            <DateRangePickerComponent value={dateRange} onChange={setDateRange} />
        {/* </Stack> */}
    </div>
    <div className="col-md-4 d-flex align-items-end">
        <button type="submit" className="btn btn-success">
            Get Tasks
        </button>
    </div>
</div>

            </form>

            <div className="mt-4">
                {groupedTasks.length > 0 ? (
                    <table className="table table-striped mt-4">
                        <thead style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
                            <tr>
                                <th>Date</th>
                                <th>Client</th>
                                <th>Main Task Name</th>
                                <th>Sub Task </th>
                                <th>Task Period</th>
                                <th>Enteries</th>
                                <th>Task Time</th>
                                <th>Travel Time</th>
                                <th>Remarks</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px', verticalAlign: 'baseline' }}>
                            {groupedTasks.map((task, index) => {
                                const taskReportingDate = moment(task.reportingDate[0]).format('DD-MM-YYYY');
                                const isEditable = !timesheetReportingDates.some(date => {
                                    const formattedDate = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');
                                    return formattedDate === taskReportingDate;
                                });

                                return (
                                    <tr key={index}>
                                        <td>{moment(task.reportingDate[0]).format('DD-MMM-YYYY')}</td>
                                        <td>{clientNames[task.clientCode] || 'Loading...'}</td>
                                        <td>{task.mainTask}</td>
                                        <td>{task.subTasks.join(',')}</td>
                                        <td>{task.taskPeriods.join(',')}</td>
                                        <td>{task.entries.join(',')}</td>
                                        <td>{TimeDisplay(task.taskTimeInMinutes)}</td>
                                        <td>{task.travelTime}</td>
                                        <td>
                                            <span data-bs-toggle="tooltip" title={task.remarks.join(' ')} className="d-inline-block">
                                                {truncateRemarks(task.remarks)}
                                            </span>
                                        </td>
                                        <td>
                                            {isEditable ? (
                                                <>
                                                    <button className="btn btn-success btn-sm" onClick={() => handleEdit(task.mid)}><FontAwesomeIcon icon={faEdit} /></button>
                                                    <button className="btn btn-danger btn-sm" onClick={() => handleDelete(task.mid)}><FontAwesomeIcon icon={faTrash} /></button>
                                                </>
                                            ) : null}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <p>No tasks found for the selected date range.</p>
                )}
            </div>
        </div>
    );
};

export default MyCompletedTask;
