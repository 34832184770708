import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import Select from 'react-select';
import { useTable, useSortBy, usePagination } from 'react-table'; // Added usePagination
import * as XLSX from 'xlsx'; // Import XLSX library

const TaskOverview = () => {
  const [tasks, setTasks] = useState([]); // Main tasks
  const [mainTask, setMainTask] = useState(''); // Selected main task
  const [subTasks, setSubTasks] = useState([]); // Subtasks
  const [selectedSubTasks, setSelectedSubTasks] = useState([]); // Selected subtasks (array)
  const [clients, setClients] = useState([]); // Client data
  const [responsibleMembers, setResponsibleMembers] = useState({});
  const [loading, setLoading] = useState(true);
  const [taskToData, setTaskToData] = useState([]); // Task-to values
  const [searchTerm, setSearchTerm] = useState(''); // Search term

  // Fetch Main Tasks
  useEffect(() => {
    const fetchMainTasks = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getmaintasks');
        setTasks(response.data);
      } catch (error) {
        console.error('Failed to fetch tasks');
      }
    };

    fetchMainTasks();
  }, []);

  // Fetch Subtasks for selected main task
  const fetchSubTasks = async (selectedMainTask) => {
    if (!selectedMainTask) return;
    try {
      const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/getsubtasks', {
        maintasks: selectedMainTask,
      });
      setSubTasks(response.data);
      setSelectedSubTasks([]); // Reset selected subtasks when main task changes
    } catch (error) {
      Swal.fire('Error', 'There was an error fetching subtasks!', 'error');
    }
  };

  // Fetch Clients and their responsible members
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/clients');
        setClients(response.data);

        const clientCCs = response.data.map((client) => client.CC);
        const responsibleData = await fetchResponsibleMembers(clientCCs);
        setResponsibleMembers(responsibleData);
      } catch (error) {
        Swal.fire('Error', 'There was an error fetching clients!', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  const fetchResponsibleMembers = async (clientCCs) => {
    const responsibleMap = {};
    for (const CC of clientCCs) {
      try {
        const response = await axios.post('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/fetchresponsibemember', { CC });
        responsibleMap[CC] = response.data.responsibleMember?.firstname || 'N/A';
      } catch (error) {
        console.error(`Error fetching responsible member for ${CC}:`, error);
        responsibleMap[CC] = 'N/A'; // In case of error, set 'N/A'
      }
    }
    return responsibleMap;
  };

  // Function to fetch TaskTo values
  const fetchTaskToValues = async () => {
    try {
      const response = await axios.get('https://timesheet-server-d5fueghjbebyd9fe.centralindia-01.azurewebsites.net/latest-taskto');
      if (response.data && Array.isArray(response.data)) {
        setTaskToData(response.data);
        console.log(response.data); // Log the fetched data to verify
      } else {
        console.warn('Unexpected data format:', response.data);
      }
    } catch (error) {
      Swal.fire('Error', 'There was an error fetching taskto values!', 'error');
    }
  };

  // Fetch TaskTo values when component loads
  useEffect(() => {
    fetchTaskToValues();
  }, []);

  // Combining client data with taskToData
  const dataWithTaskTo = useMemo(() => {
    if (!clients || !taskToData) return [];

    return clients.map(client => {
      const clientData = { ...client };
      taskToData.forEach(task => {
        if (client.CC === task.CC && task.subTask) {
          clientData[task.subTask] = task.taskto !== '1900-01-00' ? task.taskto : 'No Date Assigned';
        }
      });
      return clientData;
    });
  }, [clients, taskToData]);

  // Define columns for table
  const defaultColumns = useMemo(() => {
    const columns = [
      {
        Header: '#',
        Cell: ({ row }) => row.index + 1, // Start numbering from 1
      },
      { 
        Header: 'Responsible', 
        accessor: 'Responsible', 
        Cell: ({ row }) => responsibleMembers[row.original.CC] || 'N/A',
      },
      { Header: 'P', accessor: 'Priority' },
      { Header: 'CC', accessor: 'CC' },
      { Header: 'Trade Name', accessor: 'TradeName' },
      { Header: 'L', accessor: 'levelOfBOA' },
      { Header: 'R', accessor: 'reportingFrequency' },
    ];

    // Add selected subtasks as columns if selected
    if (selectedSubTasks.length > 0) {
      selectedSubTasks.forEach(subTask => {
        columns.push({
          Header: subTask.label, // Subtask label becomes column header
          accessor: subTask.value, // Subtask value
          Cell: ({ row }) => {
            const dateValue = row.original[subTask.value];
            if (!dateValue || dateValue === 'N/A') return 'N/A';

            // Use moment to format the date as DD-MMM-YY (e.g., 23-Oct-24)
            const formattedDate = moment(dateValue).isValid()
              ? moment(dateValue).format('DD-MMM-YY')
              : 'N/A';

            return formattedDate;
          }
        });
      });
    }

    return columns;
  }, [responsibleMembers, selectedSubTasks]);

  // Search Functionality
  const filteredData = useMemo(() => {
    return dataWithTaskTo.filter(client =>
      client.TradeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.CC.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (client.Priority && client.Priority.toString().includes(searchTerm)) ||
      (client.levelOfBOA && client.levelOfBOA.toString().includes(searchTerm))
    );
  }, [dataWithTaskTo, searchTerm]);

  // React Table Instance with Pagination
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of rows, we use page for paginated rows
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { 
      columns: defaultColumns, 
      data: filteredData, // Pass the filtered data
      initialState: { pageIndex: 0, pageSize: 10 }, // Set initial page index and size
    }, 
    useSortBy, // Add sorting functionality
    usePagination // Add pagination functionality
  );

const exportToExcel = () => {
  // Prepare Main Task and Sub Task data
  const selectedMainTaskValue = mainTask ? mainTask : 'No Main Task Selected';
  const selectedSubTaskValues =
    selectedSubTasks.length > 0
      ? selectedSubTasks.map((subTask) => subTask.label).join(', ')
      : 'No Sub Tasks Selected';

  // Top section with Field-Value pairs
  const topSection = [
    ['Main Task', selectedMainTaskValue],
    ['Sub Tasks', selectedSubTaskValues],
    [], // Blank row for spacing before table data
  ];

  // Prepare table data for the second section
  const tableHeaders = [
    '#',
    'Responsible',
    'P',
    'CC',
    'Trade Name',
    'L',
    'R',
    ...selectedSubTasks.map((subTask) => subTask.label), // Add dynamic subtask headers
  ];

  const tableData = filteredData.map((row, index) => {
    const tableRow = [
      index + 1, // Row number
      responsibleMembers[row.CC] || 'N/A',
      row.Priority || 'N/A',
      row.CC,
      row.TradeName || 'N/A',
      row.levelOfBOA || 'N/A',
      row.reportingFrequency || 'N/A',
      ...selectedSubTasks.map((subTask) => {
        const taskDate = row[subTask.value];
        return moment(taskDate).isValid()
          ? moment(taskDate).format('DD-MMM-YY')
          : taskDate || 'N/A';
      }),
    ];
    return tableRow;
  });

  // Combine sections for export
  const dataToExport = [...topSection, tableHeaders, ...tableData];

  // Create a worksheet and workbook
  const worksheet = XLSX.utils.aoa_to_sheet(dataToExport); // Use aoa_to_sheet for array-of-arrays
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Task Overview');

  // Export the file
  XLSX.writeFile(workbook, 'TaskOverview.xlsx');
};


  return (
    <div className="container mt-5">
      <h2 className="mb-2">Task Overview</h2>

      {/* Main Task Select */}
      <div className="mb-3">
        <label htmlFor="mainTaskSelect" className="form-label">Main Task</label>
        <Select
          id="mainTaskSelect"
          value={mainTask ? { value: mainTask, label: mainTask } : null}
          onChange={(option) => {
            const selectedMainTask = option?.value || '';
            setMainTask(selectedMainTask);
            fetchSubTasks(selectedMainTask); // Fetch subtasks when a main task is selected
          }}
          options={tasks.map(task => ({ value: task.MainTasks, label: task.MainTasks }))}
          placeholder="Select Main Task"
        />
      </div>

      {/* Sub Task Multi-Select */}
      <div className="mb-3">
        <label htmlFor="subTaskSelect" className="form-label">Sub Task</label>
        <Select
          id="subTaskSelect"
          value={selectedSubTasks}
          onChange={(selectedOptions) => setSelectedSubTasks(selectedOptions || [])}
          options={subTasks.map(subTask => ({ value: subTask.SubTasks, label: subTask.SubTasks }))}
          isDisabled={!mainTask}
          isMulti
          placeholder="Select Subtasks"
        />
      </div>
      <div className="mb-3">
        <button className="btn btn-success" onClick={exportToExcel}>
          Export to Excel
        </button>
      </div>
      {/* Search Bar */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by Trade Name, CC, Priority..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Client Data Table */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <table className="table table-striped table-hover" {...getTableProps()} style={{ fontSize: '13px', verticalAlign: 'baseline' }}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {/* Add a sort indicator */}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <div>
              <button
                className="btn btn-secondary me-2"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
            </div>
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <select
              className="form-select w-auto"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};

export default TaskOverview;
